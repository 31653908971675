
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"
import { pad } from "../bk utils/_bk utils.js";
import { COOKIES } from "../gw main/cookies.js";
import { AUTH, FIRESTORE } from "./firebase.js"
import { GW_MAIN } from "../gw main/_gw main.js"
import { LOADING } from "./loading.js"
import { SAVING } from "./saving.js"


const DEVELOP = {lang: "", isShowEveryPoint: false, isShowWhyPointExcluded: false, isShowEveryDayFaith: false};

const COOKIE_NAME = {lang: "gwUserLang", data: "gwUserData"};

const GAME_GRACE          = {a: [80, 90, 100],      // according to difficultyI
                             b: [ 5, 10,  20]},
      TIME_BETWEEN_DAYS   = 6 * 60 * 60 * 1000,     // time (ms) that must pass between the user gained GRACE last day and gained GRACE the next day
      TIME_UPDATE         = 1000,                   // update a cookie or user state
      PASSWORD_LENGTH_MIN = 6,
      NAME                = {lengthMax: 100, timeUpdate: 30 * 24 * 60 * 60},    // lenghtMax: the same like in ui and on the server
      CONTACT             = {lengthMax: 100, timeUpdate: 30 * 24 * 60 * 60},    // timeUpdate: seconds until next possible update, must be the same like on the server!
      COUNTRY_TAGS        = ["af","al","dz","as","ad","ao","aq","ag","ar","am","aw","au","at","az","bs","bh","bd","bb","by","be","bz","bj","bm","bt","bo","ba","bw","bv","br","io","bn","bg","bf","bi","kh","cm","ca","cv","ky","cf","td","cl","cn","cx","cc","co","km","cg","cd","ck","cr","ci","hr","cu","cy","cz","dk","dj","dm","do","ec","eg","sv","gq","er","ee","et","fk","fo","fj","fi","fr","gf","pf","tf","ga","gm","ge","de","gh","gi","gr","gl","gd","gp","gu","gt","gn","gw","gy","ht","hm","hn","hk","hu","is","in","id","ir","iq","ie","il","it","jm","jp","jo","kz","ke","ki","kp","kr","kw","kg","la","lv","lb","ls","lr","ly","li","lt","lu","mo","mk","mg","mw","my","mv","ml","mt","mh","mq","mr","mu","yt","mx","fm","md","mc","mn","me","ms","ma","mz","mm","na","nr","np","nl","an","nc","nz","ni","ne","ng","nu","nf","mp","no","om","pk","pw","ps","pa","pg","py","pe","ph","pn","pl","pt","pr","qa","re","ro","ru","rw","sh","kn","lc","pm","vc","ws","sm","st","sa","sn","rs","sc","sl","sg","sk","si","sb","so","za","gs","ss","es","lk","sd","sr","sj","sz","se","ch","sy","tw","tj","tz","th","tl","tg","tk","to","tt","tn","tr","tm","tc","tv","ug","ua","ae","gb","us","um","uy","uz","vu","ve","vn","vg","vi","wf","eh","ye","zm","zw"],
      RELIG               = ["c","o","b","a"],      // christian, other religion, beliver in God but no religion, atheist
      RELIG_DENOM         = ["c","p","o","t"];      // denomination: catholic, protestant, orthodox, other


class Points {
    constructor(date) {
        this.gameCode    = "";
        this.date        = date;
        this.dayUTC      = Math.floor(date.valueOf() / (1000 * 60 * 60 * 24));      // UTC day from 1.1.1970, useful for some calculations
        this.bookI       = 0;
        this.chapterI    = 0;
        this.difficultyI = 0;
        this.grace       = 0;       // GRACE for this game (check if maybe already discovered or time out...)
    }
}

class aDay {
    constructor(points, grace) {
        this.points     = points;
        this.dayUTC     = points[0].dayUTC;
        this.grace      = Math.min(grace, 100);             // total grace for that day
        this.faith      = (this.grace == 100) ? 1 : 0;      // faith for that day (0 or 1)
        this.faithTotal = 0;                                // faith total at that day
    }
}

class GWuser extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.auth          = AUTH;
        this.firestore     = FIRESTORE;

        this.item          = null;

        this._name         = "";
        this._lang         = "";
        this._country      = "";
        this._denom        = "c";       // denomination: c: catholic, p; protestant, (o: orthodox - not implemented)
        this._relig        = "";
        this._religDenom   = "";
        this._contact      = "";
        this._dateFormat   = "dmy";     // 3 possibilites: dmy, mdy, ymd
        this.authorization = {name: false, lang: false, country: false, relig: false, religDenom: false, faith: false, contact: false};     // the order can't be changed!

        this.nameTime      = 0;         // seconds passed after they were updated for the last time
        this.contactTime   = 0;

        this.points        = [];        // all points gained (can be more than one a day)
        this.days          = [];        // all days played
        this.faith         = {total: 0, max: 0};

        this.timer         = 0;
        this.lastSession   = {lang: "", date: {year: 0, month: 0, day: 0, hours: 0, minutes: 0}};

        this.isLangChanged = false;     // these 2: call from outside to take action, if true
        this.isVersionSet  = false;
        this.isInitiated   = false;
    }

    get address() {
        return this.isWallet ? AUTH.user.id : this.email;
    }

    get addressShort() {
        return this.isWallet ? this.address.substring(0, 5) + "..." + this.address.substring(this.address.length - 4) : this.address;
    }

    get email() {
        return this.isLoggedIn ? AUTH.auth.currentUser.email : "";
    }

    get id() {
        return AUTH.user.id;
    }

    get isNewUser() {
        return COOKIES.isNewUser;
    }

    get isDataLoading() {
        return LOADING.isDataLoading;
    }

    get isLoaded() {
        return LOADING.isAllLoaded;
    }

    get isLoggedIn() {
        return AUTH.user.id.length > 0;
    }

    get isVerified() {
        if (!this.isLoggedIn)
            return false;
        // if (this.DATABASE.auth == null || this.DATABASE.auth.currentUser == null)
        //     return false;

        if (this.isWallet)
            return true;

        if (!AUTH.auth.currentUser.emailVerified)
            AUTH.auth.currentUser.reload();

        return AUTH.auth.currentUser.emailVerified;
    }

    get isWallet() {
        return AUTH.user.id.indexOf("0x") == 0;     // not sure, if that's enough
    }

    set contact(value) {
        let val = value.substring(0, CONTACT.lengthMax);

        if (val != this._contact) {
            this._contact    = val;
            this.contactTime = Math.floor(BK_ENGINE.timer.now / 1000);

            SAVING.add("contact", val);
        }
    }

    get contact() {
        return this._contact;
    }

    set country(value) {
        if (value != this._country && COUNTRY_TAGS.includes(value)) {
            this._country = value;

            SAVING.add("country", value);
            console.log("country set to: " + value);
        }
    }

    get country() {
        return this._country;
    }

    set dateFormat(value) {
        if (value != this._dateFormat) {
            this._dateFormat = value;

            SAVING.add("dateFormat", value);
            console.log("Date format set to: " + value);
        }
    }

    get dateFormat() {
        return this._dateFormat;
    }

    set denom(value) {
        if (value != this._denom) {
            this._denom = value;

            SAVING.add("denom", value);
            this.printDenom();
        }
    }

    get denom() {
        return this._denom;
    }

    set lang(value) {
        if (value != this._lang) {
            this._lang = value;

            SAVING.add("lang", value);
        }
    }

    get lang() {
        return this._lang;
    }

    set name(value) {
        let val = value.substring(0, NAME.lengthMax);

        if (val != this._name) {
            this._name    = val;
            this.nameTime = Math.floor(BK_ENGINE.timer.now / 1000);

            SAVING.add("name", val);
        }
    }

    get name() {
        return this._name;
    }

    get nameOrAddress() {
        return this.name.length > 0 ? this._name : this.addressShort;
    }

    set relig(value) {
        if (value != this._relig) {
            this._relig = value;

            SAVING.add("relig", value);
        }
    }

    get relig() {
        return this._relig;
    }

    set religDenom(value) {
        if (value != this._religDenom) {
            this._religDenom = value;

            SAVING.add("religDenom", value);
        }
    }

    get religDenom() {
        return this._religDenom;
    }

    get passwordLengthMin() {
        return PASSWORD_LENGTH_MIN;
    }

    get progress() {
        return LOADING.progress == null ? [] : LOADING.progress;
    }

    get graceToday() {
        if (this.days.length == 0)
            return 0;

        let lastDay = this.days[this.days.length - 1];

        if (this.getDayUTCfromDate(new Date()) == lastDay.dayUTC)
            return lastDay.grace;

        return 0;
    }

    get timeRemaining() {
        let date        = this.getDate();
        let minutesLeft = 24 * 60 - (date.hours * 60 + date.minutes);

        return {hours: Math.floor(minutesLeft / 60), minutes: minutesLeft % 60};
    }

    get lastSessionDate() {
        let s = this.lastSession.date;

        return new Date(s.year, s.month, s.day, s.hours, s.minutes);
    }

    getDate() {     // Coordinated Universal Time
        let d = new Date();

        return {year: d.getUTCFullYear(), month: d.getUTCMonth(), day: d.getUTCDate(), hours: d.getUTCHours(), minutes: d.getUTCMinutes()};
    }

    getDayUTCfromDate(date) {
        return Math.floor(date.valueOf() / (1000 * 60 * 60 * 24));
    }

    getDateFromDayUTC(dayUTC) {
        let date = new Date();

        date.setTime(dayUTC * 24 * 60 * 60 * 1000);

        return date;
    }

    getNameUpdateTime() {
        return NAME.timeUpdate - (Math.floor(BK_ENGINE.timer.now / 1000) - this.nameTime);
    }

    getContactUpdateTime() {
        return CONTACT.timeUpdate - (Math.floor(BK_ENGINE.timer.now / 1000) - this.contactTime);
    }

    getStringFromDate(day, month, year, isEven = false) {
        let d = day,
            m = month,
            y = year - 2000;

        if (isEven) {
            if (d < 10) d =  "0" + d;
            if (m < 10) m =  "0" + m;
        }

        switch (this.dateFormat) {
            case "dmy":
                return d + "/" + m + "/" + y;
            case "mdy":
                return m + "/" + d + "/" + y;
            case "ymd":
                return y + "/" + m + "/" + d;
        }
    }

    getStringFromTimestamp(timestamp, isEven) {
        let t = new Date(timestamp);
        let d = t.getUTCDate();
        let m = t.getUTCMonth() + 1;
        let y = t.getUTCFullYear();

        return this.getStringFromDate(d, m, y, isEven);
    }

    getStringFromDayUTC(dayUTC, version = 0) {
        let date = GW_USER.getDateFromDayUTC(dayUTC);
        let year = date.getUTCFullYear(),
            month, day;

        switch (version) {
            case 0:
                month = date.getUTCMonth() + 1;
                day   = date.getUTCDate();
                return this.getStringFromDate(day, month, year);
            case 1:
                month = date.toLocaleString('default', {month: 'long'});
                return month + " " + (year - 2000);
        }
    }

    setAuthorizations() {
        let isSave = false,
            s      = "",
            i, prop, value;

        for (i = 0; i < arguments.length; i += 2) {
            prop  = arguments[i];
            value = arguments[i + 1];

            if (this.authorization[prop] != value) {
                this.authorization[prop] = value;
                isSave                   = true;
            }
        }

        if (isSave) {
            for (const perm in this.authorization)
                s += this.authorization[perm] ? "1" : "0";

            SAVING.add("auths", s);
        }
    }

    reset() {       // reset the user (when log out)
        this.name       = "";
        this.country    = "";
        this.contact    = "";
        this.relig      = "";
        this.religDenom = "";

        this.nameTime = this.contactTime = 0;

        this.days        = [];
        this.faith.total = this.faith.max = 0;

        for (const prop in this.authorization) {
            this.authorization[prop] = false;
        }
    }

    printDenom() {
        let txt = this.denom == "c" ? "catholic" : "protestant";

        console.log("Website version set to: " + txt);
    }

    initDays() {
        let i, point, pointLast, arrPoints, graceTotal;

        this.days = [];

        this.calcGrace();

        if (this.points.length == 0)
            return;

        point      = this.points[0];
        arrPoints  = [point];
        pointLast  = point;
        graceTotal = point.grace;

        for (i = 1; i < this.points.length; i++) {
            point = this.points[i];

            if (pointLast.dayUTC == point.dayUTC) {
                if (graceTotal < 100) {
                    graceTotal += point.grace;

                    arrPoints.push(point);
                } else {
                    this.points.splice(i--, 1);     // remove the point, because there were no points gained anymore that day

                    if (DEVELOP.isShowWhyPointExcluded)
                        console.log("point excluded (graceTotal >= 100): ", point);
                }
            } else {
                this.days.push(new aDay(arrPoints, graceTotal));

                pointLast  = point;
                arrPoints  = [point];
                graceTotal = point.grace;
            }
        }

        // add the last one?
        if (this.days.length == 0 || (this.days[this.days.length - 1].dayUTC != point.dayUTC))
            this.days.push(new aDay(arrPoints, graceTotal));
    }

    calcGrace() {
        let books  = [],
            quotes = [],
            i, j, book, point;

        // init books chapters' difficulties
        for (i = 0; i < 100; i++) {
            book = {chapters: []};

            for (j = 0; j < 151; j++)
                book.chapters.push(-1);

            books.push(book);
        }

        // count the grace
        for (i = 0; i < this.points.length; i++) {
            point       = this.points[i];
            point.grace = this.calcGrace_value(books, quotes, point);

            if (DEVELOP.isShowEveryPoint)
                console.log(point);

            if (point.grace == 0 || this.calcGrace_time(i, point)) {
                this.points.splice(i--, 1);

                if (DEVELOP.isShowWhyPointExcluded)
                    console.log("point excluded (grace = 0 || time): " + point);
            }
        }
    }

    calcGrace_value(books, quotes, point) {
        let i, graceLast, graceNow, lastDifficultyI;

        if (point.difficultyI < 0 || point.difficultyI > 2)
            return 0;

        switch (point.gameCode) {
            case "a":
                lastDifficultyI = books[point.bookI].chapters[point.chapterI];
                
                graceLast = (lastDifficultyI == -1) ? 0 : GAME_GRACE.a[lastDifficultyI];
                graceNow  = GAME_GRACE.a[point.difficultyI];

                if (graceNow > graceLast) {
                    books[point.bookI].chapters[point.chapterI] = point.difficultyI;

                    return graceNow - graceLast;
                }
                break;
            case "b":
                i = quotes.map(function(item) {
                        return (item.dayUTC == point.dayUTC) ? 1 : -1;
                    }).indexOf(1);

                if (i == -1) {              // a new quote?
                    graceLast = 0;

                    quotes.push({dayUTC: point.dayUTC, difficultyI: point.difficultyI});

                    return GAME_GRACE.b[point.difficultyI];
                }

                // already existing quote
                graceLast = GAME_GRACE.b[quotes[i].difficultyI];
                graceNow  = GAME_GRACE.b[point.difficultyI];

                if (graceNow > graceLast) {
                    quotes[i].difficultyI = point.difficultyI;

                    return graceNow - graceLast;
                }
        }

        return 0;
    }

    calcGrace_time(pointI, point) {        // if it's the same day it's ok, if not, must be passed TIME_BETWEEN_DAYS
        if (pointI == 0)
            return false;

        let pointLast = this.points[pointI - 1];

        if (pointLast.dayUTC == point.dayUTC)
            return false;

        if (DEVELOP.isShowWhyPointExcluded && (point.date - pointLast.date < TIME_BETWEEN_DAYS))
            console.log("point time excluded (pointLast, point): ", pointLast, point);

        return point.date - pointLast.date < TIME_BETWEEN_DAYS;
    }

    calcFaith() {
        let i, day, dayLast, delta;

        this.faith.total = this.faith.max = 0;  // no need?

        if (this.days.length == 0)
            return;

        day            = this.days[0];
        day.faithTotal = this.faith.max = day.faith;

        if (DEVELOP.isShowEveryDayFaith)
            console.log(day.dayUTC, day.grace, day.faithTotal);

        for (i = 1; i < this.days.length; i++) {
            dayLast = day;
            day     = this.days[i];

            delta = day.dayUTC - dayLast.dayUTC;

            day.faithTotal = Math.max(0, dayLast.faithTotal - delta) + day.faith * 2;

            if (day.faithTotal > this.faith.max)
                this.faith.max = day.faithTotal;

            if (DEVELOP.isShowEveryDayFaith)
                console.log(day.dayUTC, day.grace, day.faithTotal);
        }

        this.faith.total = day.faithTotal;
    }

    readCookie() {
        let DATA   = ["year",4, "month",2, "day",2, "hours",2, "minutes",2],
            lang   = COOKIES.getOne(COOKIE_NAME.lang),
            cookie = COOKIES.getOne(COOKIE_NAME.data),
            from   = 0,
            i, d, to, prop;

        // lang
        if (GW_MAIN.languages.tags.includes(lang))
            this.lastSession.lang = lang;

        // date
        for (i = 0; i < DATA.length; i += 2) {
            prop = DATA[i];
            to   = from + DATA[i + 1];
            d    = COOKIES.extractNumber(cookie, from, to);
            
            if (!d.isValid)
                return;

            this.lastSession.date[prop] = d.value;

            from = to;
        }

        // date format
        if (cookie.length < from) return;
        d = cookie.substring(from, from + 1);
        if (d == "1") this.dateFormat = "mdy"
        else this.dateFormat = (d == "2") ? "ymd" : "dmy";

        // denomination
        if (cookie.length < ++from) return;
        this.denom = (cookie.substring(from, from + 1) == "c") ? "c" : "p";
    }

    updateCookie() {
        let date            = this.getDate();
        let lastSessionDate = pad(date.year, 4) + pad(date.month, 2) + pad(date.day, 2) + pad(date.hours, 2) + pad(date.minutes, 2),
            dateFormat      = "0";

        if (this.dateFormat == "mdy")
            dateFormat = "1";
        else if (this.dateFormat == "ymd")
            dateFormat = "2";

        COOKIES.setOne(COOKIE_NAME.lang, this.lang);
        COOKIES.setOne(COOKIE_NAME.data, lastSessionDate + dateFormat + this.denom);
    }

    init() {
        let browserLang = navigator.language;
        let lang        = browserLang.substring(0, 2);

        this.readCookie();

        if (DEVELOP.lang != "")
            this.lang = DEVELOP.lang;
        else {
            if (this.lastSession.lang.length > 0)
                this.lang = this.lastSession.lang;
            else {
                if (lang == "es" && browserLang != "es_ES")
                    lang = "esla";      // spanish (Latin America)
                this.lang = GW_MAIN.languages.tags.includes(lang) ? lang : "en";    // if a language is not implemented set it to english
            }
        }

        this.printDenom();
        LOADING.end();

        this.isInitiated = true;
    }

    saveGame(name, value) {
        SAVING.add(name, value);
        this.saveGame_addPoints(value);
    }

    saveGame_addPoints(d) {      // add points right after they've been earned
        let g = this.graceToday,
            p = new Points(new Date());

        if (g >= 100)
            return;

        p.gameCode = d.charAt(0);

        switch (p.gameCode) {
            case "a":
                p.bookI       = parseInt(d.substring(1, 3));
                p.chapterI    = parseInt(d.substring(3, 6));
                p.difficultyI = parseInt(d.substring(6, 7)) - 1;
                break;
            case "b":
                p.difficultyI = parseInt(d.substring(9,10));
        }

        this.points.push(p);

        this.initDays();
        this.calcFaith();
    }

    start2() {
        !this.isInitiated && this.init();
    }

    end2() {
        // this.item.loading.isVisible = this.item.saving.isVisible = false;
    }

    update() {
        let now = Date.now();

        if (now - this.timer < TIME_UPDATE)
            return;

        this.timer = now;

        this.updateCookie();        // the cookies will be updated every min, because there is an information about user's last presence

        if (!this.isVerified)       // if user is not verified, do nothing
            return;

        // loading?
        if (LOADING.isActive)
            return;

        if (!LOADING.isFinished)
            return LOADING.start();

        // saving?
        if (SAVING.isActive)
            return;

        if (SAVING.isToBeUpdated)
            SAVING.start();
    }

    afterLoaded(u) {     // call from LOADING (u = userInfo)
        let i = 0;

        SAVING.flushToUpdate();

        if (u.hasOwnProperty("name"))        this._name       = u.name;     // using "_" to avoid SAVING
        if (u.hasOwnProperty("country"))     this._country    = u.country;
        if (u.hasOwnProperty("denom"))       this._denom      = u.denom;
        if (u.hasOwnProperty("relig"))       this._relig      = u.relig;
        if (u.hasOwnProperty("religDenom"))  this._religDenom = u.religDenom;
        if (u.hasOwnProperty("contact"))     this._contact    = u.contact;
        if (u.hasOwnProperty("dateFormat"))  this._dateFormat = u.dateFormat;
        if (u.hasOwnProperty("nameTime"))    this.nameTime    = u.nameTime.seconds;
        if (u.hasOwnProperty("contactTime")) this.contactTime = u.contactTime.seconds;

        if (u.hasOwnProperty("lang") && this.lang != u.lang) {
            this._lang         = u.lang;
            this.isLangChanged = true;
        }

        if (u.hasOwnProperty("auths")) {
            for (const prop in this.authorization) {
                if (u.auths.length > i)
                    this.authorization[prop] = u.auths.charAt(i++) == 1;
            }
        }

        this.isVersionSet = true;

        console.log("User data loaded");

        this.afterLoaded_countPoints();
    }

    afterLoaded_countPoints() {
        let i, l, p, d, year, month, day;

        this.points = [];

        for (i = 0, l = GW_USER.progress.length; i < l; i++) {
            d = GW_USER.progress[i].data;
            p = new Points(GW_USER.progress[i].time.toDate());

            p.gameCode = d.charAt(0);

            switch (p.gameCode) {
                case "a":
                    p.bookI       = parseInt(d.substring(1, 3));
                    p.chapterI    = parseInt(d.substring(3, 6));
                    p.difficultyI = parseInt(d.substring(6, 7)) - 1;    // yep, game's A difficultyI is 1-3 (0: chapter not read)

                    this.points.push(p);
                    break;
                case "b":
                    year  = parseInt(d.substring(1,5));
                    month = parseInt(d.substring(5,7)) - 1;
                    day   = parseInt(d.substring(7,9));

                    if (year == p.date.getUTCFullYear() && month == p.date.getUTCMonth() && day == p.date.getUTCDate()) {   // does the quote's date match the server date?
                        p.difficultyI = parseInt(d.substring(9,10));

                        this.points.push(p);
                    } else
                        console.log("too late: " + year, month, day);
            }
        }

        this.points.sort(function(a, b) { return (a.date - b.date) });

        this.initDays();
        this.calcFaith();
    }

    afterSaved() {      // call from SAVING
        console.log("User data saved");

        // this.item.saving.isVisible = this.state == this.STATE.saving;
    }

    logOut() {      // call it when the user is logged out
        AUTH.signOut();
        LOADING.reset();
        SAVING.reset();
        this.reset();
    }
}

const GW_USER = BK_ENGINE.tasks.add(new GWuser(BK_ENGINE.tasks));

export { GW_USER, COUNTRY_TAGS, RELIG, RELIG_DENOM };
