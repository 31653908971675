// possible formatting tags (between: [* and *]):
// justify, alignLeft, alignCenter, alignRight
// 0-9: texts indexes

class Word {
    constructor(textI, chars) {
        this.textI = textI;
        this.chars = chars;
    }
}

class Expression {
    constructor() {
        this.chars = "";
        this.words = [];
    }
}

class Line {
    constructor() {
        this.formatting  = "";          // formatting tags, divided by a space
        this.expressions = [];
    }
}


export class BKengineTextContent {
    constructor(oContent) {
        this.texts       = [];
        this.lines       = [];
        
        this.currTextI   = 0;

        this.isInitiated = false;

        if (oContent)
            this.init(oContent);
    }

    setTexts(arrTexts) {
        this.texts = [...arrTexts];
    }

    init(value) {               // value: text data (usually from a text file)
        let content      = ("" + value).replace(/<br>/g, "\n");
        let contentLines = (content + String.fromCharCode(10)).split(/\r?\n/),
            i, l,
            line, expression,
            chars, charI, charsL, char;

        this.lines = [];

        for (i = 0, l = contentLines.length; i < l; i++) {
            line       = new Line();
            chars      = contentLines[i];
            charI      = 0;
            charsL     = chars.length;
            expression = new Expression();

            while (charI < charsL) {
                char = chars.charAt(charI++);

                if (char == " ") {                     // a space?
                    if (expression.chars.length > 0)
                        expression = this.init_addExpression(line, expression);
                    expression.chars = " ";
                    expression       = this.init_addExpression(line, expression);
                } else                                  // any other char
                    expression.chars += char;
            }

            if (expression.chars.length > 0)
                expression = this.init_addExpression(line, expression);

            this.lines.push(line);
        }

        this.lines.pop();

        this.isInitiated = true;
    }

    init_addExpression(line, expression) {
        let chars = expression.chars,
            start, end, tag, i;

        expression.chars = "";

        // check for formatting tags (text index)
        while ((start = chars.indexOf("[*")) > -1) {
            if (start > 0) {
                this.init_addWord(expression, chars.substring(0, start));     // add the word before the formatting tag
                chars = chars.substring(start);
            }
            
            end = chars.indexOf("*]");

            if (end > -1) {
                tag = chars.substring(2, end);
                i   = parseInt(tag);

                if (isNaN(i))               // a line formatting tag?
                    line.formatting += tag + " ";
                else
                    this.currTextI = i;     // no, it's a text index

                chars = chars.substring(end + 2);
            } else
                chars = chars.substring(2);
        }

        if (chars.length > 0)
            this.init_addWord(expression, chars);

        line.expressions.push(expression);
    
        return new Expression();
    }

    init_addWord(expression, chars) {
        let word = new Word(this.currTextI, chars);

        expression.chars += chars;

        expression.words.push(word);
    }

    reset() {
        this.lines       = [];
        this.isInitiated = false;
    }
}
