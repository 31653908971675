
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";


const TIME_MOVE = 500;


class ReadMove extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.time = 0;
    }

    start2(dst, isOpen = false) {
        this.time                    = (dst == GW_BIBLE.readSelected.src) ? Date.now() - TIME_MOVE : Date.now();
        GW_BIBLE.readSelected.dst    = dst;
        GW_BIBLE.readSelected.isOpen = isOpen;
    }

    update(oProgress, isOpen = true) {
        let progress = oProgress || Math.min((Date.now() - this.time) / TIME_MOVE, 1),
            src      = -0.5 - GW_BIBLE.readSelected.src,
            dst      = -0.5 - GW_BIBLE.readSelected.dst;

        GW_BIBLE.item.desktopReadBtn.pivX = src + (dst - src) * progress;

        if (progress == 1) {                        // animation ended
            GW_BIBLE.readSelected.src = GW_BIBLE.readSelected.dst;

            this.end();
            GW_BIBLE.resetMenuBar();
            GW_BIBLE.updateCookie();

            if (isOpen && GW_BIBLE.readSelected.isOpen)      // open the book (or bible atlas)?
                GW_BIBLE.read();
        }
    }
}

export const READ_MOVE = BK_ENGINE.tasks.add(new ReadMove(BK_ENGINE.tasks));
