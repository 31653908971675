
import { PLAY_LOGICS as LOGICS } from "./_bible g0002 play logics.js";
import { LOGICS_BRICKS as BRICKS } from "./logics bricks.js";
import { LOGICS_EVENTS as EVENTS } from "./logics events.js";


// trigger types
const BALL_HITS_BRICK = 0;
const PLAYFIELD_CLICKED      = 1;


class Trigger {
    constructor(type, data) {
        this.type = type;
        this.data = data;
    }
}


class LogicsTriggers {
    constructor() {
        this.all            = [];
        this.index          = 0;
        this.trigger        = null;
        this.letterBricksNm = 0;
    }

    add(type, data) {
        this.all.push(new Trigger(type, data));
    }

    start() {}

    reset() {
        this.all            = [];
        this.letterBricksNm = LOGICS.charsToGuessNm;
    }

    update() {
        let isRemove;

        this.index = 0;

        // if (this.all.length > 0)
        //     console.log(this.all.length);

        while (this.index < this.all.length) {
            this.trigger = this.all[this.index];

            switch (this.trigger.type) {
                case BALL_HITS_BRICK:
                    isRemove = this.ballHitsBrick();
                    break;
                case PLAYFIELD_CLICKED:
                    isRemove = this.playfieldClicked();
                    break;
                default:
                    console.error("trigger type: " + this.trigger.type + " doesn't exist!");
                    isRemove = true;
            }

            if (isRemove)
                this.all.splice(this.index, 1);
        }
    }

    ballHitsBrick() {
        let brick = BRICKS.getBrick(this.trigger.data.col, this.trigger.data.row),
            sound = "";

        if (brick.hp > 0) {
            if (--brick.hp == 0) {      // letter brick
                brick.isEmpty = brick.isRemovable = true;
                sound         = "letterBrickBroken";

                this.letterBricksNm--;
            } else
                sound = "ballHitsLetter";
        } else {
            if (brick.isRemovable)
                sound = "ballHitsPlayer";
            else
                sound = "ballHitsBrick";
        }

        EVENTS.add("audio", sound);

        // console.log(this.trigger.data.col, this.trigger.data.row);

        return true;
    }

    playfieldClicked() {
        let brick = LOGICS.getBrickFromLeftTop(this.trigger.data.left, this.trigger.data.top);

        if (brick.col > -1 && brick.isRemovable) {       // no need to check the "row"
            EVENTS.add("audio", "placeBrick");
            BRICKS.replaceRemovable(brick);
            LOGICS.playerMoves++;
        }
    
        return true;
    }
}

const TRIGGERS = new LogicsTriggers();


export { TRIGGERS, BALL_HITS_BRICK, PLAYFIELD_CLICKED };
