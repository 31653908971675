
import { INPUT } from "./input.js"
import { VIEW_PORT } from "./view port.js"
import { BKengineCanvas } from "../primitives/canvas.js"


class Pointer {
    constructor() {
        this.LIGHT    = {"verNarrow": 3.0, "verWide": 2, "horNarrow": 2, "horWide": 1.5};
        
        this.canvas   = new BKengineCanvas();

        this.isActive = true;

        this.viewPort = {width: 0, height: 0};
        this.coords   = {left: 0, top: 0, width: 0, height: 0};
    }

    resize() {
        this.viewPort.width  = VIEW_PORT.width;
        this.viewPort.height = VIEW_PORT.height;

        this.coords.width  = this.viewPort.width  * 2;
        this.coords.height = this.viewPort.height * 2;

        this.canvas.resize(this.coords.width, this.coords.height);

        this.canvas.drawTranspCircle(this.LIGHT[VIEW_PORT.orientation]);
    }

    update() {
        if (this.viewPort.width != VIEW_PORT.width || this.viewPort.height != VIEW_PORT.height)
            this.resize();

        this.coords.left = INPUT.left - Math.round(this.coords.width  / 2);
        this.coords.top  = INPUT.top  - Math.round(this.coords.height / 2);
    }

    draw(canvas) {
        canvas.drawImage(this.canvas, {left: 0, top: 0, width: this.coords.width, height: this.coords.height}, this.coords);
        // console.log("o")
        // 
    }
}

export const POINTER = new Pointer();
