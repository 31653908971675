
const ADDRESS_SHORT = {first: 5, last: 4};

const CRYPTO = {
    tokenName: "BUSD",
    api:       "https://api.bscscan.com/api",
    apiKey:    "RCFUQBMK5DGF2BTCNQI5KFGMKF95B3ZSJG",
    address:   {tokenContract: "0xe9e7cea3dedca5984780bafc599bd69add087d56",    // BUSD
                main:          "0xde87f4828528C059E861E2A2E96272D7baEba54e",
                Levites:       "0x9f878c2Da3F8814322f1187A09D6C8683839B147"},
    block:     {start: 28268074, end: 999999999},   // start: the first block that has to do sth. with our address, knowing this will speed up getting the tx list
    webScan:   "https://bscscan.com/",

    addressToShort(address) {
        return address.substring(0, ADDRESS_SHORT.first) + "..." + address.substring(address.length - ADDRESS_SHORT.last);
    },

    valueToDecBUSD(value, isInteger = false) {
        let v       = (typeof value == "number") ? value : parseInt(value),
            toFixed = isInteger ? 0 : 2;
    
        return (v / 1000000000000000000).toFixed(toFixed);
    }
}

const PAYPAL = {
    address: "https://www.paypal.com/donate",
    id:      "PFR8UKA9JE66N"
};


export { CRYPTO, PAYPAL };
