
import { PLAY_LOGICS as LOGICS } from "./_bible g0001 play logics.js";


class LogicsGuessing {
    constructor() {
        this.chars = "";
    }

    reset() {
        this.chars = "";
    }

    update() {
        let i, l, helper;

        this.chars = "";

        for (i = 0, l = LOGICS.word.shortSorted.length; i < l; i++) {
            helper      = LOGICS.helpers.getByI(i);
            this.chars += (helper.char.length == 0) ? " " : helper.char;
        }
        // console.log(this.chars)
    }

    updateFromStates(guessedStates) {
        let i, l,
            helper, letter;

        for (i = 0, l = LOGICS.word.shortSorted.length; i < l; i++) {
            helper = LOGICS.helpers.getByI(i);

            if (helper.isCorrect = guessedStates[i]) {
                letter           = LOGICS.letters.getByI(helper.letterI);
                letter.isGuessed = true;
            }
        }

        this.update();
    }

    updateCurr() {
        LOGICS.word.curr = LOGICS.word.wholeGuessed.replace(/ /g, "ˍ");
    }
}

export const LOGICS_GUESSING = new LogicsGuessing();
