// the book will always fit its in pages inside the parent!

import { setMore } from "../bk engine utils.js"
import { VIEW_PORT } from "../_main objects/view port.js"
import { BKengineItem } from "./_item.js"
import { BKengineTextDisplay } from "../complex/text display.js"


export class BKengineBook extends BKengineItem {
    constructor() {
        super();

        this.textDisplay        = new BKengineTextDisplay();
        this.btn                = {coverF: null, pageL: null, pageR: null, coverB: null};   // children must content 4 buttons: coverF, pageL, pageR, coverB
        this.btnRedraw          = {pageL: null, pageR: null};
        this.btnTurnPage        = {prev: null, next: null},
        this.page               = {nm: 0, xL: 0, xR: 0, y: 0, w: 0, h: 0};
        this.texts              = [];

        this.type               = "book";

        this.isClickable        = false;
        this.isBothPagesVisible = false;

        this.parentWidth        = 0;
        this.parentHeight       = 0;

        this.pagesNm            = 4;
    }

    set pageW(value) {
        this.page.w = value;
    }

    set pageH(value) {
        this.page.h = value;
    }

    set pageLx(value) {
        this.page.xL = value;
    }

    set pageRx(value) {
        this.page.xR = value;
    }

    set pageY(value) {
        this.page.y = value;
    }

    set pageNm(value) {
        this.page.nm = value;
    }

    set btnTurnPagePrev(value) {
        this.btnTurnPage.prev = value;
    }

    set btnTurnPageNext(value) {
        this.btnTurnPage.next = value;
    }

    get pageNm() {
        return this.page.nm;
    }

    getPageNmByChars(pageStart, chars) {
        return this.textDisplay.getPageNmByChars(pageStart, chars);
    }

    addChild(child) {
        this.children.push(child);

        if (this.children.length == 4) {
            let i = 0;

            for (const prop in this.btn) {
                this.btn[prop]             = this.children[i++];
                this.btn[prop].isUpdatePos = this.btn[prop].isUpdateSize = false;
            }
        }
    }

    addText(text) {
        this.texts.push(text);
    }

    addEmptyPage() {
        this.pagesNm = this.textDisplay.pages.length + 3;

        return this.textDisplay.addEmptyPage() + 1;
    }

    addTextContent(textContent, oIsEven) {
        let ratio  = (this.page.w * this.w) / (this.page.h * this.h);
        let width  = Math.round(ratio * 1000);
        let height = Math.round(width / ratio);

        if (this.texts.length == 0) {
            console.error("No texts found for the book: \"" + this.name + "\"!");
            return;
        }

        textContent.texts = this.texts;

        this.textDisplay.resize(width, height);
        this.textDisplay.addTextContent(textContent, oIsEven);

        this.pagesNm = this.textDisplay.pages.length + 2;

        this.btnRedraw.pageL = this.btn.pageL.redraws[this.btn.pageL.redraws.length - 1];
        this.btnRedraw.pageR = this.btn.pageR.redraws[this.btn.pageR.redraws.length - 1];

        setMore(this.btnRedraw.pageL, "src", this.textDisplay, "x", this.page.xL, "y", this.page.y, "w", this.page.w, "h", this.page.h);
        setMore(this.btnRedraw.pageR, "src", this.textDisplay, "x", this.page.xR, "y", this.page.y, "w", this.page.w, "h", this.page.h);

        // console.log(this.textDisplay)
        this.isRedrawn = false;
    }

    isPageEmpty() {
        return this.page.nm > 0 ? this.textDisplay.isPageEmpty(this.page.nm - 1) : false;
    }

    reset() {
        this.textDisplay.reset();

        this.pagesNm = 2;
    }

    updateSize() {
        if (this.parentWidth != this.parent.width || this.parentHeight != this.parent.height) {
            this.parentWidth  = this.parent.width;
            this.parentHeight = this.parent.height;

            this.isResized = false;
        } else
            this.isResized = true;
    }

    resize() {
        let ratioBook   = this.w / this.h,
            ratioParent = this.parent.width / this.parent.height,
            btn;

        if (ratioBook < ratioParent) {
            this.height = this.parent.height;
            this.width  = Math.round(ratioBook * this.height);
        } else {
            this.width  = this.parent.width;
            this.height = Math.round(this.width / ratioBook);
        }

        for (const prop in this.btn) {
            btn = this.btn[prop];

            btn.width  = this.width;
            btn.height = this.height;

            btn.resize();
        }

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        let pageI = this.page.nm % 2 == 1 ? this.page.nm - 1 : this.page.nm - 2;

        this.btnRedraw.pageL.srcCol = pageI;
        this.btnRedraw.pageR.srcCol = pageI + 1;

        this.btn.pageL.isRedrawn = this.btn.pageR.isRedrawn = false;

        this.isRedrawn = true;
    }

    update() {
        this.updateParentMin();
        this.updateSize();

        if (!this.isResized)
            this.resize();
            
        if (!this.isRedrawn)
            this.redraw();
            
        if (this.isUpdatePos)
            this.updatePos();

        this.updateBtnsPos();

        if (this.isActive)
            this.updateBtnsState();
    }

    updatePos() {
        this.left = Math.round((this.parent.width  - this.width)  / 2);
        this.top  = Math.round((this.parent.height - this.height) / 2);
    }

    updateBtnsPos() {
        this.isBothPagesVisible = this.width * 2 <= this.parent.width;

        this.btn.coverF.left = this.btn.coverB.left = this.left;
        this.btn.coverF.top  = this.btn.coverB.top  = this.btn.pageL.top = this.btn.pageR.top = this.top;

        if (this.isBothPagesVisible) {
            this.btn.pageL.left = this.btn.coverF.left - Math.round(this.width / 2);
            this.btn.pageR.left = this.btn.pageL.left + this.width;
        } else {
            if (this.page.nm % 2 == 1) {
                this.btn.pageL.left = 0;
                this.btn.pageR.left = this.btn.pageL.left + this.width;
            } else {
                this.btn.pageR.left = VIEW_PORT.width - this.width;
                this.btn.pageL.left = this.btn.pageR.left - this.width;                
            }
        }
    }

    updateBtnsState() {
        let isFirst  = this.page.nm == 0,
            isLast   = this.page.nm == this.pagesNm - 1,
            isActive = VIEW_PORT.width > this.btn.pageL.width * 1.1;     // if the screen is too narrow don't permit to turn the pages to be clicked

        this.btn.coverF.isVisible = isFirst;
        this.btn.coverB.isVisible = isLast;
        this.btn.pageL.isVisible  = this.btn.pageR.isVisible = !isFirst && !isLast;
        this.btn.pageL.isActive   = this.btn.pageR.isActive = isActive;

        this.btnTurnPage.prev.isDisabled = isFirst;
        this.btnTurnPage.next.isDisabled = isLast;
    }

    turnPagePrev() {
        let step = this.isBothPagesVisible ? 2 : 1;

        this.goToPage(Math.max(0, this.page.nm - step));
    }

    turnPageNext(oValue) {
        let step = this.isBothPagesVisible ? 2 : 1;

        step = oValue || step;

        this.goToPage(Math.min(this.page.nm + step, this.pagesNm - 1));
    }

    goToPage(pageNm) {
        if (this.page.nm != pageNm) {
            this.page.nm = pageNm;

            this.isRedrawn = false;
            
            this.updateBtnsPos();
        }
    }
}
