
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js";
import { CRYPTO } from "../../../const.js";
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_MAIN, INFO_BOX } from "../../_gw main.js";
import { COOKIES } from "../../cookies.js";
import { GW_DB } from "../../../gw db/_gw db.js";
import { DONATOR_INFO } from "./donator info.js";


const STATE          = {loadingData: 10, main: 20},
      COOKIE         = "gwMainDonatorsListIntro",
      LINE_WIDTH     = {ext: 0.01, int: 0.003},
      PADDING        = {text: 0.02, flag: 0.1},
      COLOR          = {line:        "#dddddd",
                        bgd:         "#999999",
                        btnBgd:      "#777777",
                        itemBgd1:    "#00000011",
                        itemBgd2:    "#00000022",
                        hover:       "#ffffff33",
                        pressed:     "#00000044",
                        keepPressed: "#ff000022"},
      IMG_MERGE_SIZE = 0.6,
      IMG_ARROW_SIZE = 0.8,
      BTN_H          = {verNarrow: 0.1, verWide: 0.07, horNarrow: 0.055, horWide: 0.055},
      COLS_H         = 0.8,         // of BTN_H
      ITEM_H         = 0.75,
      COLS_W         = {verNarrow: {date: 3.75, value: 3.25, country: 3, address: 5.25 },    // name: the rest
                        verWide:   {date: 3.75, value: 3.75, country: 3, address: 5.25 },
                        horNarrow: {date: 3.75, value: 3.75, country: 3, address: 5.25 },
                        horWide:   {date: 3.75, value: 3.75, country: 3, address: 5.25 }};


class Flag {
    constructor(tag) {
        this.tag    = tag;
        this.canvas = BK_ENGINE.canvases.addNew();
    }
}

class DispItem {
    constructor(index, address, value, timestamp) {
        this.index        = index;
        this.address      = address;
        this.addressShort = CRYPTO.addressToShort(address);
        this.value        = value;
        this.valueShown   = "";
        this.valueTotal   = 0;
        this.timestamp    = timestamp;      // from blockchain is in seconds
        this.date         = "";
        this.name         = "";
        this.lang         = "";             // some of them will not be used here, but in DONATOR_INFO
        this.country      = "";
        this.relig        = "";
        this.religDenom   = "";
        this.faith        = -1;
        this.contact      = "";
        this.flag         = null;
        this.coords       = new Coords();

        this.init();
    }

    updateValueShown() {
        this.valueShown = CRYPTO.valueToDecBUSD(this.value, true);
    }

    updateDate() {
        this.date = GW_USER.getStringFromTimestamp(this.timestamp * 1000, true);
    }

    init() {
        this.updateValueShown();
        this.updateDate();
    }

    merge(value, timestamp) {
        this.value += value;

        if (this.timestamp < timestamp)
            this.timestamp = timestamp;

        this.init();
    }
}

class DispBtn {
    constructor(name, caption = "") {
        this.name          = name;
        this.caption       = caption;
        this.coords        = new Coords();
        this.canvas        = BK_ENGINE.canvases.addNew();
        this.isKeepPressed = false;
    }
}


class DonatorsList extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = 0;

        this.image       = null;
        this.canvas      = null;
        this.text        = null;
        this.item        = null;

        this.coords      = {main:  new Coords(),
                            title: new Coords(),
                            cols:  new Coords(),
                            item:  new Coords(),
                            tmp:   new Coords()};

        this.btn         = {merge:   new DispBtn("merge"),
                            sortDir: new DispBtn("sortDir"),
                            quit:    new DispBtn("quit"),
                            date:    new DispBtn("date"),
                            value:   new DispBtn("value", "BUSD"),
                            country: new DispBtn("country"),
                            address: new DispBtn("address"),
                            name:    new DispBtn("name"),
                            up:      new DispBtn("up"),
                            down:    new DispBtn("down")};

        this.mouse       = {btns:  {mouseDown: -1, hover: 0, pressed: 0, clicked: 0},
                            items: {mouseDown: -1, hover: 0, pressed: 0, clicked: 0}};

        this.padding     = {text: 0, flag: 0};
        this.lineWidth   = {ext: 0, int: 0};
        
        this.items       = [];
        this.itemIstart  = 0;
        this.itemIstep   = 0;

        this.txns        = [];      // transactions

        this.flags       = {isInitiated: false, tags: [], all: [],
                            reset() {this.isInitiated = false;
                                     this.tags        = [];
                                     this.all         = []},

                            getByTag(tag) {
                                let i;

                                for (i = 0; i < this.all.length; i++) {
                                    if (this.all[i].tag == tag)
                                        return this.all[i];
                                }
                            }};

        this.colSelected = "date";
        this.isMerge     = false;
        this.isAscend    = false;
        this.isOnceShown = false;

        this.isInitiated = false;
    }

    getItemByAddress(address) {
        let i, l;

        for (i = 0, l = this.items.length; i < l; i++) {
            if (this.items[i].address == address)
                return this.items[i];
        }

        return -1;
    }

    init() {
        let T = BK_ENGINE.texts;

        this.image = {arrows: BK_ENGINE.images.getByName("main_simpleControls")};

        this.canvas = {main: BK_ENGINE.canvases.getByName("main_donatorsList"),
                       bgd:  BK_ENGINE.canvases.addNew()};

        this.text = {title: T.getByName("main_donatorsList_title"),
                     cols:  T.getByName("main_donatorsList_cols"),
                     item:  T.getByName("main_donatorsList_item"),
                     itemC: T.getByName("main_donatorsList_itemC"),
                     itemR: T.getByName("main_donatorsList_itemR")};

        this.item = {mainFrame: BK_ENGINE.items.getByName("main_donatorsList")};

        this.isInitiated = true;
    }

    initBtns() {
        this.btn.date.caption    = GW_MAIN.file.lang.getCaption("donations_date");
        this.btn.address.caption = GW_MAIN.file.lang.getCaption("donations_address");
        this.btn.name.caption    = GW_MAIN.file.lang.getCaption("donations_name");
    }

    initItems() {
        let i, t;

        this.items = [];

        for (i = 0; i < this.txns.length; i++) {
            t = this.txns[i];

            if (t.to.toLowerCase() == CRYPTO.address.main.toLowerCase())    // only "IN", not "OUT" :)
                this.initItems_one(i, t.from, parseInt(t.value), parseInt(t.timeStamp));
        }

        this.sortItems();
    }

    initItems_one(i, address, value, timestamp) {
        let item, newItem, user;

        if (this.isMerge) {
            item = this.getItemByAddress(address);
            
            if (item != -1)
                return item.merge(value, timestamp);
        }

        newItem = new DispItem(i, address, value, timestamp);
        user    = GW_DB.users.getByProp("address", address);

        if (user) {
            for (const prop in user)
                newItem[prop] = user[prop];

            newItem.flag = this.flags.getByTag(user.country);
        } else
            newItem.flag = this.flags.getByTag("unknown");

        this.items.push(newItem);
    }

    sortItems() {
        let prop;

        switch (this.colSelected) {
            case "date":
                prop = "timestamp";
                break;
            default:
                prop = this.colSelected;
        }

        if (this.isAscend)
            return this.items.sort((a,b) => (a[prop] > b[prop]) ? 1 : ((b[prop] > a[prop]) ? -1 : 0));

        this.items.sort((a,b) => (b[prop] > a[prop]) ? 1 : ((a[prop] > b[prop]) ? -1 : 0));
    }

    calcValueTotal(item) {
        let i, l, itemAdd;

        item.valueTotal = item.value;

        for (i = 0, l = this.items.length; i < l; i++) {
            itemAdd = this.items[i];

            if (item.address == itemAdd.address && item.index != itemAdd.index)
                item.valueTotal += itemAdd.value;
        }
    }

    start2(txns) {
        !this.isInitiated && this.init();

        this.txns        = txns;
        this.itemIstart  = 0;
        this.state       = STATE.loadingData;
        this.isOnceShown = COOKIES.getOne(COOKIE).length > 0;

        this.flags.reset();
        this.initBtns();
        this.show(true);
    }

    end2() {
        this.show(false);

        this.canvas.main.resize(1, 1);
    }

    update() {
        if (this.state == STATE.loadingData)
            return this.update_loadingData();
        
        this.update_main();
    }

    update_loadingData() {
        let i;

        // users and images ready?
        if (!GW_DB.isReady() || !GW_DB.users.areReady() || !this.image.arrows.isLoaded || this.item.mainFrame.width == 0 || this.item.mainFrame.height == 0)
            return;

        // flags ready?
        if (!this.flags.isInitiated) {
            this.flags.tags = GW_DB.users.getCountries();

            this.flags.tags.push("unknown");

            for (i = 0; i < this.flags.tags.length; i++)
                this.flags.all.push(new Flag(this.flags.tags[i]));

            this.flags.isInitiated = true;
        }

        if (!GW_DB.areFlagsLoaded(this.flags.tags))
            return;

        // continue!
        this.initItems();
        this.resize();

        if (!this.isOnceShown) {
            this.isOnceShown = true;

            COOKIES.setOne(COOKIE);
            INFO_BOX.start(GW_MAIN.file.lang.getCaption("donatorsList_intro"), "gotIt");
        }

        this.state = STATE.main;
    }

    update_main() {
        if (DONATOR_INFO.isActive)
            return;

        if (this.item.mainFrame.width != this.canvas.main.width || this.item.mainFrame.height != this.canvas.main.height)
            this.resize();

        this.refresh();

        // check buttons clicked
        this.update_main_checkMouseBtns();
        this.update_main_checkBtns();

        // check items clicked
        this.mouse.items.hover = this.mouse.items.pressed = -1;

        if (this.mouse.btns.hover == -1) {
            this.update_main_checkMouseItems();

            if (this.mouse.items.clicked) {
                this.calcValueTotal(this.mouse.items.clicked);
                DONATOR_INFO.start(this.mouse.items.clicked)
            }
        }
    }

    update_main_checkMouseBtns() {
        let i = 0,
            btn;

        this.mouse.btns.hover   = this.mouse.btns.pressed = -1;
        this.mouse.btns.clicked = "";

        for (const prop in this.btn) {
            btn = this.btn[prop];

            if (BK_ENGINE.input.isMouseInsideRect({left: btn.coords.left, top: btn.coords.top, width: btn.coords.width, height: btn.coords.height})) {
                this.mouse.btns.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.btns.mouseDown == -1)
                        this.mouse.btns.mouseDown = i;

                    this.mouse.btns.pressed = i;
                } else {
                    if (this.mouse.btns.mouseDown == i)
                        this.mouse.btns.clicked   = btn.name;

                    this.mouse.btns.mouseDown = -1;
                }

                return;
            }

            i++;
        }
    }

    update_main_checkMouseItems() {
        let i, item;

        this.mouse.items.clicked = null;
        this.coords.tmp.left     = 0;
        this.coords.tmp.top      = this.coords.cols.top + this.coords.cols.height;

        this.coords.tmp.resize(this.coords.item.width, this.coords.item.height);

        for (i = this.itemIstart; i < this.items.length; i++) {
            item = this.items[i];

            if (BK_ENGINE.input.isMouseInsideRect(this.coords.tmp)) {
                this.mouse.items.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.items.mouseDown == -1)
                        this.mouse.items.mouseDown = i;

                    this.mouse.items.pressed = i;
                } else {
                    if (this.mouse.items.mouseDown == i)
                        this.mouse.items.clicked   = item;

                    this.mouse.items.mouseDown = -1;
                }

                return;
            }

            this.coords.tmp.top += this.coords.item.height;
        }
    }

    update_main_checkBtns() {
        switch (this.mouse.btns.clicked) {
            case "merge":
                this.isMerge = !this.isMerge;
                return this.initItems();
            case "sortDir":
                this.isAscend = !this.isAscend;
                this.redraw_btnSortDir();
                return this.sortItems();
            case "quit":
                return this.end("quit");
            case "date":
            case "value":
            case "country":
            case "address":
            case "name":
                if (this.colSelected == this.mouse.btns.clicked) {
                    this.isAscend = !this.isAscend;
                    this.redraw_btnSortDir();
                } else
                    this.colSelected = this.mouse.btns.clicked;
                return this.sortItems();
            case "up":
                this.itemIstart -= this.itemIstep;
                break;
            case "down":
                this.itemIstart += this.itemIstep;
                break;
            default:
                if (BK_ENGINE.input.mouseWheel < 0)
                    this.itemIstart--;
                else if (BK_ENGINE.input.mouseWheel > 0)
                    this.itemIstart++;
        }

        this.itemIstart = Math.max(0, Math.min(this.items.length - this.itemIstep - 1, this.itemIstart));
    }

    resize() {
        let width     = this.item.mainFrame.width,
            height    = this.item.mainFrame.height;
        let min       = this.coords.main.min = width < height ? width : height;
        let btnHeight = Math.round(min * BTN_H[BK_ENGINE.viewPort.orientation]);

        // different sizes
        this.lineWidth.ext = Math.max(1, Math.round(min * LINE_WIDTH.ext));
        this.lineWidth.int = Math.max(1, Math.round(min * LINE_WIDTH.int));
        
        // coords
        this.coords.main.resize(width, height);
        this.coords.title.resize(width, btnHeight);
        this.coords.cols.resize(width, Math.round(COLS_H * btnHeight));
        this.coords.item.resize(width, Math.round(ITEM_H * btnHeight));
        
        this.coords.cols.top = this.coords.title.height;

        // padding
        this.padding.text = Math.max(1, Math.round(min * PADDING.text));
        this.padding.flag = Math.max(1, Math.round(PADDING.flag * this.coords.item.height));

        // canvases
        this.canvas.main.resize(width, height);
        this.canvas.bgd.resize(width, height);

        // items
        height = this.coords.item.height;

        this.text.item.calcHeight(height);
        this.text.item.prepare(this.canvas.main);
        this.text.itemC.calcHeight(height);
        this.text.itemC.prepare(this.canvas.main);
        this.text.itemR.calcHeight(height);
        this.text.itemR.prepare(this.canvas.main);
 
        // the rest
        this.resize_items();
        this.resize_btns();
        this.resize_flags();

        this.redraw();
    }

    resize_items() {
        let i;

        for (i = 0; i < this.items.length; i++)
            this.items[i].coords.resize(this.item.mainFrame.width, this.coords.item.height);
    }

    resize_btns() {
        let colsW = COLS_W[BK_ENGINE.viewPort.orientation],
            top, width, height, size;

        // btn merge, quit
        size = this.coords.title.height;

        this.btn.merge.coords.resize(size, size);
        this.btn.merge.canvas.resize(size, size);

        this.btn.sortDir.coords.resize(size, size);
        this.btn.sortDir.canvas.resize(size, size);
        this.btn.sortDir.coords.left = this.btn.merge.coords.width;

        this.btn.quit.coords.resize(size, size);
        this.btn.quit.canvas.resize(size, size);
        this.btn.quit.coords.left = this.coords.title.width - size;

        // column buttons
        top    = this.coords.cols.top;
        height = this.coords.cols.height;

        this.btn.date.coords.resize(Math.round(colsW.date * height), height);
        this.btn.date.canvas.resize(this.btn.date.coords.width, height);
        this.btn.date.coords.top = top;

        this.btn.value.coords.resize(Math.round(colsW.value * height), height);
        this.btn.value.canvas.resize(this.btn.value.coords.width, height);
        this.btn.value.coords.top  = top;
        this.btn.value.coords.left = this.btn.date.coords.width;

        this.btn.country.coords.resize(Math.round(colsW.country * height), height);
        this.btn.country.canvas.resize(this.btn.country.coords.width, height);
        this.btn.country.coords.top  = top;
        this.btn.country.coords.left = this.btn.value.coords.left + this.btn.value.coords.width;

        this.btn.address.coords.resize(Math.round(colsW.address * height), height);
        this.btn.address.canvas.resize(this.btn.address.coords.width, height);
        this.btn.address.coords.top  = top;
        this.btn.address.coords.left = this.btn.country.coords.left + this.btn.country.coords.width;

        this.btn.name.coords.resize(this.coords.cols.width - this.btn.address.coords.left - this.btn.address.coords.width, height);
        this.btn.name.canvas.resize(this.btn.name.coords.width, height);
        this.btn.name.coords.top  = top;
        this.btn.name.coords.left = this.btn.address.coords.left + this.btn.address.coords.width;

        // btn up and down
        width  = Math.round(this.coords.main.width / 2);
        height = this.coords.cols.height;
        top    = this.coords.main.height - height;

        this.btn.up.canvas.resize(width, height);
        this.btn.up.coords.resize(width, height);
        this.btn.up.coords.top  = top;

        this.btn.down.canvas.resize(width, height);
        this.btn.down.coords.resize(width, height);
        this.btn.down.coords.left = width;
        this.btn.down.coords.top  = top;
    }

    resize_flags() {
        let width   = this.btn.country.coords.width - this.padding.flag * 2,
            height  = this.coords.item.height       - this.padding.flag * 2,
            i;

        for (i = 0; i < this.flags.all.length; i++)
            this.flags.all[i].canvas.resize(width, height);
    }

    redraw() {
        this.canvas.bgd.drawRect(this.coords.main,  COLOR.bgd, true);

        // title
        this.canvas.bgd.drawRect(this.coords.title, COLOR.btnBgd, true);
        this.canvas.bgd.drawRect(this.coords.title, COLOR.line, false, this.lineWidth.int);

        this.text.title.calcHeight(this.coords.title.height);
        this.text.title.prepare(this.canvas.bgd);
        this.text.title.draw(this.canvas.bgd, {left: Math.round(this.coords.title.width / 2), top: this.coords.title.top + Math.round(this.coords.title.height / 2)}, GW_MAIN.file.lang.getCaption("donatorsList_donators"));

        // cols
        this.text.cols.calcHeight(this.coords.cols.height);

        // buttons
        this.redraw_btnCol("date");
        this.redraw_btnCol("value");
        this.redraw_btnCol("country");
        this.redraw_btnCol("address");
        this.redraw_btnCol("name");

        this.redraw_btn(this.btn.merge, IMG_MERGE_SIZE, 7);
        this.redraw_btn(this.btn.quit,  IMG_MERGE_SIZE, 2);
        this.redraw_btn(this.btn.up,    IMG_ARROW_SIZE, 0);
        this.redraw_btn(this.btn.down,  IMG_ARROW_SIZE, 1);
        this.redraw_btnSortDir();

        // flags
        this.redraw_flags();
    }

    redraw_btnCol(name) {
        let btn     = this.btn[name];
        let canvas  = btn.canvas,
            caption = (name == "country") ? "" : btn.caption;
        let center  = Math.round(canvas.width  / 2),
            top     = Math.round(canvas.height / 2),
            coords  = new Coords(0, 0, canvas.width, canvas.height);

        canvas.clear();
        canvas.drawRect(coords, COLOR.btnBgd, true);
        canvas.drawRect(coords, COLOR.line, false, this.lineWidth.int);

        this.text.cols.prepare(canvas);
        this.text.cols.draw(canvas, {left: center, top: top}, caption);
    }

    redraw_btn(btn, sizeMultiply, srcCol) {
        let size = Math.round(sizeMultiply * btn.coords.height);
        let left = Math.round((btn.coords.width  - size) / 2),
            top  = Math.round((btn.coords.height - size) / 2);

        btn.canvas.clear();
        btn.canvas.drawRect({left: 0, top: 0, width: btn.coords.width, height: btn.coords.height}, COLOR.btnBgd, true);
        BK_ENGINE.canvases.drawImageQuality(this.image.arrows, btn.canvas, {left: left, top: top, width: size, height: size}, srcCol);
    }

    redraw_btnSortDir() {
        let srcCol = this.isAscend ? 8 : 9;

        this.redraw_btn(this.btn.sortDir, IMG_MERGE_SIZE, srcCol);
    }

    redraw_flags() {
        let i, flag;

        for (i = 0; i < this.flags.all.length; i++) {
            flag = this.flags.all[i];

            GW_DB.country[flag.tag].drawFlag(flag.canvas);
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        BK_ENGINE.pointer.isActive = !isVisible;
    }

    refresh() {
        this.btn.merge.isKeepPressed = this.isMerge;

        this.canvas.main.clear();
        this.canvas.main.drawImage(this.canvas.bgd);
        this.refresh_items();
        this.refresh_btns();
        this.canvas.main.drawRect(this.coords.main, COLOR.line, false, this.lineWidth.ext);

        this.item.mainFrame.isRedrawn = false;
    }

    refresh_items() {
        let i, l, item;

        this.itemIstep         = -1;     // let it be 1 less than max
        this.coords.tmp.left   = 0;
        this.coords.tmp.top    = this.coords.cols.top + this.coords.cols.height;
        this.coords.tmp.width  = this.coords.cols.width;
        this.coords.tmp.height = this.coords.item.height;

        for (i = this.itemIstart, l = this.items.length; i < l; i++) {
            item = this.items[i];

            if (this.coords.tmp.top + item.coords.height >= this.btn.up.coords.top)
                return;

            this.refresh_items_one(i, item);

            this.itemIstep++;
        }
    }

    refresh_items_one(i, item) {
        let isHover   = false,
            isPressed = false,
            colorBgd  = i % 2 == 0 ? COLOR.itemBgd1 : COLOR.itemBgd2,
            tmp       = this.coords.tmp;
        let top       = Math.round(tmp.top + this.coords.item.height / 2);

        if (i == this.mouse.items.pressed)
            isPressed = true;
        else if (!BK_ENGINE.isTouchScreen && i == this.mouse.items.hover)
            isHover = true;

        this.canvas.main.drawRect(tmp, colorBgd, true);
        
        // text fields
        this.text.item.prepare(this.canvas.main);
        this.text.item.draw(this.canvas.main, {left: this.padding.text + this.btn.name.coords.left,    top: top}, item.name);

        this.text.itemC.prepare(this.canvas.main);
        this.text.itemC.draw(this.canvas.main, {left: this.btn.address.coords.left + Math.round(this.btn.address.coords.width / 2), top: top}, item.addressShort);
        this.text.itemC.draw(this.canvas.main, {left: this.btn.date.coords.left + Math.round(this.btn.date.coords.width / 2), top: top}, item.date);

        this.text.itemR.prepare(this.canvas.main);
        this.text.itemR.draw(this.canvas.main, {left: this.btn.country.coords.left - this.padding.text, top: top}, item.valueShown);

        // country flag
        if (item.flag)
            this.canvas.main.drawImage(item.flag.canvas, {left: 0, top: 0, width: item.flag.canvas.width, height: item.flag.canvas.height}, {left: this.btn.country.coords.left, top: tmp.top + this.padding.flag, width: item.flag.canvas.width, height: item.flag.canvas.height});

        // separation lines
        this.canvas.main.drawLine({left: this.btn.value.coords.left,   top: tmp.top, width: 0, height: tmp.height}, "strokeStyle", COLOR.line, "lineWidth", this.lineWidth.int);
        this.canvas.main.drawLine({left: this.btn.country.coords.left, top: tmp.top, width: 0, height: tmp.height}, "strokeStyle", COLOR.line, "lineWidth", this.lineWidth.int);
        this.canvas.main.drawLine({left: this.btn.address.coords.left, top: tmp.top, width: 0, height: tmp.height}, "strokeStyle", COLOR.line, "lineWidth", this.lineWidth.int);
        this.canvas.main.drawLine({left: this.btn.name.coords.left,    top: tmp.top, width: 0, height: tmp.height}, "strokeStyle", COLOR.line, "lineWidth", this.lineWidth.int);

        this.refresh_rect(tmp, isHover, isPressed);

        tmp.top += this.coords.item.height;
    }

    refresh_btns() {
        let i = 0,
            btn, isHover, isPressed, isKeepPressed;

        for (const btnName in this.btn) {
            btn     = this.btn[btnName];
            isHover = isPressed = isKeepPressed = false;

            if (btn.isKeepPressed || this.colSelected == btnName)
                isKeepPressed = true;

            if (i == this.mouse.btns.pressed)
                isPressed = true;
            else if (!BK_ENGINE.isTouchScreen && i == this.mouse.btns.hover)
                isHover = true;

            this.canvas.main.drawImage(btn.canvas, {left: 0, top: 0, width: btn.coords.width, height: btn.coords.height}, btn.coords);
            this.refresh_rect(btn.coords, isHover, isPressed, isKeepPressed);
            this.canvas.main.drawRect(btn.coords, COLOR.line, false, this.lineWidth.int);

            i++;
        }
    }

    refresh_rect(coords, isHover, isPressed, isKeepPressed) {
        if (isKeepPressed)
            this.canvas.main.drawRect(coords, COLOR.keepPressed, true);

        if (isPressed)
            return this.canvas.main.drawRect(coords, COLOR.pressed, true);

        if (isHover && !BK_ENGINE.isTouchScreen)
            this.canvas.main.drawRect(coords, COLOR.hover, true);
    }
}

export const DONATORS_LIST = BK_ENGINE.tasks.add(new DonatorsList(BK_ENGINE.tasks));
