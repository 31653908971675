
import { BKengineItem } from "./_item.js"
import { BKengineTextContent } from "../complex/text content.js"
import { BKengineTextDisplay } from "../complex/text display.js"


class BKengineTextArea extends BKengineItem {
    constructor() {
        super();

        this.textContent = new BKengineTextContent();
        this.textDisplay = new BKengineTextDisplay();
        this.texts       = [];

        this.type        = "textArea";
    }

    set isCenterH(value) {
        this.textDisplay.isCenterH = value;
    }

    set isCenterV(value) {
        this.textDisplay.isCenterV = value;
    }

    set content(value) {
        this.textContent.init(value);

        this.isRedrawn = false;
    }

    addText(text) {
        this.texts.push(text);
        this.textContent.setTexts(this.texts);
    }

    resize() {
        if (this.texts.length > 0)
            this.textDisplay.resize(this.width, this.height);
        else
            console.error("Text area: " + this.name + " - no texts found!!!");

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        this.textDisplay.reset();
        this.textDisplay.addTextContent(this.textContent);

        this.isRedrawn = true;
    }

    draw(canvas) {
        canvas.saveTransp();
        canvas.setTransp(this.transp);
        this.textDisplay.draw(canvas, this);
        canvas.restoreTransp();

        // canvas.drawImage(this.canvas,
        //                  {left: 0, top: 0, width: this.width, height: this.height},
        //                  this);
    }
}


export { BKengineTextArea };
