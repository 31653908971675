
import { BKengineRect } from "../primitives/rect.js"
import { BKengineText } from "../primitives/text.js"
import { BKengineItem } from "./_item.js"


class BKengineProgressBar extends BKengineItem {
    constructor() {
        super();
        
        this.type            = "progressBar";
        
        this.rectOut         = new BKengineRect();
        this.rectIn          = new BKengineRect();
        this.text            = new BKengineText();
        
        this.rectInCoords    = {left: 0, top: 0, width: 0, height: 0, m: 0.1, margin: 0};
        this.textCoords      = {left: 0, top: 0};

        this.progress        = 0;
        this.caption         = "";

        this.isUpdateCaption = true;        // when true there will be "%progress"

        this.init();
    }

    init() {
        this.rectOut.isFill    = true;
        this.rectOut.colorFill = "#eeeeee";
        this.rectOut.colorLine = "#eeeeee";
        this.rectOut.round     = 3;

        this.rectIn.isFill     = true;
        this.rectIn.colorFill  = "#8888ff";
        this.rectIn.colorLine  = "#8888ff";
        this.rectIn.round      = 3;

        this.text.font         = "Cambria";
        this.text.align        = "center";
        this.text.baseline     = "middle";
        this.text.color        = "#000000";
        this.text.h            = 0.6;
    }

    resize() {
        let m = Math.round(this.rectInCoords.m * this.width),
            n = Math.round(this.rectInCoords.m * this.height);

        this.rectInCoords.margin = m < n ? m : n;
        this.rectInCoords.height = this.height - this.rectInCoords.margin * 2;

        this.text.calcHeight(this.height);

        this.isResized = true;
        this.isRedrawn = false;
    }

    update() {
        this.updateParentMin();

        if (this.isUpdateSize)
            this.updateSize();

        if (!this.isResized)
            this.resize();

        if (!this.isRedrawn)
            this.redraw();

        if (this.isUpdatePos)
            this.updatePos();

        this.updateInsidePos();
        this.updateProgress();
    }

    updateInsidePos() {
        this.rectInCoords.left = this.left + this.rectInCoords.margin;
        this.rectInCoords.top  = this.top  + this.rectInCoords.margin;

        this.textCoords.left = Math.round(this.left + this.width  / 2);
        this.textCoords.top  = Math.round(this.top  + this.height / 2);
    }

    updateProgress() {
        this.rectInCoords.width = Math.round((this.width - this.rectInCoords.margin * 2) * this.progress);

        if (this.isUpdateCaption)
            this.caption = Math.round(this.progress * 100) + "%";
    }

    draw(canvas) {
        this.rectOut.draw(canvas, this);
        this.rectIn.draw(canvas, this.rectInCoords);

        this.text.prepare(canvas);
        this.text.draw(canvas, this.textCoords, this.caption);

        // let coordsSrc = {left: 0, top: 0, width: this.width, height: this.height},
        //     canvasSrc = this.isChecked ? this.canvas.checked : this.canvas.unchecked;

        // if (this.isPressed)
        //     canvas.drawImage(canvasSrc.pressed, coordsSrc, this);
        // else if (this.isHover)
        //     canvas.drawImage(canvasSrc.hover, coordsSrc, this);
        // else
        //     canvas.drawImage(canvasSrc.normal, coordsSrc, this);
    }
}


export { BKengineProgressBar };
