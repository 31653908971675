// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/AlexBrush-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\nbody {\r\n    -webkit-tap-highlight-color: rgba(0,0,0,0);\r\n    -webkit-user-select:   none;\r\n    -webkit-touch-callout: none;\r\n    -moz-user-select:      none;\r\n    -ms-user-select:       none;\r\n    user-select:           none;\r\n    font-size:             100%;\r\n    margin:                0;\r\n    background-color:    #000000;\r\n}\r\n\r\ncanvas {\r\n    padding-left:     0;\r\n    padding-right:    0;\r\n    margin-left:      auto;\r\n    margin-right:     auto;\r\n    display:          block;\r\n    background-color: #010101;\r\n}\r\n\r\n#mainDonate { \r\n    display:    none;\r\n}\r\n\r\n@font-face {\r\n  font-family: bibleQuote;\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/css/styles.css"],"names":[],"mappings":";AACA;IACI,0CAA0C;IAC1C,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;IAC3B,wBAAwB;IACxB,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE,uBAAuB;EACvB,4CAAqC;AACvC","sourcesContent":["\r\nbody {\r\n    -webkit-tap-highlight-color: rgba(0,0,0,0);\r\n    -webkit-user-select:   none;\r\n    -webkit-touch-callout: none;\r\n    -moz-user-select:      none;\r\n    -ms-user-select:       none;\r\n    user-select:           none;\r\n    font-size:             100%;\r\n    margin:                0;\r\n    background-color:    #000000;\r\n}\r\n\r\ncanvas {\r\n    padding-left:     0;\r\n    padding-right:    0;\r\n    margin-left:      auto;\r\n    margin-right:     auto;\r\n    display:          block;\r\n    background-color: #010101;\r\n}\r\n\r\n#mainDonate { \r\n    display:    none;\r\n}\r\n\r\n@font-face {\r\n  font-family: bibleQuote;\r\n  src: url(fonts/AlexBrush-Regular.ttf);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
