
import { BKengineFile } from "../primitives/file.js"


class Files {
    constructor() {
        this.all = [];
    }

    add(file) {
        return this.all[this.all.push(file) - 1];
    }

    addNew() {
        let file = new BKengineFile(),
            i, l, prop, value;

        for (i = 0, l = arguments.length; i < l; i += 2) {
            prop  = arguments[i];
            value = arguments[i + 1];

            file[prop] = value;
        }

        return this.add(file);
    }
}


export const FILES = new Files();
