
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";


class ShowScore extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    set caption(value) {
        this.item.label.caption = "" + value;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("bible_score"),
                     label:     I.getByName("bible_score_label")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const SHOW_SCORE = BK_ENGINE.tasks.add(new ShowScore(BK_ENGINE.tasks));
