
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { pad } from "../bk utils/_bk utils.js";
import { GW_USER } from "../gw user/_gw user.js";
import { GW_MAIN } from "../gw main/_gw main.js";
import { COOKIES } from "../gw main/cookies.js";
import { HINT_BOX } from "../gw main/hint box.js";
import { SWITCH_READ_PLAY } from "./switch read play.js";
import { READ_MOVE } from "./read move.js";
import { PLAY_MOVE } from "./play move.js";
import { BIBLE_PARTS } from "./parts.js";
import { BIBLE_BOOKS } from "./books.js";
import { INFO_BAR } from "./info bar.js";
import { MENU_BAR } from "./menu bar.js";
import { BIBLE_ATLAS } from "./read/bible atlas/_bible atlas.js";
import { BIBLE_INTRO } from "./read/bible intro/_bible intro.js";
import { HOLY_BIBLE } from "./read/holy bible/_holy bible.js";
import { G0001 } from "./play/g0001/_bible g0001.js";
import { G0002 } from "./play/g0002/_bible g0002.js";


// TO DO: there are no chapters 13,14 in the book of Daniel (protestant)
const DEVELOP = {jumpTo:                  "",                                       // g0001, g0002, holy bible, bibleAtlas,
                 booksGuessed:            {start: -1, nm: 10, chaptersNm: 50},      // start = -1: do nothing, then 0-72
                 isSetRandomBookAtResume: false};

const PATH   = {ui:       "gw bible/ui",
                uiImages: "gw bible/ui",
                lang:     "gw bible/lang"},
      FILES  = {ui:       {verNarrow: "_ver narrow - 20230705", verWide: "_ver wide - 20230705", horNarrow: "_hor narrow - 20230705", horWide: "_hor wide - 20230705"},
                uiImages: {low: "images low - 20230705", med: "images med - 20230705", high: "images high - 20230705"},
                lang:     {en:   {c: "en c - 20230801",   p: "en p - 20230801"},
                           es:   {c: "es c - 20230801",   p: "es p - 20230801"},
                           esla: {c: "esla c - 20230801", p: "esla p - 20230801"},
                           fr:   {c: "fr c - 20230801",   p: "fr p - 20230801"},
                           it:   {c: "it c - 20230801",   p: "it p - 20230801"},
                           pl:   {c: "pl c - 20230801",   p: "pl p - 20230801"}}},
      COOKIE_NAME = "gwBible";

const READ = {bibleIntro: 0, holyBible: 1, bibleAtlas: 2},
      PLAY = {g0001: 0, g0002: 1};


class Init extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.MAIN = null;

        this.file = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                     uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages)};
    }

    start2(MAIN) {
        this.MAIN                   = MAIN;
        this.file.ui.fileName       = FILES.ui[BK_ENGINE.viewPort.orientation];
        this.file.uiImages.fileName = FILES.uiImages[BK_ENGINE.viewPort.imagesQuality];
    }

    update() {
        let ITEMS = BK_ENGINE.items;

        if (!this.file.ui.isLoaded || !this.file.uiImages.isLoaded)
            return;

        // user interface
        BK_ENGINE.addFromData(this.file.ui.data);
        BK_ENGINE.setFromData(this.file.uiImages.data);

        // items
        this.MAIN.item = {mainFrame:      ITEMS.getByName("bible"),
                          background:     ITEMS.getByName("bible_background"),
                          desktopRead:    ITEMS.getByName("bible_desktopRead"),
                          desktopReadBtn: ITEMS.getByName("bible_desktopRead_btn"),
                          desktopPlay:    ITEMS.getByName("bible_desktopPlay"),
                          desktopPlayBtn: ITEMS.getByName("bible_desktopPlay_btn"),
                          game:           ITEMS.getByName("bible_game")};

        this.MAIN.isInitiated = true;
   
        ITEMS.orderZ();
        this.end();
        this.MAIN.starting();
    }
}

const INIT = BK_ENGINE.tasks.add(new Init(BK_ENGINE.tasks));


class SetLang extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.MAIN = null;
    }

    start2(MAIN) {
        this.MAIN               = MAIN;
        MAIN.file.lang.fileName = FILES.lang[MAIN.user.lang][MAIN.user.denom];

        MAIN.file.lang.load();
    }

    update() {
        if (!this.MAIN.file.lang.isLoaded)
            return;

        BK_ENGINE.setFromData(this.MAIN.file.lang.data);

        this.end();
        this.MAIN.afterLangSet();
    }
}

const SET_LANG = BK_ENGINE.tasks.add(new SetLang(BK_ENGINE.tasks));


class GWbible extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file         = {lang: BK_ENGINE.files.addNew("path", PATH.lang)};
        this.item         = null;
        this.parts        = BIBLE_PARTS;
        this.books        = BIBLE_BOOKS;
        this.user         = {id: "", lang: "", denom: "", isResetChapters: false, isRefreshBooks: false, isProgressLoaded: false};      // if changed, reset some things (the books)
        this.readSelected = {src: 0, dst: 0, isOpen: false};    // 0-2: bible intro, holy bible, bible atlas; isOpen: open at the end of moving?
        this.playSelected = {src: 0, dst: 0, isOpen: false};    // 0-1: g0001, g0002, isOpen: open at the end of moving?
        this.preset       = {name: ""};                         // from GW_MAIN would like to jump somewhere (call presetSet())

        this.isPaused     = false;
        this.isShowRead   = false;          // if false, it's showing "play"
        this.isInitiated  = false;

        this._isShowFirstTimeHint = true;
    }

    set isShowFirstTimeHint(value) {
        this._isShowFirstTimeHint = value;

        this.updateCookie();
    }

    get isShowFirstTimeHint() {
        return this._isShowFirstTimeHint;
    }

    presetSet(name) {
        this.preset.name = name;
    }

    readCookie() {
        let cookie = COOKIES.getOne(COOKIE_NAME),
            i;

        if (cookie.length == 0) return;
        this.isShowFirstTimeHint = cookie.charAt(0) == "1";

        if (cookie.length < 3) return;
        i = parseInt(cookie.substring(1,3));
        if (!isNaN(i)) this.readSelected.dst = Math.max(0, Math.min(Object.keys(READ).length - 1, i));
            
        if (cookie.length < 5) return;
        i = parseInt(cookie.substring(3,5));
        if (!isNaN(i)) this.playSelected.dst = Math.max(0, Math.min(Object.keys(PLAY).length - 1, i));
    }

    updateCookie() {
        let isShowFirstHint = this.isShowFirstTimeHint ? "1" : "0",
            readSelectedDst = pad(this.readSelected.dst, 2),
            playSelectedDst = pad(this.playSelected.dst, 2);

        COOKIES.setOne(COOKIE_NAME, isShowFirstHint + readSelectedDst + playSelectedDst);
    }

    start2() {
        if (!this.isInitiated) {
           this.readCookie();

           return INIT.start(this);
        }

        this.starting();
    }

    starting() {
        this.user.isResetChapters = this.user.isRefreshBooks = this.user.isLangChanged = false;

        // user changed?
        if (this.user.id != GW_USER.id) {
            this.user.isResetChapters = this.user.id.length == 0 ? false : true;
            this.user.id              = GW_USER.id;

            G0002.reset();
        }

        // language changed?
        if (this.user.lang != GW_USER.lang) {
            this.user.lang = GW_USER.lang;

            this.user.isRefreshBooks = true;
        }

        // version changed?
        if (this.user.denom != GW_USER.denom) {
            this.user.denom = GW_USER.denom;

            this.user.isRefreshBooks = this.user.isResetBooks = true;
        }

        if (this.user.isRefreshBooks)
            return SET_LANG.start(this);

        this.afterLangSet();
    }

    afterLangSet() {
        // reset chapters?
        if (this.user.isResetChapters) {
            this.user.isProgressLoaded = false;

            this.books.resetChapters();
        }

        // refresh books? (because lang changed, for example)
        if (this.user.isRefreshBooks) {
            this.parts.refresh();
            this.books.refresh();
        }

        // show all
        if (this.isShowFirstTimeHint) {
            let content              = "content=" + this.file.lang.getCaption("hintSwitchReadPlay");
            this.isShowFirstTimeHint = false;

            HINT_BOX.start(content, "x=1", "y=1", "pivX=-1.03", "pivY=-1.4", "btnName=any");
        }

        INFO_BAR.start();
        MENU_BAR.start();
        this.show(true);

        // DEVELOP
            // set some chapters to be guessed
        if (DEVELOP.booksGuessed.start > -1) {
            let booksNm = Math.min(DEVELOP.booksGuessed.start + DEVELOP.booksGuessed.nm, this.books.denomIndexes.length),
                i, j, book, chapterMax;

            for (i = DEVELOP.booksGuessed.start; i < booksNm; i++) {
                book       = this.books.getByI(this.books.denomIndexes[i]);
                chapterMax = (DEVELOP.booksGuessed.chaptersNm > 0) ? DEVELOP.booksGuessed.chaptersNm + 1: book.chaptersNm;

                for (j = 1; j < chapterMax; j++)
                    book.setChapterDifficulty(j, 3);
            }
        }

            // jump
        switch (DEVELOP.jumpTo) {
            case "g0001":
            case "g0002":
                return this.startGame(DEVELOP.jumpTo);
            case "holy bible":
                return SWITCH_READ_PLAY.start();
            case "bibleAtlas":
                return this.bibleAtlas.start();
        }

        // update because of presets or cookies
        if (this.preset.name == "game0002") {
            this.preset.name      = "";
            this.playSelected.dst = 1;

            if (this.isShowRead)
                SWITCH_READ_PLAY.start();
        }

        READ_MOVE.update(1, false);
        PLAY_MOVE.update(1, false);
    }

    end2() {
        this.show(false);
        INFO_BAR.end();
        MENU_BAR.end();
        GW_MAIN.start();
    }

    pause() {
        this.isPaused                   = true;
        this.item.desktopRead.isVisible = false;

        INFO_BAR.end();
    }

    resume() {
        this.isPaused                   = false;
        this.item.desktopRead.isVisible = true;

        if (DEVELOP.isSetRandomBookAtResume) {
            let book = this.books.getByI(this.books.denomIndexes[0]);
            let rnd = Math.round(Math.random() * book.chaptersNm);

            book.setChapterDifficulty(rnd, 3);
        }

        INFO_BAR.start();
        this.resetMenuBar();
    }

    update() {
        if (this.isPaused      || INIT.isActive      || SET_LANG.isActive || HINT_BOX.isActive ||
            READ_MOVE.isActive || PLAY_MOVE.isActive || SWITCH_READ_PLAY.isActive)
                return;

        switch (MENU_BAR.selected) {           // check menu bar buttons
            case "switchReadPlay":
                return SWITCH_READ_PLAY.start();
            case "quit":
                return this.end();
            default:                                    // mouse wheel?
                if (BK_ENGINE.input.mouseWheel < 0 && !this.isShowRead)
                    return SWITCH_READ_PLAY.start();

                if (BK_ENGINE.input.mouseWheel > 0 && this.isShowRead)
                    return SWITCH_READ_PLAY.start();

                this.update_userData();

                if (this.isShowRead)
                    return this.update_showingRead();

                this.update_showingPlay();
        }
    }

    update_showingRead() {
        switch (BK_ENGINE.clicked.name) {
            case "bible_btnBibleIntro":
                return READ_MOVE.start(READ.bibleIntro, true);
            case "bible_btnHolyBible":
                return READ_MOVE.start(READ.holyBible, true);
            case "bible_btnBibleAtlas":
                return READ_MOVE.start(READ.bibleAtlas, true);
            default:
                switch (MENU_BAR.selected) {           // check menu bar buttons
                    case "prev":
                        return READ_MOVE.start(this.readSelected.dst - 1);
                    case "next":
                        READ_MOVE.start(this.readSelected.dst + 1);
                }
        }
    }

    update_showingPlay() {
        switch (BK_ENGINE.clicked.name) {
            case "bible_desktopPlay_btnG0001":
                return PLAY_MOVE.start(PLAY.g0001, true);
            case "bible_desktopPlay_btnG0002":
                return PLAY_MOVE.start(PLAY.g0002, true);
            default:
                switch (MENU_BAR.selected) {           // check menu bar buttons
                    case "prev":
                        return PLAY_MOVE.start(this.playSelected.dst - 1);
                    case "next":
                        PLAY_MOVE.start(this.playSelected.dst + 1);
                }
        }
    }

    update_userData() {
        if (this.user.isProgressLoaded || !GW_USER.isLoggedIn || !GW_USER.isVerified || !GW_USER.isLoaded)
            return;
        
        let i, l, data, bookI, chapterI, difficulty, book;

        for (i = 0, l = GW_USER.progress.length; i < l; i++) {
            data = GW_USER.progress[i].data;

            if (data.charAt(0) == "a") {                        // game 0001
                bookI      = parseInt(data.substring(1, 3)),
                chapterI   = parseInt(data.substring(3, 6)),
                difficulty = parseInt(data.substring(6, 7));
                book       = this.books.getByI(bookI);

                book.setChapterDifficulty(chapterI, difficulty);
            }
        }

        this.user.isProgressLoaded = true;

        console.log("User progress upadated");
    }

    read() {
        let name = Object.keys(READ)[this.readSelected.src];

        this.pause();

        switch(name) {
            case "bibleAtlas":
                return BIBLE_ATLAS.start();
            case "bibleIntro":
                return BIBLE_INTRO.start();
            case "holyBible":
                HOLY_BIBLE.start();
        }
    }

    startGame(oName) {
        let name = oName || Object.keys(PLAY)[this.playSelected.src];

        this.isPaused                   = true;
        this.item.desktopRead.isVisible = this.item.desktopPlay.isVisible = false;

        INFO_BAR.end();

        switch (name) {
            case "g0001":
                return G0001.start();
            case "g0002":
                MENU_BAR.end();
                this.item.game.isVisible = true;
                G0002.start();
        }
    }

    resumeFromGame() {
        this.isPaused                   = this.playSelected.isOpen        = this.item.game.isVisible = false;
        this.item.desktopRead.isVisible = this.item.desktopPlay.isVisible = true;

        INFO_BAR.start();
        MENU_BAR.start();
        this.resetMenuBar();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible)
            this.resetMenuBar();
    }

    showBackground(isVisible = true) {
        this.item.background.isVisible = isVisible;
    }

    refreshDesktop() {
        this.item.desktopRead.isVisible = this.isShowRead;
        this.item.desktopPlay.isVisible = !this.isShowRead;
    }

    resetMenuBar() {
        if (this.isShowRead) {
            this.item.desktopPlay.isVisible = false;

            MENU_BAR.reset("mainRead", this.readSelected.src == 0, this.readSelected.src == Object.keys(READ).length - 1);
        } else {
            this.item.desktopRead.isVisible = false;

            MENU_BAR.reset("mainPlay", this.playSelected.src == 0, this.playSelected.src == Object.keys(PLAY).length - 1);
        }
    }
}

const GW_BIBLE = BK_ENGINE.tasks.add(new GWbible(BK_ENGINE.tasks));

export { GW_BIBLE, INFO_BAR, MENU_BAR };
