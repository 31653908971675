
import { BK_ENGINE, Coords } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0002 play display.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";


let OFFSET_LEFT   = 0.1,
    OFFSET_BOTTOM = 0.1,
    GAP_CHAR      = 0.01,   // gap between 2 chars
    GAP_WORD      = 0.25,   // gap between 2 words
    GAP_LINE      = 0.04;   // gap between 2 lines


class Letter {
    constructor(char = "") {
        this.char   = char;
        this.coords = new Coords();
    }
}

class Word {
    constructor() {
        this.letters = [];
        this.coords  = new Coords();
    }
}


class DisplayQuote {
    constructor() {
        let CANVASES = BK_ENGINE.canvases;

        this.canvas      = {bgd: CANVASES.addNew(),
                            pgd: CANVASES.addNew(),
                            tmp: CANVASES.tmp};
        this.coords      = new Coords();
        this.wordHeight  = 0;

        this.text        = null;
        this.words       = null;

        this.isInitiated = false;
    }

    getLastWord() {
        if (this.words.length > 0)
            return this.words[this.words.length - 1];
        
        return null;
    }

    getLastLetterCoords() {
        let word    = this.getLastWord(),
            letterI = 1;

        do {} while (++letterI < word.letters.length - 1 && word.letters[letterI].char != " ");

        let coords  = word.letters[letterI].coords,
            gapChar = Math.round(PLAYFIELD.tileSize * GAP_CHAR);

        return {left: coords.left + coords.width + gapChar, top: coords.top, width: coords.width, height: this.wordHeight};
    }

    init() {
        this.text = BK_ENGINE.texts.getByName("bible_g0002_play_letter");

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.words = [];
    }

    reset(isShowLastWord = false) {
        let word, logicsWord;

        this.words = [];

        while (this.words.length < LOGICS.words.length) {
            logicsWord = LOGICS.words[this.words.length];
            word       = new Word();

            this.reset_addLetters(word, logicsWord.partFirst);
            this.reset_addLetters(word, logicsWord.charsToGuess);
            this.reset_addLetters(word, logicsWord.partLast);

            this.words.push(word);
        }

        this.refresh(isShowLastWord);
    }

    reset_addLetters(word, chars) {
        let letterI;

        for (letterI = 0; letterI < chars.length; letterI++)
            word.letters.push(new Letter(chars.charAt(letterI)));
    }

    resize() {
        this.coords.width  = DISPLAY.coords.width;
        this.coords.height = PLAYFIELD.coords.main.top;

        this.canvas.bgd.resize(this.coords.width, this.coords.height);
        this.canvas.pgd.resize(this.coords.width, this.coords.height);

        this.redraw();
        this.refresh();
    }
    
    redraw() {
        let img = DISPLAY.image.quoteBgd;

        this.canvas.bgd.drawImage(img, {left: 0, top: 0, width: img.width, height: img.height}, this.coords);
    }

    refresh(isShowLastWord = false) {         // call only when changed!
        let wordsNm = isShowLastWord ? this.words.length : this.words.length - 1,
            wordI, word, letterI, letter, lettersNm;

        this.refresh_lettersPos();
        // this.canvas.pgd.clear();
        this.canvas.pgd.drawImage(this.canvas.bgd);
        this.text.prepare(this.canvas.pgd);

        for (wordI = 0; wordI < wordsNm; wordI++) {
            word = this.words[wordI];

            lettersNm = word.letters.length;
            // lettersNm = (wordI == (this.wordI - 1) && this.isShowLastLetter) ? word.length : word.length - 1;

            for (letterI = 0; letterI < lettersNm; letterI++) {
                letter = word.letters[letterI];

                this.text.draw(this.canvas.pgd, {left: letter.coords.left, top: letter.coords.top}, letter.char);
            }            
        }
    }

    refresh_lettersPos() {
        let size         = Math.floor(PLAYFIELD.coords.main.height / PLAYFIELD.size);
        let offsetLeft   = Math.floor(size * OFFSET_LEFT),
            offsetBottom = Math.floor(size * OFFSET_BOTTOM),
            gapChar      = Math.round(size * GAP_CHAR),
            gapWord      = Math.round(size * GAP_WORD);
        let left         = offsetLeft,
            top          = 0,
            gapLine, word, wordI, letter, letterI, topLast;

        this.wordHeight = this.text.calcHeight(size);
        gapLine         = Math.max(1, Math.round(this.wordHeight * GAP_LINE)),
            
        this.canvas.tmp.resize(size, size);
        this.text.prepare(this.canvas.pgd);

        // calc widths and words' left
        for (wordI = 0; wordI < this.words.length; wordI++) {
            word              = this.words[wordI];
            word.coords.left  = left;
            word.coords.top   = top;
            word.coords.width = 0;
    
            for (letterI = 0; letterI < word.letters.length; letterI++) {
                letter              = word.letters[letterI];
                letter.coords.width = Math.ceil(this.text.getWidth(this.canvas.tmp, letter.char)) + gapChar;

                word.coords.width += letter.coords.width;
            }

            if (left + word.coords.width + gapChar >= this.coords.width) {
                word.coords.left = left = offsetLeft;
                word.coords.top  = top  = top + 1;
            }

            left += word.coords.width + gapWord;
        }

        // update letters' left and top
        if (this.words.length == 0)
            return;

        topLast = this.words[this.words.length - 1].coords.top;

        for (wordI = 0; wordI < this.words.length; wordI++) {
            word            = this.words[wordI];
            word.coords.top = this.coords.height - offsetBottom - Math.round((topLast - word.coords.top + 0.5) * (this.wordHeight + gapLine));
            left            = word.coords.left;
    
            for (letterI = 0; letterI < word.letters.length; letterI++) {
                letter             = word.letters[letterI];
                letter.coords.left = left;
                letter.coords.top  = word.coords.top;

                left += letter.coords.width;
            }
        }
    }
}

export const DISPLAY_QUOTE = new DisplayQuote();
