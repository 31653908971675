
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js";
import { AUDIO } from "./g0002 audio.js";


const VOLUME = {multiply: 1.08, repeatSoundEvery: 200};     // multiply: the volume image doesn't respect exactly the audio volume, so it will be corrected


class Settings extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE       = {loading: 10, starting: 20, main: 30},
        this.state       = 0;

        this.image       = null;
        this.canvas      = null;
        this.item        = null;
        this.coords      = {soundVolume: new Coords()};

        this.isInitiated = false;
    }

    init() {
        let CANVASES = BK_ENGINE.canvases,
            ITEMS    = BK_ENGINE.items;

        this.image             = {soundVolume: BK_ENGINE.images.getByName("bible_g0002_menu_volume")};
        this.canvas            = {soundVolume: CANVASES.getByName("bible_g0002_settings_soundVolume"),
                                  tmp:         CANVASES.addNew()};
        this.item              = {main:        ITEMS.getByName("bible_g0002_settings"),
                                //   frame:       ITEMS.getByName("bible_g0002_settings_frame"),
                                //   bgd:         ITEMS.getByName("bible_g0002_settings_bgd"),
                                  soundOff:    ITEMS.getByName("bible_g0002_settings_soundOff"),
                                  soundOn:     ITEMS.getByName("bible_g0002_settings_soundOn"),
                                  soundVolume: ITEMS.getByName("bible_g0002_settings_soundVolume")};
        this.timer             = 0;

        this.state       = this.STATE.loading;
        this.isInitiated = true;
    }

    start2() {
        this.state = this.STATE.starting;

        !this.isInitiated && this.init();
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (this.state) {
            case this.STATE.loading:
                this.loading();
                break;
            case this.STATE.starting:
                this.starting();
                break;
            case this.STATE.main:
                this.main();
        }
    }

    loading() {
        if (!this.image.soundVolume.isLoaded)
            return;

        this.state = this.STATE.starting;
    }

    starting() {
        this.show(true);

        if (this.item.main.width  == 0 ||
            this.item.main.height == 0)
                return;

        this.state = this.STATE.main;
    }

    main() {
        if (this.item.soundVolume.width  != this.coords.soundVolume.width ||
            this.item.soundVolume.height != this.coords.soundVolume.height)
                this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_settings_soundOn":
                AUDIO.play("menuClick");
                AUDIO.isOn = false;
                return this.redraw();
            case "bible_g0002_settings_soundOff":
                AUDIO.play("menuClick");
                AUDIO.isOn = true;
                return this.redraw();
            case "bible_g0002_settings_info":
                AUDIO.play("menuClick");
                return HOW.start();
            case "bible_g0002_settings_play":
                AUDIO.play("menuClick");
                return this.end("play");
            case "bible_g0002_settings_quit":
                AUDIO.play("menuClick");
                return this.end("quit");
            default:
                this.main_checkVolume();
        }

        this.refresh();
    }

    main_checkVolume() {
        if (!BK_ENGINE.input.isMouseDown ||
            BK_ENGINE.input.top < this.item.soundVolume.top ||
            BK_ENGINE.input.top > this.item.soundVolume.top + this.item.soundVolume.height)
                return;

        let volume = (BK_ENGINE.input.left - this.item.soundVolume.left) / this.item.soundVolume.width;

        if (volume < 0 || volume > 1)
            return;

        // adjust the volume
        AUDIO.isOn   = true;
        AUDIO.volume = Math.min(1, Math.max(0, (volume - 0.5) * VOLUME.multiply + 0.5));

        // make a sound?
        let dateNow = Date.now();

        if (dateNow - this.timer >= VOLUME.repeatSoundEvery) {
            this.timer = dateNow;

            AUDIO.play("menuClick");
        }

        this.redraw();
    }

    resize() {
        this.coords.soundVolume.resize(this.item.soundVolume.width, this.item.soundVolume.height);
        this.canvas.soundVolume.resize(this.item.soundVolume.width, this.item.soundVolume.height);

        this.redraw();
    }

    redraw() {
        let img       = this.image.soundVolume,
            width     = this.coords.soundVolume.width,
            height    = this.coords.soundVolume.height;
        let volumeWidth = Math.round(AUDIO.volume * width);

        this.canvas.tmp.resize(width, height * 2);

        BK_ENGINE.canvases.drawImageQuality(img, this.canvas.tmp, this.coords.soundVolume, 0, 0);
        BK_ENGINE.canvases.drawImageQuality(img, this.canvas.tmp, {left: 0, top: height, width: width, height: height}, 0, 1);

        this.canvas.soundVolume.drawImage(this.canvas.tmp, this.coords.soundVolume, this.coords.soundVolume);       // volume background

        if (AUDIO.isOn)
            this.canvas.soundVolume.drawImage(this.canvas.tmp, {left: 0, top: height, width: volumeWidth, height: height}, {left: 0, top: 0, width: volumeWidth, height: height});  // volume foreground

        this.item.soundVolume.isRedrawn = false;
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;
    }

    refresh() {
        // this.canvas.main.drawImage(this.canvas.bgd, this.coords.bgd, this.item.bgd);
        // this.canvas.main.drawImage(this.canvas.soundVolume, this.coords.soundVolume, this.item.soundVolume);

        this.item.soundOn.isVisible  = AUDIO.isOn;
        this.item.soundOff.isVisible = !AUDIO.isOn;
    }
}

export const SETTINGS = BK_ENGINE.tasks.add(new Settings(BK_ENGINE.tasks));
