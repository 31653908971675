
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js";
import { BKengineTextContent } from "../../../bk engine/complex/text content.js"
import { BKengineTextDisplay } from "../../../bk engine/complex/text display.js"
import { copyToClipboard } from "../../../bk utils/_bk utils.js";
import { CRYPTO } from "../../../const.js";
import { GW_MAIN, INFO_BOX, CONTROL_BOX } from "../../_gw main.js";
import { GW_DB } from "../../../gw db/_gw db.js";


const ITEMS_PADDING = {hor: 0.25, ver: 0.25},   // of btn "verify" height
      STATE         = {main: 20, contactMenu: 30};


class Item {
    constructor(texts, content) {
        this.textContent = new BKengineTextContent();
        this.textDisplay = new BKengineTextDisplay();
        this.height      = 0;

        this.textContent.texts = texts;

        this.textContent.init(content);
    }
}

class DonatorInfo extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.canvas       = null;
        this.texts        = [];
        this.item         = null;
        this.user         = null;
        this.items        = [];
        this.itemI        = 0;
        this.itemsPadding = {hor: 0, ver: 0};
        this.state        = 0;

        this.isScrollDownPossible = false;

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.canvas = BK_ENGINE.canvases.getByName("main_donatorInfo_bgdCanvas");

        this.item = {mainFrame:      I.getByName("main_donatorInfo"),
                     btnVerify:      I.getByName("main_donatorInfo_btnVerify"),
                     btnContact:     I.getByName("main_donatorInfo_btnContact"),
                     btnContactCopy: I.getByName("main_donatorInfo_btnContactCopy"),
                     btnContactGoTo: I.getByName("main_donatorInfo_btnContactGoTo"),
                     btnUp:          I.getByName("main_donatorInfo_btnUp"),
                     btnDown:        I.getByName("main_donatorInfo_btnDown")};

        this.texts.push(BK_ENGINE.texts.getByName("main_donatorInfo_itemTitle"),
                        BK_ENGINE.texts.getByName("main_donatorInfo_itemContent"));

        this.isInitiated = true;
    }

    initItems() {
        let U    = GW_DB.user,
            user = this.user;

        this.items = [];

        this.initItems_one(U.address, user.addressShort);
        this.initItems_one(U.name,    user.name);
        this.initItems_one(U.donated, CRYPTO.valueToDecBUSD(user.valueTotal) + " " + CRYPTO.tokenName);

        user.lang.length       > 0 && this.initItems_one(U.lang,       GW_DB.lang[user.lang]);
        user.country.length    > 0 && this.initItems_one(U.country,    GW_DB.country[user.country].name);
        user.relig.length      > 0 && this.initItems_one(U.relig,      GW_DB.relig[user.relig]);
        user.religDenom.length > 0 && this.initItems_one(U.religDenom, GW_DB.religDenom[user.religDenom]);

        user.faith > -1 && this.initItems_one(U.faith, "" + user.faith);

        this.initItems_one(U.contact, user.contact);
    }

    initItems_one(name, value) {
        if (value.length > 0)
            this.items.push(new Item(this.texts, "[*0*]" + name + ":" + "<br>" + "[*1*]" + value));
    }

    start2(user) {
        !this.isInitiated && this.init();

        this.state                      = STATE.main;
        this.user                       = user;
        this.item.btnContact.isDisabled = user.contact.length == 0;

        this.initItems();
        this.show(true);
    }

    end2() {
        this.show(false);
        this.canvas.resize(1, 1);
    }

    update() {
        if (this.item.mainFrame.width != this.canvas.width || this.item.mainFrame.height != this.canvas.height)
            this.resize();

        switch (this.state) {
            case STATE.main:
                return this.update_main();
            default:
                this.update_contactMenu();
        }
    }

    update_main() {
        switch (BK_ENGINE.clicked.name) {
            case "main_donatorInfo_btnVerify":
                window.open(CRYPTO.webScan + "address/" + this.user.address + "#tokentxns", '_blank');
                break;
            case "main_donatorInfo_btnContact":
                if (this.user.contact.indexOf("http") == 0 || this.user.contact.includes("@")) {
                    this.state = STATE.contactMenu;
                    this.showContactMenu(true);
                } else
                    copyToClipboard(this.user.contact, GW_MAIN.file.lang.getCaption("donatorInfo_contactCopied"), GW_MAIN.file.lang.getCaption("donatorInfo_contactCopyError"));
                break;
            case "main_donatorInfo_btnUp":
                this.itemI > 0 && this.itemI--;
                break;
            case "main_donatorInfo_btnDown":
                this.isScrollDownPossible && this.itemI++;
                break;
            case "main_donatorInfo_btnQuit":
                return this.end();
            default:
                BK_ENGINE.input.mouseWheel < 0 && this.itemI > 0 && this.itemI--;
                BK_ENGINE.input.mouseWheel > 0 && this.isScrollDownPossible && this.itemI++;
        }

        this.refresh();
    }

    update_contactMenu() {
        switch (BK_ENGINE.clicked.name) {
            case "main_donatorInfo_btnContactCopy":
                this.state = STATE.main;
                copyToClipboard(this.user.contact, GW_MAIN.file.lang.getCaption("donatorInfo_contactCopied"), GW_MAIN.file.lang.getCaption("donatorInfo_contactCopyError"));
                break;
            case "main_donatorInfo_btnContactGoTo":
                if (this.user.contact.indexOf("http") == 0)
                    window.open(this.user.contact, '_blank');
                else
                    window.open("mailto:"+this.user.contact, '_blank');
            default:
                if (BK_ENGINE.clicked.name.length > 0)
                    this.state = STATE.main;
        }

        this.state == STATE.main && this.showContactMenu(false);
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible)
            this.showContactMenu(false);
    }

    showContactMenu(isVisible) {
        this.item.btnContact.isKeepPressed = isVisible;
        this.item.btnContactCopy.isVisible = this.item.btnContactGoTo.isVisible = isVisible;
    }

    resize() {
        let width  = this.item.mainFrame.width,
            height = this.item.mainFrame.height;

        this.canvas.resize(width, height);

        this.itemsPadding.hor = Math.round(this.item.btnVerify.height * ITEMS_PADDING.hor);
        this.itemsPadding.ver = Math.round(this.item.btnVerify.height * ITEMS_PADDING.ver);

        this.resize_items(width, height);
    }

    resize_items(width, height) {
        let min = width < height ? width : height,
            i, item;

        min -= this.itemsPadding.hor * 2;

        for (i = 0; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.reset();
            item.textDisplay.resize(min, min);
            item.textDisplay.addTextContent(item.textContent);

            item.height = item.textDisplay.effectiveHeight;
        }
    }

    refresh() {
        this.refresh_items();

        this.item.btnUp.isVisible = this.item.btnDown.isVisible = this.itemI > 0 || this.isScrollDownPossible;
    }

    refresh_items() {
        let top    = this.item.btnVerify.top + this.item.btnVerify.height + this.itemsPadding.ver,
            width  = this.item.mainFrame.width,
            height = this.item.mainFrame.height,
            i, item;

        this.canvas.clear();

        this.isScrollDownPossible     = false;
        this.item.mainFrame.isRedrawn = false;

        for (i = this.itemI; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.draw(this.canvas, {left: this.itemsPadding.hor, top: top, width: width, height: height});

            top += item.height;

            if (top >= this.item.btnUp.top)
                return this.isScrollDownPossible = true;

            top += this.itemsPadding.ver * 2;
        }
    }
}

export const DONATOR_INFO = BK_ENGINE.tasks.add(new DonatorInfo(BK_ENGINE.tasks));
