
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { HELP } from "../../../help/help.js"
import { GW_MAIN, INFO_BOX } from "../../_gw main.js"
import { STATS } from "./stats.js";
import { FAITH_GRAPH } from "./faith graph.js";


const TIME_REFRESH = 500;


class UserInfo {
    constructor() {
        this.item        = null;
        this.date        = new Date();

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {notLoggedIn: I.getByName("main_userInfo_notLoggedIn"),
                     notVerified: I.getByName("main_userInfo_notVerified"),
                     loggedIn:    I.getByName("main_userInfo_loggedIn"),
                     grace:       I.getByName("main_userInfo_graceContent"),
                     faith:       I.getByName("main_userInfo_faithContent"),
                     timeLeft:    I.getByName("main_userInfo_timeLeftContent")};

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();
    }

    end() {}

    update() {
        if (INFO_BOX.isActive || STATS.isActive || FAITH_GRAPH.isActive)
            return;

        switch (BK_ENGINE.clicked.name) {
            case "main_userInfo_btnStats":
                return STATS.start();
            case "main_userInfo_btnFaithGraph":
                if (GW_USER.days.length == 0)
                    return INFO_BOX.start(GW_MAIN.file.lang.getCaption("user_faithGraph_noData"), "gotIt");
                return FAITH_GRAPH.start();
            case "main_userInfo_btnInfo":
                HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userInfo").trim());
        }

        this.refresh();
    }

    refresh() {
        let date = new Date();

        if (date - this.date < TIME_REFRESH)
            return;

        this.date = date;

        this.refresh_userState();
        this.refresh_userData();
    }

    refresh_userState() {
        this.item.notLoggedIn.isVisible = !GW_USER.isLoggedIn;
        this.item.notVerified.isVisible = GW_USER.isLoggedIn && !GW_USER.isVerified;
        this.item.loggedIn.isVisible    = GW_USER.isVerified;

        this.item.loggedIn.caption = GW_USER.nameOrAddress;
    }

    refresh_userData() {
        let t   = GW_USER.timeRemaining,
            txt = "" + t.minutes;

        if (t.minutes == 0)
            txt = "00";
        else if (t.minutes < 10)
            txt = "0" + t.minutes;

        this.item.grace.caption    = "" + GW_USER.graceToday;
        this.item.faith.caption    = "" + GW_USER.faith.total;
        this.item.timeLeft.caption = t.hours + " : " + txt;
    }
}

export const USER_INFO = new UserInfo();
