
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";


const TIME_MOVE = 500;


class SwitchReadPlay extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.time = 0;
    }

    start2() {
        GW_BIBLE.item.desktopRead.isVisible = GW_BIBLE.item.desktopPlay.isVisible = true;
        GW_BIBLE.isShowRead                 = !GW_BIBLE.isShowRead;
        this.time                           = Date.now();
    }

    update(oProgress) {
        let progress = oProgress || Math.min((Date.now() - this.time) / TIME_MOVE, 1);

        if (GW_BIBLE.isShowRead) {
            GW_BIBLE.item.desktopRead.y = -1 + progress;
            GW_BIBLE.item.desktopPlay.y = progress;
        } else {
            GW_BIBLE.item.desktopRead.y = 0 - progress;
            GW_BIBLE.item.desktopPlay.y = 1 - progress;
        }

        if (progress == 1) {
            this.end();
            GW_BIBLE.resetMenuBar();
        }
    }
}

export const SWITCH_READ_PLAY = BK_ENGINE.tasks.add(new SwitchReadPlay(BK_ENGINE.tasks));
