
import { BK_ENGINE, Task } from "../../../../bk engine/_bk engine.js";
import { getAlphabet } from "../../../../bk utils/languages.js";
import { pad } from "../../../../bk utils/_bk utils.js";
import { GW_USER } from "../../../../gw user/_gw user.js";
import { CONTROL_BOX } from "../g0002 control box.js";
import { G0002, GAME_CODE, DIFFICULTY } from "../_bible g0002.js";
import { AUDIO } from "../g0002 audio.js";
import { MENU } from "../g0002 menu.js";
import { QUOTES } from "../quotes/_g0002 quotes.js";
import { SHOW_QUOTE } from "../quotes/show quote.js";
import { SERVER } from "../g0002 server.js";
import { SETTINGS } from "../g0002 settings.js";
import { PLAY_LOGICS as LOGICS } from "./logics/_bible g0002 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./display/_bible g0002 play display.js";


const DEVELOP = {isShowStats: false, hp: -1, time: -1, attemptsNm: -1};    // -1: will be set to default

const ATTEMPTS_MAX   = 7,
      HP             = {min: 2, max: 10},
      PLAYFIELD_SIZE = 9,
      TIME           = {max: 9 * 60 + 59, guessingLastChar: 1000, highlightingWord: 1500};

const STARTING           = BK_ENGINE.tasks.addNew(),
      NEW_WORD           = BK_ENGINE.tasks.addNew(),
      GUESSING           = BK_ENGINE.tasks.addNew(),
      GUESSING_LAST_CHAR = BK_ENGINE.tasks.addNew(),
      HIGHLIGHTING_WORD  = BK_ENGINE.tasks.addNew(),
      MENU_SETTINGS      = BK_ENGINE.tasks.addNew(),
      QUITTING           = BK_ENGINE.tasks.addNew(),
      TIME_OUT           = BK_ENGINE.tasks.addNew(),
      ALL_GUESSED        = BK_ENGINE.tasks.addNew();


STARTING.end2 = function() {
    NEW_WORD.start();
}

STARTING.update = function() {
    if (!LOGICS.isActive) {
        LOGICS.start();
        DISPLAY.start();
    }

    if (!LOGICS.isReady() || !DISPLAY.isReady())
        return;

    let dif = DIFFICULTY[G0002.difficulty];

    LOGICS.startGame(PLAYFIELD_SIZE, dif.bricksNm, dif.ballSpeed, PLAY.calcAttemptsNm(), PLAY.calcHp(), getAlphabet(G0002.user.lang, true), PLAY.serverPacket.allCharsUsed);
    this.end();
}


class Play extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.serverPacket   = null;
        this.wordsToGuessNm = 0;
        this.charsToGuessNm = 0;
        this.hp             = 0;
        this.timePerWord    = 0;
        this.timeTotal      = 0;
        this.wordI          = -1;
    }

    getServerPacket(type, lastChar = "") {
        switch (type) {
            case "newWord":
                this.wordI++;
            case "stats":
            case "ref":
            case "checkLastChar":
                return this.serverPacket = SERVER.getPacket({type: type, wordI: this.wordI, lastChar: lastChar});
        }
    }

    calcAttemptsNm() {
        let nm = Math.floor(this.wordsToGuessNm / DIFFICULTY[G0002.difficulty].wordsPerAttempt);

        if (DEVELOP.attemptsNm > -1)
            nm = DEVELOP.attemptsNm;

        return Math.max(1, Math.min(ATTEMPTS_MAX, nm));
    }

    calcHp() {
        return this.hp = (DEVELOP.hp > -1) ? DEVELOP.hp : this.calcHp_find();
    }

    calcHp_find() {
        let hp         = HP.max,
            totalHpMax = DIFFICULTY[G0002.difficulty].totalHpMax;

        while (hp > HP.min && (this.charsToGuessNm * hp > totalHpMax)) {
            hp--;
        }

        return hp;
    }

    calcTimePerWord(charsToGuess) {
        let diff = DIFFICULTY[G0002.difficulty];
        let time = Math.round(diff.timeStart + charsToGuess.length * (diff.timePerLetter + this.hp / 2));

        if (DEVELOP.time > -1)
            time = DEVELOP.time;

        return Math.min(TIME.max, time) * 1000;
    }

    start2() {
        this.wordI = -1;

        GW_USER.end();           // stop it during gameplay
        SERVER.resetWords();

        this.getServerPacket("stats");

        this.wordsToGuessNm = this.serverPacket.wordsToGuessNm;
        this.charsToGuessNm = this.serverPacket.charsToGuessNm;

        if (DEVELOP.isShowStats)
            console.log("wordsToGuess: " + this.wordsToGuessNm, ", charsToGuessNm: " + this.charsToGuessNm);

        this.timeTotal = Date.now();

        STARTING.start();
    }

    end2() {
        LOGICS.end();
        DISPLAY.end();
        MENU.start();

        if (!GW_USER.isActive)
            GW_USER.start();
    }

    saveGame(quote) {
        let date  = quote.year + pad(quote.month + 1, 2) + pad(quote.day, 2),
            time  = pad(Math.min(9999, Math.floor((Date.now() - this.timeTotal) / 1000)), 4),     // seconds
            moves = pad(Math.min(9999, LOGICS.playerMoves), 4);

        // console.log("gameCode: " + GAME_CODE, "date: " + date + " difficultyI: " + G0002.difficultyI + " time: " + time, "moves: " + moves + "attemptsLeft: " + LOGICS.attemptsNm);
    
        GW_USER.saveGame("bible g0002", GAME_CODE + date + G0002.difficultyI + time + moves + LOGICS.attemptsNm);
    }    
}

const PLAY = BK_ENGINE.tasks.add(new Play(BK_ENGINE.tasks));


NEW_WORD.update = function() {
    let sp;

    if (LOGICS.getTimerWaiting() > 0)       // sometimes needs to wait before starting a new word
        return;

    do {
        sp = PLAY.getServerPacket("newWord");

        LOGICS.addNewWord(sp.partFirst, sp.charsToGuess, sp.partLast);

        if (sp.isToGuess) {
            LOGICS.startGuessingNewWord(PLAY.calcTimePerWord(sp.charsToGuess), false);
            GUESSING.start(sp.charsToGuess);

            return this.end();
        }
    } while (!sp.isToGuess && !sp.isLastWord);

    this.end();
    ALL_GUESSED.start();
}


GUESSING.chars = "";

GUESSING.start2 = function(chars) {
    this.chars = chars;
    // console.log("guessing chars: " + this.chars)
}

GUESSING.end2 = function(result) {
    if (result == "timeOut")
        return TIME_OUT.start();
// console.log(this.chars)
    if (this.chars && this.chars.length == 1) {       // TO DO! very seldom it throws an error (chars is undefined)
        AUDIO.play("lastCharCorrect");
        PLAY.getServerPacket("checkLastChar", this.chars);      // just to set word.isGuessed = true

        return HIGHLIGHTING_WORD.start();
    }

    GUESSING_LAST_CHAR.start();
}

GUESSING.update = function() {
    if (LOGICS.isWordGuessed)
        return this.end("wordGuessed");

    if (LOGICS.isTimeOut)
        return this.end("timeOut");

    if (!LOGICS.isPlayingStarted) {
        if (BK_ENGINE.clicked.name == "")
            return;

        LOGICS.startPlaying();
    }

    if (QUITTING.isActive || MENU_SETTINGS.isActive)
        return;

    switch (BK_ENGINE.clicked.name) {
        case "bible_g0002_play_pgd":
            return LOGICS.playfieldClicked({left: BK_ENGINE.input.left, top: BK_ENGINE.input.top});
        case "bible_g0002_play_btnQuit":
            AUDIO.play("menuClick");
            return QUITTING.start();
        case "bible_g0002_play_btnSettings":
            AUDIO.play("menuClick");
            MENU_SETTINGS.start();
    }
}


GUESSING_LAST_CHAR.STATE = {guide: 10, main: 20, finishing: 30};
GUESSING_LAST_CHAR.state = 0;

GUESSING_LAST_CHAR.start2 = function() {
    if (G0002.isShowGuessLastChar) {
        G0002.isShowGuessLastChar = false;
        G0002.updateCookie();
        CONTROL_BOX.start("", G0002.file.lang.getCaption("GuessLastCharGuide"), ["gotIt"]);

        this.state = this.STATE.guide;
    } else
        this.state = this.STATE.main;

    LOGICS.startGuessingLastChar();
}

GUESSING_LAST_CHAR.update = function() {
    switch (this.state) {
        case this.STATE.guide:
            return this.guide();
        case this.STATE.main:
            return this.main();
        case this.STATE.finishing:
            return this.finishing();
    }
}

GUESSING_LAST_CHAR.guide = function() {
    if (CONTROL_BOX.selected != "confirm")
        return;

    CONTROL_BOX.end();

    this.state = this.STATE.main;
}

GUESSING_LAST_CHAR.main = function() {
    let sp;

    if (BK_ENGINE.clicked.name != "bible_g0002_play_pgd")
        return;

    let charSelected = DISPLAY.getGuessingLastCharClicked(BK_ENGINE.input.left, BK_ENGINE.input.top);

    if (charSelected.length == 0)
        return;

    sp = PLAY.getServerPacket("checkLastChar", charSelected);

    if (sp.isGuessed)
        LOGICS.setLastCharGuessed(sp.char, TIME.guessingLastChar);
    else
        LOGICS.setLastCharToGuess(charSelected, sp.char, TIME.guessingLastChar);

    LOGICS.startWaiting();

    this.state = this.STATE.finishing;
}

GUESSING_LAST_CHAR.finishing = function() {
    // console.log(LOGICS.getTimerWaiting())
    if (LOGICS.getTimerWaiting() > 0)
        return;

    this.end();

    if (PLAY.serverPacket.isGuessed)
        HIGHLIGHTING_WORD.start();
    else {
        LOGICS.startGuessingNewWord(PLAY.calcTimePerWord(PLAY.serverPacket.char), true);
        GUESSING.start(PLAY.serverPacket.char);
    }
}


HIGHLIGHTING_WORD.start2 = function() {
    LOGICS.startHighlightingWord(TIME.highlightingWord);
}

HIGHLIGHTING_WORD.update = function() {
    if (LOGICS.getTimerWaiting() > 0)
        return;

    this.end();

    if (PLAY.serverPacket.isLastWord)
        ALL_GUESSED.start();
    else
        NEW_WORD.start();
}


MENU_SETTINGS.start2 = function() {
    LOGICS.pause();
    SETTINGS.start();
}

MENU_SETTINGS.update = function() {
    if (SETTINGS.isActive)
        return;

    LOGICS.unpause();
    this.end();
}


QUITTING.start2 = function() {
    LOGICS.pause();
    CONTROL_BOX.start("", G0002.file.lang.getCaption("Quitting"), ["yes", "no"]);
}

QUITTING.end2 = function(selected) {
    CONTROL_BOX.end();
    LOGICS.unpause();
}

QUITTING.update = function() {
    switch (CONTROL_BOX.selected) {
        case "confirm":
            PLAY.end();
            MENU.start();
            return this.end();
        case "abandon":
            this.end();
    }
}


TIME_OUT.start2 = function() {
    let content;

    switch (--LOGICS.attemptsNm) {
        case 0:
            content = G0002.file.lang.getCaption("TimeOut_noMoreAttempts");
            break;
        case 1:
            content = G0002.file.lang.getCaption("TimeOut_oneAttempt");
            break;
        default:
            content = G0002.file.lang.getCaption("TimeOut_manyAttempts");
            content = content.replace("**", "" + LOGICS.attemptsNm);
    }

    CONTROL_BOX.start("", content, ["yes", "no"]);
}

TIME_OUT.end2 = function() {
    CONTROL_BOX.end();
}

TIME_OUT.update = function() {
    switch (CONTROL_BOX.selected) {
        case "confirm":
            if (LOGICS.attemptsNm == 0)
                PLAY.start();
            else {
                LOGICS.startGuessingNewWord();
                GUESSING.start(PLAY.serverPacket.charsToGuess);
            }

            return this.end();
        case "abandon":
            PLAY.end();
            this.end();
    }
}


ALL_GUESSED.isUpdating = true;

ALL_GUESSED.start2 = function() {
    this.isUpdating = true;

    this.saveGame();
    AUDIO.play("allGuessed");
    LOGICS.end();
    DISPLAY.end();
    QUOTES.start();
}

ALL_GUESSED.update = function() {
    if (this.isUpdating) {
        if (QUOTES.isActive)
            return;

        SHOW_QUOTE.start();

        this.isUpdating = false;
    }

    if (SHOW_QUOTE.isActive)
        return;

    AUDIO.startFadeOut();
    PLAY.end();
    this.end();
    MENU.start();
}

ALL_GUESSED.saveGame = function() {
    let qServer = SERVER.getPacket({type: "quote"});

    if (QUOTES.add(qServer, G0002.difficultyI)) {
        PLAY.saveGame(QUOTES.getLast());
        GW_USER.start();
    }
}

export { PLAY };
