
import { BK_ENGINE, Task, Coords } from "../../bk engine/_bk engine.js"
import { GW_USER } from "../../gw user/_gw user.js"
import { GW_MAIN } from "../_gw main.js"
import { USER_INFO } from "./user info/_user info.js";
import { MENU_SETTINGS } from "./menu settings/_menu settings.js";
import { MENU_LOG_IN } from "./menu log in/_menu log in.js";
import { FAITH_GRAPH } from "./user info/faith graph.js";


const DEVELOP = {jumpTo: ""};     // stats, faithGraph


class MenuUser extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.coords      = {mainFrame: new Coords()};

        this.selected    = "";

        this.isOnceShown = false;
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame:    I.getByName("main_menuUser"),
                     menuInfo:     I.getByName("main_userInfo"),
                     menuSettings: I.getByName("main_userSettings"),
                     menuLogIn:    I.getByName("main_menuLogIn"),
                     btnLogIn:     I.getByName("main_menuLogIn_btnTitle"),
                     btnSettings:  I.getByName("main_userSettings_btnTitle"),
                     btnInfo:      I.getByName("main_userInfo_btnTitle")};

        switch(DEVELOP.jumpTo) {
            case "stats":
            case "faithGraph":
                FAITH_GRAPH.start();
        }

        this.isInitiated = true;
    }

    start2(req = "") {
        !this.isInitiated && this.init();

        USER_INFO.start();
        MENU_SETTINGS.start();
        MENU_LOG_IN.start(req);

        req.includes("menuInfoClose")    && (this.item.menuInfo.isVisible     = false);
        req.includes("menuSettingsOpen") && (this.item.menuSettings.isVisible = true);
        req.includes("menuLogInClose")   && (this.item.menuLogIn.isVisible    = false);

        GW_USER.isLoggedIn && !this.isOnceShown && (this.item.menuSettings.isVisible = false);

        this.show(true);

        this.selected    = "";
        this.isOnceShown = true;
    }

    end2(selected = "") {
        this.selected = selected;

        this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked,
            i;

        if (this.coords.mainFrame.left != this.item.mainFrame.left || this.coords.mainFrame.width != this.item.mainFrame.width || this.coords.mainFrame.height != this.item.mainFrame.height)
            this.resize();

        switch (btn.name) {
            case "main_userInfo_btnTitle":
                this.item.menuInfo.isVisible = !this.item.menuInfo.isVisible;
                break;
            case "main_userSettings_btnTitle":
                this.item.menuSettings.isVisible = !this.item.menuSettings.isVisible;
                break;
            case "main_menuLogIn_btnTitle":
                this.item.menuLogIn.isVisible = !this.item.menuLogIn.isVisible;
                break;
            case "main_menuUser_btnQuit":
                return this.end();
            default:
                if (btn.name != "" && !MENU_SETTINGS.isBusy()) {
                    if (btn.name == "main_bgd")
                        return this.end();
                    if ((i = GW_MAIN.checkMenus()) != "")
                        return this.end(i);
                }
        }

        USER_INFO.update();
        MENU_SETTINGS.update();
        MENU_LOG_IN.update();

        this.refresh();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }

    resize() {
        this.coords.mainFrame.left = this.item.mainFrame.left;

        this.coords.mainFrame.resize(this.item.mainFrame.width, this.item.mainFrame.height);
    }

    refresh() {
        for (const name in this.item)
            this.item[name].left = this.item.mainFrame.left;

        this.item.btnSettings.top = this.item.menuSettings.top = this.item.menuInfo.isVisible ? this.item.menuInfo.height : this.item.btnInfo.height;
        this.item.btnLogIn.top    = this.item.menuLogIn.top    = this.item.menuSettings.isVisible ? this.item.menuSettings.top + this.item.menuSettings.height : this.item.btnSettings.top + this.item.btnSettings.height;
    }
}

export const MENU_USER = BK_ENGINE.tasks.add(new MenuUser(BK_ENGINE.tasks));
