
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"
import { GW_USER } from "./_gw user.js"


class Loading extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.isUserInfoLoading = false;
        this.isProgressLoading = false;

        this.userInfo          = null;
        this.progress          = null;

        this.isFinished        = false;     // if true, that means the data was loaded and processed
    }

    get isDataLoading() {
        return this.isUserInfoLoading || this.isProgressLoading;
    }

    get isAllLoaded() {
        return (this.userInfo != null && this.progress != null);
    }

    reset() {       // call it as soon as the user is logged out
        this.isUserInfoLoading = this.isProgressLoading = this.isFinished = false;
        this.userInfo          = this.progress = null;

        if (this.isAcive)
            this.end();
    }

    start2() {      // call it only if user is logged in and verified
        this.isUserInfoLoading = this.isProgressLoading = true;

        // user info
        GW_USER.firestore.getDocs(GW_USER.firestore.collection(GW_USER.firestore.firestore, "users", GW_USER.auth.auth.currentUser.uid, "userInfo"))
            .then((userInfo) => {
                this.userInfo          = userInfo.docs.map(doc => doc.data())[0];
                this.isUserInfoLoading = false;})
            .catch((err) => {
                console.error("Error, loading user info!", err.code, err.message);});

        // progress
        GW_USER.firestore.getDocs(GW_USER.firestore.collection(GW_USER.firestore.firestore, "users", GW_USER.auth.auth.currentUser.uid, "progress"))
            .then((progress) => {
                this.progress          = progress.docs.map(doc => doc.data());
                this.isProgressLoading = false;})
            .catch((err) => {
                console.error("Error, loading user progress!", err.code, err.message)});
    }

    update() {
        if (!this.isAllLoaded)
            return;

        this.isFinished = true;

        this.end();
        GW_USER.afterLoaded(this.userInfo);
        // console.log(this.progress)
    }
}

export const LOADING = BK_ENGINE.tasks.add(new Loading(BK_ENGINE.tasks));
