
import { BKengineCanvas } from "../primitives/canvas.js"
import { BKengineItem } from "./_item.js"


class BKengineSprite extends BKengineItem {
    constructor() {
        super();

        this.canvas  = new BKengineCanvas();

        this.type    = "sprite";
        this.caption = "";          // there can be sprites with a text upon them
    }

    resize() {
        this.canvas.resize(this.width, this.height);

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        this.canvas.clear();

        this.isRedrawn = this.redrawAll(this.canvas, this.caption);
    }

    draw(canvas) {
        let coordsDst = (this.zoom == 1) ? this : this.zoomCoords;

        canvas.saveTransp();
        canvas.setTransp(this.transp);
        canvas.drawImage(this.canvas, {left: 0, top: 0, width: this.width, height: this.height}, coordsDst);
        canvas.restoreTransp();
    }
}


export { BKengineSprite };
