
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { G0001, ADDING_CHAR_TIME } from "./_bible g0001.js";


const LINE_W = 0.1,
      ROUND  = 0.15,
      COLOR  = "#ed7d31";


class Settings extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.canvas      = null;
        this.item        = null;

        this.isInitiated = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.canvas      = {selectBar: BK_ENGINE.canvases.getByName("bible_g0001_settings_selectBarBgd")};
        this.item        = {main:      ITEMS.getByName("bible_g0001_settings"),
                            title:     ITEMS.getByName("bible_g0001_settings_title"),
                            selectBar: ITEMS.getByName("bible_g0001_settings_selectBar")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.show(true);
        this.resize();
    }

    end2() {
        this.show(false);
    }

    update() {
        if (this.item.selectBar.width != this.canvas.selectBar.width || this.item.selectBar.height != this.canvas.selectBar.height)
            this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0001_settings_subBtn":
                G0001.addingCharTime--;
                return this.redraw();
            case "bible_g0001_settings_addBtn":
                G0001.addingCharTime++;
                return this.redraw();
            case "bible_g0001_settings_quit":
                return this.end();
            default:
                this.main_checkSelectBar();
        }
        // this.refresh();
    }

    main_checkSelectBar() {
        if (!BK_ENGINE.input.isMouseDown ||
            BK_ENGINE.input.top < this.item.selectBar.top ||
            BK_ENGINE.input.top > this.item.selectBar.top + this.item.selectBar.height)
                return;

        let i = (BK_ENGINE.input.left - this.item.selectBar.left) / this.item.selectBar.width;

        if (i < 0 || i > 1)
            return;

        G0001.addingCharTime = ADDING_CHAR_TIME.min + Math.round((ADDING_CHAR_TIME.max - ADDING_CHAR_TIME.min) * i);

        this.redraw();
    }

    resize() {
        this.canvas.selectBar.resize(this.item.selectBar.width, this.item.selectBar.height);
        
        this.redraw();
    }

    redraw() {
        let width     = Math.round((G0001.addingCharTime - ADDING_CHAR_TIME.min) / (ADDING_CHAR_TIME.max - ADDING_CHAR_TIME.min) * this.item.selectBar.width),
            lineWidth = Math.max(1, Math.round(LINE_W * this.item.selectBar.height)),
            round     = Math.max(1, Math.round(ROUND * this.item.selectBar.height));

        this.canvas.selectBar.clear();
        this.canvas.selectBar.drawRect({left: 0, top: 0, width: this.item.selectBar.width, height: this.item.selectBar.height}, COLOR, false,  lineWidth, round);
        this.canvas.selectBar.drawRect({left: 0, top: 0, width: width, height: this.item.selectBar.height}, COLOR, true,  1, round);

        this.item.selectBar.caption   = "" + G0001.addingCharTime;
        this.item.selectBar.isRedrawn = false;
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;

        if (isVisible)
            this.item.title.content = "[*centerV,center*]" + G0001.file.lang.getCaption("settingsTitle");
    }
}

export const SETTINGS = BK_ENGINE.tasks.add(new Settings(BK_ENGINE.tasks));
