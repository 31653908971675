
import { initializeApp } from "firebase/app";
// import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-app.js"
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, signInWithCustomToken, signOut} from "firebase/auth";
import { getFirestore, collection, doc, setDoc, addDoc, getDocs, updateDoc, serverTimestamp } from "firebase/firestore";


const APP = initializeApp({apiKey: "AIzaSyCi7QQJ1gLu64ysSIO8F9llzl2yzVlzLeM",
                           authDomain: "god-s-world-d402d.firebaseapp.com",
                           projectId: "god-s-world-d402d",
                           storageBucket: "god-s-world-d402d.appspot.com",
                           messagingSenderId: "349855308603",
                           appId: "1:349855308603:web:7f13558ac576b62364751e"});


class Auth {
    constructor() {
        this.auth  = getAuth(APP);

        this.user  = {id: ""};
        this.state = {isReady: false,
                      isError: false,
                      message: "",
                      code:    "",

                      reset() {
                          this.isReady = false,
                          this.isError = false
                      },

                      signError(message = "", code = "") {
                          this.isReady = true,
                          this.isError = true,
                          this.message = message,
                          this.code    = code
                      }};
    }
}

const AUTH = new Auth();


class Firestore {
    constructor() {
        this.firestore       = getFirestore(APP);

        this.collection      = collection;
        this.doc             = doc;
        this.setDoc          = setDoc;
        this.addDoc          = addDoc;
        this.getDocs         = getDocs;
        this.updateDoc       = updateDoc;
        this.serverTimestamp = serverTimestamp;
    }
}

const FIRESTORE = new Firestore();


onAuthStateChanged(AUTH.auth, user => {
    if (user == null) {
        AUTH.user.id = "";
    } else {
        // console.log(user)
        AUTH.user.id = user.uid;
    }
})


AUTH.createUserWithEmailAndPassword = function(email, password, lang, denom) {
    this.state.reset();

    createUserWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
            // collection "info"
            let doc = FIRESTORE.doc(FIRESTORE.firestore, "users", this.auth.currentUser.uid, "userInfo", "info");

            FIRESTORE.setDoc(doc, {lang:  lang,
                                   denom: denom});     // right now there's only version, in the future can add more flags

            // collection "progress"
            doc = FIRESTORE.doc(FIRESTORE.firestore, "users", this.auth.currentUser.uid, "progress", "init");

            FIRESTORE.setDoc(doc, {time: FIRESTORE.serverTimestamp(), data: "00"});

            this.state.isReady = true;})
        .catch((error) => {
            this.state.signError(error.message, error.code);});
}

AUTH.sendEmailVerification = function() {
    this.state.reset();

    sendEmailVerification(this.auth.currentUser)
        .then(() => {
            this.state.isReady = true;});
}

AUTH.signInWithEmailAndPassword = function(email, password) {
    this.state.reset();

    signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
            // const user = userCredential.user;
            this.state.isReady = true;})
        .catch((error) => {
            this.state.signError(error.message, error.code);});
}

AUTH.signInWithCustomToken = function(customToken) {
    this.state.reset();

    signInWithCustomToken(this.auth, customToken)
        .then(() => {
            this.state.isReady = true;})
        .catch((error) => {
            this.state.signError(error.message, error.code);});
}

AUTH.signOut = function() {
    this.state.reset();

    signOut(this.auth)
        .then(() => {
            this.state.isReady = true;

            console.log("User signed out");
            // LOADING.reset();
        }).catch((error) => {
            this.state.signError(error.message, error.code);
            console.log("User signed out, err: " + error.message, error.code);
            // LOADING.reset();       // strangely it logs out anyway, why?!
        });
}


export { AUTH, FIRESTORE };
