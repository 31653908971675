
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { QUOTES } from "../g0002/quotes/_g0002 quotes.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { BIBLE_BOOKS } from "../../books.js";
import { G0001 } from "./_bible g0001.js";
import { MENU } from "./g0001 menu.js";


class GoTo extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.quote       = null;

        this.isInitiated = false;
    }

    setBookChapter() {
        let bookI = GW_BIBLE.books.denomIndexes.indexOf(BIBLE_BOOKS.getByTag(this.quote.tag).id);

        if (bookI == -1)
            bookI = 0;

        G0001.bookI    = bookI;
        G0001.chapterI = parseInt(this.quote.chapterVerses.substring(0, this.quote.chapterVerses.indexOf(",")));

        MENU.refresh();
    }

    init() {
        this.item        = {mainFrame: BK_ENGINE.items.getByName("bible_g0001_goTo"),
                            textArea:  BK_ENGINE.items.getByName("bible_g0001_goTo_textArea")};
        this.isInitiated = true;
    }

    start2() {
        this.quote = QUOTES.getTodaysWord();

        if (this.quote) {
            this.setBookChapter();

            return this.end();
        }

        !this.isInitiated && this.init();

        this.show(true);
    }
    
    update() {
        if (BK_ENGINE.clicked.name == "bible_g0001_goTo_quit") {
            this.show(false);
            this.end();
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
        this.item.textArea.content    = "[*centerV,center*]" + G0001.file.lang.getCaption("goToContent");
    }
}

export const GO_TO = BK_ENGINE.tasks.add(new GoTo(BK_ENGINE.tasks));
