
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { G0002, PATH, FILES } from "./_bible g0002.js";
import { LOGICS_EVENTS } from "./play/logics/logics events.js";


const FADE_OUT_DURATION = 1000;     // only for "allGuessed", there are no other fades out in this game


class Sound {
    constructor(fileI, start, length, volume) {
        this.fileI    = fileI;
        this.start    = start;
        this.length   = length;     // if lenght == 0, means: full
        this.volume   = volume;
        this.buffer   = null;
        this.gainNode = null;
    }
}

class AudioFile {
    constructor(fileI, buffer) {
        this.fileI  = fileI;        // fileI in the FILES.all
        this.buffer = buffer;
        // this.soundsData = [];
    }
}


class G0002_Audio extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE          = {waiting: 5, loading: 10, main: 20};      // waiting will be deleted
        this.state          = 0;

        this._isOn          = true;
        this._volume        = 0.5;

        this.AudioContext   = null;
        this.audioCtx       = null;
        this.mainGainNode   = null;

        this.files          = [];                 // fileI, start, length, volume
        this.sound          = {allGuessed:        new Sound(0,    0,    0, 0.8),
                               ballHitsBrick:     new Sound(1,   25,   60, 0.8),
                               ballHitsLetter:    new Sound(1,  129,  289, 0.4),
                               ballHitsPlayer:    new Sound(1,  468,   22, 0.1),
                               lastCharCorrect:   new Sound(1,  543, 2476, 0.4),
                               lastCharWrong:     new Sound(1, 3049, 1688, 1.0),
                               letterBrickBroken: new Sound(1, 4801,  311, 1.0),
                               menuClick:         new Sound(1, 5169,   50, 1.0),
                               placeBrick:        new Sound(1, 5272,   64, 0.3),
                               timer10:           new Sound(1, 5374,  742, 1.0),
                               timer3:            new Sound(1, 6160,  411, 0.6),
                               timer2:            new Sound(1, 6160,  411, 0.8),
                               timer1:            new Sound(1, 6160,  411, 1.0)};

        this.timer          = 0;
        this.fadeOut        = 0;

        this.isInitiated    = false;
        this.isBuffersReady = false;
    }

    set isOn(value) {
        this._isOn = value;

        G0002.updateCookie();

        if (value)
            LOGICS_EVENTS.flush("audio");
    }

    get isOn() {
        return this._isOn;
    }

    set volume(value) {
        this._volume = value;

        if (this.mainGainNode)
            this.mainGainNode.gain.value = value;
        
        G0002.updateCookie();
    }

    get volume() {
        return this._volume;
    }

    fetchSong(fileI, fileName) {
        fetch(PATH.audio + "/" + fileName + "." + FILES.audio.ext)
        .then((res) => res.arrayBuffer())
        .then((arrayBuffer) => this.audioCtx.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {this.files.push(new AudioFile(fileI, audioBuffer))});
    }

    isReady() {
        return this.volume == 0 || this.state == this.STATE.main;
    }

    init() {
        let i;

        for (i = 0; i < FILES.audio.all.length; i ++)
            this.fetchSong(i, FILES.audio.all[i]);

        this.isInitiated = true;
    }

    initBuffers() {
        let file, sound;

        this.files.sort((a,b) => a.fileI - b.fileI);

        for (const soundName in this.sound) {
            sound = this.sound[soundName];
            file  = this.files[sound.fileI];

            if (sound.length == 0)
                sound.buffer = file.buffer;
            else
                this.initBuffers_copySound(file, sound);
        }

        this.isBuffersReady = true;
    }

    initBuffers_copySound(file, sound) {
        let sr         = this.audioCtx.sampleRate;
        let soundStart = Math.floor(sound.start  / 1000 * sr),
            samplesNm  = Math.floor(sound.length / 1000 * sr),
            i, fileBufferData0, fileBufferData1, soundBufferData0, soundBufferData1;

        sound.buffer = this.audioCtx.createBuffer(2, samplesNm, sr);

        fileBufferData0 = file.buffer.getChannelData(0);
        fileBufferData1 = file.buffer.getChannelData(1);

        soundBufferData0 = sound.buffer.getChannelData(0);
        soundBufferData1 = sound.buffer.getChannelData(1);

        for (i = 0; i < samplesNm; i++) {
            soundBufferData0[i] = fileBufferData0[soundStart + i];
            soundBufferData1[i] = fileBufferData1[soundStart + i];
        }
    }

    start2() {
        this.state = this.STATE.waiting;
    }

    startFadeOut() {
        this.timer   = Date.now();
        this.fadeOut = FADE_OUT_DURATION;
    }

    update() {
        if (!this.isOn || this.volume == 0)
            return;

        switch (this.state) {
            case this.STATE.waiting:
                return this.waiting();
            case this.STATE.loading:
                return this.loading();
            case this.STATE.main:
                this.main();
        }
    }

    waiting() {
        if (this.volume == 0)
            return;

        if (BK_ENGINE.clicked.name == "")    // can remove later
            return;

        this.AudioContext = window.AudioContext || window.webkitAudioContext;

        if (!this.AudioContext)
            return;

        this.audioCtx     = new AudioContext();
        this.mainGainNode = this.audioCtx.createGain();
        this.volume       = this._volume;

        !this.isInitiated && this.init();

        this.state = this.STATE.loading;
    }

    loading() {
        if (this.isBuffersReady)
            return this.state = this.STATE.main;

        if (this.files.length < FILES.audio.all.length)
            return;

        this.initBuffers();
    }

    main() {
        let evt = LOGICS_EVENTS.getNext("audio");

        if (this.sound.hasOwnProperty(evt.name))
            this.play(evt.name)
        else if (evt.name != "")
            console.log(evt.name);

        this.fadingOut();
    }

    fadingOut() {
        if (this.fadeOut == 0)
            return;

        let d     = Date.now();
        let delta = d - this.timer,
            progress;

        this.fadeOut = Math.max(0, this.fadeOut - delta);
        this.timer   = d;
        progress     = this.fadeOut / FADE_OUT_DURATION;

        if (this.sound.allGuessed.gainNode)
            this.sound.allGuessed.gainNode.gain.value = this.sound.allGuessed.volume * progress;
    }

    play(soundName = "ballHitsBrickNormal") {
        if (!this.isOn || !this.audioCtx)
            return;

        let sound         = this.sound[soundName],
            source        = this.audioCtx.createBufferSource(),
            soundGainNode = this.audioCtx.createGain();

        source.buffer            = sound.buffer;            
        soundGainNode.gain.value = sound.volume;

        if (soundName == "allGuessed")
            this.sound.allGuessed.gainNode = soundGainNode;

        source.connect(soundGainNode);
        soundGainNode.connect(this.mainGainNode);
        this.mainGainNode.connect(this.audioCtx.destination);
        source.start();
    }

    draw(canvas, soundName) {
        let height = Math.round(canvas.height / 2),
            sound  = this.sound[soundName];

        if (!sound || !sound.buffer)
            return;

        let channelData = [sound.buffer.getChannelData(0), sound.buffer.getChannelData(1)],
            colors      = ["#ff0000", "#0000ff"],
            channelI, coords, data, color,
            i, l, sample, left, top;

        for (channelI = 0; channelI < 2; channelI++) {
            coords = {left: 0, top: height, width: 0, height: 0};
            data   = channelData[channelI];
            color  = colors[channelI];

            for (i = 0, l = data.length; i < l; i++) {
                sample = data[i];
                
                coords.width  = left = Math.round(i / l * canvas.width);        // redo!!!
                coords.height = top  = Math.round(height - sample * height);

                canvas.drawLine(coords, "strokeStyle", color);

                coords.left = left;
                coords.top  = top;                
            }
        }
    }
}

export const AUDIO = BK_ENGINE.tasks.add(new G0002_Audio(BK_ENGINE.tasks));
