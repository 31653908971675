
import { Coords } from "../bk engine utils.js";

export class BKengineCanvas {
    constructor() {
        this.type          = "canvas";        // useful, when drawing (for images is: "image")
        this.name          = "";

        this.canvas        = document.createElement("canvas");
        this.ctx           = this.canvas.getContext("2d", { willReadFrequently: true });

        this.canvas.width  = 1;
        this.canvas.height = 1;

        this.transpSaved   = 0;
    }

    set width(width) {
        this.canvas.width = width;
    }

    get width() {
        return this.canvas.width;
    }

    set height(height) {
        this.canvas.height = height;
    }

    get height() {
        return this.canvas.height;
    }

    stringToRGBA(strRGBA) {
        const rgba = {r: 0, g: 0, b: 0, a: 0};

        rgba.r = parseInt(strRGBA.substr(1, 2), 16);
        rgba.g = parseInt(strRGBA.substr(3, 2), 16);
        rgba.b = parseInt(strRGBA.substr(5, 2), 16);
        rgba.a = (strRGBA.length == 9) ? parseInt(strRGBA.substr(7, 2), 16) : 255;

        return rgba;
    }

    RGBAtoString(rgba) {         // rgba: {r: 0-255, g: 0-255, b: 0-255, a: 0-255}
        const str = {r: "", g: "", b: "", a: ""};

        str.r = rgba.r.toString(16);
        str.g = rgba.g.toString(16);
        str.b = rgba.b.toString(16);
        str.a = rgba.a.toString(16);

        if (str.r.length == 1) str.r = "0" + str.r;
        if (str.g.length == 1) str.g = "0" + str.g;
        if (str.b.length == 1) str.b = "0" + str.b;
        if (str.a.length == 1) str.a = "0" + str.a;

        return "#" + str.r + str.g + str.b + str.a;
    }

    remove() {
        this.canvas = this.ctx = null;
    }

    reset() {                   // save some memory!
        this.width = this.height = 0;
    }

    resize(width, height) {
        if (width != this.width || height != this.height) {     // will be clear after resized
            this.width  = width;
            this.height = height;
        } else
            this.clear();                                       // so, you're sure after you've called this function, that the canvas is clear
    }

    clear() {
        this.ctx.clearRect(0, 0, this.width, this.height);
    }

    clearRect(coords) {
        this.ctx.clearRect(coords.left, coords.top, coords.width, coords.height);
    }

    setStyle(props) {
        let i, l,
            prop, value;

        for (i = 0, l = props.length; i < l; i += 2) {
            prop  = props[i];
            value = props[i + 1];

            this.canvas.style[prop] = value;
        }
    }

    fillWithColor(color) {
        this.ctx.fillStyle = color;

        this.ctx.fillRect(0, 0, this.width, this.height);
    }

    saveTransp() {
        this.transpSaved = this.ctx.globalAlpha;

        if (this.transpSaved != 1)
            console.log(this.transpSaved)
    }

    setTransp(value) {      // 0-1
        this.ctx.globalAlpha = value;
    }

    set transp(value) {
        this.ctx.globalAlpha = value;
    }

    get transp() {
        return this.ctx.globalAlpha;
    }

    restoreTransp() {
        this.ctx.globalAlpha = this.transpSaved;
    }

    globalCompositeOperationSet(value) {    // source-over (default), source-atop, source-in, source-out, destination-over, destination-atop, destination-in, destination-out, lighter, copy, xor
        this.ctx.globalCompositeOperation = value;
    }

    globalCompositeOperationReset() {
        this.ctx.globalCompositeOperation = 'source-over';
    }

    createLinearGradient(coords, arrColors) {   // arrColords = [point (0-1), color]
        let gradient = this.ctx.createLinearGradient(coords.left, coords.top, coords.left + coords.width, coords.top + coords.height),
            i, l;

        for (i = 0, l = arrColors.length; i < l; i += 2)
            gradient.addColorStop(arrColors[i], arrColors[i + 1]);
        
        this.ctx.fillStyle = gradient;

        this.ctx.fillRect(0, 0, this.width, this.height);
    }

    drawImage(imageOrCanvas, oCoordsSrc, oCoordsDst, rotation, pivotLeft = 0, pivotTop = 0) {
        let src = (imageOrCanvas.type == "image") ? imageOrCanvas.img : imageOrCanvas.canvas;
        // ctx.imageSmotthingEnabled = true;

        if (src.width == 0 || src.height == 0)
            return;
    
        if (oCoordsSrc === undefined) {
            this.ctx.drawImage(src, 0, 0);
            return;
        }

        if (oCoordsDst === undefined) {
            this.ctx.drawImage(src, oCoordsSrc.left, oCoordsSrc.top, oCoordsSrc.width, oCoordsSrc.height);
            return;
        }

        if (rotation == 0) {
            this.ctx.drawImage(src, oCoordsSrc.left, oCoordsSrc.top, oCoordsSrc.width, oCoordsSrc.height,
                                    oCoordsDst.left, oCoordsDst.top, oCoordsDst.width, oCoordsDst.height);
            return;
        }

        this.ctx.save();
        this.ctx.translate(oCoordsDst.left + pivotLeft, oCoordsDst.top + pivotTop);
        this.ctx.rotate(rotation);
        this.ctx.translate(-oCoordsDst.left - pivotLeft, -oCoordsDst.top - pivotTop);
        this.ctx.drawImage(src, oCoordsSrc.left, oCoordsSrc.top, oCoordsSrc.width, oCoordsSrc.height,
                                oCoordsDst.left, oCoordsDst.top, oCoordsDst.width, oCoordsDst.height);
        this.ctx.restore();
    }

    drawImageQuality(canvasTmp, imageOrCanvasSrc, coordsSrc, coordsDst) {  // this function is much better quality than ctx.drawImage(), the source must be greater than destination!
        let colors = [],
            i, l, sX, sY, dX, dY, srcYoffset1, srcYoffset2, dstYoffset1, dstYoffset2,
            pixelsSrc, pixelsDst, pixelsNm, r, g, b, a;

        // get the source pixels
        if (imageOrCanvasSrc.type == "image") {
            canvasTmp.resize(coordsSrc.width, coordsSrc.height);
            canvasTmp.ctx.drawImage(imageOrCanvasSrc.img, coordsSrc.left, coordsSrc.top, coordsSrc.width, coordsSrc.height,
                                                                       0,             0, coordsSrc.width, coordsSrc.height);

            pixelsSrc = canvasTmp.ctx.getImageData(0, 0, coordsSrc.width, coordsSrc.height);
        } else
            pixelsSrc = imageOrCanvasSrc.ctx.getImageData(coordsSrc.left, coordsSrc.top, coordsSrc.width, coordsSrc.height);

        pixelsDst = this.ctx.getImageData(coordsDst.left, coordsDst.top, coordsDst.width, coordsDst.height);

        // sum up pixels' colors
        for (i = 0, l = coordsDst.width * coordsDst.height; i < l; i++)
            colors.push(0, 0, 0, 0, 0);                                             // pixelsNm, totalR, totalG, totalB, totalA

        for (sY = 0; sY < coordsSrc.height; sY++) {
            dY          = Math.floor(sY * coordsDst.height / coordsSrc.height);     // destination Y
            srcYoffset1 = sY * coordsSrc.width * 4;
            dstYoffset1 = dY * coordsDst.width * 5;

            for (sX = 0; sX < coordsSrc.width; sX++) {
                srcYoffset2 = srcYoffset1 + sX * 4;

                r = pixelsSrc.data[srcYoffset2];
                g = pixelsSrc.data[srcYoffset2 + 1];
                b = pixelsSrc.data[srcYoffset2 + 2];
                a = pixelsSrc.data[srcYoffset2 + 3];

                dX          = Math.floor(sX * coordsDst.width / coordsSrc.width);   // destination X
                dstYoffset2 = dstYoffset1 + dX * 5;

                colors[dstYoffset2]++;               // pixelsNm

                colors[dstYoffset2 + 1] += r;        // totalR
                colors[dstYoffset2 + 2] += g;        // totalG
                colors[dstYoffset2 + 3] += b;        // totalB
                colors[dstYoffset2 + 4] += a;        // totalA
            }
        }

        // count the medium value and set the pixels' color in the destination
        for (i = 0, l = coordsDst.width * coordsDst.height; i < l; i++) {
            pixelsNm = colors[i * 5];

            if (pixelsNm == 0)
                r = g = b = a = 0;
            else {
                srcYoffset1 = i * 5;

                r = Math.round(colors[srcYoffset1 + 1] / pixelsNm);
                g = Math.round(colors[srcYoffset1 + 2] / pixelsNm);
                b = Math.round(colors[srcYoffset1 + 3] / pixelsNm);
                a = Math.round(colors[srcYoffset1 + 4] / pixelsNm);
            }
            dstYoffset1 = i * 4;

            pixelsDst.data[dstYoffset1]     = r;
            pixelsDst.data[dstYoffset1 + 1] = g;
            pixelsDst.data[dstYoffset1 + 2] = b;
            pixelsDst.data[dstYoffset1 + 3] = a;
        }

        canvasTmp.resize(coordsDst.width, coordsDst.height);
        canvasTmp.ctx.putImageData(pixelsDst, 0, 0);
// console.log("qual")
        this.ctx.drawImage(canvasTmp.canvas,              0,             0, coordsDst.width, coordsDst.height,
                                             coordsDst.left, coordsDst.top, coordsDst.width, coordsDst.height);
    }

    drawRect(coords, color = "#ffffff", isFill = false, lineWidth = 1, round = 0) {
        this.ctx.beginPath();

        if (isFill) {
            this.ctx.fillStyle = color;

            if (round > 0)
                this.ctx.roundRect(coords.left, coords.top, coords.width, coords.height, round);
            else
                this.ctx.rect(coords.left, coords.top, coords.width, coords.height);

            this.ctx.fill();
        } else {
            this.ctx.strokeStyle = color;
            this.ctx.lineWidth   = lineWidth;
            
            if (round > 0)
                this.ctx.roundRect(coords.left, coords.top, coords.width, coords.height, round);
            else
                this.ctx.rect(coords.left, coords.top, coords.width, coords.height);

            this.ctx.stroke();
        }
    }

    drawTriangle(v1, v2, v3, isFilled, oColor) {       // v1-3: {left: 0, top: 0}
        if (oColor !== undefined)
            this.ctx.fillStyle = oColor;

        this.ctx.beginPath();
        this.ctx.moveTo(v1.left, v1.top);
        this.ctx.lineTo(v2.left, v2.top);
        this.ctx.lineTo(v3.left, v3.top);
        this.ctx.lineTo(v1.left, v1.top);

        if (isFilled)
            this.ctx.fill();
    }

    drawPolygon(arrPoints, isFilled = false, color = "#ffffff") {
        let i, l;

        this.ctx.fillStyle = color;

        this.ctx.beginPath();
        this.ctx.moveTo(arrPoints[0].left, arrPoints[0].top);

        for (i = 1, l = arrPoints.length; i < l; i++)
            this.ctx.lineTo(arrPoints[i].left, arrPoints[i].top);

        this.ctx.closePath();

        if (isFilled)
            this.ctx.fill();
    }

    clearRect(coords) {
        this.ctx.clearRect(coords.left, coords.top, coords.width, coords.height);
    }

    // fillRect(coords, oColor) {
    //     if (oColor !== undefined)
    //         this.ctx.fillStyle = oColor;

    //     this.ctx.fillRect(coords.left, coords.top, coords.width, coords.height);
    // }

    setLineCap(value) {
        this.ctx.lineCap = value;
    }

    drawLine(coords) {          // other possible arguments: strokeStyle (color), lineWidth, lineDash, lineDashOffset
        let i, l,
            prop, value;

        for (i = 1, l = arguments.length; i < l; i += 2) {
            prop  = arguments[i];
            value = arguments[i + 1];

            if (prop == "lineDash")
                this.ctx.setLineDash(value);
            else
                this.ctx[prop] = value;
        }

        this.ctx.beginPath();
        this.ctx.moveTo(coords.left, coords.top);
        this.ctx.lineTo(coords.left + coords.width, coords.top + coords.height);
        this.ctx.stroke();
    }

    linePath(linePath, oColor, oLineWidth, oLineDash) {
        let i, l;

        this.ctx.lineWidth = oLineWidth || 1;
        this.ctx.beginPath();
        this.ctx.moveTo(linePath[0], linePath[1]);

        if (oLineDash != undefined) this.ctx.setLineDash(oLineDash);
        if (oColor != undefined)    this.ctx.strokeStyle = oColor;

        for (i = 2, l = linePath.length; i < l; i += 2)
            this.ctx.lineTo(linePath[i], linePath[i + 1]);

        this.ctx.stroke();
    }

    drawCircle(coords, radius, color, isFilled = false, lineWidth = 1) {
        this.ctx.beginPath();

        if (isFilled) {
            this.ctx.fillStyle = color;

            this.ctx.arc(coords.left, coords.top, radius, 0, 2 * Math.PI);
            this.ctx.fill();
        } else {
            this.ctx.strokeStyle = color;
            this.ctx.lineWidth   = lineWidth;

            this.ctx.arc(coords.left, coords.top, radius, 0, 2 * Math.PI);
            this.ctx.stroke();
        }
    }

    pie(coords, percent, color) {       // coords: left, top, radius
        const offset = Math.PI / 2;

        this.ctx.beginPath();
        this.ctx.fillStyle = color;
        this.ctx.moveTo(coords.left, coords.top);
        this.ctx.arc(coords.left, coords.top, coords.radius, -offset, Math.PI * 2 * percent - offset);
        this.ctx.lineTo(coords.left, coords.top);
        this.ctx.fill();
        this.ctx.closePath();
    }

    textGetWidth(text) {
        // console.log(text, this.width, this.height, this.ctx.measureText(text).width)
        return this.ctx.measureText(text).width;
    }

    textSet() {
        // pairs of properties, for example:
        // "font",          "30px Comic Sans MS"
        // "fillStyle",     "red" (#ff0000)
        // "textAlign",     "center"
        // "textBaseline"   "middle" (top, bottom, middle, alphabetic, hanging)
        // "decoration"     "underline"

        for (let i = 0, l = arguments.length; i < l; i += 2)
            this.ctx[arguments[i]] = arguments[i + 1];
    }

    textFill(text, coords) {
        this.ctx.fillText(text, coords.left, coords.top);
    }

    textFillRotated = function(text, coords, rotation) {
        const radians = rotation * Math.PI / 180;

        this.ctx.save();
        this.ctx.translate(coords.left, coords.top);
        this.ctx.rotate(radians);
        this.ctx.fillText(text, 0, 0);
        this.ctx.restore();
    }

    changeColor(srcRGB, dstRGB)     // srcRGB, dstRGB = {r: value, g: value, b: value}
    {
        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l,
            r, g, b;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            r = pixels.data[i];
            g = pixels.data[i+1];
            b = pixels.data[i+2];

            if (r == srcRGB.r && g == srcRGB.g && b == srcRGB.b) {
                pixels.data[i]   = dstRGB.r;
                pixels.data[i+1] = dstRGB.g;
                pixels.data[i+2] = dstRGB.b;
            }
        }

        this.ctx.putImageData(pixels, 0, 0);
    }

    convertColorToAlpha() {         // the medium of the color (r + g + b / 3) will become the alpha value
        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l, r, g, b;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            r = pixels.data[i];
            g = pixels.data[i + 1];
            b = pixels.data[i + 2];

            pixels.data[i + 3] = Math.round((r + g + b) / 3);
        }

        this.ctx.putImageData(pixels, 0, 0);
    }

    convertToGreyScale(multiply = 1) {
        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l, r, g, b;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            r = pixels.data[i];
            g = pixels.data[i + 1];
            b = pixels.data[i + 2];

            pixels.data[i] = pixels.data[i + 1] = pixels.data[i + 2] = Math.round((r * 0.2989 + g * 0.587 + b * 0.114) * multiply);
        }

        this.ctx.putImageData(pixels, 0, 0);
    }

    antiAlias(color = "#000000") {      // get rid of alias
        let rgba = this.stringToRGBA(color),
            a;

        if (this.width == 0 || this.height == 0)
            return;

        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l;

        for (i = 3, l = pixels.data.length; i < l; i += 4) {
            a = pixels.data[i];

            if (a < 128)
                pixels.data[i] = 0;
            else if (a >= 128) {
                pixels.data[i - 3] = rgba.r;
                pixels.data[i - 2] = rgba.g;
                pixels.data[i - 1] = rgba.b;
                pixels.data[i]     = rgba.a;
            }
        }

        this.ctx.putImageData(pixels, 0, 0);
    }

    brighten(brightness, oCoords) {         // brightness: 0-1+ (every pixel value will be multiplied by it)
        let coords = (oCoords == undefined) ? new Coords(0, 0, this.width, this.height) :
                                              new Coords(oCoords.left, oCoords.top, oCoords.width, oCoords.height);

        if (coords.width == 0 || coords.height == 0)
            return;

        let pixels = this.ctx.getImageData(coords.left, coords.top, coords.width, coords.height),
            i, l;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            pixels.data[i + 0] = Math.min(Math.round(pixels.data[i + 0] * brightness), 255);
            pixels.data[i + 1] = Math.min(Math.round(pixels.data[i + 1] * brightness), 255);
            pixels.data[i + 2] = Math.min(Math.round(pixels.data[i + 2] * brightness), 255);
        }

        this.ctx.putImageData(pixels, coords.left, coords.top);
    }

    makeShadow(value, oCoords) {                     // value: 0-1
        let coords = (oCoords == undefined) ? new Coords(0, 0, this.width, this.height) :
                                              new Coords(oCoords.left, oCoords.top, oCoords.width, oCoords.height);

        if (coords.width == 0 || coords.height == 0)
            return;

        let valueMax = value * 255,
            pixels   = this.ctx.getImageData(coords.left, coords.top, coords.width, coords.height),
            i, l;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            pixels.data[i]     = pixels.data[i + 1] = pixels.data[i + 2] = 0;
            pixels.data[i + 3] = Math.round(pixels.data[i + 3] / 255 * valueMax);
        }

        this.ctx.putImageData(pixels, coords.left, coords.top);
    }

    sharpen() {
        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l, a;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            a                  = pixels.data[i + 3];
            pixels.data[i + 3] = (a > 127) ? 255 : 0;
        }

        this.ctx.putImageData(pixels, 0, 0);
    }

    clearTransparent() {
        let pixels = this.ctx.getImageData(0, 0, this.width, this.height),
            i, l;

        for (i = 0, l = pixels.data.length; i < l; i += 4)
            pixels.data[i + 3] = 0;

        this.ctx.putImageData(pixels, 0, 0);
    }

    makeTransparent(transparency, oCoords) {      // transparency: 0 - 1
        let coords = {left: oCoords?.left || 0, top: oCoords?.top || 0, width: oCoords?.width || this.width, height: oCoords?.height || this.height};
        let pixels = this.ctx.getImageData(coords.left, coords.top, coords.width, coords.height),
            transp = Math.round(transparency * 255),
            i, l;
 
        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            if (pixels.data[i + 3] > 0)
                pixels.data[i + 3] = transp;
        }

        this.ctx.putImageData(pixels, coords.left, coords.top);
    }

    makeColorTransparent(oColorRGB, oCoords) {      // make a color transparent, by default it will be white
        let coords = {left: oCoords.left || 0, top: oCoords.top || 0, width: oCoords.width || this.width, height: oCoords.height || this.height};
        let pixels = this.ctx.getImageData(coords.left, coords.top, coords.width, coords.height),
            transp = {r: oColorRGB.r || 255, g: oColorRGB.g || 255, b: oColorRGB.b || 255},
            i, l,
            r, g, b;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            r = pixels.data[i];
            g = pixels.data[i + 1];
            b = pixels.data[i + 2];

            pixels.data[i + 3] = (r == transp.r && g == transp.g && b == transp.b) ? 0 : 255;
        }

        this.ctx.putImageData(pixels, coords.left, coords.top);
    }

    moreWhiteMoreTransparent(oCoords) {
        let coords = {left: oCoords.left || 0, top: oCoords.top || 0, width: oCoords.width || this.width, height: oCoords.height || this.height};
        let pixels = this.ctx.getImageData(coords.left, coords.top, coords.width, coords.height),
            i, l,
            r, g, b;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            r = pixels.data[i];
            g = pixels.data[i + 1];
            b = pixels.data[i + 2];

            pixels.data[i + 3] = (r == g && g == b) ? 255 - r : 255;
        }

        this.ctx.putImageData(pixels, coords.left, coords.top);
    }

    drawTranspCircle(light, version = 0) {          // light: 0-...
        let pixels  = this.ctx.getImageData(0, 0, this.width, this.height),
            centerX = this.width  / 2,
            centerY = this.height / 2;
        let maxR    = Math.sqrt(Math.pow(centerX, 2) + Math.pow(centerY, 2));

        switch (version) {
            case 0:
                this.drawTranspCircle_v1(light, pixels, centerX, centerY, maxR);
                break;
            case 1:
                this.drawTranspCircle_v2(light, pixels, centerX, centerY, maxR);
                break;
            case 2:
                this.drawTranspCircle_v3(light, pixels, centerX, centerY, maxR);
        }
        
        this.ctx.putImageData(pixels, 0, 0);
    }

    drawTranspCircle_v1(light, pixels, centerX, centerY, maxR) {    // center transp, outside solid
        let i, l, x, y, r;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            y = Math.floor(i / 4 / this.width);
            x = i / 4 - y * this.width;
            r = Math.max(1, Math.sqrt(Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)));

            pixels.data[i + 3] = Math.min(255, Math.round(r / maxR * 255 / light));
        }
    }

    drawTranspCircle_v2(light, pixels, centerX, centerY, maxR) {    // center solid, outside transp
        let i, l, x, y, r;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            y = Math.floor(i / 4 / this.width);
            x = i / 4 - y * this.width;
            r = Math.max(1, Math.sqrt(Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)));

            pixels.data[i + 3] = Math.min(255, Math.round((maxR - r) / maxR * 255 / light));
        }
    }

    drawTranspCircle_v3(light, pixels, centerX, centerY, _maxR) {    // center solid, outside transp, hyperbolic
        let maxR = Math.max(1, _maxR),
            i, l, x, y, r;

        for (i = 0, l = pixels.data.length; i < l; i += 4) {
            y = Math.floor(i / 4 / this.width);
            x = i / 4 - y * this.width;
            r = Math.max(1, Math.sqrt(Math.pow(centerX - x, 2) + Math.pow(centerY - y, 2)));

            pixels.data[i + 3] = Math.min(255, Math.round(Math.pow(maxR - r, 2) / light * 255));
        }
    }

    setColorFromRgba(pixelsData, i, rgba) {
        pixelsData[i]     = rgba.r;
        pixelsData[i + 1] = rgba.g;
        pixelsData[i + 2] = rgba.b;
        pixelsData[i + 3] = rgba.a;
    }

    outline(color) {      // 
        if (this.width < 2 || this.height < 2)
            return;
        // let coords = {left: oCoords.left || 0, top: oCoords.top || 0, width: oCoords.width || this.width, height: oCoords.height || this.height};
        let pixelsSrc = this.ctx.getImageData(0, 0, this.width, this.height),
            pixelsDst = this.ctx.getImageData(0, 0, this.width, this.height),
            rowWidth  = this.width * 4,
            i, l, m, rgba, offset, offsetX;

        rgba = this.stringToRGBA(color);

        // top and bottom line
        offset = pixelsSrc.data.length - rowWidth;

        for (i = 3; i < rowWidth; i += 4) {
            if (pixelsSrc.data[i]          == 0 && pixelsSrc.data[i          + rowWidth] > 0) this.setColorFromRgba(pixelsDst.data,          i - 3, rgba);
            if (pixelsSrc.data[i + offset] == 0 && pixelsSrc.data[i + offset - rowWidth] > 0) this.setColorFromRgba(pixelsDst.data, offset + i - 3, rgba);
        }

        // left and right column
        offset = rowWidth - 4;

        for (i = rowWidth + 3, l = pixelsSrc.data.length; i < l; i += rowWidth) {
            if (pixelsSrc.data[i]          == 0 && pixelsSrc.data[i          + 4] > 0) this.setColorFromRgba(pixelsDst.data,          i - 3, rgba);
            if (pixelsSrc.data[i + offset] == 0 && pixelsSrc.data[i + offset - 4] > 0) this.setColorFromRgba(pixelsDst.data, offset + i - 3, rgba);
        }

        // inside
        for (offset = rowWidth + 3, l = pixelsSrc.data.length - rowWidth; offset < l; offset += rowWidth) {
            for (i = 0, m = rowWidth - 4; i < m; i += 4) {
                offsetX = offset + i;

                if (pixelsSrc.data[offsetX] == 0) {
                    if (pixelsSrc.data[offsetX - rowWidth] > 0 ||
                        pixelsSrc.data[offsetX + rowWidth] > 0 ||
                        pixelsSrc.data[offsetX - 4]        > 0 ||
                        pixelsSrc.data[offsetX + 4]        > 0)
                            this.setColorFromRgba(pixelsDst.data, offsetX - 3, rgba);
                }
            }
        }
        
        this.ctx.putImageData(pixelsDst, 0, 0);
    }

    outlineInside(color) {      // 
        if (this.width < 2 || this.height < 2)
            return;
        // let coords = {left: oCoords.left || 0, top: oCoords.top || 0, width: oCoords.width || this.width, height: oCoords.height || this.height};
        let pixelsSrc = this.ctx.getImageData(0, 0, this.width, this.height),
            pixelsDst = this.ctx.getImageData(0, 0, this.width, this.height),
            rowWidth  = this.width * 4,
            i, l, m, rgba, offset, offsetX;

        rgba = this.stringToRGBA(color);

        // top and bottom line
        offset = pixelsSrc.data.length - rowWidth;

        for (i = 3; i < rowWidth; i += 4) {
            if (pixelsSrc.data[i] > 0)          this.setColorFromRgba(pixelsDst.data, i          - 3, rgba);
            if (pixelsSrc.data[i + offset] > 0) this.setColorFromRgba(pixelsDst.data, i + offset - 3, rgba);
        }

        // left and right column
        offset = rowWidth - 4;

        for (i = rowWidth + 3, l = pixelsSrc.data.length; i < l; i += rowWidth) {
            if (pixelsSrc.data[i         ] > 0) this.setColorFromRgba(pixelsDst.data, i          - 3, rgba);
            if (pixelsSrc.data[i + offset] > 0) this.setColorFromRgba(pixelsDst.data, i + offset - 3, rgba);
        }

        // inside
        for (offset = rowWidth + 3, l = pixelsSrc.data.length - rowWidth; offset < l; offset += rowWidth) {
            for (i = 0, m = rowWidth - 4; i < m; i += 4) {
                offsetX = offset + i;

                if (pixelsSrc.data[offsetX] > 0) {
                    if (pixelsSrc.data[offsetX - rowWidth] == 0 ||
                        pixelsSrc.data[offsetX + rowWidth] == 0 ||
                        pixelsSrc.data[offsetX - 4]        == 0 ||
                        pixelsSrc.data[offsetX + 4]        == 0)
                            this.setColorFromRgba(pixelsDst.data, offsetX - 3, rgba);
                }
            }
        }
        
        this.ctx.putImageData(pixelsDst, 0, 0);
    }


}
