// after calling SERVER.start(lang), it will initialize only today's quote

import { getAlphabet } from "../../../bk utils/languages.js";
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";


const PATH      = {lang: "gw bible/play/g0002/lang/", quotes: "/quotes" },
      FILE_NAME = "quotes";


const WORDS_TO_GUESS_MAX = 10,      // limit the number of words to guess if the quote is too long
      WORD_LENGTH_MAX    = 15;      // limit the word's to guess length (Ich spreche deutsch nicht ;-)


class Word {
    constructor(wholeWord) {
        this.whole        = wholeWord;
        this.partFirst    = "";
        this.charsToGuess = "";
        this.partLast     = "";

        this.isToGuess    = false;
        this.isGuessed    = false;
    }
}

class Server extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file      = BK_ENGINE.files.addNew();

        this.user      = {lang: "", denom: ""};
        this.date      = {year: -1, month: -1, day: -1};
        this.alphabet  = "";

        this.ref       = "";
        this.quote     = "";

        this.words     = [];

        this.isGuessed = false;
    }

    isLetter(char) {
        return this.alphabet.includes(char.toLowerCase());
    }

    printWordsToGuessI() {
        let nm = 0,
            i, j, txt, word;

        for (j = 3; j < WORD_LENGTH_MAX; j++) {
            txt = "";

            for (i = 0; i < this.words.length; i++) {
                word = this.words[i];

                if (word.isToGuess && word.charsToGuess.length == j) {
                    txt += i + " ";
                    nm++;
                }
            }

            if (txt.length > 0)
                console.log("" + j + ": " + txt);
        }

        console.log("*** " + nm + " ***");
    }

    resetWords() {
        let wordsToGuess = [],
            i, j, arrWords, word, charsToGuess, arr, wordsLeftNm;

        this.words = [];
        arrWords   = this.quote.split(" ");

        // add all words
        for (i = 0; i < arrWords.length; i++) {
            word              = new Word(arrWords[i]);
            charsToGuess      = this.resetWords_getCharsToGuess(word.whole);
            word.partFirst    = word.whole.substring(0, charsToGuess.charsI);
            word.charsToGuess = charsToGuess.chars;
            word.partLast     = word.whole.substring(word.partFirst.length + word.charsToGuess.length);
            word.isToGuess    = charsToGuess.isToGuess;

            this.words.push(word);

            if (word.isToGuess)
                wordsToGuess.push(i, word.charsToGuess.length);
        }
// this.printWordsToGuessI();

        // limit the number of words to guess
        if (wordsToGuess.length / 2 <= WORDS_TO_GUESS_MAX)
            return;

        wordsLeftNm = wordsToGuess.length / 2;

        for (i = 3; i < 20; i++) {
            arr = [];

            for (j = 0; j < wordsToGuess.length; j += 2) {
                if (wordsToGuess[j + 1] == i)
                    arr.push(wordsToGuess[j]);
            }

            if (arr.length > 0) {
                if (wordsLeftNm - arr.length > WORDS_TO_GUESS_MAX) {
                    this.resetWords_removeEqually(arr, arr.length);

                    wordsLeftNm -= arr.length;
                } else {
                    this.resetWords_removeEqually(arr, wordsLeftNm - WORDS_TO_GUESS_MAX);
                    // this.printWordsToGuessI();
                    return;
                }
            }
        }
    }

    resetWords_getCharsToGuess(wholeWord) {
        let letterIstart = 0,
            letterIend   = -1, charsL;

        // find the first letter
        while (letterIstart < wholeWord.length && !this.isLetter(wholeWord.charAt(letterIstart))) {
            letterIstart++;
        }

        letterIend = letterIstart + 1;

        // find the last letter
        while (letterIend < wholeWord.length && this.isLetter(wholeWord.charAt(letterIend))) {
            letterIend++;
        }

        // return the result
        charsL = letterIend - letterIstart;

        if (charsL > 2 && charsL <= WORD_LENGTH_MAX)
            return {chars: wholeWord.substring(letterIstart, letterIend), charsI: letterIstart, isToGuess: true};

        return {chars: "", charsI: 0, isToGuess: false};
    }

    resetWords_removeEqually(arr, nm) {
        if (nm == 0)
            return;

        let step = arr.length / nm;
        let i    = 0,
            j;

        while ((j = Math.round(i)) < arr.length) {
            this.words[arr[j]].isToGuess = false;

            i += step;
        }
    }

    getDate() {
        let d      = new Date();
        let result = {year: d.getUTCFullYear(), month: d.getUTCMonth(), day: d.getUTCDate(), hours: d.getUTCHours(), minutes: d.getUTCMinutes()};

        return result;
    }

    getFirstWordInotGuessed() {
        let wordI = 0;

        while (wordI < this.words.length && this.words[wordI].isGuessed) {
            wordI++;
        }

        return wordI;
    }

    getStats() {
        let wordsToGuessNm = 0,
            charsToGuessNm = 0,
            allCharsUsed   = "",
            i, j, word, char;

        for (i = 0; i < this.words.length; i++) {
            word = this.words[i];

            if (word.isToGuess) {
                wordsToGuessNm++;
                charsToGuessNm += word.charsToGuess.length;
            }

            for (j = 0; j < word.whole.length; j++) {
                char = word.whole.charAt(j);

                if (allCharsUsed.indexOf(char) == -1)
                    allCharsUsed += char;
            }
        }

        return {wordsToGuessNm: wordsToGuessNm, charsToGuessNm: charsToGuessNm, allCharsUsed: allCharsUsed};
    }

    getPacket(clientPacket) {
        let wordI, word, lastCharToGuess, isLastWord, charsToGuess;

        switch (clientPacket.type) {
            case "stats":
                return this.getStats();
            case "ref":
                return {ref: this.ref};
            case "newWord":
                if (clientPacket.wordI > 0)
                    wordI = (this.words[clientPacket.wordI - 1].isGuessed) ? clientPacket.wordI : this.getFirstWordInotGuessed();
                else
                    wordI = 0;

                word       = this.words[wordI];
                isLastWord = wordI == this.words.length - 1;

                if (!word.isToGuess)
                    word.isGuessed = true;

                charsToGuess = word.isGuessed ? word.charsToGuess : word.charsToGuess.substring(0, word.charsToGuess.length - 1) + " ";

                return {isToGuess: word.isToGuess, partFirst: word.partFirst, charsToGuess: charsToGuess, partLast: word.partLast, isLastWord: isLastWord};
            case "checkLastChar":
                wordI           = this.getFirstWordInotGuessed();
                word            = this.words[wordI];
                lastCharToGuess = word.charsToGuess.charAt(word.charsToGuess.length - 1);
                word.isGuessed  = clientPacket.lastChar == lastCharToGuess;
                isLastWord      = wordI == this.words.length - 1;

                if (word.isGuessed && isLastWord)
                    this.isGuessed = true;

                return {isGuessed: word.isGuessed, char: lastCharToGuess, isLastWord: isLastWord};
            case "quote":
                return {year: this.date.year, month: this.date.month, day: this.date.day, ref: this.isGuessed ? this.ref : "", content: this.isGuessed ? this.quote : ""};
        }
    }

    start2(lang, denom) {
        let date = this.getDate();
        let month = date.month + 1;

        month = "" + month;

        if (month.length == 1)
            month = "0" + month;

        if (this.user.lang != lang || this.user.denom != denom || this.date.year != date.year || this.date.month != date.month || this.date.day != date.day) {
            this.user.lang     = lang;
            this.user.denom    = denom;
            this.date.year     = date.year;
            this.date.month    = date.month;
            this.date.day      = date.day;
            this.alphabet      = getAlphabet(this.user.lang);
            this.isGuessed     = false;
            this.file.path     = PATH.lang + lang + PATH.quotes;
            this.file.fileName = FILE_NAME + " " + lang + " " + month + " " + denom;

            return this.file.load();
        }

        this.end();
    }

    update() {
        if (!this.file.isLoaded)
            return;

        this.update_initQuote();
        this.end();
    }

    update_initQuote() {
        let lines     = this.file.data.split(/\r?\n/),
            firstLine = "*" + this.date.day,
            i         = -1;

        do {
            ++i;
        } while(i < lines.length && lines[i].trim() != firstLine)

        if (i > lines.length - 2)
            return "day's quote not found: " + this.date.day + "!";

        this.ref   = lines[i + 1];
        this.quote = lines[i + 2];
    }
}

const SERVER = BK_ENGINE.tasks.add(new Server(BK_ENGINE.tasks));

export { SERVER, PATH, FILE_NAME };
