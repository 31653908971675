
export class BKengineRect {
    constructor() {
        this.type      = "rect";
        this.name      = "";

        this.isFill    = false;

        this.colorFill = "#000000";
        this.colorLine = "#000000";

        this.lineWidth = 1;
        this.round     = 0;
    }

    draw(canvas, coords, oRound) {
        let round = oRound || this.round;

        if (this.isFill) {
            canvas.drawRect(coords, this.colorFill, true, 1, round);
            canvas.drawRect(coords, this.colorLine, false, this.lineWidth, round);
        } else
            canvas.drawRect(coords, this.colorLine, false, this.lineWidth, round);
    }
}
