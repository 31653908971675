
import { BK_ENGINE, Task } from "../../bk engine/_bk engine.js"
import { HELP } from "../../help/help.js"
import { GW_MAIN, DEVELOP } from "../_gw main.js"
import { DONATIONS } from "./donations/_donations.js"
import { SOCIAL_MEDIA } from "./social media.js"


const STATE = {main: 10, socialMedia: 20, donations: 30};


class MenuInfo extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = "";
        this.item        = null;
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        this.item = {mainFrame: BK_ENGINE.items.getByName("main_menuInfo")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = STATE.main;

        this.show(true);

        if (DEVELOP.jumpTo == "donations")
            DONATIONS.start();
    }

    end2(selected = "") {
        this.selected = selected;

        this.show(false);
    }

    update() {
        switch (this.state) {
            case STATE.main:
                return this.update_main();
            case STATE.socialMedia:
                return this.update_socialMedia();
            default:
                this.update_donations();
        }
    }

    update_main() {
        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "main_menuInfo_socialMedia":
                this.state = STATE.socialMedia;
                this.show(false);
                return SOCIAL_MEDIA.start();
            case "main_menuInfo_donations":
                this.state = STATE.donations;
                this.show(false);
                return DONATIONS.start();
            case "main_menuInfo_about":
                HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_about").trim());
                return this.end();
            default:
                if (btn.name != "")
                    return this.end(GW_MAIN.checkMenus());
        }
    }

    update_socialMedia() {
        if (!SOCIAL_MEDIA.isActive)
            this.end();
    }

    update_donations() {
        if (!DONATIONS.isActive)
            this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const MENU_INFO = BK_ENGINE.tasks.add(new MenuInfo(BK_ENGINE.tasks));
