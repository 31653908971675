
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { MENU_BAR } from "../../menu bar.js";
import { SELECT_BIBLE_BOOK } from "../../select bible book.js";
import { SELECT_BIBLE_CHAPTER } from "../../select bible chapter.js";
import { CONTROL_BOX } from "../../control box.js";
import { LOADING_BOOKS } from "./loading books.js";


const DEVELOP = {isCheckContent: false, charsMin: 1500};

const PATH  = {ui:       "gw bible/read/holy bible/ui",
               uiImages: "gw bible/read/holy bible/ui",
               lang:     "gw bible/read/holy bible/lang"},
      FILES = {ui:       {verNarrow: "_ver narrow - 20230501", verWide: "_ver wide - 20230501", horNarrow: "_hor narrow - 20230501", horWide: "_hor wide - 20230501"},
               uiImages: {low: "images low - 20230501", med: "images med - 20230501", high: "images high - 20230501"},
               lang:     {en: "en - 20230501", es: "es - 20230501", esla: "esla - 20230801", fr: "fr - 20230701", it: "it - 20230501", pl: "pl - 20230501"}};


const T                 = BK_ENGINE.tasks;
const INIT              = T.addNew(),
      SET_LANG          = T.addNew(),
      LOADING           = T.addNew(),
      STARTING          = T.addNew(),
      SELECTING_BOOK    = T.addNew(),
      SELECTING_CHAPTER = T.addNew();


class HolyBible extends Task {
    constructor(PARENT) {
        super(PARENT);
    
        this.file         = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                             uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages),
                             lang:     BK_ENGINE.files.addNew("path", PATH.lang)};
        this.item         = null;

        this.user         = {lang: "", denom: ""};

        this.booksIndexes = [];
        this.book         = null;

        this.isShown_NoBooksRead = false;    // if there are no chapters guessed, show once an info
    }

    start2() {
        if (INIT.isInitiated)
            return SET_LANG.start();

        INIT.start();
    }

    end2() {
        MENU_BAR.selected = "";

        this.show(false);
        GW_BIBLE.resume();
    }

    resume() {
        this.show(true);
    }

    update() {
        if (INIT.isActive           || SET_LANG.isActive          || LOADING.isActive ||
            SELECTING_BOOK.isActive || SELECTING_CHAPTER.isActive)
            return;

        let selected;

        switch (BK_ENGINE.clicked.name) {
            case "bible_bookHolyBible_pageL":
            case "bible_bookHolyBible_coverB":
                selected = "prev";
                break;
            case "bible_bookHolyBible_pageR":
            case "bible_bookHolyBible_coverF":
                selected = "next";
                break;
            default:
                selected = MENU_BAR.selected;
        }

        switch (selected) {
            case "prev":
                this.item.book.turnPagePrev();

                if (this.item.book.isBothPagesVisible && this.item.book.isPageEmpty())
                    this.item.book.turnPageNext(1);

                this.refresh();
                break;
            case "next":
                this.item.book.turnPageNext();
                this.refresh();
                break;
            case "selectBibleBook":
                SELECTING_BOOK.start();
                break;
            case "selectChapter":
                SELECTING_CHAPTER.start(this.book);
                break;
            case "quit":
                this.end();
        }
    }

    getPageNmByChapter(pageStart, chapterNm) {
        let pageNm = this.item.book.getPageNmByChars(pageStart, "" + chapterNm);

        return pageNm > -1 ? pageNm : pageStart;        // just in case check if the page number is correct :)
    }

    goTo(data) {
        let book, pageNm;

        switch (data.bookTag) {
            case "OT":
                pageNm = GW_BIBLE.parts.OT.pageNm;
                break;
            case "NT":
                pageNm = GW_BIBLE.parts.NT.pageNm;
                break;
            default:
                book   = GW_BIBLE.books.getByTag(data.bookTag);
                pageNm = (data.chapter == 0) ? book.pageNm : this.getPageNmByChapter(book.pageNm + 1, data.chapter);
        }

        this.item.book.goToPage(pageNm);
        this.refresh();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = this.item.book.isVisible = isVisible;
        BK_ENGINE.pointer.isActive    = !isVisible;

        if (isVisible) {
            this.refresh();
            MENU_BAR.reset("holyBible");
        }
    }

    refresh() {
        let btn = MENU_BAR.getItem("selectChapter");

        this.book = GW_BIBLE.books.getByPageNm(this.item.book.pageNm);

        btn.isDisabled = (this.book.tag == "OT" || this.book.tag == "NT");
    }
}

const HOLY_BIBLE = BK_ENGINE.tasks.add(new HolyBible(BK_ENGINE.tasks));


INIT.file        = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                    uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages)};
INIT.isInitiated = false;

INIT.start2 = function() {
    this.file.ui.fileName       = FILES.ui[BK_ENGINE.viewPort.orientation];
    this.file.uiImages.fileName = FILES.uiImages[BK_ENGINE.viewPort.imagesQuality];

    this.file.ui.load();
    this.file.uiImages.load();
}

INIT.update = function() {
    let HB = HOLY_BIBLE;

    if (!this.file.ui.isLoaded || !this.file.uiImages.isLoaded)
        return;

    // add user interface
    BK_ENGINE.addFromData(this.file.ui.data);
    BK_ENGINE.setFromData(this.file.uiImages.data);

    // items
    HB.item = {mainFrame: BK_ENGINE.items.getByName("bible_upCenter"),
               book:      BK_ENGINE.items.getByName("bible_bookHolyBible")};

    // the book
    HB.item.book.btnTurnPagePrev = MENU_BAR.getItem("prev");
    HB.item.book.btnTurnPageNext = MENU_BAR.getItem("next");

    // this.texts.push(this.MAIN.texts.getByName("bibleBookHolyBibleNormal"));
    // this.texts.push(this.MAIN.texts.getByName("bibleBookHolyBibleBold"));

    // the rest
    this.isInitiated = true;

    this.end();
    SET_LANG.start();
}


SET_LANG.start2 = function() {
    const HB = HOLY_BIBLE;

    if (HB.user.lang == GW_BIBLE.user.lang)
        return this.end();

    HB.user.lang          = GW_BIBLE.user.lang;
    HB.file.lang.fileName = FILES.lang[HB.user.lang];

    HB.file.lang.load();
}

SET_LANG.end2 = function() {
    LOADING.start();
}

SET_LANG.update = function() {
    const HB = HOLY_BIBLE;

    if (!HB.file.lang.isLoaded)
        return;

    BK_ENGINE.setFromData(HB.file.lang.data);
    this.end();
}


LOADING.start2 = function() {
    if (GW_BIBLE.books.isAnyChanged)
        return LOADING_BOOKS.start();

    this.end();
}

LOADING.end2 = function() {
    STARTING.start();
}

LOADING.checkContent = function() {
    let arr = [],
        i, j, l, book;

    // fill up the arr with the content
    for (i = 0; i < GW_BIBLE.books.denomIndexes.length; i++) {
        book = GW_BIBLE.books.getByI(GW_BIBLE.books.denomIndexes[i]);

        // too short?
        if (book.content.length < DEVELOP.charsMin)
            console.warn("bookTag: " + book.tag + " content length low: " + book.content.length);

        // is the content equal to the one that already exists?
        l = book.content.length;

        for (j = 0; j < arr.length; j++) {
            if (arr[j].l == l)
                console.warn("bookTag: " + book.tag + " content the same length: " + arr[j].tag + ": " + l);
        }

        console.log(book.tag, l);

        arr.push({tag: book.tag, l: l});
    }
}

LOADING.checkContent_isPresent = function(arr, content) {
    let i;
}

LOADING.update = function() {
    let HB = HOLY_BIBLE;

    if (LOADING_BOOKS.isActive)
        return;

    HB.item.book.goToPage(0);
    this.end();

    if (DEVELOP.isCheckContent)
        this.checkContent();
}


STARTING.start2 = function() {
    if (HOLY_BIBLE.isShown_NoBooksRead || GW_BIBLE.books.chaptersGuessedNm > 0)
        return this.end();

    HOLY_BIBLE.isShown_NoBooksRead = true;

    CONTROL_BOX.start("", HOLY_BIBLE.file.lang.getCaption("noBooksRead"), ["gotIt"]);
}

STARTING.end2 = function() {
    HOLY_BIBLE.resume();
}

STARTING.update = function() {
    if (CONTROL_BOX.selected == "confirm") {
        CONTROL_BOX.end();
        this.end();
    }
}


SELECTING_BOOK.start2 = function() {
    HOLY_BIBLE.item.book.isActive = false;

    SELECT_BIBLE_BOOK.start();
}

SELECTING_BOOK.update = function() {
    const HB = HOLY_BIBLE;

    if (SELECT_BIBLE_BOOK.isActive)
        return;

    HB.item.book.isActive = true;

    if (SELECT_BIBLE_BOOK.selected != "quit")
        HB.goTo({bookTag: SELECT_BIBLE_BOOK.selected, chapter: 0});

    this.end();
    HB.resume();
}


SELECTING_CHAPTER.start2 = function(book) {
    HOLY_BIBLE.item.book.isActive = false;

    SELECT_BIBLE_CHAPTER.start(book);
}

SELECTING_CHAPTER.update = function() {
    const HB = HOLY_BIBLE;

    if (SELECT_BIBLE_CHAPTER.isActive)
        return;

    HB.item.book.isActive = true;

    if (SELECT_BIBLE_CHAPTER.selected != "quit")
        HB.goTo({bookTag: HB.book.tag, chapter: SELECT_BIBLE_CHAPTER.selected});

    this.end();
    HB.resume();
}


export {HOLY_BIBLE};
