
import { BK_ENGINE, Coords } from "../../../../../../bk engine/_bk engine.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "../_bible g0002 play display.js";
import { PLAY_LOGICS as LOGICS } from "../../logics/_bible g0002 play logics.js";
import { LOGICS_BRICKS } from "../../logics/logics bricks.js";
import { DISPLAY_PLAYFIELD_BALL as BALL } from "./playfield ball.js";
import { DISPLAY_PLAYFIELD_REMOVABLE_BRICKS as REMOVABLE_BRICKS } from "./playfield removable bricks.js";


const DEVELOP = {imageI: -1};   // default: -1

const TOP_MIN       = 0.2,      // above there is the quote
      SIZE          = 9,        // "frame" included, so inside there are 7x7 bricks
      CORNER_ROUND  = 0.2,
      COLOR_OUTLINE = "#ffffff44";


class DisplayPlayfield {
    constructor() {
        let CANVASES = BK_ENGINE.canvases;

        this.canvas      = {bgd:    CANVASES.addNew(),
                            shadow: CANVASES.addNew(),
                            pgd:    CANVASES.addNew(),
                            tmp:    CANVASES.tmp};

        this.coords      = {main:   new Coords(),
                            shadow: new Coords(),
                            brick:  new Coords(),
                            tmp:    new Coords(),
                            corner: {tl: new Coords(), tr: new Coords(), br: new Coords(), bl: new Coords()}};

        this.size        = SIZE;
        this.tileSize    = 0;
        this.imageI      = 0;

        this.corner      = {size1: 0, size2: 0, round: 0};

        this.fromLogics  = {wordI: 0, charsToGuessNm: 0};

        this.isInitiated = false;
    }

    getColFromLeft(left) {
        return Math.floor((left - this.coords.main.left) / this.coords.main.width * this.size);
    }

    getRowFromTop(top) {
        return Math.floor((top - this.coords.main.top) / this.coords.main.height * this.size);
    }

    init() {
        this.size = LOGICS.playfieldSize;

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.fromLogics.wordI = this.fromLogics.charsToGuessNm = 0;
        this.imageI           = (DEVELOP.imageI >-1) ? DEVELOP.imageI : Math.floor(Math.random() * DISPLAY.image.playfield.cols);

        BALL.start();
        REMOVABLE_BRICKS.start();
    }

    reset() {
        REMOVABLE_BRICKS.reset();
        this.redraw();
    }

    update() {
        this.canvas.pgd.clear();
        this.canvas.pgd.drawImage(this.canvas.bgd);
        DISPLAY.canvas.shadows.drawImage(this.canvas.shadow, {left:                     0, top:                    0, width: this.coords.shadow.width, height: this.coords.shadow.height},
                                                             {left: this.coords.main.left, top: this.coords.main.top, width: this.coords.shadow.width, height: this.coords.shadow.height});

        REMOVABLE_BRICKS.update();
        BALL.update();
    }

    resize() {
        let top    = Math.round(DISPLAY.coords.height * TOP_MIN);
        let height = DISPLAY.coords.height - top;
        let min    = DISPLAY.coords.width < height ? DISPLAY.coords.width : height,
            size, shadowOffset;

        if (BK_ENGINE.viewPort.orientation == "verNarrow")  // wider than a scrren in this case
            min *= 1.25;

        // tileSize
        this.tileSize = Math.floor(min / this.size);
        size          = this.tileSize * this.size;

        // main
        this.coords.main.resize(size, size);
        this.canvas.bgd.resize(size, size);
        this.canvas.pgd.resize(size, size);

        this.coords.main.left = Math.round((DISPLAY.coords.width - size) / 2);
        this.coords.main.top  = DISPLAY.coords.height - size;

        // shadow
        shadowOffset = Math.round(this.tileSize * SHADOW.size);

        this.coords.shadow.resize(size + shadowOffset, size + shadowOffset);
        this.canvas.shadow.resize(size, size);

        // the rest
        this.resize_bricks();
        REMOVABLE_BRICKS.resize();
        BALL.resize();
        this.redraw();
    }

    resize_bricks() {   // copy the playground image and make the corners of the bricks rounded
        let s     = this.tileSize;
        let sHalf = Math.floor(s / 2);

        this.coords.brick.resize(s, s);
 
        this.corner.size1 = sHalf;
        this.corner.size2 = s - sHalf;      // if PLAYFIELD.tileSize is odd must be done like this
        this.corner.round = Math.round(CORNER_ROUND * s);

        this.coords.corner.tl.resize(this.corner.size1, this.corner.size1);
        this.coords.corner.tr.resize(this.corner.size2, this.corner.size1);
        this.coords.corner.br.resize(this.corner.size2, this.corner.size2);
        this.coords.corner.bl.resize(this.corner.size1, this.corner.size2);

        this.coords.corner.tr.left = this.corner.size1;
        this.coords.corner.br.left = this.corner.size1;
        this.coords.corner.br.top  = this.corner.size1;
        this.coords.corner.bl.top  = this.corner.size1;
    }

    redraw() {
        this.redraw_bricks();
        this.redraw_shadow();
    }

    redraw_bricks() {
        let s     = this.tileSize,
            opIn  = "destination-in",
            opOut = "destination-out",
            brickT, brickR, brickB, brickL, brickTl, brickTr, brickBr, brickBl,
            t, r, b, l, tl, tr, br, bl,
            isTl, isTr, isBr, isBl, col, row, brick;

        this.coords.tmp.resize(s, s);
        this.canvas.tmp.resize(s, s);
        this.canvas.pgd.clear();
        this.canvas.bgd.clear();

        // copy playfield image
        DISPLAY.image.playfield.draw(this.canvas.pgd, {left: 0, top: 0, width: this.coords.main.width, height: this.coords.main.height}, this.imageI);

        // frame
            // corners
        this.redraw_bricks_corners(            0,             0, opIn, true,  false, false, false);
        this.redraw_bricks_corners(this.size - 1,             0, opIn, false, true,  false, false);
        this.redraw_bricks_corners(this.size - 1, this.size - 1, opIn, false, false, true,  false);
        this.redraw_bricks_corners(            0, this.size - 1, opIn, false, false, false, true);

            // top and bottom
        for (col = 1; col < this.size - 1; col++) {
            this.redraw_bricks_corners(col,             0, opIn, false, false, false, false);
            this.redraw_bricks_corners(col, this.size - 1, opIn, false, false, false, false);
        }

            // left and right
        for (row = 1; row < this.size - 1; row++) {
            this.redraw_bricks_corners(            0, row, opIn, false, false, false, false);
            this.redraw_bricks_corners(this.size - 1, row, opIn, false, false, false, false);
        }

        for (row = 1; row < this.size - 1; row++) {
            for (col = 1; col < this.size - 1; col++) {
                brick  = LOGICS_BRICKS.getBrick(col, row);

                brickT = LOGICS_BRICKS.getBrick(col, row - 1);
                brickR = LOGICS_BRICKS.getBrick(col + 1, row);
                brickB = LOGICS_BRICKS.getBrick(col, row + 1);
                brickL = LOGICS_BRICKS.getBrick(col - 1, row);

                brickTl = LOGICS_BRICKS.getBrick(col - 1, row - 1);
                brickTr = LOGICS_BRICKS.getBrick(col + 1, row - 1);
                brickBl = LOGICS_BRICKS.getBrick(col - 1, row + 1);
                brickBr = LOGICS_BRICKS.getBrick(col + 1, row + 1);

                t = !(brickT.isEmpty || brickT.letter);
                r = !(brickR.isEmpty || brickR.letter);
                b = !(brickB.isEmpty || brickB.letter);
                l = !(brickL.isEmpty || brickL.letter);

                tl = !(brickTl.isEmpty || brickTl.letter);
                tr = !(brickTr.isEmpty || brickTr.letter);
                bl = !(brickBl.isEmpty || brickBl.letter);
                br = !(brickBr.isEmpty || brickBr.letter);

                if (brick.isEmpty || brick.letter) {
                    isTl = t && l;
                    isTr = t && r;
                    isBr = b && r;
                    isBl = b && l;

                    this.redraw_bricks_corners(col, row, opOut, isTl, isTr, isBr, isBl, false);
                } else if (!brick.isRemovable) {
                    isTl = isTr = isBr = isBl = true;

                    if (t || tl || l) isTl = false;
                    if (t || tr || r) isTr = false;
                    if (b || bl || l) isBl = false;
                    if (b || br || r) isBr = false;
    
                    this.redraw_bricks_corners(col, row, opIn, isTl, isTr, isBr, isBl);
                }
            }
        }

        this.canvas.bgd.outlineInside(COLOR_OUTLINE);
    }

    redraw_bricks_corners(col, row, op, tl, tr, br, bl, isFull = true) {
        this.coords.brick.left = col * this.tileSize;
        this.coords.brick.top  = row * this.tileSize;

        if (tl) this.redraw_bricks_corners_copy(op, this.coords.corner.tl);
            else if (isFull) this.redraw_bricks_corners_copy2(this.coords.corner.tl);
        if (tr) this.redraw_bricks_corners_copy(op, this.coords.corner.tr);
            else if (isFull) this.redraw_bricks_corners_copy2(this.coords.corner.tr);
        if (br) this.redraw_bricks_corners_copy(op, this.coords.corner.br);
            else if (isFull) this.redraw_bricks_corners_copy2(this.coords.corner.br);
        if (bl) this.redraw_bricks_corners_copy(op, this.coords.corner.bl);
            else if (isFull) this.redraw_bricks_corners_copy2(this.coords.corner.bl);
    }

    redraw_bricks_corners_copy(op, coordsCorner) {
        this.canvas.tmp.clear();
        this.canvas.tmp.drawImage(this.canvas.pgd, this.coords.brick, this.coords.tmp);
        
        this.canvas.tmp.globalCompositeOperationSet(op);
        this.canvas.tmp.drawRect(this.coords.tmp, "#ffffff", true, 1, this.corner.round);
        this.canvas.tmp.globalCompositeOperationReset();

        this.canvas.bgd.drawImage(this.canvas.tmp, coordsCorner, {left: this.coords.brick.left + coordsCorner.left, top: this.coords.brick.top + coordsCorner.top, width: coordsCorner.width, height: coordsCorner.height});
    }

    redraw_bricks_corners_copy2(coordsCorner) {
        let coords = {left:   this.coords.brick.left + coordsCorner.left,
                      top:    this.coords.brick.top  + coordsCorner.top,
                      width:  coordsCorner.width,
                      height: coordsCorner.height};

        this.canvas.bgd.drawImage(this.canvas.pgd, coords, coords);
    }

    redraw_shadow() {
        let shadowOffset = Math.round(this.tileSize * SHADOW.size),
            i;

        this.canvas.shadow.clear();

        for (i = 1; i < shadowOffset; i++)
            this.canvas.shadow.drawImage(this.canvas.bgd, {left: i, top: i, width: this.coords.main.width, height: this.coords.main.height});

        this.canvas.shadow.makeShadow(SHADOW.transp);
    }
}

export const DISPLAY_PLAYFIELD = new DisplayPlayfield();
