
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { AUDIO } from "./g0002 audio.js";


class ControlBox extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.item = {main:     ITEMS.getByName("bible_g0002_controlBox"),
                     textArea: ITEMS.getByName("bible_g0002_controlBox_textArea"),
                     btn:      {yes:   ITEMS.getByName("bible_g0002_controlBox_btnYes"),
                                no:    ITEMS.getByName("bible_g0002_controlBox_btnNo"),
                                gotIt: ITEMS.getByName("bible_g0002_controlBox_btnGotIt"),
                                copy:  ITEMS.getByName("bible_g0002_controlBox_btnCopy")}};

        this.isInitiated = true;
    }

    setBtns(btns) {
        for (const i in this.item.btn)
            this.item.btn[i].isVisible = false;

        btns.forEach(element => {
            this.item.btn[element].isVisible = true;});
    }

    start2(title, content, btns) {
        !this.isInitiated && this.init();

        this.item.textArea.content = "[*centerV,center*]" + content;
        this.selected              = "";

        this.setBtns(btns);
        this.show(true);
    }

    end2(selected) {
        this.selected = selected;
        AUDIO.play("menuClick");

        this.show(false);
    }

    update() {
        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_controlBox_btnYes":
            case "bible_g0002_controlBox_btnGotIt":
            case "bible_g0002_controlBox_btnCopy":
                return this.end("confirm");
            case "bible_g0002_controlBox_btnNo":
                this.end("abandon");
        }
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;
    }
}

export const CONTROL_BOX = BK_ENGINE.tasks.add(new ControlBox(BK_ENGINE.tasks));
