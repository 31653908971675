
class Circle {
    constructor(x, y, r, color, oIsFilled) {
        this.type     = "circle";

        this.x        = x;
        this.y        = y;
        this.r        = r;
        this.color    = color;
        this.isFilled = oIsFilled || false;

        this.left     = 0;      // these will be updated
        this.top      = 0;
        this.radius   = 0;
    }

    draw(canvas, coordsParent) {
        let coords = {left: coordsParent.left + this.left, top: coordsParent.top + this.top};

        canvas.drawCircle(coords, this.radius, this.color, this.isFilled);
    }
}

class Triangle {
    constructor(verticesXY, color, oIsFilled) {
        this.type           = "triangle";

        this.verticesXY     = verticesXY;
        this.color          = color;
        this.isFilled       = oIsFilled || false;

        this.verticesCoords = [0,0,0,0,0,0];       // left,top for 3 vertices
    }

    draw(canvas, coordsParent) {
        let coords = [coordsParent.left + this.verticesCoords[0], coordsParent.top + this.verticesCoords[1],
                      coordsParent.left + this.verticesCoords[2], coordsParent.top + this.verticesCoords[3],
                      coordsParent.left + this.verticesCoords[4], coordsParent.top + this.verticesCoords[5]];

        canvas.drawTriangle(coords, this.color, this.isFilled);
    }
}


export class BKengineShapes {
    constructor() {
        this.type = "shapes";
        this.name = "";

        this.all  = [];
        // this.width    = 0;
        // this.height   = 0;
    }

    // call these 2 functions before calling any other
    // setSize(width, height) {
    //     this.width  = width;
    //     this.height = height;
    // }

    addCircle(x, y, r, color, oIsFilled) {
        return this.all[this.all.push(new Circle(x, y, r, color, oIsFilled)) - 1];
    }

    addTriangle(verticesXY, color, oIsFilled) {
        return this.all[this.all.push(new Triangle(verticesXY, color, oIsFilled)) - 1];
    }

    updateCoordsFromXY(coords) {
        let min = coords.width < coords.height ? coords.width : coords.height,
            i, l, shape;

        for (i = 0, l = this.all.length; i < l; i++) {
            shape = this.all[i];

            switch (shape.type) {
                case "circle":
                    shape.left   = Math.round(shape.x * coords.width);
                    shape.top    = Math.round(shape.y * coords.height);        
                    shape.radius = Math.round(shape.r * min);
                    break;
                case "triangle":
                    shape.verticesCoords[0] = Math.round(shape.verticesXY[0] * coords.width);
                    shape.verticesCoords[1] = Math.round(shape.verticesXY[1] * coords.height);
                    shape.verticesCoords[2] = Math.round(shape.verticesXY[2] * coords.width);
                    shape.verticesCoords[3] = Math.round(shape.verticesXY[3] * coords.height);
                    shape.verticesCoords[4] = Math.round(shape.verticesXY[4] * coords.width);
                    shape.verticesCoords[5] = Math.round(shape.verticesXY[5] * coords.height);
            }
        }
    }

    draw(canvas, coords) {
        for (let i = 0, l = this.all.length; i < l; i++)
            this.all[i].draw(canvas, coords);
    }
}
