
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_DB } from "../../../gw db/_gw db.js";
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_MAIN } from "../../_gw main.js";
import { CONTROL_BOX, INFO_BOX } from "../../_gw main.js";


const STATE = {notLoggedIn: 10, intro: 20, loading: 30, main: 40};


class SetAuthorizations extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = null;
        this.item        = null;

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_userSetAuthorizations"),
                     cb:        {name:       I.getByName("main_userSetAuthorizations_name"),
                                 lang:       I.getByName("main_userSetAuthorizations_lang"),
                                 country:    I.getByName("main_userSetAuthorizations_country"),
                                 relig:      I.getByName("main_userSetAuthorizations_relig"),
                                 religDenom: I.getByName("main_userSetAuthorizations_religDenom"),
                                 faith:      I.getByName("main_userSetAuthorizations_faith"),
                                 contact:    I.getByName("main_userSetAuthorizations_contact")}};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        if (GW_USER.isLoggedIn) {
            this.state = STATE.intro;

            return INFO_BOX.start(GW_MAIN.file.lang.getCaption("userSetAuthorizations_intro"), "gotIt");
        }

        this.state = STATE.notLoggedIn;

        INFO_BOX.start(GW_MAIN.file.lang.getCaption("userSetAuthorizations_notLoggedIn"), "gotIt");
    }

    end2(selected) {
        let I = this.item.cb;

        selected == "save" && GW_USER.setAuthorizations(
                                "name",       I.name.isChecked,
                                "lang",       I.lang.isChecked,
                                "country",    I.country.isChecked,
                                "relig",      I.relig.isChecked,
                                "religDenom", I.religDenom.isChecked,
                                "faith",      I.faith.isChecked,
                                "contact",    I.contact.isChecked);

        this.show(false);
    }

    update() {
        switch (this.state) {
            case STATE.notLoggedIn:
                return this.update_notLoggedIn();
            case STATE.intro:
                return this.update_intro();
            case STATE.loading:
                return this.update_loading();
            case STATE.main:
                this.update_main();
        }
    }

    update_notLoggedIn() {
        if (!INFO_BOX.isActive) {
            INFO_BOX.end();
            this.end();
        }
    }

    update_intro() {
        if (!INFO_BOX.isActive) {
            this.state = STATE.loading;

            INFO_BOX.end();
        }
    }

    update_loading() {
        if (GW_DB.isReady()) {
            this.state = STATE.main;

            // init items
            for (const prop in this.item.cb) {
                this.item.cb[prop].caption   = GW_DB.user[prop];
                this.item.cb[prop].isChecked = GW_USER.authorization[prop];
            }

            CONTROL_BOX.start("", "", ["abandonL", "confirm"]);
            this.show(true);
        }
    }

    update_main() {
        switch (CONTROL_BOX.selected) {
            case "confirm":
                return this.end("save");
            case "abandon":
                this.end();
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        !isVisible && CONTROL_BOX.end();
    }
}

export const SET_AUTHORIZATIONS = BK_ENGINE.tasks.add(new SetAuthorizations(BK_ENGINE.tasks));
