
import "./css/styles.css"
import "./index.html"
import { BK_ENGINE } from "./bk engine/_bk engine.js";
import { GW_DB } from "./gw db/_gw db.js";
import { GW_MAIN } from "./gw main/_gw main.js";


console.log("updated: 11/9/2023");

BK_ENGINE.start();
GW_DB.init();
GW_MAIN.start();
