// *** BK UTILS: some useful functions ***

function copyObj(objSrc, objDst) {
    for (let prop in objSrc) {
        if (objDst.hasOwnProperty(prop))
            objDst[prop] = objSrc[prop];
    }
}

function copyToClipboard(content, msgSuccess = "", msgError = "") {
    if (msgSuccess.length > 0 && msgError.length > 0) {
        navigator.clipboard.writeText(content).then(() => {
            alert(msgSuccess);
        }).catch(() => {
            alert(msgError);
        });
    } else if (msgSuccess.length > 0 && msgError.length == 0) {
        navigator.clipboard.writeText(content).then(() => {
            alert(msgSuccess);
        });
    } else {
        navigator.clipboard.writeText(content).then(() => {
        }).catch(() => {
            alert(msgError);
        });        
    }
}

function getIdByProp(arr, prop1, value1, prop2, value2) {
    let i = -1,
        l = arr.length;

    if (prop2 == undefined) {       // one property to search
        while (++i < l) {
            if (arr[i][prop1] == value1)
                return i;
        }
    } else {                        // two properties to search
        while (++i < l) {
            if (arr[i][prop1] == value1 && arr[i][prop2] == value2)
                return i;
        }
    }

    return -1;
}

function getIdByPropExcept(arr, prop, value, except) {
    let i = -1,
        l = arr.length;

    while (++i < l) {
        if (arr[i][prop] == value && except.indexOf(i) == -1)
            return i;
    }

    return -1;
}

function getByProp(arr, prop, value) {
    let i = getIdByProp(arr, prop, value);

    return arr[i];
}

function getIdByName(arr, name) {
    let i = -1,
        l = arr.length;

    while (++i < l) {
        if (arr[i].name == name)
            return i;
    }

    return -1;
}

function getByName(arr, name) {
    let i = getIdByName(arr, name);

    return arr[i];
}

function isObjEmpty(obj) {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return true;
}

function isFirstDateEarlier(date1, date2) {
    let PROPS = ["year", "month", "day", "hours", "minutes"],
        i, prop, value1, value2;

    for (i = 0; i < PROPS.length; i++) {
        prop = PROPS[i];

        if (date1.hasOwnProperty(prop) && date2.hasOwnProperty(prop)) {
            value1 = date1[prop];
            value2 = date2[prop];

            if (value1 < value2)
                return true;

            if (value1 > value2)
                return false;
        }
    }

    return false;
}

function msToDHMS(ms) {
    let result = {days: 0, hours: 0, mins: 0, secs: 0};
    let s      = parseInt(ms / 1000);

    result.days = parseInt(s / (24 * 60 * 60));
    s           = s % (24 * 60 * 60);

    result.hours = parseInt(s / (60 * 60));
    s            = s % (60 * 60);

    result.mins = parseInt(s / 60);
    result.secs = s % 60;

    return result;
}

function numberToDigits(number) {
    let nm      = number,
        roundNm = 1000,         // so, max 4 digits!
        digits  = [],
        isFirst = false,
        digit;

    do {
        digit    = Math.floor(nm / roundNm);
        nm      -= digit * roundNm;
        roundNm /= 10;

        if (digit > 0 || roundNm < 1)
            isFirst = true;
        if (isFirst)
            digits.push(digit);
    } while (roundNm >= 1);

    return digits;
}

function numberToStringFixedL(number, stringL) {
    let arrDigits = bk_utils.numberToDigits(number),
        strNumber = "",
        i, l;

    for (i = 0, l = stringL - arrDigits.length; i < l; i++)
        strNumber += "0";

    for (i = 0, l = arrDigits.length; i < l; i++)
        strNumber += arrDigits[i];

    return strNumber;
}

function randomMinMax(min, max) {        // min, max included
    return (min + Math.random() * (max - min));
}

function stringToHex(stringToConvert) {
    return stringToConvert.split('').map((c) => c.charCodeAt(0).toString(16).padStart(2, '0')).join('');
}

function trimToMinMax(number, min, max) {
    return Math.max(min, Math.min(max, number));
}

function cloneObj(objSrc, objDst) {
    for (let prop in objSrc)
        objDst[prop] = objSrc[prop];
}

function percentOfInt(string, number) {
    return (string.indexOf(".") == -1) ? parseInt(string) : Math.round(parseFloat(string) * number);
}

function getArrayFromString(text, oSeparator, oIsTrim, oStart, oEnd) {
    let separator = oSeparator || "\n",
        isTrim    = oIsTrim    || true,
        start     = oStart     || 0,
        end       = oEnd       || text.length,
        arr;

    arr = text.substring(start, end);
    arr = arr.split(separator);

    if (isTrim) {
        for (let i = 0, l = arr.length; i < l; i++)
            arr[i] = arr[i].trim();
    }

    return arr;
}

function isEmailFormatCorrect(email) {
    return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) != null;
//  return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

function pad(value, stringMaxLength) {       // integer to string with max string length (add zeros, if necessary, at the beginning)
    let s = "00000000000000";

    while (s.length < stringMaxLength)      // just in case :)
        s.push("0");

    s += value;

    return s.substring(s.length - stringMaxLength);
}

function replaceTextFormattingTags(content, arrPairs) {
    let result = content;

    for (let i = 0, l = arrPairs.length; i < l; i += 2)
        result = result.split("[*" + arrPairs[i] + "*]").join("[*" + arrPairs[i + 1] + "*]");

    return result;
}


export { copyObj,
         copyToClipboard,
         getByProp,
         getIdByProp,
         getIdByPropExcept,
         isEmailFormatCorrect,
         isFirstDateEarlier,
         msToDHMS,
         pad,
         replaceTextFormattingTags,
         stringToHex };
