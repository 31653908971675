
// GENERAL: 
// DISPLAY: guessingNewWord, guessingLastChar


class LogicsEvent {
    constructor(name, data) {
        this.name = name;
        this.data = data;
    }
}


class LogicsEvents {
    constructor() {
        this.general = [];
        this.display = [];
        this.audio   = [];
    }

    add(where, name, data = "") {
        this[where].push(new LogicsEvent(name, data));
    }

    getNext(where) {
        let fromWhere = this[where];

        if (fromWhere.length > 0)
            return fromWhere.shift();

        return {name: ""};
    }

    flush(where) {
        this[where] = [];
    }
}

const LOGICS_EVENTS = new LogicsEvents();


export { LOGICS_EVENTS };
