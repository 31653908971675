
class Coords {
    constructor(left = 0, top = 0, width = 0, height = 0) {
        this.left   = left;
        this.top    = top;
        this.width  = width;
        this.height = height;
    }

    copyFrom(coords) {
        this.left   = coords.left;
        this.top    = coords.top;
        this.width  = coords.width;
        this.height = coords.height;
    }

    resize(width, height) {
        this.width  = width;
        this.height = height;
    }
}

function copyObj(objSrc, objDst) {
    for (let prop in objSrc) {
        if (objDst.hasOwnProperty(prop))
            objDst[prop] = objSrc[prop];
    }
}

function fitPictureIntoFrame(coordsPicture, coordsFrame, isCenterH = false, isCenterV = false) {
    let ratioPicture = coordsPicture.width / coordsPicture.height,
        ratioFrame   = coordsFrame.width   / coordsFrame.height,
        result       = {left: 0, top: 0, width: 0, height: 0};

    if (ratioPicture > ratioFrame) {
        result.width  = coordsFrame.width;
        result.height = Math.round(coordsFrame.width / ratioPicture);
    } else {
        result.height = coordsFrame.height;
        result.width  = Math.round(coordsFrame.height * ratioPicture);
    }

    if (isCenterH)
        result.left = Math.round((coordsFrame.width - result.width) / 2);

    if (isCenterV)
        result.top = Math.round((coordsFrame.height - result.height) / 2);

    return result;
}

function getArrayFromString(text, oSeparator, oIsTrim, oStart, oEnd) {
    let separator = oSeparator || "\n",
        isTrim    = oIsTrim    || true,
        start     = oStart     || 0,
        end       = oEnd       || text.length,
        arr;

    arr = text.substring(start, end);
    arr = arr.split(separator);

    if (isTrim) {
        for (let i = 0, l = arr.length; i < l; i++)
            arr[i] = arr[i].trim();
    }

    return arr;
}

function getIdByName(arr, name) {
    let i = -1,
        l = arr.length;

    while (++i < l) {
        if (arr[i].name == name)
            return i;
    }

    return -1;
}

function getByName(arr, name) {
    let i = getIdByName(arr, name);

    return arr[i];
}

function getIdByProp(arr, prop1, value1, oProp2, oValue2) {
    let i = -1,
        l = arr.length;

    if (oProp2 == undefined) {       // one property to search
        while (++i < l) {
            if (arr[i][prop1] == value1)
                return i;
        }
    } else {                        // two properties to search
        while (++i < l) {
            if (arr[i][prop1] == value1 && arr[i][oProp2] == oValue2)
                return i;
        }
    }

    return -1;
}

function getPropertiesFromSrc(src) {
    let result = {path: "", fileName: "", ext: ""},
        lastSlashI, pointI;

    lastSlashI = src.lastIndexOf("/");
    pointI     = src.indexOf(".");

    result.path     = src.substring(0, lastSlashI);
    result.fileName = src.substring(lastSlashI + 1, pointI);
    result.ext      = src.substr(pointI + 1);

    return result;
}

function getSrcFromProperties(props) {
    return props.path + "/" + props.fileName + "." + props.ext;
}

function getStringBetweenSpaces(text, oStart) {
    let start  = oStart || 0,
        result = {text: "", end: 0};

    while (text.charAt(start) == " ")
        start++;
    result.end = text.indexOf(" ", start + 1);

    if (result.end == -1)
        result.end = text.length;

    result.text = text.substring(start, result.end);

    return result;
}

function readPropFromText(text, start) {
    let result   = {name: "", value: 0, isFound: false, end: 0},
        end      = text.indexOf(":", start),
        valueStart, word;

    if (end > -1) {
        result.name = text.substring(start, end).trim();
        word        = getStringBetweenSpaces(text, end + 1);

        if (isNaN(word.text)) {
            if (result.name.indexOf("is") == 0) {   // a boolean
                end            = word.end;
                result.value   = (word.text == "true") ? true : false;
                result.isFound = true;
            } else {                                // a string
                valueStart = text.indexOf("\"", end);

                if (valueStart > -1) {
                    end = text.indexOf("\"", ++valueStart);

                    if (end > -1) {
                        result.value   = text.substring(valueStart, end);
                        result.isFound = true;
                    }
                }
            }
        } else {                                    // a number
            end            = word.end;
            result.value   = parseFloat(word.text);
            result.isFound = true;
        }

        if (result.isFound)
            result.end = end + 1;
    }

    return result;
}

function RGBAtoString(rgba) {         // rgba: {r: 0-255, g: 0-255, b: 0-255, a: 0-255}
    const str = {r: "", g: "", b: "", a: ""};

    str.r = rgba.r.toString(16);
    str.g = rgba.g.toString(16);
    str.b = rgba.b.toString(16);
    str.a = rgba.a.toString(16);

    if (str.r.length == 1) str.r = "0" + str.r;
    if (str.g.length == 1) str.g = "0" + str.g;
    if (str.b.length == 1) str.b = "0" + str.b;
    if (str.a.length == 1) str.a = "0" + str.a;

    return "#" + str.r + str.g + str.b + str.a;
}

function setMore(obj) {              // object, then pairs of property & value
    for (let i = 1, l = arguments.length; i < l; i += 2)
        obj[arguments[i]] = arguments[i + 1];
}

function stringToRGBA(strRGBA = "#00000000") {
    const rgba = {r: 0, g: 0, b: 0, a: 0};

    rgba.r = parseInt(strRGBA.substr(1, 2), 16);
    rgba.g = parseInt(strRGBA.substr(3, 2), 16);
    rgba.b = parseInt(strRGBA.substr(5, 2), 16);
    rgba.a = (strRGBA.length == 9) ? parseInt(strRGBA.substr(7, 2), 16) : 255;

    return rgba;
}


export { Coords,
         copyObj,
         fitPictureIntoFrame,
         getArrayFromString,
         getByName,
         getIdByProp,
         getSrcFromProperties,
         getPropertiesFromSrc,
         readPropFromText,
         RGBAtoString,
         setMore,
         stringToRGBA };
