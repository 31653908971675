
import { BK_ENGINE, Coords, fitPictureIntoFrame } from "../bk engine/_bk engine.js"
import { GW_USER } from "../gw user/_gw user.js"
import { GW_DB_USERS } from "./gw db users.js"


// call GW_DB.init() first!

const PATH = {lang: "gw db/lang", flags: "gw db/flags"},
      FILE = {lang: {en: "en - 20230801", es: "es - 20230801", esla: "esla - 20230801", fr: "fr - 20230801", it: "it - 20230801", pl: "pl - 20230801"}},
      EXT  = {flag: "svg"};

const CANVAS_TMP = BK_ENGINE.canvases.addNew();


class Country {
    constructor(tag) {
        this.name = "";     // in nationa language
        this.img  = BK_ENGINE.images.addNew("path", PATH.flags, "fileName", tag.toUpperCase(), "ext", EXT.flag);
    }

    isFlagLoaded() {
        return this.img.isLoaded;
    }

    drawFlag(canvas) {
        let coordsSrc = new Coords(0, 0, this.img.width, this.img.height);
        let coordsDst = fitPictureIntoFrame(coordsSrc, new Coords(0, 0, canvas.width, canvas.height), true, true);

        CANVAS_TMP.resize(this.img.width, this.img.height);
        CANVAS_TMP.clear();
        CANVAS_TMP.drawImage(this.img);

        BK_ENGINE.canvases.drawCanvasQuality(CANVAS_TMP, canvas, coordsSrc, coordsDst);
    }
}
    
class GWdb {
    constructor() {
        this.file           = {lang: BK_ENGINE.files.addNew("path", PATH.lang)};

        this.user           = {address:"",name:"",lang:"",country:"",relig:"",religDenom:"",faith:"",contact:"",donated:""};
        this.lang           = {en:"",es:"",esla:"",fr:"",it:"",pl:""};
                              // countries ordered by english names, these will be initiated as Country objects, the last country is: "unknown"
        this.country        = {af:"",al:"",dz:"",as:"",ad:"",ao:"",aq:"",ag:"",ar:"",am:"",aw:"",au:"",at:"",az:"",bs:"",bh:"",bd:"",bb:"",by:"",be:"",bz:"",bj:"",bm:"",bt:"",bo:"",ba:"",bw:"",bv:"",br:"",io:"",bn:"",bg:"",bf:"",bi:"",kh:"",cm:"",ca:"",cv:"",ky:"",cf:"",td:"",cl:"",cn:"",cx:"",cc:"",co:"",km:"",cg:"",cd:"",ck:"",cr:"",ci:"",hr:"",cu:"",cy:"",cz:"",dk:"",dj:"",dm:"",do:"",ec:"",eg:"",sv:"",gq:"",er:"",ee:"",et:"",fk:"",fo:"",fj:"",fi:"",fr:"",gf:"",pf:"",tf:"",ga:"",gm:"",ge:"",de:"",gh:"",gi:"",gr:"",gl:"",gd:"",gp:"",gu:"",gt:"",gn:"",gw:"",gy:"",ht:"",hm:"",hn:"",hk:"",hu:"",is:"",in:"",id:"",ir:"",iq:"",ie:"",il:"",it:"",jm:"",jp:"",jo:"",kz:"",ke:"",ki:"",kp:"",kr:"",kw:"",kg:"",la:"",lv:"",lb:"",ls:"",lr:"",ly:"",li:"",lt:"",lu:"",mo:"",mk:"",mg:"",mw:"",my:"",mv:"",ml:"",mt:"",mh:"",mq:"",mr:"",mu:"",yt:"",mx:"",fm:"",md:"",mc:"",mn:"",me:"",ms:"",ma:"",mz:"",mm:"",na:"",nr:"",np:"",nl:"",an:"",nc:"",nz:"",ni:"",ne:"",ng:"",nu:"",nf:"",mp:"",no:"",om:"",pk:"",pw:"",ps:"",pa:"",pg:"",py:"",pe:"",ph:"",pn:"",pl:"",pt:"",pr:"",qa:"",re:"",ro:"",ru:"",rw:"",sh:"",kn:"",lc:"",pm:"",vc:"",ws:"",sm:"",st:"",sa:"",sn:"",rs:"",sc:"",sl:"",sg:"",sk:"",si:"",sb:"",so:"",za:"",gs:"",ss:"",es:"",lk:"",sd:"",sr:"",sj:"",sz:"",se:"",ch:"",sy:"",tw:"",tj:"",tz:"",th:"",tl:"",tg:"",tk:"",to:"",tt:"",tn:"",tr:"",tm:"",tc:"",tv:"",ug:"",ua:"",ae:"",gb:"",us:"",um:"",uy:"",uz:"",vu:"",ve:"",vn:"",vg:"",vi:"",wf:"",eh:"",ye:"",zm:"",zw:"",unknown:""};
        this.relig          = {c:"",o:"",b:"",a:""};    // see "info" file
        this.religDenom     = {c:"",p:"",o:"",t:""};    // see "info" file
        this.users          = GW_DB_USERS;
        this.currLang       = "";

        this.isUpdated      = false;
    }

    init() {
        // init countries
        for (const tag in this.country)
            this.country[tag] = new Country(tag);
    }

    update() {
        let L = this.file.lang,
            i, arr, caption;

        // user
        for (const prop in this.user)
            this.user[prop] = L.getCaption(prop);

        // religion
        this.relig.c = L.getCaption("relig_christian");
        this.relig.o = L.getCaption("relig_other");
        this.relig.b = L.getCaption("relig_believer");
        this.relig.a = L.getCaption("relig_atheist");

        // religion denomination
        this.religDenom.c = L.getCaption("religDenom_catholic");
        this.religDenom.p = L.getCaption("religDenom_protestant");
        this.religDenom.o = L.getCaption("religDenom_orthodox");
        this.religDenom.t = L.getCaption("religDenom_other");

        // languages
        caption = L.getCaption("languages");
        arr     = caption.split(/\r?\n|\r|\n/g);
        i       = 0;

        if (arr.length >= Object.keys(this.lang).length) {
            for (const prop in this.lang)
                this.lang[prop] = arr[i++];
        } else
            console.error("languages names too few");

        // countries
        caption = L.getCaption("countries");
        arr     = caption.split(/\r?\n|\r|\n/g);
        i       = 0;

        if (arr.length >= Object.keys(this.country).length - 1) {
            for (const tag in this.country) {
                if (tag != "unknown")
                    this.country[tag].name = arr[i++];
            }
        } else
            console.error("countries names too few");

        this.isUpdated = true;
    }

    isReady() {
        if (this.currLang != GW_USER.lang) {
            this.currLang           = GW_USER.lang;
            this.file.lang.fileName = FILE.lang[GW_USER.lang];

            this.file.lang.load();

            this.isUpdated = false;
        }

        !this.isUpdated && this.file.lang.isLoaded && this.update();

        return this.isUpdated;
    }

    areFlagsLoaded(arrFlags) {
        let areLoaded = true,
            i;

        for (i = 0; i < arrFlags.length; i++) {
            if (!this.country[arrFlags[i]].isFlagLoaded())  // check all, so they will start to load
                areLoaded = false;
        }

        return areLoaded;
    }
}


export const GW_DB = new GWdb();
