
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_USER } from "../gw user/_gw user.js";
import { GW_BIBLE } from "./_gw bible.js";


class InfoBar extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item         = null;
        this.isInititated = false;
    }

    init() {
        this.item = {mainFrame: BK_ENGINE.items.getByName("bible_infoBar"),
                     userInfo:  BK_ENGINE.items.getByName("bible_infoBar_userInfo")};

        this.isInititated = true;
    }

    start2() {
        !this.isInititated && this.init();

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    show(isVisible) {
        let caption = GW_USER.email;

        if (!GW_USER.isLoggedIn)
            caption = GW_BIBLE.file.lang.getCaption("UserNotLoggedIn");
        else if (!GW_USER.isVerified)
            caption = GW_BIBLE.file.lang.getCaption("UserNotVerified");
        else
            caption = GW_USER.nameOrAddress;

        this.item.mainFrame.isVisible = isVisible;
        this.item.userInfo.caption    = caption;
    }
}

export const INFO_BAR = BK_ENGINE.tasks.add(new InfoBar(BK_ENGINE.tasks));
