
import { getByProp } from "../bk utils/_bk utils.js";
import { BK_ENGINE } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";


const DEVELOP = {isShowFileLength: false};

const PATH               = {lang: "gw bible/bible books/lang"},
      DN_CHAPT13_14_EXCL = ["esla", "pl"];      // missing chapters 13-14 of Dn, in catholic version (in protestant version these are always missing)

// 1) tag 2) confession NOT used by (c: cattholic, p: protestant) 3) chapters nm
const BOOKS_DATA =
["",         "",     0,
"Gn",       "",    50,
"Ex",       "",    40,
"Lv",       "",    27,
"Nm",       "",    36,
"Dt",       "",    34,
"Jos",      "",    24,
"Jgs",      "",    21,
"Ru",       "",     4,
"1Sm",      "",    31,
"2Sm",      "",    24,
"1Kgs",     "",    22,
"2Kgs",     "",    25,
"1Chr",     "",    29,
"2Chr",     "",    36,
"Ezr",      "",    10,
"Neh",      "",    13,
"1Esdras", "cp",    1,
"Tb",       "p",   14,
"Jdt",      "p",   16,
"Est",      "",    10,
"1Mc",      "p",   16,
"2Mc",      "p",   15,
"3Mc",      "cp",   1,
"4Mc",      "cp",   1,
"Jb",       "",    42,
"Ps",       "",   150,
"Prv",      "",    31,
"Eccl",     "",    12,
"Sg",       "",     8,
"Ws",       "p",   19,
"Sir",      "p",   51,
"Is",       "",    66,
"Jer",      "",    52,
"Lam",      "",     5,
"Bar",      "p",    6,
"LetJer",   "cp",   1,
"Ez",       "",    48,
"Dn",       "",    14,
"Hos",      "",    14,
"Jl",       "",     4,
"Am",       "",     9,
"Ob",       "",     1,
"Jon",      "",     4,
"Mi",       "",     7,
"Na",       "",     3,
"Hb",       "",     3,
"Zep",      "",     3,
"Ghg",      "",     2,
"Zec",      "",    14,
"Mal",      "",     4,
"Mt",       "",    28,
"Mk",       "",    16,
"Lk",       "",    24,
"Jn",       "",    21,
"Acts",     "",    28,
"Rom",      "",    16,
"1Cor",     "",    16,
"2Cor",     "",    13,
"Gal",      "",     6,
"Eph",      "",     6,
"Phil",     "",     4,
"Col",      "",     4,
"1Thes",    "",     5,
"2Thes",    "",     3,
"1Tm",      "",     6,
"2Tm",      "",     4,
"Ti",       "",     3,
"Phlm",     "",     1,
"Heb",      "",    13,
"Jas",      "",     5,
"1Pt",      "",     5,
"2Pt",      "",     3,
"1Jn",      "",     5,
"2Jn",      "",     1,
"3Jn",      "",     1,
"Jude",     "",     1,
"Rv",       "",    22];

const TEXT_I = {chapterNm: 0, verseNm: 1, verseNormal: 2, verseItalic: 3, bookName: 5, playToDiscover: 6};


class BibleBooks {
    constructor() {                 // formattingTags: ["n", "0", "i", "1", "b", "2", "c1", "3"]};
        this.texts        = [];
        this.all          = [];     // book 0 at the beginning

        this.isAnyChanged = true;   // is any book changed (guessed or changed lang, or version)?

        this.lang         = "";
        this.denom        = "c";
        this.denomIndexes = [];     // indexes only of books of current denomination
    }

    get chaptersGuessedNm() {
        let result = 0,
            i, l, book;

        for (i = 1, l = this.all.length; i < l; i++) {
            book = this.all[i];

            result += book.chaptersGuessedNm;
        }

        return result;
    }

    getByI(i) {
        return this.all[i];
    }

    getByTag(tag) {
        return getByProp(this.all, "tag", tag);
    }

    getByPageNm(pageNm) {
        let i = 1,
            l = this.denomIndexes.length,
            book;

        // Old Testament?
        if (pageNm < 4)
            return ({tag: "OT"});
            
        // New Testament?
        book = this.getByTag("Mt");

        if (pageNm == book.pageNm - 2 || pageNm == book.pageNm - 3)
            return ({tag: "NT"});

        // some other book
        do {
            book = this.all[this.denomIndexes[i]];

            if (pageNm < book.pageNm - 1)
                return this.all[this.denomIndexes[i - 1]];
            else
                i++;
        } while (i < l);

        return book;
    }

    resetChapters() {
        let i, l, book;

        for (i = 1, l = this.all.length; i < l; i++) {
            book = this.all[i];

            book.resetChapters();
        }
    }

    refresh() {
        let fileNames = GW_BIBLE.file.lang.getCaption("booksFileNames").split(/\r?\n/),
            i, l, book, fileName;

        this.isAnyChanged = true;
        this.lang         = GW_BIBLE.user.lang;
        this.denom        = GW_BIBLE.user.denom;
        this.denomIndexes = [];

        for (i = 1, l = this.all.length; i < l; i++) {
            book = this.all[i];

            if (book.denoms.includes(this.denom)) {
                fileName = this.refresh_getFileName(book, fileNames);

                if (fileName.length > 0) {      // if a book is missing, remove it from the list in the ui file
                    this.denomIndexes.push(i);
                    book.refresh(fileName);
                }
            }
        }
    }

    refresh_getFileName(book, fileNames) {
        let fileTag = " " + book.tag.toLowerCase() + " ",
            i = -1;

        while (++i < fileNames.length) {
            if (fileNames[i].includes(fileTag))
                return fileNames[i].trim();
        }

        return "";
    }
}

const BIBLE_BOOKS = new BibleBooks();


class BibleBook {
    constructor(id, denoms, part, tag, chaptersNm) {
        this.file               = BK_ENGINE.files.addNew();

        this.textContent        = new BK_ENGINE.textContent();     // content of the book
        this.textContentName    = new BK_ENGINE.textContent();     // name of the book
        this.textContentGuessed = new BK_ENGINE.textContent();     // content of the book, only with chapters guessed (if they're not guessed, there's a caption: "CHAPTER X: play to discover!")

        this.id                 = id;             // index in books.all
        this.denoms             = denoms;         // a string: list of denominations that recognize this book
        this.part               = part;           // "OT" or "NT"
        this.tag                = tag;            // english
        this.tagNational        = "";
        this.pageNm             = 0;              // useful for "goTo" function in "display"

        this.name               = "";             // full name of the book
        this.nameSingular       = "";             // full name: singular (i.e.: Psalm)
        this.content            = "";             // all book's content with formatting tags

        this.chapters           = [];             // contains numbers, at what level they've been discovered: 0: none, 1: easy, 2: medium, 3: hard

        // init
        for (let i = 0; i <= chaptersNm; i++)     // chapter 0 included
            this.chapters.push(0);
    }

    get chaptersNm() {      // never call "chapters.length"!    (Dn sometimes misses chapters 13 and 14)
        let nm = this.chapters.length;

        if (this.tag == "Dn" &&
           (GW_BIBLE.user.denom == "p" || DN_CHAPT13_14_EXCL.includes(GW_BIBLE.user.lang)))
            nm -= 2;    // In some cases Dn misses the last 2 chapters

        return nm;
    }

    get chaptersGuessedNm() {
        let nm = 0,
            i, l;

        for (i = 1, l = this.chaptersNm; i < l; i++) {
            if (this.chapters[i] > 0)
                nm++;
        }

        return nm;
    }

    get isLoaded() {
        let txt;

        if (this.file.isLoaded) {
            this.content = this.file.data;

            if (DEVELOP.isShowFileLength) {
                if (this.id == 1)
                    console.log("*************");

                txt = this.file.fileName + ": " + this.file.data.length;

                if (this.file.data.length < 1000)
                    console.error(txt);
                else
                    console.log(txt);
            }

            this.formatContent();

            return true;
        }

        return false;
    }

    getChapterDifficulty(chapterI) {
        return this.chapters[chapterI];
    }

    setChapterDifficulty(chapterI, difficulty) {
        let currDifficulty = this.chapters[chapterI];

        if (currDifficulty >= difficulty)
            return false;

        if (difficulty == 0 || currDifficulty == 0) {
            BIBLE_BOOKS.isAnyChanged = true;

            this.textContentGuessed.reset();
        }

        this.chapters[chapterI] = difficulty;

        return true;
    }

    getTextContent() {
        if (!this.textContent.isInitiated) {
            if (this.isLoaded)
                this.textContent.init(this.content);
            else
                return null;
        }
    
        return this.textContent;
    }

    getTextContentGuessed() {
        if (!this.textContentGuessed.isInitiated) {
            if (this.chaptersGuessedNm == 0 || this.isLoaded)
                this.textContentGuessed.init(this.getContentGuessed());
            else
                return null;
        }
            
        return this.textContentGuessed;
    }

    resetChapters() {
        let i, l;

        for (i = 0, l = this.chaptersNm; i < l; i++)
            this.chapters[i] = 0;
    }

    formatContent() {
        let l, s, s1, s2, start, end;

        // format chapter numbers
        s1  = "[*chapter*][*" + TEXT_I.chapterNm + "*]";
        s2  = "[*" + TEXT_I.verseNormal + "*] ";
        l   = s1.length + s2.length;
        end = 0;

        do {
            start = this.content.indexOf("[*", end);

            if (start > -1) {
                end           = this.content.indexOf("*", start + 2),
                s             = this.content.substring(start + 2, end);
                this.content  = this.content.substring(0, start) + s1 + s + s2 + this.content.substring(end + 2).trim();
                end          += l + s.length;
            }
        } while (start > -1);

        // format verse numbers
        s1  = "[*" + TEXT_I.verseNm + "*]";
        s2  = "[*" + TEXT_I.verseNormal + "*]";
        l   = s1.length + s2.length;
        end = 0;

        do {
            start = this.content.indexOf("[_", end);

            if (start > -1) {
                end           = this.content.indexOf("_", start + 2),
                s             = this.content.substring(start + 2, end);
                this.content  = this.content.substring(0, start) + s1 + s + s2 + this.content.substring(end + 1);
                end          += l + s.length;
            }
        } while (start > -1);

        // do {
        //     s = "[_" + i + "_";

        //     if (this.content.indexOf(s) > -1)
        //         this.content = this.content.split(s).join(s1 + i + s2);
        //     else
        //         isContinue = this.content.indexOf("[_" + ++i + "_") > -1;
        // } while (isContinue);
    }

    getContentGuessed() {
        let CHAPTER          = "[*chapter*][*" + TEXT_I.chapterNm + "*]",
            PLAY_TO_DISCOVER = " [*" + TEXT_I.playToDiscover + "*]" + GW_BIBLE.file.lang.getCaption("PLAY_TO_DISCOVER!") + "\n",
            content          = "",
            chapterI;

        for (chapterI = 1; chapterI < this.chaptersNm; chapterI++) {
            if (this.chapters[chapterI] == 0)
                content += CHAPTER + chapterI + PLAY_TO_DISCOVER;
            else
                content += this.getContentGuessed_chapter(chapterI);
        }

        return content;
    }

    getContentGuessed_chapter(chapterI) {
        let CHAPTER = "[*chapter*][*" + TEXT_I.chapterNm + "*]",
            i, start, end;

        i     = chapterI + 1;
        start = this.content.indexOf(CHAPTER + chapterI);
        end   = this.content.indexOf(CHAPTER + i);

        if (end == -1)
            end = this.content.length;

        return "[*justify*]" + this.content.substring(start, end);
    }

    getChapterPlainContent(chapterI) {
        let content = this.getContentGuessed_chapter(chapterI),
            start, end;

        do {
            start = content.indexOf("[*");
            end   = content.indexOf("*]") + 2;

            if (start > -1)
                content = content.substring(0, start) + content.substring(end);
        } while (start > -1);

        content = content.replace(/[0-9]/g, '');

        return content.trim();
    }

    refresh(fileName) {
        if (!this.denoms.includes(BIBLE_BOOKS.denom))
            return;

        this.tagNational   = GW_BIBLE.file.lang.getCaption("book" + this.tag).trim();
        this.name          = GW_BIBLE.file.lang.getCaption("book" + this.tag + "F").trim();
        this.nameSingular  = (this.tag == "Ps") ? GW_BIBLE.file.lang.getCaption("book" + this.tag + "Singular").trim() : this.name;

        this.file.path     = PATH.lang + "/" + BIBLE_BOOKS.lang + "/" + BIBLE_BOOKS.denom;
        this.file.fileName = fileName;

        this.file.reset();

        this.textContent.reset();
        this.textContentName.init("[*centerV,center*]" + "[*" + TEXT_I.bookName + "*]" + this.name);
        this.textContentGuessed.reset();
    }
}


// init the bible books

let part = "OT",
    i, l, denoms, tag, exc, chaptersNm;

for (i = 0, l = BOOKS_DATA.length; i < l; i += 3) {
    denoms     = "";
    tag        = BOOKS_DATA[i];
    exc        = BOOKS_DATA[i + 1];
    chaptersNm = BOOKS_DATA[i + 2];

    if (!exc.includes("c")) denoms += "c";
    if (!exc.includes("p")) denoms += "p";
    if (!exc.includes("o")) denoms += "o";

    if (tag == "Mt")
        part = "NT";

    BIBLE_BOOKS.all.push(new BibleBook(i / 3, denoms, part, tag, chaptersNm));
}


export { BIBLE_BOOKS };
