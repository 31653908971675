
import { SHADOW } from "../bk engine constants.js"
import { PERFORMANCE } from "../_main objects/performance.js"
import { VIEW_PORT } from "../_main objects/view port.js"
import { WORLD3D, Mesh } from "../_main objects/world3d.js"
import { BKengineCanvas } from "../primitives/canvas.js"
import { BKengineItem } from "./_item.js"


export class BKengineButton extends BKengineItem {
    constructor() {
        super();

        this.brightness     = {hover: 1.30, pressed: 0.7};
        this.disabled       = {greyMultiply: 0.8, transp: 0.4};

        this.canvas         = {normal:   new BKengineCanvas(),
                               hover:    new BKengineCanvas(),
                               pressed:  new BKengineCanvas(),
                               disabled: new BKengineCanvas(),
                               shadow:   new BKengineCanvas(),
                               glassH:   new BKengineCanvas(),
                               glassV:   new BKengineCanvas()};
        this.mesh           = new Mesh();

        this.type           = "button";
        this.cursor         = "pointer";
        this.caption        = "";

        this.isClickable    = true;
        this.isDisabled     = false;
        this.isGlass        = true;
        this.isKeyPressed   = false;    // a key, not mouse
        this.isShadow       = true;

        this.border3        = 0.2;      // border 3d
        this.keyCode        = 0;        // will be used if isBinded == true

        this.init();
    }

    set brightnessHover(value) {
        this.brightness.hover = value;
    }

    set brightnessPressed(value) {
        this.brightness.pressed = value;
    }

    set disabledGreyMultiply(value) {
        this.disabled.greyMultiply = value;
    }

    set isHoverEnabled(value) {
        if (!value)
            this.canvas.hover = null;
    }

    init() {
        this.mesh.init();
    }

    resize() {
        for (const prop in this.canvas) {
            if (this.canvas[prop])
                this.canvas[prop].resize(this.width, this.height);
        }

        if (this.isGlass) {
            this.mesh.resize(this.width, this.height, this.border3);

            // if (this.name == "main_menuEnter_menu") {
            //     let v0 = new BKengineVect3(2,3,6),
            //         v1 = new BKengineVect3(9,6,2);

            //     console.log(v0, v1, v0.getNormal(v1), v0.getVectSin(v1));
                
            // }

            this.mesh.draw(this.canvas.glassH, this.canvas.glassV, true, "#ffffff");
        }

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        if (this.width > 0 && this.height > 0) {
            this.canvas.normal.clear();

            if (!this.redrawAll(this.canvas.normal, this.caption))
                return;

            this.canvas.hover?.clear();
            this.canvas.hover?.drawImage(this.canvas.normal);
            this.canvas.hover?.brighten(this.brightness.hover);

            this.canvas.pressed.clear();
            this.canvas.pressed.drawImage(this.canvas.normal);
            this.canvas.pressed.brighten(this.brightness.pressed);

            this.canvas.disabled.clear();
            this.canvas.disabled.drawImage(this.canvas.normal);
            this.canvas.disabled.convertToGreyScale(this.disabled.greyMultiply);
            // this.canvas.disabled.makeTransparent(this.disabled.transp);

            this.canvas.shadow.clear();
            this.canvas.shadow.drawImage(this.canvas.normal);
            this.canvas.shadow.makeShadow(SHADOW.transp);
        }

        this.isRedrawn = true;
    }

    update() {
        this.updateParentMin();

        if (this.isUpdateSize)
            this.updateSize();

        if (!this.isResized)
            this.resize();

        if (!this.isRedrawn)
            this.redraw();

        if (this.isUpdatePos)
            this.updatePos();

        if (PERFORMANCE.isGlass && this.isGlass) {
            this.mesh.updateVertices(VIEW_PORT, this.left, this.top);
            this.mesh.updateLight(WORLD3D.light);

            // if (this.name == "main_menuEnter_menu") {
                // console.log(this.mesh.faces[0].light)
            // }
        }
    }

    draw(canvas) {
        let canvasSrc = this.canvas.normal,
            coords    = (this.zoom == 1) ? this : this.zoomCoords;

        if (this.isDisabled)
            canvasSrc = this.canvas.disabled;
        else if (this.isPressed || this.isKeepPressed)
            canvasSrc = this.canvas.pressed;
        else if (this.isHover && this.canvas.hover)
            canvasSrc = this.canvas.hover;

        // if (this.name == "bibleMenuControlsNext")
        //     console.log(this.canvas.hover);
        // if (this.id == 121)
        //     console.log(this.caption, this.left, this.top);

        canvas.saveTransp();
        canvas.setTransp(this.transp);
        canvas.drawImage(canvasSrc, {left: 0, top: 0, width: this.width, height: this.height}, coords);
 
        if (PERFORMANCE.isGlass && this.isGlass && !this.isDisabled)
            this.draw_glass(canvas, coords);

        canvas.restoreTransp();
    }

    drawShadow(canvas) {
        let coords = (this.zoom == 1) ? this : this.zoomCoords;

        canvas.drawImage(this.canvas.shadow,
                         {left: 0, top: 0, width: this.width, height: this.height},
                         {left: coords.left - this.getShadowLeft(coords.left, coords.width), top: coords.top - this.getShadowTop(coords.top, coords.height), width: coords.width, height: coords.height});
    }

    draw_glass(canvas, coords) {
        let f  = this.mesh.faces,
            p4 = this.mesh.points[4],
            p6 = this.mesh.points[6];

        canvas.setTransp(f[1].light);       // top
        canvas.drawImage(this.canvas.glassH,
                         {left:           0, top:          0, width:   this.width, height: p4.top},
                         {left: coords.left, top: coords.top, width: coords.width, height: p4.top});

        canvas.setTransp(f[3].light);       // bottom
        canvas.drawImage(this.canvas.glassH,
                         {left:           0, top:              p6.top, width:   this.width, height: p4.top},
                         {left: coords.left, top: coords.top + p6.top, width: coords.width, height: p4.top});

        canvas.setTransp(f[4].light);       // center
        canvas.drawImage(this.canvas.glassH,
                         {left:           0, top:              p4.top, width:   this.width, height: p6.top - p4.top},
                         {left: coords.left, top: coords.top + p4.top, width: coords.width, height: p6.top - p4.top});
    
        canvas.setTransp(f[0].light);       // left
        canvas.drawImage(this.canvas.glassV,
                         {left:           0, top:          0, width: p4.left, height: this.height},
                         {left: coords.left, top: coords.top, width: p4.left, height: this.height});
    
        canvas.setTransp(f[2].light);       // right
        canvas.drawImage(this.canvas.glassV,
                         {left:               p6.left, top:          0, width: p4.left, height: this.height},
                         {left: coords.left + p6.left, top: coords.top, width: p4.left, height: this.height});
    }
}
