
import { BK_ENGINE } from "../bk engine/_bk engine.js"
import { CRYPTO } from "../const.js"


const DEVELOP = {isShowAfterLoaded: false};

const PATH       = "gw db",
      FILE_NAME  = "_users",
      PROPS_ABBR = ["name","n", "lang","l", "country","c", "relig","r", "religDenom","s", "faith","f", "contact","d"];


class User {
    constructor(address) {
        this.address      = address;
        this.addressShort = CRYPTO.addressToShort(address);
        this.name         = "";
        this.lang         = "";
        this.country      = "";
        this.relig        = "";
        this.religDenom   = "";
        this.faith        = -1;
        this.contact      = "";
    }
}


class GWdbUsers {
    constructor() {
        this.file      = BK_ENGINE.files.addNew("path", PATH, "fileName", FILE_NAME);
        this.all       = [];

        this.areLoaded = false;
    }

    init() {
        let lines = this.file.data.split(/\r?\n|\r|\n/g),
            lineI = 0;

        while (lineI < lines.length) {
            if (lines[lineI].indexOf("0x") == 0)
                lineI = this.init_addOne(lines, lineI);
            else
                lineI++;
        }

        DEVELOP.isShowAfterLoaded && console.log(this.all);

        this.areLoaded = true;
    }

    init_addOne(lines, lineI) {
        let user = new User(lines[lineI++]),
            i, prop, value;

        while (lineI < lines.length && lines[lineI].length > 0) {
            i = PROPS_ABBR.indexOf(lines[lineI]);

            if (i > -1) {
                prop  = PROPS_ABBR[i - 1];
                value = lines[lineI + 1];

                if (prop == "faith")
                    value = parseInt(value);

                user[prop] = value;
            } else {
                console.error("GW_DB, invalid data format: \"" + lines[lineI] + "\" in file: " + this.file.fileName);

                return lineI + 1;
            }

            lineI += 2;
        }

        this.all.push(user);

        return lineI;
    }

    areReady() {
        let time = Math.floor(BK_ENGINE.timer.now / (24 * 60 * 60 * 1000));     // force to reload this file once a day

        if (this.file.extAdd != time) {
            this.file.extAdd = time;
            this.areLoaded   = false;

            this.file.load();
        }

        if (!this.areLoaded) {
            if (this.file.isLoaded)
                this.init();
        }

        return this.areLoaded;
    }

    getNm() {
        return this.all.length;
    }

    getByI(i) {
        return this.all[i];
    }

    getByProp(prop, value) {
        let i, l;

        for (i = 0, l = this.getNm(); i < l; i++) {
            if (this.all[i][prop] == value)
                return this.all[i];
        }

        return null;
    }
    
    getCountries() {
        let arr = [],
            i, l, user;

        for (i = 0, l = this.getNm(); i < l; i++) {
            user = this.getByI(i);

            if (arr.indexOf(user.country) == -1)
                arr.push(user.country);
        }

        i = arr.indexOf("");

        i > -1 && (arr[i] = "unknown");

        return arr;
    }
}


export const GW_DB_USERS = new GWdbUsers();
