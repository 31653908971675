
import { INPUT } from "./input.js"
import { VIEW_PORT } from "./view port.js"


class Point3 {           // 2d
    constructor(oX, oY) {
        this.x    = oX || 0;
        this.y    = oY || 0;

        this.left = 0;
        this.top  = 0;
    }
}

class Vertex {
    constructor(oX, oY, oZ) {
        this.x    = oX || 0;
        this.y    = oY || 0;
        this.z    = oZ || 0;

        this.left = 0;
        this.top  = 0;
    }
}

class Vect3 {
    constructor(oX, oY, oZ) {
        this.x = oX || 0;
        this.y = oY || 0;
        this.z = oZ || 0;
    }

    getLength() {
        return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2) + Math.pow(this.z, 2));
    }

    updateFromVertices(v0, v1) {
        this.x = v1.x - v0.x;
        this.y = v1.y - v0.y;
        this.z = v1.z - v0.z;
    }

    normalize() {
        let vL = this.getLength();

        if (vL != 0) {
            this.x /= vL;
            this.y /= vL;
            this.z /= vL;
        }
    }

    getNormal(v) {      // cross product
        return new Vect3(this.y * v.z - this.z * v.y,
                         this.z * v.x - this.x * v.z,
                         this.x * v.y - this.y * v.x);
    }

    getVectSin(v) {
        let denomin = this.getLength() * v.getLength();

        return (this.x * v.x + this.y * v.y + this.z * v.z) / denomin;
    }

    // getVectSinWithNormal(v) {
    //     let normal = this.getNormal(v),
    //         l      = this.getLength(),
    //         vl     = v.getLength();

    //     return Math.sqrt(Math.pow(normal.x, 2) + Math.pow(normal.y, 2) + Math.pow(normal.z, 2)) / (l * vl);
    // }
}


class Face {
    constructor() {
        this.vertices = [];
        this.center   = new Vertex();
        this.normal   = new Vect3();
        this.dot      = 0;
        this.light    = 1;

        if (arguments)
            this.init(arguments);
    }

    init(vertices) {
        let i, l;

        for (i = 0, l = vertices.length; i < l; i++)
            this.vertices.push(vertices[i]);
    }

    getVect0() {
        let v0 = this.vertices[0],
            v1 = this.vertices[1];

        return new Vect3(v1.x - v0.x, v1.y - v0.y, v1.z - v0.z);
    }

    getVect1() {
        let v0 = this.vertices[1],
            v2 = this.vertices[2];

        return new Vect3(v2.x - v0.x, v2.y - v0.y, v2.z - v0.z);
    }

    updateDot() {
        let v0 = this.getVect0(),
            v1 = this.getVect1();

        this.dot = v0.x * v1.x + v0.y * v1.y + v0.z * v1.z;
    }

    updateNormal() {
        let v0 = this.getVect0(),
            v1 = this.getVect1();

        this.normal = v0.getNormal(v1);
    }

    updateLight(light) {
        light.vect3.updateFromVertices(light.point, this.center);

        this.light = Math.max(0, light.vect3.getVectSin(this.normal) * 0.3);
    }
}


class Mesh {
    constructor() {
        this.points   = [];     // inside points
        this.vertices = [];     // 3d
        this.faces    = [];
    }

    init() {
        let v0 = new Vertex(),
            v1 = new Vertex(),
            v2 = new Vertex(),
            v3 = new Vertex(),
            v4 = new Vertex(),
            v5 = new Vertex(),
            v6 = new Vertex(),
            v7 = new Vertex(),
            i;

        // button mesh
        for (i = 0; i < 8; i++)
            this.points.push(new Point3());

        this.vertices.push(v0, v1, v2, v3, v4, v5, v6, v7);

        this.faces.push(new Face(v0, v4, v7, v3));      // left
        this.faces.push(new Face(v0, v1, v5, v4));      // top
        this.faces.push(new Face(v1, v2, v6, v5));      // right
        this.faces.push(new Face(v3, v7, v6, v2));      // bottom
        this.faces.push(new Face(v4, v5, v6, v7));      // center
    }

    resize(width, height, border3) {
        let min    = width < height ? width : height;
        let w      = width  / min,
            h      = height / min,
            i, l, p;

        // update x, y
        this.points[1].x = w;

        this.points[2].x = w;
        this.points[2].y = h;

        this.points[3].y = h;

        this.points[4].x = border3;
        this.points[4].y = border3;

        this.points[5].x = w - border3;
        this.points[5].y = border3;

        this.points[6].x = w - border3;
        this.points[6].y = h - border3;

        this.points[7].x = border3;
        this.points[7].y = h - border3;

        // update coords
        for (i = 1, l = this.points.length; i < l; i++) {
            p = this.points[i];
    
            p.left = Math.round(p.x * min);
            p.top  = Math.round(p.y * min);
        }
    }

    updateVertices(viewPort, left, top) {
        let denomin = viewPort.min / 2;
        let z     = -(this.points[4].left - this.points[0].left) / denomin,
            i, l, p, v, x, y;

        // update vertices x, y
        for (i = 0, l = this.points.length; i < l; i++) {
            p = this.points[i];
            v = this.vertices[i];

            v.x = (viewPort.centerX - (left + p.left)) / denomin;
            v.y = (viewPort.centerY - (top  + p.top))  / denomin;
        }

        // update inside rectangle's z
        for (i = 4; i < l; i++)
            this.vertices[i].z = z;

        // update normals
        for (i = 0, l = this.faces.length; i < l; i++)
            this.faces[i].updateNormal();

        // update faces' centers (for light calculations)
        z = z / 2;

        this.faces[0].center.x = (this.vertices[0].x + this.vertices[4].x) / 2;   // left
        this.faces[0].center.y = (this.vertices[0].y + this.vertices[3].y) / 2;
        this.faces[0].center.z = z;

        this.faces[1].center.x = (this.vertices[0].x + this.vertices[1].x) / 2;   // top
        this.faces[1].center.y = (this.vertices[0].y + this.vertices[4].y) / 2;
        this.faces[1].center.z = z;

        this.faces[2].center.x = (this.vertices[1].x + this.vertices[5].x) / 2;   // right
        this.faces[2].center.y = (this.vertices[1].y + this.vertices[2].y) / 2;
        this.faces[2].center.z = z;

        this.faces[3].center.x = (this.vertices[3].x + this.vertices[2].x) / 2;   // bottom
        this.faces[3].center.y = (this.vertices[3].y + this.vertices[7].y) / 2;
        this.faces[3].center.z = z;

        this.faces[4].center.x = (this.vertices[4].x + this.vertices[5].x) / 2;   // center
        this.faces[4].center.y = (this.vertices[4].y + this.vertices[7].y) / 2;
        this.faces[4].center.z = z;
    }

    updateLight(light) {
        let i, l;

        for (i = 0, l = this.faces.length; i < l; i++)
            this.faces[i].updateLight(light);
    }

    draw(canvasH, canvasV, isFill, color) {
        let p = this.points;

        canvasH.drawPolygon([p[0], p[1], p[5], p[4]], isFill, color);     // top
        canvasH.drawPolygon([p[7], p[6], p[2], p[3]], isFill, color);     // bottom
        canvasH.drawPolygon([p[4], p[5], p[6], p[7]], isFill, color);     // center

        canvasV.drawPolygon([p[0], p[4], p[7], p[3]], isFill, color);     // left
        canvasV.drawPolygon([p[1], p[2], p[6], p[5]], isFill, color);     // right
    }
}


class World3d {
    constructor() {
        this.eye   = {point: new Vertex(0, 0, -4),   vect3: new Vect3()};
        this.light = {point: new Vertex(0, 0, -0.2), vect3: new Vect3()};

        this.init();
    }

    init() {
        let p0 = new Vertex();

        this.eye.vect3.updateFromVertices(this.eye.point, p0);
    }

    update() {
        let vp      = VIEW_PORT;
        let denomin = vp.min / 2;

        this.light.point.x = (vp.centerX - INPUT.left) / denomin;
        this.light.point.y = (vp.centerY - INPUT.top)  / denomin;
    }
}

const WORLD3D = new World3d();

export { WORLD3D, Mesh };
