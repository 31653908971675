
import { BK_ENGINE, Coords } from "../../../../../../bk engine/_bk engine.js";
import { LOGICS_BALL } from "../../logics/logics ball.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "../_bible g0002 play display.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./_display playfield.js";


const DEVELOP = {imageI: -1};        // default: -1

const SHADOW_MULTIPLY = 0.5;


class DisplayPlayfieldBall {
    constructor() {
        this.canvas      = {ball:   BK_ENGINE.canvases.addNew(),
                            shadow: BK_ENGINE.canvases.addNew()};
        this.coords      = {ball:     new Coords(),
                            shadow:   new Coords(),
                            sizeHalf: 0};
        this.imageI      = 0;
        this.isShow      = true;
        this.isRedrawn   = false;

        this.isInitiated = false;
    }

    getBallCoords() {
        return {left: Math.round(LOGICS_BALL.x / PLAYFIELD.size * PLAYFIELD.coords.main.width),
                top:  Math.round(LOGICS_BALL.y / PLAYFIELD.size * PLAYFIELD.coords.main.height)};
    }

    init() {        // to do: redraw from canvas
        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.imageI    = (DEVELOP.imageI > -1) ? DEVELOP.imageI : Math.floor(Math.random() * (DISPLAY.image.balls.cols - 1)); // random ball image
        this.isRedrawn = false;

        this.show(true);
    }

    update() {
        if (!this.isShow)
            return;

        if (!this.isRedrawn)
            this.redraw();

        this.refresh();
    }

    resize() {
        let size         = Math.round(LOGICS_BALL.r * 2 / PLAYFIELD.size * PLAYFIELD.coords.main.width),
            shadowOffset = Math.round(PLAYFIELD.tileSize * SHADOW.size * SHADOW_MULTIPLY);      // smaller shadow (/ 2): looks better

        this.coords.sizeHalf = Math.floor(size / 2);

        // ball
        this.canvas.ball.resize(size, size);
        this.coords.ball.resize(size, size);

        // shadow
        this.canvas.shadow.resize(size + shadowOffset, size + shadowOffset);
        this.coords.shadow.resize(size + shadowOffset, size + shadowOffset);

        this.redraw();
    }

    redraw() {
        let shadowOffset = Math.round(PLAYFIELD.tileSize * SHADOW.size * SHADOW_MULTIPLY),
            i;

        // ball
        BK_ENGINE.canvases.drawImageQuality(DISPLAY.image.balls, this.canvas.ball, this.coords.ball, this.imageI);

        // shadow
        for (i = 1; i < shadowOffset; i++)
            this.canvas.shadow.drawImage(this.canvas.ball, this.coords.ball, {left: i, top: i, width: this.coords.ball.width, height: this.coords.ball.height});

        this.canvas.shadow.makeShadow(SHADOW.transp);

        this.isRedrawn = true;
    }

    show(isVisible) {
        this.isShow = isVisible;
    }

    refresh() {
        let coords = this.getBallCoords();

        PLAYFIELD.canvas.pgd.drawImage(this.canvas.ball, this.coords.ball, {left: coords.left - this.coords.sizeHalf, top: coords.top - this.coords.sizeHalf, width: this.coords.ball.width, height: this.coords.ball.height});
        DISPLAY.canvas.shadows.drawImage(this.canvas.shadow, this.coords.shadow, {left: PLAYFIELD.coords.main.left + coords.left - this.coords.sizeHalf, top: PLAYFIELD.coords.main.top + coords.top - this.coords.sizeHalf, width: this.coords.shadow.width, height: this.coords.shadow.height});
    }
}

export const DISPLAY_PLAYFIELD_BALL = new DisplayPlayfieldBall();
