
export class BKengineRedraw {
    constructor(oType) {
        this.type    = oType || "";
        this.name    = "";

        this.caption = "";

        this.src     = {};       // image, sprite, canvas...

        this.srcCol  = 0;
        this.srcRow  = 0;

        this.col     = 0;
        this.row     = 0;

        this.x       = 0;
        this.y       = 0;
        this.w       = 0;
        this.h       = 0;

        this.xMin    = 0;        // of parent's minimum: width or height
        this.yMin    = 0;
        this.hMin    = 0;
        this.wMin    = 0;

        this.pivX    = 0;
        this.pivY    = 0;

        this.left    = 0;
        this.top     = 0;
        this.width   = 0;
        this.height  = 0;

        this.transp  = 1;       // transparency
    }

    updateCoords(coords) {
        let min = (coords.width < coords.height) ? coords.width : coords.height;

        this.width  = Math.round(this.w * coords.width  + this.wMin * min);
        this.height = Math.round(this.h * coords.height + this.hMin * min);
        this.left   = Math.round(this.x * coords.width  + this.xMin * min + this.pivX * this.width);
        this.top    = Math.round(this.y * coords.height + this.yMin * min + this.pivY * this.height);
    }

    draw(canvas, oCaption) {
        let caption = oCaption || "",
            obj     = this.src,
            i, min;

        switch (this.type) {
            case "image":
                if (!obj.isLoaded)
                    return false;

                if (this.transp < 1) {
                    canvas.saveTransp();
                    canvas.setTransp(this.transp);
                    canvas.drawImage(obj, {left: this.srcCol * obj.width, top: this.srcRow * obj.height, width: obj.width, height: obj.height}, this);
                    canvas.restoreTransp();
                } else
                    canvas.drawImage(obj, {left: this.srcCol * obj.width, top: this.srcRow * obj.height, width: obj.width, height: obj.height}, this);
                break;
            case "canvas":
                canvas.drawImage(obj, {left: this.srcCol * obj.width, top: this.srcRow * obj.height, width: obj.width, height: obj.height}, this);
                break;
            case "rect":
                if (obj.round > 0 && obj.round < 1) {
                    min = this.width < this.height ? this.width : this.height;
                    i   = Math.round(obj.round * min);
                } else
                    i = obj.round;
                obj.draw(canvas, this, i);
                break;
            case "shapes":
                obj.updateCoordsFromXY(this);
                obj.draw(canvas, this);
                break;
            case "text":
                obj.height = this.height;

                if (caption.length == 0)
                    caption = (this.caption.length > 0) ? this.caption : obj.caption;

                obj.prepare(canvas);
                obj.draw(canvas, this, caption);
                break;
            case "textDisplay":
                obj.updateTextsHeight(this.height);
                obj.updateWordsCoordsFromXY(this, this.srcCol);
                obj.draw(canvas, this, this.srcCol);

                // if (!obj)
                //     console.log("no src")
                // obj.height = this.height;

                // if (caption.length == 0)
                //     caption = (this.caption.length > 0) ? this.caption : obj.caption;

                // obj.prepare(canvas);
                // obj.draw(canvas, this, caption);
        }
        return true;
    }
}
