
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js";
import { BKengineTextContent } from "../../../bk engine/complex/text content.js"
import { BKengineTextDisplay } from "../../../bk engine/complex/text display.js"


const STATE      = {loadingImages: 10, main: 20},
      LINE_WIDTH = {ext: 0.01, int: 0.003},
      PADDING    = 0.02,
      COLOR      = {line:     "#dddddd",
                    bgd:      "#000000cc",
                    item:     "#00000088",
                    hover:    "#ffffff44",
                    pressed:  "#00000044",
                    selected: "#ffffff55"},
      BTN_SIZE   = 0.5,
      BTN_H      = {verNarrow: 0.1, verWide: 0.09, horNarrow: 0.08, horWide: 0.07};


class DispItem {
    constructor() {
        this.name        = "";
        this.content     = "";
        this.textContent = new BKengineTextContent();
        this.textDisplay = new BKengineTextDisplay();
        this.coords      = new Coords();
    }
}

class DispBtn {
    constructor(name) {
        this.name   = name;
        this.coords = new Coords();
        this.canvas = BK_ENGINE.canvases.addNew();
    }
}


class MenuSelect extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = 0;

        this.image       = null;
        this.canvas      = null;
        this.text        = null;
        this.item        = null;
        this.coords      = {main:  new Coords(),
                            title: new Coords(),
                            item:  new Coords(),
                            tmp:   new Coords()};
        this.lineWidth   = {ext: 0, int: 0};
        this.padding     = 0;
        this.title       = "";
        this.items       = [];
        this.itemIstart  = 0;
        this.itemIstep   = 0;
        this.btn         = {up:   new DispBtn("up"),
                            down: new DispBtn("down"),
                            quit: new DispBtn("quit")};
        this.mouse       = {btns:  {mouseDown: -1, hover: 0, pressed: 0, clicked: 0},
                            items: {mouseDown: -1, hover: 0, pressed: 0, clicked: 0}};
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        this.image = {arrows: BK_ENGINE.images.getByName("main_simpleControls")};

        this.canvas = {main: BK_ENGINE.canvases.getByName("main_userMenuSelect"),
                       bgd:  BK_ENGINE.canvases.addNew()};

        this.text = {titleBig: BK_ENGINE.texts.getByName("main_userMenuSelect_title"),
                     item:     BK_ENGINE.texts.getByName("main_userMenuSelect_item")};

        this.item = {main: BK_ENGINE.items.getByName("main_userMenuSelect")};

        this.isInitiated = true;
    }

    initItems() {
        let i;

        this.items = [];

        for (i = 0; i < this.arrData.length; i += 2)
            this.initItems_one(this.arrData[i], this.arrData[i + 1]);
    }

    initItems_one(name, content) {
        let item = new DispItem();

        item.name              = name;
        item.content           = content;
        item.textContent.texts = [this.text.item];

        item.textContent.init(content);

        this.items.push(item);
    }

    start2(title, arrData, selected = "") {
        !this.isInitiated && this.init();

        this.title      = title;
        this.arrData    = arrData;
        this.selected   = selected;
        this.itemIstart = 0;
        this.state      = STATE.loadingImages;

        this.initItems();
        this.show(true);
    }

    end2(selected = this.selected) {
        this.selected = selected;

        this.show(false);
    }

    update() {
        if (this.state == STATE.loadingImages)
            return this.update_loadingImages();

        this.update_main();
    }

    update_loadingImages() {
        if (!this.image.arrows.isLoaded || this.item.main.width == 0 || this.item.main.height == 0)
            return;

        this.resize();

        this.state = STATE.main;
    }

    update_main() {
        if (this.item.main.width != this.canvas.main.width || this.item.main.height != this.canvas.main.height)
            this.resize();

        this.refresh();

        // check buttons clicked
        this.update_main_checkMouseBtns();
 
        switch (this.mouse.btns.clicked) {
            case "up":
                this.itemIstart -= this.itemIstep;
                break;
            case "down":
                this.itemIstart += this.itemIstep;
                break;
            case "quit":
                return this.end("quit");
            default:
                if (BK_ENGINE.input.mouseWheel < 0)
                    this.itemIstart--;
                else if (BK_ENGINE.input.mouseWheel > 0)
                    this.itemIstart++;
        }

        this.itemIstart = Math.max(0, Math.min(this.items.length - this.itemIstep - 1, this.itemIstart));

        // check items clicked
        this.mouse.items.hover = this.mouse.items.pressed = -1;

        if (this.mouse.btns.hover == -1) {
            this.update_main_checkMouseItems();

            if (this.mouse.items.clicked)
                return this.end(this.mouse.items.clicked.name);
        }
    }

    update_main_checkMouseBtns() {
        let i = 0,
            btn;

        this.mouse.btns.hover   = this.mouse.btns.pressed = -1;
        this.mouse.btns.clicked = "";

        for (const prop in this.btn) {
            btn = this.btn[prop];

            if (BK_ENGINE.input.isMouseInsideRect({left: this.item.main.left + btn.coords.left, top: this.item.main.top + btn.coords.top, width: btn.coords.width, height: btn.coords.height})) {
                this.mouse.btns.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.btns.mouseDown == -1)
                        this.mouse.btns.mouseDown = i;

                    this.mouse.btns.pressed = i;
                } else {
                    if (this.mouse.btns.mouseDown == i)
                        this.mouse.btns.clicked   = btn.name;

                    this.mouse.btns.mouseDown = -1;
                }

                return;
            }

            i++;
        }
    }

    update_main_checkMouseItems() {
        let i, item;

        this.mouse.items.clicked = null;
        this.coords.item.left    = this.item.main.left;
        this.coords.item.top     = this.coords.title.height;

        for (i = this.itemIstart; i < this.items.length; i++) {
            item = this.items[i];

            this.coords.item.height = item.coords.height;

            if (BK_ENGINE.input.isMouseInsideRect(this.coords.item)) {
                this.mouse.items.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.items.mouseDown == -1)
                        this.mouse.items.mouseDown = i;

                    this.mouse.items.pressed = i;
                } else {
                    if (this.mouse.items.mouseDown == i)
                        this.mouse.items.clicked   = item;

                    this.mouse.items.mouseDown = -1;
                }

                return;
            }

            this.coords.item.top += item.coords.height;
        }
    }

    resize() {
        let width  = this.item.main.width,
            height = this.item.main.height,
            btnH   = BTN_H[BK_ENGINE.viewPort.orientation],
            min;

        min = this.coords.main.min = width < height ? width : height;

        this.lineWidth.ext = Math.max(1, Math.round(min * LINE_WIDTH.ext));
        this.lineWidth.int = Math.max(1, Math.round(min * LINE_WIDTH.int));
        this.padding       = Math.max(1, Math.round(min * PADDING));

        this.coords.main.resize(width, height);
        this.coords.title.resize(width, Math.round(min * btnH));

        this.coords.item.width = width;

        this.canvas.main.resize(width, height);
        this.canvas.bgd.resize(width, height);

        this.resize_items();
        this.resize_btns();
        this.redraw();
    }

    resize_items() {
        let i, item;
        
        for (i = 0; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.reset();
            item.textDisplay.resize(this.coords.main.width, this.coords.main.height);
        }
    }

    resize_btns() {
        let width  = Math.round(this.coords.main.width / 3),
            height = this.coords.title.height,
            top    = this.coords.main.height - this.coords.title.height;

        // btn up
        this.btn.up.canvas.resize(width, height);
        this.btn.up.coords.resize(width, height);
        this.btn.up.coords.top  = top;

        // btn down
        this.btn.down.canvas.resize(width, height);
        this.btn.down.coords.resize(width, height);
        this.btn.down.coords.left = width;
        this.btn.down.coords.top  = top;

        // btn quit
        this.btn.quit.coords.left = this.coords.main.width - width;

        width = this.coords.main.width - this.btn.quit.coords.left;

        this.btn.quit.canvas.resize(width, height);
        this.btn.quit.coords.resize(width, height);
        this.btn.quit.coords.top = top;
    }

    redraw() {
        this.canvas.bgd.drawRect(this.coords.main,  COLOR.bgd, true);

        // title
        this.text.titleBig.calcHeight(this.coords.title.height);
        this.text.titleBig.prepare(this.canvas.bgd);
        this.text.titleBig.draw(this.canvas.bgd, {left: Math.round(this.coords.title.width / 2), top: this.coords.title.top + Math.round(this.coords.title.height / 2)}, this.title);

        this.redraw_items();
        this.redraw_btns();
    }

    redraw_items() {
        let i, item;

        for (i = 0; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.addTextContent(item.textContent);
            item.coords.resize(this.item.main.width, item.textDisplay.effectiveHeight + this.padding * 2);
        }
    }

    redraw_btns() {
        let i = 0,
            btn, left, top, size;

        for (const prop in this.btn) {
            btn = this.btn[prop];

            size = Math.round(BTN_SIZE * btn.coords.height);
            left = Math.round((btn.coords.width - size) / 2);
            top  = Math.round((btn.coords.height - size) / 2);

            btn.canvas.clear();
            BK_ENGINE.canvases.drawImageQuality(this.image.arrows, btn.canvas, {left: left, top: top, width: size, height: size}, i++);
        }
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;
    }

    refresh() {
        this.canvas.main.clear();
        this.canvas.main.drawImage(this.canvas.bgd);
        this.refresh_items();
        this.refresh_btns();
        this.canvas.main.drawRect(this.coords.main, COLOR.line, false, this.lineWidth.ext);

        this.item.main.isRedrawn = false;
    }

    refresh_items() {
        let i, l, item;

        this.itemIstep        = -1;     // let it be 1 less than max
        this.coords.tmp.left  = 0;
        this.coords.tmp.top   = this.coords.title.height;
        this.coords.tmp.width = this.coords.title.width;

        for (i = this.itemIstart, l = this.items.length; i < l; i++) {
            item = this.items[i];

            if (this.coords.tmp.top + item.coords.height >= this.btn.up.coords.top)
                return;

            this.refresh_items_one(i, item);

            this.itemIstep++;
        }
    }

    refresh_items_one(i, item) {
        let isHover   = false,
            isPressed = false;

        if (i == this.mouse.items.pressed)
            isPressed = true;
        else if (!BK_ENGINE.isTouchScreen && i == this.mouse.items.hover)
            isHover = true;

        this.coords.tmp.height = item.coords.height;

        this.canvas.main.drawRect(this.coords.tmp, COLOR.item, true);
        item.textDisplay.draw(this.canvas.main, {left: this.coords.tmp.left + this.padding, top: this.coords.tmp.top + this.padding, width: item.coords.width - this.padding * 4, height: item.coords.height - this.padding * 2});
        this.refresh_rect(this.coords.tmp, isHover, isPressed, item.name == this.selected);

        this.canvas.main.drawRect(this.coords.tmp, COLOR.line, false, this.lineWidth.int);

        this.coords.tmp.top += item.coords.height;
    }

    refresh_btns() {
        let i = 0,
            btn, isHover, isPressed;

        for (const prop in this.btn) {
            btn     = this.btn[prop];
            isHover = isPressed = false;

            if (i == this.mouse.btns.pressed)
                isPressed = true;
            else if (!BK_ENGINE.isTouchScreen && i == this.mouse.btns.hover)
                isHover = true;

            this.canvas.main.drawImage(btn.canvas, {left: 0, top: 0, width: btn.coords.width, height: btn.coords.height}, btn.coords);
            this.refresh_rect(btn.coords, isHover, isPressed);
            this.canvas.main.drawRect(btn.coords, COLOR.line, false, this.lineWidth.int);

            i++;
        }
    }

    refresh_rect(coords, isHover, isPressed, isSelected = false) {
        if (isPressed)  return this.canvas.main.drawRect(coords, COLOR.pressed, true);
        if (isSelected) return this.canvas.main.drawRect(coords, COLOR.selected, true);
        if (isHover)    return this.canvas.main.drawRect(coords, COLOR.hover, true);
    }
}

export const MENU_SELECT = BK_ENGINE.tasks.add(new MenuSelect(BK_ENGINE.tasks));
