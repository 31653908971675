
import { getByName } from "../bk engine utils.js"
import { BKengineText } from "../primitives/text.js"


class Texts {
    constructor() {
        this.all = [];
    }

    add(text) {
        return this.all[this.all.push(text) - 1];
    }

    addNew() {
        let args = (typeof arguments[0] == "string") ? arguments : arguments[0],
            text = new BKengineText(),
            i, l, prop, value;

        for (i = 0, l = args.length; i < l; i += 2) {
            prop  = args[i];
            value = args[i + 1];

            text[prop] = value;
        }

        return this.add(text);
    }

    addFromNames = function(obj, names) {
        let arr = names.split(" "),
            i, l, name, text;

        for (i = 0, l = arr.length; i < l; i++) {
            name = arr[i];

            if (name.length > 0) {
                text = this.getByName(name);

                if (text != undefined)
                    obj.addText(text);
                else
                    console.log("text: *" + name + "* not found!");
            }
        }
    }

    getByName(value) {
        return getByName(this.all, value);
    }
}


export const TEXTS = new Texts();
