
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_MAIN, CONTROL_BOX } from "../../_gw main.js";


class SelectDateFormat extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_userSelectDateFormat"),
                     content:   I.getByName("main_userSelectDateFormat_content"),
                     rbDMY:     I.getByName("main_userSelectDateFormat_rbDMY"),
                     rbMDY:     I.getByName("main_userSelectDateFormat_rbMDY"),
                     rbYMD:     I.getByName("main_userSelectDateFormat_rbYMD")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.show(true);        
    }

    end2() {
        if (this.item.rbDMY.isChecked)
            GW_USER.dateFormat = "dmy";
        else
            GW_USER.dateFormat = this.item.rbMDY.isChecked ? "mdy" : "ymd";

        this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "main_userSelectDateFormat_rbDMY":
                return this.refresh("dmy");
            case "main_userSelectDateFormat_rbMDY":
                return this.refresh("mdy");
            case "main_userSelectDateFormat_rbYMD":
                return this.refresh("ymd");
        }

        if (CONTROL_BOX.selected == "abandon")
            this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible) {
            this.item.content.content = "[*centerV,center*]" + GW_MAIN.file.lang.getCaption("userSelectDateFormat_content");
            this.item.rbDMY.caption   = GW_MAIN.file.lang.getCaption("userSelectDateFormat_dmy");
            this.item.rbMDY.caption   = GW_MAIN.file.lang.getCaption("userSelectDateFormat_mdy");
            this.item.rbYMD.caption   = GW_MAIN.file.lang.getCaption("userSelectDateFormat_ymd");

            CONTROL_BOX.start("", "", ["close"]);
            this.refresh(GW_USER.dateFormat);
        } else
            CONTROL_BOX.end();
    }

    refresh(selected) {
        this.item.rbDMY.isChecked = selected == "dmy";
        this.item.rbMDY.isChecked = selected == "mdy";
        this.item.rbYMD.isChecked = selected == "ymd";
    }
}

export const SELECT_DATE_FORMAT = BK_ENGINE.tasks.add(new SelectDateFormat(BK_ENGINE.tasks));
