import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_USER } from "../gw user/_gw user.js";
import { GW_BIBLE } from "../gw bible/_gw bible.js";
import { COOKIES } from "./cookies.js";
import { CONTROL_BOX } from "./control box.js";
import { INFO_BOX } from "./info box.js";
import { MENU_LANG } from "./menu lang.js";
import { MENU_NEWS } from "./menu news.js";
import { MENU_USER } from "./menu user/_menu user.js";
import { MAIN_TASK } from "./_main task.js";


const DEVELOP = {jumpTo: ""};       // menuUser, enterGuest, enterEmail, menuInfo, donations

const PATH = {ui:   "gw main/ui",
              lang: "gw main/lang/"},
      FILE = {filesListUi: "_files", filesListLang: "files"};

const AUTOMATIC_LANGUAGES = ["fr", "es", "esla"];       // set them here (translated by online translators)

const STATE     = {init: 10, setOrient: 20, setLang: 30, langWarning: 40, loadNews: 50, main: 60},
      TIME      = {refreshUserState: 500},
      LANGUAGES = {names: ["english", "español", "español (América Latina)", "français", "italiano", "polski"],
                   tags:  ["en",      "es",      "esla",                     "fr",       "it",       "pl"]};


class GWmain extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state         = 0;

        const F            = BK_ENGINE.files;
        this.file          = {filesListUi:   F.addNew("path", PATH.ui, "fileName", FILE.filesListUi),
                              filesListLang: F.addNew("fileName", FILE.filesListLang),
                              uiCommon:      F.addNew("path", PATH.ui),
                              uiImgs:        F.addNew("path", PATH.ui),
                              uiOrient:      F.addNew("path", PATH.ui),
                              lang:          F.addNew()};
        this.orient        = "";
        this.redraw        = null;
        this.item          = null;
        this.user          = {timer: 0, redraw: null};
        this.lang          = {warned: []};
        this.selected      = "";         // "enterGuest", "enterEmail"

        this.isInitiated   = false;
    }

    get languages() {
        return LANGUAGES;
    }

    alertCaption(caption, oTxt) {
        let txt1 = this.file.lang.getCaption(caption),
            txt2 = oTxt || "";

        alert(txt1 + txt2);
    }

    checkMenus() {
        let result = this.checkMenus_one();

        if (result != "")
            BK_ENGINE.flushInput();

        return result;
    }

    checkMenus_one() {
        switch (BK_ENGINE.clicked.name) {
            case "main_menuLang_menu":
                return "menuLang";
            case "main_menuBtn_user":
                return "menuUser";
            case "main_menuBtn_news":
                return "menuNews";
            case "main_menuBtn_info":
                return "menuInfo";
            case "main_menuEnter_menu":
                return "menuEnter";
            case "main":
                return "out";
        }

        return "";
    }

    init() {
        let R      = BK_ENGINE.redraws,
            I      = BK_ENGINE.items,
            cookie = COOKIES.getOne("autoLangs"),
            i, lang;

        // redraws and items
        this.redraw = {user:         R.getByName("main_menuBtn_user"),
                       userNotify:   R.getByName("main_menuBtn_userNotify"),
                       userLoggedIn: R.getByName("main_menuBtn_userLoggedIn"),
                       news:         R.getByName("main_menuBtn_news"),
                       newsNotify:   R.getByName("main_menuBtn_newsNotify")};

        this.item = {mainFrame:       I.getByName("main"),
                     userDataLoading: I.getByName("main_userDataLoading"),
                     btnUser:         I.getByName("main_menuBtn_user"),
                     btnNews:         I.getByName("main_menuBtn_news")};

        BK_ENGINE.items.orderZ();

        // languages to be warned about automatic translation
        for (i = 0; i < AUTOMATIC_LANGUAGES.length; i++) {
            lang = AUTOMATIC_LANGUAGES[i];

            if (AUTOMATIC_LANGUAGES.includes(lang) && cookie.includes(lang) && !this.lang.warned.includes(lang))
                this.lang.warned.push(lang);
        }   
    
        this.isInitiated = true;
    }

    start2() {
        if (this.isInitiated)
            return this.resume();

        this.state = STATE.init;

        // force the browser to load these files!
        this.file.filesListUi.extAdd = this.file.filesListLang.extAdd = "?" + BK_ENGINE.timer.now;

        this.file.filesListUi.load();
        COOKIES.preload();

        !GW_USER.isActive && GW_USER.start();
    }

    end2(selected = "enterGuest") {
        this.selected = selected;

        this.show(false);
        GW_BIBLE.start();
    }

    resume() {
        !GW_USER.isActive && GW_USER.start();

        this.show(true);
    }

    update() {
        switch (this.state) {
            case STATE.init:
                return this.update_init();
            case STATE.setOrient:
                return this.update_setOrient();
            case STATE.setLang:
                return this.update_setLang();
            case STATE.langWarning:
                return this.update_langWarning();
            default:
                this.update_main();
        }
    }

    update_init() {
        if (!this.file.filesListUi.isLoaded)
            return;

        if (this.file.uiCommon.fileName.length == 0) {
            this.file.uiCommon.fileName = this.file.filesListUi.getCaption("ui_common").trim();
            this.file.uiImgs.fileName   = this.file.filesListUi.getCaption("ui_imgs_" + BK_ENGINE.viewPort.imagesQuality).trim();

            this.file.uiCommon.load();
            this.file.uiImgs.load();
        }

        if (this.file.uiCommon.isLoaded && this.file.uiImgs.isLoaded) {
            BK_ENGINE.addFromData(this.file.uiCommon.data);
            BK_ENGINE.setFromData(this.file.uiImgs.data);
            this.update_setOrient();
        }
    }

    update_setOrient() {
        if (this.state != STATE.setOrient) {
            this.state                  = STATE.setOrient;
            this.orient                 = BK_ENGINE.viewPort.orientation;
            this.file.uiOrient.fileName = this.file.filesListUi.getCaption("ui_orient_" + BK_ENGINE.viewPort.orientation).trim();

            this.file.uiOrient.load();
        }
    
        if (this.file.uiOrient.isLoaded) {
            BK_ENGINE.setFromData(this.file.uiOrient.data);

            if (this.isInitiated)
                return this.state = STATE.main;

            this.init();
            this.update_setLang();
        }
    }

    update_setLang() {
        if (this.state != STATE.setLang) {
            this.state                       = STATE.setLang;
            this.file.filesListLang.path     = PATH.lang + GW_USER.lang;
            this.file.filesListLang.fileName = "_" + GW_USER.lang + " " + FILE.filesListLang;
            this.file.lang.path              = PATH.lang + GW_USER.lang;
            this.file.lang.fileName          = "";

            this.file.filesListLang.load();
        }

        if (!this.file.filesListLang.isLoaded)
            return;

        if (this.file.lang.fileName.length == 0) {
            this.file.lang.fileName = this.file.filesListLang.getCaption("ui").trim();

            this.file.lang.load();
        }

        if (!this.file.lang.isLoaded)
            return;

        BK_ENGINE.setFromData(this.file.lang.data);
        MENU_LANG.refresh();

        console.log("Language set to: \"" + GW_USER.lang + "\"");

        // automatic translation?
        if (AUTOMATIC_LANGUAGES.indexOf(GW_USER.lang) > -1) {
            if (this.lang.warned.indexOf(GW_USER.lang) == -1) {
                this.lang.warned.push(GW_USER.lang);

                return this.update_langWarning();
            }
        }

        this.update_loadNews();
    }

    update_langWarning() {
        if (this.state != STATE.langWarning) {
            this.state = STATE.langWarning;

            INFO_BOX.start(this.file.lang.getCaption("alertTranslationAutomatic"), "gotIt");
        }
        
        if (!INFO_BOX.isActive)
            this.update_loadNews();
    }

    update_loadNews() {
        let cookie = "",
            i;

        if (!MENU_NEWS.isInitiated) {       // first time calling?
            this.state = STATE.loadNews;

            MENU_NEWS.start(false);
            COOKIES.start();

            // update "autoLangs" cookie
            for (i = 0; i < this.lang.warned.length; i++)
                cookie += this.lang.warned[i] + ",";
            
            COOKIES.setOne("autoLangs", cookie);
        }

        if (this.state != STATE.loadNews || !MENU_NEWS.isActive)
            this.state = STATE.main;
    }

    update_main() {
        let selected;

        if (this.orient != BK_ENGINE.viewPort.orientation)
            return this.update_setOrient();

        if (MAIN_TASK.userDataLoading.isActive || MAIN_TASK.menuEnter.isActive || MAIN_TASK.menuInfo.isActive          || MAIN_TASK.menuLang.isActive || MAIN_TASK.menuNews.isActive || MAIN_TASK.menuUser.isActive)
            return;

        selected = this.checkMenus();

        switch (selected) {
            case "menuEnter":
            case "menuInfo":
            case "menuLang":
            case "menuNews":
            case "menuUser":
                MAIN_TASK[selected].start();
        }

        this.update_main_checkUserDataLoaded();
        this.update_main_btnUser();
    }

    update_main_checkUserDataLoaded() {
        if (GW_USER.isDataLoading)
            MAIN_TASK.userDataLoading.start();

        if (GW_USER.isLangChanged) {
            GW_USER.isLangChanged = false;

            this.refresh();
            this.update_setLang();
        }
    }

    update_main_btnUser() {
        let redraw = this.redraw.user;

        this.user.timer += BK_ENGINE.timer.delta;

        if (this.user.timer < TIME.refreshUserState)
            return;

        this.user.timer = 0;

        if (GW_USER.isLoggedIn)
            redraw = this.redraw.userLoggedIn;
        else if (GW_USER.isNewUser && !MENU_USER.isOnceShown)
            redraw = this.redraw.userNotify;
        
        if (redraw != this.user.redraw) {
            this.item.btnUser.setRedraw(redraw);
            
            this.item.btnUser.isRedrawn = false;
            this.user.redraw            = redraw;
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible)
            this.refresh();
    }

    refresh() {
        this.refresh_btnNews();
        // this.item.menuLogInMark.isVisible = !GW_USER.isVersionSet;      // a mark on the menu log in button
    }

    refresh_btnNews() {
        let newsDate = MENU_NEWS.getNewestDate(),
            userDate = GW_USER.lastSessionDate,
            redraw   = this.redraw.news;

        if (!MENU_NEWS.isOnceShown && newsDate > userDate)
            redraw = this.redraw.newsNotify;

        this.item.btnNews.setRedraw(redraw);
        
        this.item.btnNews.isRedrawn = false;
    }
}

const GW_MAIN = BK_ENGINE.tasks.add(new GWmain(BK_ENGINE.tasks));


export { GW_MAIN, DEVELOP, PATH, CONTROL_BOX, INFO_BOX };
