
import { getByName, getIdByProp } from "../bk engine utils.js";

import { BKengineItem } from "../items/_item.js";
import { BKengineBook } from "../items/book.js";
import { BKengineButton } from "../items/button.js";
import { BKengineLabel } from "../items/label.js";
import { BKenginePicture } from "../items/picture.js";
import { BKengineProgressBar } from "../items/progress bar.js";
import { BKengineSprite } from "../items/sprite.js";
import { BKengineTextArea } from "../items/text area.js";
import { BKengineTextInput } from "../items/text input.js";
import { BKengineCheckBox } from "../items/check box.js";
import { BKengineRadioBox } from "../items/radio box.js";

import { INPUT } from "../_main objects/input.js";
import { PERFORMANCE } from "../_main objects/performance.js";
import { POINTER } from "../_main objects/pointer.js";

import { CANVASES } from "./canvases.js";
import { REDRAWS } from "./redraws.js";
import { TEXTS } from "./texts.js";


class Items {
    constructor() {
        this.id       = 0;         // will be assigned to every new added item
        this.all      = [];
        this.toRender = [new Array, new Array];    // after every update() here will be placed items to be rendered (only the visible ones), odd indexes: shadows, even indexes: items
    }

    getWidth(item) {
        switch (item.type) {
            case "label":
                return item.text.getWidth(CANVASES.tmp, item.caption);  // CANVAS.tmp can be any size, the result is the same
        }

    }

    add(item) {
        return this.all[this.all.push(item) - 1];
    }

    addNew() {             // first argument must be the type: sprite, button, label, if empty or "item", it will be "item" type
        let args = (typeof arguments[0] == "string") ? arguments : arguments[0];
        let type = args[0],
            i, l, prop, value, item;

        switch (type) {
            case "book":
                item = new BKengineBook();
                break;
            case "button":
                item = new BKengineButton();
                break;
            case "checkBox":
                item = new BKengineCheckBox();
                break;
            case "label":
                item = new BKengineLabel();
                break;
            case "picture":
                item = new BKenginePicture();
                break;
            case "progressBar":
                item = new BKengineProgressBar();
                break;
            case "radioBox":
                item = new BKengineRadioBox();
                break;    
            case "sprite":
                item = new BKengineSprite();
                break;
            case "textArea":
                item = new BKengineTextArea();
                break;
            case "textInput":
                item = new BKengineTextInput();
                break;
            default:
                item = new BKengineItem();
        }

        item.id     = this.id++;
        item.parent = this.all[0];

        if (this.all.length > 0)
            this.all[0].addChild(item);

        // set properties
        for (i = 1, l = args.length; i < l; i += 2) {
            prop  = args[i];
            value = args[i + 1];

            switch (prop) {
                case "parent":
                    // remove the item from parent's children
                    // j = this.MAIN.utils.getIdByName(item.parent.children, item.name);

                    item.parent.removeChild(item);

                    item.parent = this.getByName(value);
        
                    // then add it to new parent's children
                    if (item.parent)
                        item.parent.addChild(item);
                    else
                        console.log("Item's: " + item.name + " parent not found!");
                    break;
                case "redraws":
                    REDRAWS.addFromNames(item, value);
                    break;
                case "text":
                    item.text = TEXTS.getByName(value);
                    break;
                case "texts":
                    TEXTS.addFromNames(item, value);
                    break;
                case "isVisible":
                    if (item.type == "textInput") {
                        item.setVisible(value);
                        break;
                    }
                default:
                    item[prop] = value;
            }
        }

        return this.add(item);
    }

    clone(item) {
        let i, l,
            obj, itemProp, objProp;

        switch (item.type) {
            case "button":
                obj = new BKengineButton();

                for (const prop in item) {
                    switch (prop) {
                        case "canvas":          // don't clone these!
                        case "children":
                            break;
                        case "brightness":
                        case "zoomCoords":
                            itemProp = item[prop];
                            objProp  = obj[prop];
                            
                            for (const prop2 in itemProp)
                                objProp[prop2] = itemProp[prop2];
                            break;
                        case "redraws":
                            for (i = 0, l = item.redraws.length; i < l; i++)
                                obj.redraws.push(item.redraws[i]);
                            break;
                        default:
                            obj[prop] = item[prop];
                    }
                }
                obj.isHover = false;
                obj.parent.addChild(obj);
                break;
            case "sprite":
                obj = new BKengineSprite();

                for (const prop in item) {
                    switch (prop) {
                        case "canvas":          // don't clone these!
                        case "children":
                            break;
                        case "zoomCoords":
                            itemProp = item[prop];
                            objProp  = obj[prop];
                            
                            for (const prop2 in itemProp)
                                objProp[prop2] = itemProp[prop2];
                            break;
                        case "redraws":
                            for (i = 0, l = item.redraws.length; i < l; i++)
                                obj.redraws.push(item.redraws[i]);
                            break;
                        default:
                            obj[prop] = item[prop];
                    }
                }
                obj.parent.addChild(obj);
                break;
            default:
                console.log("items.clone, TO DO: " + item.type);
        }

        obj.id = this.id++;

        if (obj.width > 0 && obj.height > 0)
            obj.resize();

        return this.add(obj);
    }

    getByName(value) {
        return getByName(this.all, value);
    }

    remove(item) {
        let i = getIdByProp(this.all, "id", item.id);

        if (i > -1) {
            item.parent.removeChild(item);

            this.all.splice(i, 1);
        } else
            console.error("No item to remove with id: " + item.id);
    }

    redrawAll() {
        for (let i = 1, l = this.all.length; i < l; i++)
            this.all[i].isRedrawn = false;
    }

    orderZ(oParent) {
        let parent    = (oParent == undefined) ? this.all[0] : oParent;
        let childrenL = parent.children.length - 1,
            childI, isRepeat;

        do {
            childI   = 0;
            isRepeat = false;

            while (childI < childrenL && !isRepeat)
                isRepeat = this.orderZ_one(parent, childI++);

        } while (isRepeat);
    }

    orderZ_one(parent, childI) {
        let child1 = parent.children[childI],
            child2 = parent.children[childI + 1];

        if (child1.z > child2.z) {
            parent.children[childI]     = child2;
            parent.children[childI + 1] = child1;

            return true;
        }

        return false;
    }

    update() {
        let i, l, lastZ;

        // find items to render
        for (i = 0, l = this.toRender.length; i < l; i++)
            this.toRender[i] = [];

        this.update_children(this.all[0], 1);

        lastZ = this.toRender.length - 1;

        // pointer
        if (PERFORMANCE.isPointer && POINTER.isActive) {
            POINTER.update();

            this.toRender[lastZ].push(POINTER);    // push to the last layer
        }

        // performance testing
        if (PERFORMANCE.isShow)
            this.toRender[lastZ].push(PERFORMANCE);

        // clickables
        this.clickable.update(this.toRender);
    }

    update_children(item, z) {
        let child, zTotal;

        for (let i = 0, l = item.children.length; i < l; i++) {
            child  = item.children[i];
            zTotal = z + child.z * 2;

            if (child.isVisible) {
                child.update();

                while (this.toRender.length <= zTotal)      // increase toRender array?
                    this.toRender.push(new Array);

                this.toRender[zTotal].push(child);          // add item to render

                if (PERFORMANCE.isShadows && child.isShadow)
                    this.toRender[zTotal - 1].push(child);  // and its shadow (z - 1)

                if (child.children.length > 0)
                    this.update_children(child, zTotal + 2);
            }
        }
    }

    render() {
        let i, l, m, z,
            layer, item;

        for (z = 0, l = this.toRender.length; z < l; z += 2) {
            // shadows
            if (PERFORMANCE.isShadows) {
                layer = this.toRender[z];

                for (i = 0, m = layer.length; i < m; i++) {
                    item = layer[i];
                    
                    item.drawShadow(CANVASES.buffer);
                }
            }

            // items
            layer = this.toRender[z + 1];

            for (i = 0, m = layer.length; i < m; i++) {
                item = layer[i];

                item.draw(CANVASES.buffer);
            }
        }
    }
}

const ITEMS = new Items();

ITEMS.clickable = {
    BUFFER_MAX:  10,

    all:         [],
    clicked:     [],

    hover:       {},
    lastPressed: {},
    lastClicked: {},

    dragging:    {is: false, left: 0, top: 0},

    add(itemClicked) {
        this.clicked.push(itemClicked);

        if (this.clicked.length >= this.BUFFER_MAX)     // limit the queue of clicked items
            this.clicked.shift();
    },

    update(toRender) {
        let i, itemHover;

        this.update_initAll(toRender);

        if (this.all.length == 0)
            return;

        // dragging anything?
        if (this.draggingUpdate())
            return;

        // reset last hover/pressed
        this.hover.isHover   = false;
        this.hover.isPressed = false;

        //find the hover
        i = this.all.length;

        while (i > 0 && !this.all[--i].isMouseOverItem()) {}

        itemHover = this.all[i];

        // console.log(itemHover.name);

        // assign a new hover/pressed
        if (INPUT.isMouseDown) {
            if (this.lastPressed.name == "") {               // start of a new click (or dragging)
                this.hover         = itemHover;
                this.hover.isHover = this.hover.isPressed = this.hover.isMouseDown = true;
                this.lastPressed   = this.hover;

                if (this.hover.isDraggable) {
                    this.draggingStart();

                    return;
                }
            } else if (this.lastPressed.name == itemHover.name)  // mouse down on the same clickable
                this.hover.isHover = this.hover.isPressed = this.hover.isMouseDown = true;
        } else {
            if (this.lastPressed.name == itemHover.name) {       // clicked
                if (!this.lastPressed.isDisabled) {
                // if (!this.lastPressed.isKeepPressed && !this.lastPressed.isDisabled) {
                    this.add(this.lastPressed);
                    this.lastPressed.onClick();
                }
            }
            
            this.hover             = itemHover;
            this.hover.isHover     = true;
            this.hover.isMouseDown = false;
            this.lastPressed       = {name: ""};

            if (this.hover.isZoomable && INPUT.mouseWheel != 0)
                this.hover.setZoom(this.hover.zoom - INPUT.mouseWheel / 1000, INPUT.left, INPUT.top);
        }

        this.update_binded();

        document.body.style.cursor = itemHover.isDisabled ? "default" : itemHover.cursor;
    },

    update_initAll(toRender) {
        let z = 1,
            l = toRender.length,
            i, m, item, layer;

        this.all = [ITEMS.all[0]];

        while (z < l) {
            layer = toRender[z];
            i     = 0;
            m     = layer.length;

            while (i < m) {
                item = layer[i++];

                if (item.isClickable && item.isActive)
                    this.all.push(item);
            }

            z += 2;
        }
    },

    update_binded() {
        let i, l, btn, isDown;

        for (i = 0, l = this.all.length; i < l; i++) {
            btn = this.all[i];

            if (btn.isBinded && !btn.isMouseDown) {
                isDown = INPUT.getKey(btn.keyCode).isDown;

                // if (btn.name == "bible_menuBar_prev")
                //     console.log(btn.isBinded, btn.keyCode, btn.isMouseDown, btn.isPressed, isDown)

                if (btn.isKeyPressed && !isDown) {
                    btn.isKeyPressed = btn.isPressed = false;

                    this.add(btn);
                } else if (isDown)
                    btn.isKeyPressed = btn.isPressed = true;
            }
        }
    },

    draggingStart() {
        this.dragging.is           = true;
        document.body.style.cursor = "grabbing";

        this.draggingUpdateCoords();
    },

    draggingUpdateCoords() {
        this.dragging.left = INPUT.left;
        this.dragging.top  = INPUT.top;
    },

    draggingUpdate() {
        if (this.dragging.is) {
            if (INPUT.isMouseDown) {
                this.hover.left += INPUT.left - this.dragging.left;
                this.hover.top  += INPUT.top  - this.dragging.top;

                this.draggingUpdateCoords();
            } else {
                document.body.style.cursor = this.hover.cursor;
                this.dragging.is           = false;
            }
        }

        return this.dragging.is;
    },

    getLast() {
        if (this.clicked.length > 0)
            this.lastClicked = this.clicked.shift();
        else
            this.lastClicked = {name: ""};

        return this.lastClicked;
    },

    flush() {
        this.clicked = [];
    }
};


export { ITEMS };
