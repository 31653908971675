
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js"
import { GW_DB } from "../../../gw db/_gw db.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { GW_MAIN, INFO_BOX } from "../../_gw main.js"


const STATE  = {loading: 10, main: 20},
      COORDS = {x: 0.01, lineGap: 0.5};


class Stats extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.canvas      = null;
        this.color       = null;
        this.text        = null;
        this.item        = null;

        this.isResized   = true;

        this.isInitiated = false;
    }

    init() {
        this.canvas = BK_ENGINE.canvases.getByName("main_userStats");
        this.color  = BK_ENGINE.rects.getByName("main_userStats_bgd").colorFill;
        this.text   = BK_ENGINE.texts.getByName("main_userStats_txt");
        this.item   = {mainFrame: BK_ENGINE.items.getByName("main_userStats"),
                       btnQuit:   BK_ENGINE.items.getByName("main_userStats_btnQuit")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state     = STATE.loading;
        this.isResized = true;

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        if (this.state == STATE.loading)
            return this.update_loading();

        this.update_main();
    }

    update_loading() {
        GW_DB.isReady() && (this.state = STATE.main);
    }

    update_main() {
        if (INFO_BOX.isActive)
            return;

        (this.canvas.width != this.item.mainFrame.width || this.canvas.height != this.item.mainFrame.height) && (this.isResized = true);

        this.isResized && this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "main_userStats_btnInfo":
                return INFO_BOX.start(GW_MAIN.file.lang.getCaption("userStats_info"), "gotIt");
            case "main_userStats_btnQuit":
                this.end();
        }
    }

    resize() {
        this.canvas.resize(this.item.mainFrame.width, this.item.mainFrame.height);
        this.redraw();

        this.isResized = false;
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
        BK_ENGINE.pointer.isActive    = !isVisible;
    }

    redraw() {
        let row  = 0,
            left = Math.round(COORDS.x * BK_ENGINE.viewPort.min),
            top, height, txt;

        this.canvas.drawRect(this.item.mainFrame, this.color, true);
        
        height  = this.text.calcHeight(BK_ENGINE.viewPort.min);
        height += Math.round(COORDS.lineGap * height);
        top     = this.item.btnQuit.top + this.item.btnQuit.height + height;

        this.text.prepare(this.canvas);

        // name
        txt = GW_USER.name.length == 0 ? "---" : GW_USER.name;
        this.redraw_one(row++, left, top, height, GW_DB.user.name + ": " + txt);

        // address
        txt = GW_USER.address.length == 0 ? "---" : GW_USER.address;
        this.redraw_one(row++, left, top, height, GW_DB.user.address + ": " + txt);

        // day started
        txt = GW_USER.days.length == 0 ? GW_MAIN.file.lang.getCaption("userStats_noData") : GW_USER.getStringFromDayUTC(GW_USER.days[0].dayUTC);
        this.redraw_one(row++, left, top, height, GW_MAIN.file.lang.getCaption("userStats_dayStarted") + ": " + txt);

        // day ended
        txt = GW_USER.days.length == 0 ? GW_MAIN.file.lang.getCaption("userStats_noData") : GW_USER.getStringFromDayUTC(GW_USER.days[GW_USER.days.length - 1].dayUTC);
        this.redraw_one(row++, left, top, height, GW_MAIN.file.lang.getCaption("userStats_lastDayPlayed") + ": " + txt);

        // Days played
        txt = GW_USER.days.length == 0 ? 0 : GW_USER.days[GW_USER.days.length - 1].dayUTC - GW_USER.days[0].dayUTC + 1;
        this.redraw_one(row++, left, top, height, GW_MAIN.file.lang.getCaption("userStats_daysPlayed") + ": " + txt);

        // Effective days played
        txt = GW_USER.days.length == 0 ? 0 : GW_USER.days.length;
        this.redraw_one(row++, left, top, height, GW_MAIN.file.lang.getCaption("userStats_daysPlayedEffective") + ": " + txt);

        // faith max
        this.redraw_one(row++, left, top, height, GW_MAIN.file.lang.getCaption("userStats_faithMax") + ": " + GW_USER.faith.max);
            
        this.item.mainFrame.isRedrawn = false;
    }

    redraw_one(i, left, top, height, caption) {
        this.text.draw(this.canvas, {left: left, top: top + i * height}, caption);
    }
}

export const STATS = BK_ENGINE.tasks.add(new Stats(BK_ENGINE.tasks));
