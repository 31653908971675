
import { BK_ENGINE } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";


const TEXT_I = 4;       // 4: part name, in texts


class BiblePart {
    constructor(NAME) {
        this.NAME        = NAME;    // english name

        this.name        = "";      // national language name
        this.pageNm      = 0;       // used by display "goTo" function

        this.textContent = new BK_ENGINE.textContent();
    }

    refresh() {
        this.name = GW_BIBLE.file.lang.getCaption(this.NAME);

        this.textContent.init("[*centerV,center*]" + "[*" + TEXT_I + "*]" + this.name);
    }
}


class BibleParts {
    constructor() {
        this.OT = new BiblePart("OldTestament");
        this.NT = new BiblePart("NewTestament");
    }

    getByTag(tag) {
        return tag == "OT" ? this.OT : this.NT;
    }

    refresh() {
        this.OT.refresh();
        this.NT.refresh();
    }
}

export const BIBLE_PARTS = new BibleParts();
