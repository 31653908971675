
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { pad } from "../../../bk utils/_bk utils.js";
import { COOKIES } from "../../../gw main/cookies.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { BIBLE_BOOKS } from "../../books.js";
import { MENU } from "./g0001 menu.js";
import { PLAY } from "./play/_bible g0001 play.js";


const DEVELOP = {jumpTo: ""};           // play

const PATH   = {ui:       "gw bible/play/g0001/ui",
                uiCommon: "gw bible/play/g0001/ui",
                uiImages: "gw bible/play/g0001/ui",
                lang:     "gw bible/play/g0001/lang"},
      FILES  = {ui:       {verNarrow: "_ver narrow - 20230520", verWide: "_ver wide - 20230520", horNarrow: "_hor narrow - 20230520", horWide: "_hor wide - 20230520"},
                uiCommon: {add: "common add - 20230520", set: "common set - 20230520"},
                uiImages: {low: "images low - 20230520", med: "images med - 20230520", high: "images high - 20230520"},
                lang:     {en: "g0001 en - 20230522", es: "g0001 es - 20230522", esla: "g0001 esla - 20230801", fr: "g0001 fr - 20230522", it: "g0001 it - 20230522", pl: "g0001 pl - 20230522"}},
      COOKIE_NAME = "gwBibleGame0001";

const SCORE = {easy: 80, medium: 90, hard: 100};

const SET_LANG = BK_ENGINE.tasks.addNew();

const ADDING_CHAR_TIME = {start: 100, min: 20, max: 150};


class Init extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                     uiCommon: {add: BK_ENGINE.files.addNew("path", PATH.uiCommon, "fileName", FILES.uiCommon.add),
                                set: BK_ENGINE.files.addNew("path", PATH.uiCommon, "fileName", FILES.uiCommon.set)},
                     uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages)};

        this.isInitiated = false;
    }

    start2() {
        this.file.ui.fileName       = FILES.ui[BK_ENGINE.viewPort.orientation];
        this.file.uiImages.fileName = FILES.uiImages[BK_ENGINE.viewPort.imagesQuality];
    }

    update() {
        if (!this.file.ui.isLoaded || !this.file.uiCommon.add.isLoaded || !this.file.uiCommon.set.isLoaded || !this.file.uiImages.isLoaded)
            return;

        BK_ENGINE.addFromData(this.file.uiCommon.add.data + this.file.ui.data);
        BK_ENGINE.setFromData(this.file.uiCommon.set.data + this.file.uiImages.data);

        this.isInitiated = true;

        this.end();
        SET_LANG.start();
    }
}

const INIT = BK_ENGINE.tasks.add(new Init(BK_ENGINE.tasks));


class Game0001 extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file            = {lang: BK_ENGINE.files.addNew("path", PATH.lang)};
        this.user            = {lang: "", denom: ""};

        this._bookI          = 0;      // in denomIndexes
        this._chapterI       = 1;
        this._difficultyI    = 2;
        this._addingCharTime = ADDING_CHAR_TIME.start;
        this._isShowInfo     = true;

        this.book            = null;
    }

    set bookI(value) {
        this._bookI   = Math.max(0, Math.min(value, GW_BIBLE.books.denomIndexes.length - 1));
        this.book     = GW_BIBLE.books.getByI(GW_BIBLE.books.denomIndexes[this.bookI]);
        this.chapterI = Math.min(this.chapterI, this.book.chaptersNm);

        this.updateCookie();
    }

    get bookI() {
        return this._bookI;
    }

    set chapterI(value) {
        this._chapterI = Math.max(1, Math.min(value, this.book.chaptersNm - 1));

        this.updateCookie();
    }

    get chapterI() {
        return this._chapterI;
    }

    set difficultyI(value) {
        this._difficultyI = Math.max(1, Math.min(value, 3));

        this.updateCookie();
    }

    get difficultyI() {
        return this._difficultyI;
    }

    set addingCharTime(value) {
        this._addingCharTime = Math.max(ADDING_CHAR_TIME.min, Math.min(ADDING_CHAR_TIME.max, value));

        this.updateCookie();
    }

    get addingCharTime() {
        return this._addingCharTime;
    }

    set isShowInfo(value) {
        this._isShowInfo = value;

        this.updateCookie();
    }

    get isShowInfo() {
        return this._isShowInfo;
    }

    readCookie() {
        let DATA   = ["chapterI",3, "difficultyI",1, "addingCharTime",3],
            cookie = COOKIES.getOne(COOKIE_NAME),
            from   = 2,
            i, d, to, prop;

        // bookId
        if (cookie.length < 2) return;
        i = parseInt(cookie.substring(0, 2));
        if (isNaN(i)) return;
        this.bookI = this.readCookie_findBookI(i);

        // the rest
        for (i = 0; i < DATA.length; i += 2) {
            prop = DATA[i];
            to   = from + DATA[i + 1];
            d    = COOKIES.extractNumber(cookie, from, to);
            
            if (!d.isValid)
                return;

            this[prop] = d.value;

            from = to;
        }

        // isShowInfo
        if (cookie.length < 10) return;
        this.isShowInfo = cookie.substring(9, 10) == "1";
    }

    readCookie_findBookI(bookId) {
        let denoms = GW_BIBLE.books.denomIndexes;
        let i      = denoms.indexOf(bookId),
            j      = 0;

        if (i > -1)
            return i;

        // if not present, find the lowest one
        while (++j < denoms.length) {
            if (denoms[j] > bookId)
                return j - 1;
        }

        return 0;
    }

    updateCookie() {
        if (!this.book)
            return;

        let bookId         = pad(this.book.id, 2),
            chapterI       = pad(this.chapterI, 3),
            difficultyI    = "" + this.difficultyI,
            addingCharTime = pad(this.addingCharTime, 3),
            isShowInfo     = this.isShowInfo ? "1" : "0";

        // console.log(bookId + chapterI + difficultyI + addingCharTime);
        COOKIES.setOne(COOKIE_NAME, bookId + chapterI + difficultyI + addingCharTime + isShowInfo);
    }

    start2() {
        if (!INIT.isInitiated)
            return INIT.start();

        SET_LANG.start();
    }

    update() {
        // console.log(INIT.isActive, SET_LANG.isActive, MENU.isActive, PLAY.isActive);
        if (INIT.isActive || SET_LANG.isActive || 
            MENU.isActive || PLAY.isActive)
                return;

        this.end();
        GW_BIBLE.resumeFromGame();
    }
}

const G0001 = BK_ENGINE.tasks.add(new Game0001(BK_ENGINE.tasks));


SET_LANG.start2 = function() {
    if (G0001.user.lang != GW_BIBLE.user.lang) {
        G0001.user.lang          = GW_BIBLE.user.lang;
        G0001.file.lang.fileName = FILES.lang[G0001.user.lang];

        return G0001.file.lang.load();
    }

    this.end();
}

SET_LANG.end2 = function() {
    G0001.readCookie();

    if (G0001.isShowInfo) {
        if (BIBLE_BOOKS.chaptersGuessedNm > 0)
            G0001.isShowInfo = false;
    }

    G0001.bookI = G0001.bookI;        // will init: G0001.book

    MENU.start();
}

SET_LANG.update = function() {
    if (!G0001.file.lang.isLoaded)
        return;

    BK_ENGINE.setFromData(G0001.file.lang.data);

    this.end();

    if (DEVELOP.jumpTo == "play")
        return PLAY.start();
}


export { G0001, ADDING_CHAR_TIME, SCORE };
