
class Point {           // 2d
    constructor(x = 0, y = 0) {
        this.x    = x;
        this.y    = y;

        this.left = 0;
        this.top  = 0;
    }

    round() {
        this.left = Math.round(this.x);
        this.top  = Math.round(this.y);
    }

    translate(dX, dY) {
        this.x += dX;
        this.y += dY;
    }

    rotate(angle, x = 0, y = 0) {
        let cos = Math.cos(angle),
            sin = Math.sin(angle);
        
        this.translate(-x, -y);

        this.x = this.x * cos - this.y * sin;
        this.y = this.x * sin + this.y * cos;

        this.translate(x, y);
    }
}

class Line {
    constructor() {
        this.point1 = new Point();
        this.point2 = new Point();
    }

    set x1(value) {
        this.point1.x = value;
    }

    set y1(value) {
        this.point1.y = value;
    }

    set x2(value) {
        this.point2.x = value;
    }

    set y2(value) {
        this.point2.y = value;
    }
}

function getThunder(pointSrc, pointDst, breaksNm, widthStart, coneWidth) {
    let dist  = Math.sqrt(Math.pow(pointDst.x - pointSrc.x, 2) + Math.pow(pointDst.y - pointSrc.y, 2));
    let ratio = coneWidth / dist,
        arr   = [new Point(pointSrc.x, pointSrc.y + widthStart / 2), new Point(pointSrc.x, pointSrc.y - widthStart / 2)],
        x     = 0,
        pointI, point1, point2, yMax, h;
// console.log("dist: " + dist, "ratio: " + ratio, "widthStart: " + widthStart, "coneWidth: " + coneWidth);
    for (pointI = 0; pointI < breaksNm; pointI++) {
        point1 = new Point();
        point2 = new Point();

        x = point1.x = point2.x = x + Math.random() * (dist - x);

        yMax     = ratio * (dist - point1.x);
        point1.y = Math.random() < 0.5 ? -Math.random() * yMax : Math.random() * yMax;
        h        = (dist - point1.x) / dist * widthStart;
        point1.y = point1.y < 0 ? Math.max(point1.y, -yMax) : Math.min(point1.y, yMax - h);
        point2.y = point1.y + h;

        arr.push(point1, point2);
    }

    arr.push(new Point(pointDst.x, pointDst.y), new Point(pointDst.x, pointDst.y));

    return arr;
}

export { Point, getThunder }
