
const LANG = {en: "abcdefghijklmnopqrstuvwxyz",
              es: "abcdefghijklmnopqrstuvwxyzáéíñóúü",
              esla: "abcdefghijklmnopqrstuvwxyzáéíñóúü",
              fr: "abcdefghijklmnopqrstuvwxyzàâçèéêëîïôùûü",
              it: "abcdefghijklmnopqrstuvzàéìòù",
              pl: "aąbcćdeęfghijklłmnńoóprsśtuwyzźż"};


export function getAlphabet(lang) {
    return LANG[lang];
}
