
import { stringToHex } from "../bk utils/_bk utils.js"
import MetaMaskOnboarding from "@metamask/onboarding";


class Metamask {
    constructor() {
        this.endPoint = {getNonceToSign:      "https://us-central1-god-s-world-d402d.cloudfunctions.net/getNonceToSign",
                         verifySignedMessage: "https://us-central1-god-s-world-d402d.cloudfunctions.net/verifySignedMessage"};
        this.account  = {address: "", nonce: 0, token: 0};
        this.state    = {isReady: false,
                         isError: false,
                         message: "",

                         reset()  {
                             this.isReady = false;
                             this.isError = false;
                             this.message = "";
                         },

                         signError(message = "") {
                             this.isReady = true;
                             this.isError = true;
                             this.message = message;
                         }};
    }

    isInstalled() {
        return typeof window.ethereum != 'undefined';
        // return typeof window.ethereum != 'undefined' && window.ethereum.isMetaMask;
    }

    startOnboarding() {
        // openTab= "OPEN_TAB";
        const onBoarding = new MetaMaskOnboarding();

        onBoarding.startOnboarding();
    }

    async getAccount() {
        this.state.reset();
    
        try {
            await ethereum.request({method: 'eth_requestAccounts' }).
                then((response) => {
                    this.state.isReady   = true;
                    this.account.address = response[0];});
        } catch(error) {
            this.state.signError(error);
        }
    }

    async getNonceToSign(lang = "en", denom = "c") {  // pass the lang and data (usually = denom), because the user will be created, if all ok
        const options = {method: 'POST',
                         body:    JSON.stringify({userAddress: this.account.address, lang: lang, denom: denom})};

        this.state.reset();

        try {
            await fetch(this.endPoint.getNonceToSign, options).
                then((response) => response.json()).
                then((data) => {
                    this.state.isReady = true;
                    this.state.message = data?.msg;     // possible: "User already exists"
                    this.account.nonce = data.nonce;});
        } catch(error) {
            this.state.signError(error);
        }
    }

    async signIn() {
        this.state.reset();
    
        try {
            await ethereum.request({ method: 'personal_sign',
                                     params: [`0x${stringToHex(this.account.nonce)}`, this.account.address]}).
            then((data) => {
                this.state.isReady = true;
                this.account.nonce = data});
        } catch(error) {
            this.state.signError(error);
        }
    }

    async verifySignedMessage() {
        const options = {method: 'POST',
                         body:   JSON.stringify({userAddress: this.account.address, signature: this.account.nonce})};

        this.state.reset();

        try {
            await fetch(this.endPoint.verifySignedMessage, options).
                then((response) => response.json()).
                then((data) => {
                    this.state.isReady = true;
                    this.account.token = data.token;});
        } catch(error) {
            this.state.signError(error);
        }
    }
}

export const METAMASK = new Metamask();
