
import { BK_ENGINE, Task } from "../../../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../../../../gw bible/_gw bible.js";
import { G0001 } from "../../_bible g0001.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";
import { DISPLAY_HELPERS } from "./display helpers.js";
import { DISPLAY_HELPS } from "./display helps.js";
import { DISPLAY_LETTERS } from "./display letters.js";
import { DISPLAY_LINES } from "./display lines.js";
import { DISPLAY_PROGRESS } from "./display progress.js";


const LETTERS_MAX = 14;


class CurrWord {
    constructor() {
        this.chars       = "";
        this.shortSorted = "";
    }

    start() {
        this.chars       = "";
        this.shortSorted = "";
    }

    update() {
        if (LOGICS.word.shortSorted != this.shortSorted) {
            this.shortSorted = LOGICS.word.shortSorted;
            this.chars       = LOGICS.word.chars;
        }
    }
}


class PlayDisplay extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.LETTERS_MAX     = LETTERS_MAX;
        this.STATE           = {starting: 10, main: 20};

        this.state           = 0;

        this.helpers         = DISPLAY_HELPERS;
        this.helps           = DISPLAY_HELPS;
        this.letters         = DISPLAY_LETTERS;
        this.lines           = DISPLAY_LINES;
        this.progress        = DISPLAY_PROGRESS;

        this.lettersPerRow   = 0;
        this.helpNm          = 0;
        this.wordsToGuessNm  = 0;
        
        this.currWord        = new CurrWord();
        
        this.item            = null;

        this.isInitiated     = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.item = {bibleGame: ITEMS.getByName("bible_game"),
                     mainFrame: ITEMS.getByName("bible_g0001_play"),
                     btn:       {esc:       ITEMS.getByName("bible_g0001_play_btnEsc"),
                                 spacebar:  ITEMS.getByName("bible_g0001_play_btnSpacebar"),
                                 backSpace: ITEMS.getByName("bible_g0001_play_btnBackSpace"),
                                 finish:    ITEMS.getByName("bible_g0001_play_btnFinish")}};

        this.lettersPerRow = BK_ENGINE.viewPort.isHor ? LETTERS_MAX : LETTERS_MAX / 2;

        BK_ENGINE.items.orderZ();

        // BK_ENGINE.items.orderZ(BK_ENGINE.items.getByName("bible_game0001_playPgd"));

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = this.STATE.starting;
    }

    starting() {
        if (!LOGICS.isStarted)
            return;

        this.state = this.STATE.main;

        this.show(true);
        this.currWord.start();
        this.lines.start(G0001.book.name, GW_BIBLE.file.lang.getCaption("Chapter") + " " + G0001.chapterI);
        this.helpers.start();
        this.letters.start();
        this.progress.start();
        this.helps.start();
    }

    end2() {
        this.show(false);
    }

    update() {
        if (this.state == this.STATE.main)
            return this.main();

        this.starting();
    }

    main() {
        this.currWord.update();
        this.lines.update();
        this.helpers.update();
        this.letters.update();
        this.progress.update();
        this.helps.update();
    }

    show(isVisible) {
        this.item.bibleGame.isVisible = this.item.mainFrame.isVisible = isVisible;
        BK_ENGINE.pointer.isActive    = !isVisible;

        if (isVisible)
            this.showMainBtns(isVisible);
    }

    showMainBtns(isVisible) {
        this.item.btn.esc.isVisible    = this.item.btn.spacebar.isVisible = this.item.btn.backSpace.isVisible = isVisible;
        this.item.btn.finish.isVisible = !isVisible;
    }
}

export const PLAY_DISPLAY = BK_ENGINE.tasks.add(new PlayDisplay(BK_ENGINE.tasks));
