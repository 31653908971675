
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0001 play display.js";


class DisplayHelps {
    constructor() {
        this.itemSpacebar = null;

        this.isPossible   = false;
        this.nm           = 0;

        this.isInitiated = false;
    }

    init() {
        this.itemSpacebar = DISPLAY.item.btn.spacebar;

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.isPossible = LOGICS.help.isPossible;
        this.nm         = LOGICS.help.nm;

        this.refresh();
    }

    update() {
        if (LOGICS.help.isPossible != this.isPossible ||
            LOGICS.help.nm         != this.nm)
                this.start();
    }

    refresh() {
        this.itemSpacebar.caption    = "" + this.nm;
        this.itemSpacebar.isDisabled = !this.isPossible;
        this.itemSpacebar.isRedrawn  = false;
    }
}

export const DISPLAY_HELPS = new DisplayHelps();
