
export class BKengineText {
    constructor() {
        this.type       = "text";
        this.name       = "";

        this.caption    = "";               // will be used this if the object's caption is empty
        this.font       = "Arial";
        this.align      = "left";
        this.color      = "#ffffffff";
        this.boldItalic = "";               // empty/bold/italic
        this.baseline   = "alphabetic";     // middle, alphabetic, top, bottom

        this.h          = 0;                // float
        this.hMin       = 0;
        this.height     = 0;                // px
    }

    calcHeight(refHeight, oRefWidth) {      // pass height first
        let min = refHeight;

        oRefWidth && oRefWidth < min && (min = oRefWidth);

        return this.height = Math.round(this.h * refHeight + this.hMin * min);
    }

    getWidth(canvas, content) {
        this.prepare(canvas);

        return content.length == 0 ? 0 : canvas.textGetWidth(content);
    }

    prepare(canvas) {
        let font = (this.boldItalic + " " + this.height + "px " + this.font);

        canvas.textSet("font", font, "fillStyle", this.color, "textAlign", this.align, "textBaseline", this.baseline);
    }

    draw(canvas, coords, oCaption) {
        let caption = (oCaption != undefined && oCaption.length > 0) ? oCaption : this.caption;
        // bk_gfx.globalAlpha.set(canvas, transp);

        canvas.textFill(caption, coords);
        // bk_gfx.fillTextRotated(canvas, text.caption, coords.left, coords.top, text.rotation);
    }
}
