
import { CANVASES } from "../groups/canvases.js";

const DEVELOP = {isShowKeyCode: false};

// LINES: 1) non letters 2) english 3) french (TO DO!) 4) italian 5) polish
// the number higher than 1000 is a special characters (pressed with the right "Alt")
const KEY_CODES = ["BkSp", 8, "Esc", 27, "Space", 32, "0", 48, "1", 49, "2", 50, "3", 51, "4", 52, "5", 53, "6", 54, "7", 55, "8", 56, "9", 57, "-", 189,
                   "a", 65, "A", 65, "b", 66, "B", 66, "c", 67, "C", 67, "d", 68, "D", 68, "e", 69, "E", 69, "f", 70, "F", 70, "g", 71, "G", 71, "h", 72, "H", 72, "i", 73, "I", 73, "j", 74, "J", 74, "k", 75, "K", 75, "l", 76, "L", 76, "m", 77, "M", 77, "n", 78, "N", 78, "o", 79, "O", 79, "p", 80, "P", 80, "q", 81, "Q", 81, "r", 82, "R", 82, "s", 83, "S", 83, "t", 84, "T", 84, "u", 85, "U", 85, "v", 86, "V", 86, "w", 87, "W", 87, "x", 88, "X", 88, "y", 89, "Y", 89, "z", 90, "Z", 90,
                   "ç", 1057,
                   "è", 186, "È", 186, "ù", 191, "Ù", 191, "ò", 192, "Ò", 192, "ì", 221, "Ì", 221, "à", 222, "À", 222, "é", 1186, "É", 1186,
                   "ą", 1065, "Ą", 1065, "ć", 1067, "Ć", 1067, "ę", 1069, "Ę", 1069, "ł", 1076, "Ł", 1076, "ń", 1078, "Ń", 1078, "ó", 1079, "Ó", 1079, "ś", 1083, "Ś", 1083, "ź", 1088, "Ź", 1088, "ż", 1090, "Ż", 1090];

class InputKey {
    constructor() {
        this.name   = "";
        this.isDown = false;
    }
}


class Input {
    constructor() {
        this.KEY_CODES     = KEY_CODES;
        this.KEYS_NM       = 257        // 256 + 1, the last will be always off

        this.isTouchScreen = true,      // as soon as the mouse move will be detected, it will be set to false
        this.isMouseDown   = false;

        this.left          = 0;         // these values will be updated by every update() call, so they will be locked until next update()
        this.top           = 0;
        this.mouseWheel    = 0;
        // this.lastKey       = 0;
        
        this.now           = {left: 0, top: 0, isMouseDown: [], mouseWheel: 0};      // will be update by events in real time

        this.keys          = [];        // all keys' states (isDown) and names (name), ordered by codeValue

        // init
        for (let i = 0; i < this.KEYS_NM; i++)
            this.keys.push(new InputKey());
    }

    isMouseInsideRect(coords) {
        if (this.left >= coords.left && this.left < coords.left + coords.width &&
            this.top  >= coords.top  && this.top  < coords.top  + coords.height)
                return true;

        return false;
    }

    getKey(oValue) {
        if (oValue < 256) {
            if (this.keys[17].isDown || this.keys[18].isDown)
                return {isDown: false};
            return this.keys[oValue];
        }

        if (this.keys[17].isDown && this.keys[18].isDown)   // right "alt"
            return this.keys[oValue - 1000];
        return this.keys[256];                              // always off
    }

    update() {          // call it every frame to lock mouse coords
        const ref = this.now;

        // mouse coords
        this.left = ref.left;
        this.top  = ref.top;

        // mouse clicks
        if (ref.isMouseDown.length > 0)
            this.isMouseDown = ref.isMouseDown.shift();

        this.mouseWheel = ref.mouseWheel;
        ref.mouseWheel  = 0;
    }

    getKeyCodeFromName(keyName) {
        let i = this.KEY_CODES.indexOf(keyName);
    
        if (i == -1) {
            i = 0;

            console.warn("No key code found for " + keyName + "!");
        }

        return this.KEY_CODES[i + 1];
    }

    getNameFromKeyCode(keyCode) {
        let i = this.KEY_CODES.indexOf(keyCode);

        if (i == -1) {
            i = 1;

            console.warn("No name found for key code " + keyCode + "!");
        }

        return this.KEY_CODES[i - 1];
    }
}

const INPUT = new Input();


function pointerMove(evt) {
    INPUT.now.left = evt.clientX;
    INPUT.now.top  = evt.clientY;
}

function pointerUp(evt) {
    pointerMove(evt);

    if (evt.which == 1)     // only left click!
        INPUT.now.isMouseDown.push(false);
}

function pointerDown(evt) {
    pointerMove(evt);

    if (evt.which == 1)     // only left click!
        INPUT.now.isMouseDown.push(true);

    if (evt.pointerType == "mouse")
        INPUT.isTouchScreen = false;
}

function mouseWheelMove(evt) {
    INPUT.now.mouseWheel += evt.deltaY;
}

function keyDown(evt) {
    let key;

    if (typeof evt.keyCode == "number") {         // sometimes it's called but it's not a number!
        key        = INPUT.keys[evt.keyCode];
        key.isDown = true;
        key.name   = evt.key;

        // INPUT.lastKeyName = INPUT.keyboardGetNameFromCode(evt.keyCode);
    }
}

function keyUp(evt) {
    if (typeof evt.keyCode == "number")
        INPUT.keys[evt.keyCode].isDown = false;

    if (DEVELOP.isShowKeyCode)
        console.log(evt.key, evt.keyCode, typeof evt.keyCode, INPUT.getKeyCodeFromName(evt.key));
}


// keyboard
window.addEventListener("keydown", keyDown);
window.addEventListener("keyup",   keyUp);

// pointer
window.addEventListener('pointerdown', pointerDown);  // must catch up these events outside of the canvas, too
window.addEventListener('pointerup',   pointerUp);    // otherwise, will not recognize mouse up outside the canvas

CANVASES.foreground.canvas.addEventListener("pointermove", pointerMove);
CANVASES.foreground.canvas.addEventListener("pointerdown", pointerDown);
CANVASES.foreground.canvas.addEventListener("pointerup",   pointerUp);

// mouse
CANVASES.foreground.canvas.addEventListener("wheel", mouseWheelMove, false);


export { INPUT };
