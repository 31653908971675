
import { GW_USER } from "../gw user/_gw user.js";


const HELP = {path: "help/lang/",
              ext:  ".html"};


HELP.openWindow = function(fileName) {
    let path = this.path + GW_USER.lang + "/",
        file = fileName.trim() + this.ext;

    return window.open(path + file, '_blank');
}

export { HELP };
