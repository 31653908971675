
import { BK_ENGINE, Coords } from "../../../../../bk engine/_bk engine.js";
import { G0002 } from "../../_bible g0002.js";
import { LOGICS_BALL } from "../logics/logics ball.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0002 play display.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { DISPLAY_PLAYFIELD_BALL as PLAYFIELD_BALL } from "./playfield/playfield ball.js";


const COLOR = {bgd: "#ffffffcc", frame: "#ffffff"},
      FLASH = {offset: 0.7, multiply: 0.3, freq: 300},
      ARROW = {imageI: 7, size: 0.4, distFromCenter: 0.75};


class DisplayClickAnywhere {
    constructor() {
        let CANVASES = BK_ENGINE.canvases;

        this.canvas = {bgd:     CANVASES.addNew(),
                       fgd:     CANVASES.addNew(),
                       arrow:   CANVASES.addNew()};   // an arrow close to the ball, indicating the direction
        this.coords = {canvas:   new Coords(),
                       caption:  new Coords(),
                       arrowSrc: new Coords(),
                       arrowDst: new Coords()};
        this.text   = null;
        this.time   = 0;

        this.isRedrawn   = false;
        this.isInitiated = false;
    }

    init() {
        this.text = BK_ENGINE.texts.getByName("bible_g0002_play_clickAnywhere");

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.time      = 0;
        this.isRedrawn = false;
    }

    update() {
        if (this.canvas.arrow.width == 0)
            return;

        if (!this.isRedrawn)
            this.redraw();

        this.time += DISPLAY.timer.delta;

        this.refresh();
    }

    resize() {
        this.redraw();
    }

    redraw() {
        this.redraw_clickAnywhere();
        this.redraw_arrow();

        this.isRedrawn = true;
    }

    redraw_clickAnywhere() {
        let s         = PLAYFIELD.tileSize;
        let caption   = G0002.file.lang.getCaption("ClickAnywhereToStart"),
            bgdRound  = Math.round(s / 10),
            bgdMargin = Math.round(s / 10),
            width, height;

        height = this.text.calcHeight(s) + bgdMargin * 2;
        width  = Math.ceil(this.text.getWidth(this.canvas.fgd, caption)) + bgdMargin * 2;

        this.canvas.bgd.resize(width, height);
        this.canvas.fgd.resize(width, height);

        this.coords.canvas.resize(width, height);
        this.coords.caption.resize(width, height);

        this.coords.caption.left = Math.floor(PLAYFIELD.coords.main.left + PLAYFIELD.coords.main.width  / 2 - width  / 2);
        this.coords.caption.top  = Math.floor((PLAYFIELD.coords.main.top - height) / 2);

        // bgd
        this.canvas.bgd.drawRect(this.coords.canvas, COLOR.bgd,   true,  1, bgdRound);
        this.canvas.bgd.drawRect(this.coords.canvas, COLOR.frame, false, 1, bgdRound);

        // fgd
        this.text.prepare(this.canvas.fgd);
        this.text.draw(this.canvas.fgd, {left: Math.round(width / 2), top: Math.round(height / 2)}, caption);
    }

    redraw_arrow() {
        let CANVASES = BK_ENGINE.canvases,
            s        = Math.round(PLAYFIELD.tileSize * ARROW.size),
            i;

        this.coords.arrowSrc.resize(s, s);
        this.coords.arrowDst.resize(s, s);

        this.canvas.arrow.resize(s * 4, s);

        CANVASES.drawImageQuality(DISPLAY.image.balls, this.canvas.arrow, {left: 0, top: 0, width: s, height: s}, ARROW.imageI);

        // +3 more directions
        for (i = 1; i < 4; i++)
            this.canvas.arrow.drawImage(this.canvas.arrow, {left: 0, top: 0, width: s, height: s}, {left: i * s, top: 0, width: s, height: s});

        CANVASES.flip(this.canvas.arrow, false, true,  {left: s    , top: 0, width: s, height: s});   // br
        CANVASES.flip(this.canvas.arrow, true,  true,  {left: s * 2, top: 0, width: s, height: s});   // bl
        CANVASES.flip(this.canvas.arrow, true,  false, {left: s * 3, top: 0, width: s, height: s});   // tl
    }

    refresh() {
        this.refresh_clickAnywhere();
        this.refresh_arrow();
    }

    refresh_clickAnywhere() {
        let transp = Math.max(0, Math.min(1, Math.sin(this.time / FLASH.freq) * FLASH.multiply + FLASH.offset));

        DISPLAY.canvas.pgd.drawImage(this.canvas.bgd, this.coords.canvas, this.coords.caption);
        DISPLAY.canvas.pgd.saveTransp();
        DISPLAY.canvas.pgd.setTransp(transp);
        DISPLAY.canvas.pgd.drawImage(this.canvas.fgd, this.coords.canvas, this.coords.caption);
        DISPLAY.canvas.pgd.restoreTransp();
    }

    refresh_arrow() {
        let ballCoords = PLAYFIELD_BALL.getBallCoords(),
            s          = this.canvas.arrow.height;
        let sHalf      = Math.floor(s / 2),
            dist       = Math.round(s * ARROW.distFromCenter);

        if (LOGICS_BALL.dx < 0)
            this.coords.arrowSrc.left = (LOGICS_BALL.dy < 0) ? s * 3 : s * 2;
        else
            this.coords.arrowSrc.left = (LOGICS_BALL.dy < 0) ? 0 : s;

        // coords arrow dst
        this.coords.arrowDst.left = (LOGICS_BALL.dx < 0) ? -sHalf - dist : -sHalf + dist;
        this.coords.arrowDst.top  = (LOGICS_BALL.dy < 0) ? -sHalf - dist : -sHalf + dist;

        // ball's arrow
        DISPLAY.canvas.pgd.drawImage(this.canvas.arrow, this.coords.arrowSrc, {left: PLAYFIELD.coords.main.left + ballCoords.left + this.coords.arrowDst.left, top: PLAYFIELD.coords.main.top + ballCoords.top + this.coords.arrowDst.top, width: s, height: s});
    }
}

export const DISPLAY_CLICK_ANYWHERE = new DisplayClickAnywhere();
