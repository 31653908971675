
import { BK_ENGINE, Task } from "../../../../../bk engine/_bk engine.js";
import { G0001 } from "../../_bible g0001.js";
import { PLAY } from "../_bible g0001 play.js";
import { LOGICS_GUESSING } from "./logics guessing.js";
import { LOGICS_HELPERS } from "./logics helpers.js";
import { LOGICS_LETTERS } from "./logics letters.js";
import { LOGICS_WORDS } from "./logics words.js";
import { SERVER, SERVER_WORD } from "../../g0001 server.js";


class PlayLogics extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE           = {starting: 0, newWord: 10, addingWord: 20, guessingWord: 30, wordGuessed: 40, allGuessed: 50};
        this.state           = 0;

        this.guessing        = LOGICS_GUESSING;
        this.helpers         = LOGICS_HELPERS;
        this.letters         = LOGICS_LETTERS;
        this.words           = LOGICS_WORDS;

        this.lettersMax      = SERVER_WORD.lengthMax;
        this.lettersPerRow   = this.lettersMax / 2;

        this.wordsToGuessNm  = 0;
        this.word            = {curr: "", isGuessed: false, wholeSorted: "", wholeGuessed: "", shortSorted: "", shortGuessed: "", lineI: 0, guessedStates: []};    // updated from server
        this.help            = {isPossible: false, nm: 0, char: "", used: 0};
        this.isAllGuessed    = false;

        this.timer           = {dateLast: 0, delta: 0};

        this.isStarted       = false;

        // init
        this.timer.update = function() {
            let dateNow = Date.now();
    
            this.delta    = dateNow - this.dateLast;
            this.dateLast = dateNow;
        }
    }

    start2() {
        this.isAllGuessed = false;
        this.state        = this.STATE.starting;

        this.words.start();
        this.helpers.start();
        this.letters.start();
    }

    starting() {
        if (!G0001.book.isLoaded)
            return;

        // start the server
        let serverPacket = SERVER.start(G0001.book.getChapterPlainContent(G0001.chapterI), G0001.difficultyI);

        // set first values
        this.state          = this.STATE.newWord;
        this.wordsToGuessNm = serverPacket.wordsToGuessNm;
        this.help.nm        = serverPacket.helpNm;
        this.help.used      = 0;
        this.isStarted      = true;
    }

    end2() {
        this.isStarted = false;        
    }

    updateServerPacket(state, oCharsGuessed = "", oIsAskHelp = false) {
        let serverPacket = SERVER.getPacket({state: state, wordI: this.words.all.length, charsGuessed: oCharsGuessed, isAskHelp: oIsAskHelp});
        let h            = serverPacket.help,
            w            = serverPacket.words.word;

        this.isAllGuessed       = serverPacket.words.isLast;

        this.help.isPossible    = h.isPossible;
        this.help.nm            = h.nm;
        this.help.char          = h.char;

        this.word.isGuessed     = w.isGuessed;
        this.word.wholeSorted   = w.wholeSorted;
        this.word.wholeGuessed  = w.wholeGuessed;
        this.word.shortSorted   = w.shortSorted;
        this.word.shortGuessed  = w.shortGuessed;
        this.word.lineI         = w.lineI;
        this.word.guessedStates = w.guessedStates;

        this.wordsToGuessNm     = serverPacket.words.toGuessNm;
    }

    update() {
        this.timer.update();

        switch (this.state) {
            case this.STATE.starting:
                this.starting();
                break;
            case this.STATE.newWord:
                this.newWord();
                break;
            case this.STATE.addingWord:
                this.addingWord();
                break;
            case this.STATE.guessingWord:
                this.guessingWord();
                break;
            case this.STATE.wordGuessed:
                this.wordGuessed();
            // case this.STATE.allGuessed:      // no need
        }
    }

    newWord() {
        this.updateServerPacket("newWord");

        if (this.word.isGuessed) {
            this.words.startAdding();

            this.state = this.STATE.addingWord;
        } else {
            this.helpers.reset(this.word.shortSorted);
            this.letters.reset(this.word.shortSorted);
            this.guessing.updateCurr();
            PLAY.clickedFlush();

            this.state = this.STATE.guessingWord;
        }
    }

    addingWord() {
        if (this.words.update()) {
            this.words.add(this.word.wholeGuessed, this.word.lineI);

            this.word.curr = "";
            this.state     = this.isAllGuessed ? this.STATE.allGuessed : this.STATE.newWord;
        }
    }

    guessingWord() {
        let clicked   = PLAY.getClicked(),
            isChanged = false,
            letter, helper;

        switch (clicked.name) {
            case "letter":
                letter = this.letters.getByI(clicked.info);

                if (!letter.isGuessed) {
                    if (letter.dstY == 0)
                        this.letters.moveTo(clicked.info, letter.X, letter.Y);
                    else
                        this.letters.moveTo(clicked.info, this.helpers.getIdFirstEmpty(), 0);
    
                    isChanged = true;
                }
                break;
            case "backSpace":
                if (this.letters.backSpace())
                    this.guessing.update();
                break;
            case "help":
                this.updateServerPacket("guessing", this.guessing.chars, true);

                if (this.help.isPossible) {
                    helper = this.helpers.getFirstEmptyOrNotCorrect();

                    if (helper.char.length > 0) {       // remove a wrong letter first?
                        letter = this.letters.getByI(helper.letterI);

                        this.letters.moveTo(helper.letterI, letter.X, 1);
                    }

                    this.letters.moveTo(this.letters.getIdByChar(this.help.char), helper.X, 0);
                    
                    this.help.used++;

                    isChanged = true;
                }
        }

        if (isChanged) {
            this.guessing.update();

            this.updateServerPacket("guessing", this.guessing.chars, false);

            this.guessing.updateCurr();
            this.guessing.updateFromStates(this.word.guessedStates);

            if (this.word.isGuessed)
                this.state = this.STATE.wordGuessed;
        }

        this.letters.update();
    }

    wordGuessed() {
        if (this.letters.areMoving)
            this.letters.update();
        else {
            this.words.add(this.word.wholeGuessed, this.word.lineI);

            this.guessing.reset();
            this.helpers.reset();
            this.letters.reset();

            this.word.curr = "";
            this.state     = this.isAllGuessed ? this.STATE.allGuessed : this.STATE.newWord;
        }
    }
}

export const PLAY_LOGICS = BK_ENGINE.tasks.add(new PlayLogics(BK_ENGINE.tasks));
