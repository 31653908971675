
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { G0001 } from "./_bible g0001.js";


class Info extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        this.item        = {mainFrame: BK_ENGINE.items.getByName("bible_g0001_info")};
        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.show(true);

        if (G0001.isShowInfo) {
            G0001.isShowInfo = false;

            G0001.updateCookie();
        }
    }

    end2() {
        this.show(false);
        // MENU.showMenuFrame(true);
    }

    update() {
        if (BK_ENGINE.clicked.name == "bible_g0001_info_quit")
            this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const INFO = BK_ENGINE.tasks.add(new Info(BK_ENGINE.tasks));
