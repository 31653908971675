
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { MENU_BAR } from "../../menu bar.js";
import { SELECT_BIBLE_BOOK } from "../../select bible book.js";
import { SELECT_BIBLE_CHAPTER } from "../../select bible chapter.js";
import { SHOW_SCORE } from "../../show score.js";
import { G0001, SCORE } from "./_bible g0001.js";
import { GO_TO } from "./g0001 go to.js";
import { INFO } from "./g0001 info.js";
import { SETTINGS } from "./g0001 settings.js";
import { PLAY } from "./play/_bible g0001 play.js";


const DIFFICULTY        = ["Easy", "Medium", "Hard"],
      SELECTING_BOOK    = BK_ENGINE.tasks.addNew(),
      SELECTING_CHAPTER = BK_ENGINE.tasks.addNew();


class Menu extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE        = {main: 10, startingPlay: 20};
        this.state        = 0;

        this.item         = null;
        this.isInitiated  = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.item = {bibleUpCenter: ITEMS.getByName("bible_upCenter"),
                     mainFrame:     ITEMS.getByName("bible_g0001_menu"),
                     book:          {name: ITEMS.getByName("bible_g0001_menu_bookName"),
                                     prev: ITEMS.getByName("bible_g0001_menu_bookPrev"),
                                     next: ITEMS.getByName("bible_g0001_menu_bookNext")},
                     chapter:       {name: ITEMS.getByName("bible_g0001_menu_chapterName"),
                                     prev: ITEMS.getByName("bible_g0001_menu_chapterPrev"),
                                     next: ITEMS.getByName("bible_g0001_menu_chapterNext"),
                                     lvl1: ITEMS.getByName("bible_g0001_menu_lvl1"),
                                     lvl2: ITEMS.getByName("bible_g0001_menu_lvl2"),
                                     lvl3: ITEMS.getByName("bible_g0001_menu_lvl3")},
                     difficulty:    {name: ITEMS.getByName("bible_g0001_menu_difficultyName"),
                                     prev: ITEMS.getByName("bible_g0001_menu_difficultyPrev"),
                                     next: ITEMS.getByName("bible_g0001_menu_difficultyNext")}};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = this.STATE.main;

        this.show(true);
        MENU_BAR.start();
    }

    end2(selected) {
        this.show(false);
        MENU_BAR.end();

        if (selected == "play")
            PLAY.start();
    }

    resume() {
        this.show(true);
        // this.refresh();
    }

    update() {
        if (this.state == this.STATE.main)
            return this.main();

        this.startingPlay();
    }

    main() {
        if (SELECTING_BOOK.isActive || SELECTING_CHAPTER.isActive || GO_TO.isActive || INFO.isActive || SETTINGS.isActive)
            return;

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0001_menu_bookSelect":
                return SELECTING_BOOK.start();
            case "bible_g0001_menu_bookPrev":
                G0001.bookI--;
                return this.refresh();
            case "bible_g0001_menu_bookNext":
                G0001.bookI++;
                return this.refresh();
            case "bible_g0001_menu_chapterSelect":
                return SELECTING_CHAPTER.start();
            case "bible_g0001_menu_chapterPrev":
                G0001.chapterI--;
                return this.refresh();
            case "bible_g0001_menu_chapterNext":
                G0001.chapterI++;
                return this.refresh();
            case "bible_g0001_menu_difficultyPrev":
                G0001.difficultyI--;
                return this.refresh();
            case "bible_g0001_menu_difficultyNext":
                G0001.difficultyI++;
                return this.refresh();
            case "bible_g0001_menu_goTo":
                return GO_TO.start();
            case "bible_g0001_menu_info":
                return INFO.start();
            case "bible_g0001_menu_settings":
                return SETTINGS.start();
            default:                                    // menu bar
                switch (MENU_BAR.selected) {
                    case "play":
                        if (G0001.isShowInfo) {
                            INFO.start();
                            return this.state = this.STATE.startingPlay;
                        }
                    case "quit":
                        this.end(MENU_BAR.selected);
                }
        }
    }

    startingPlay() {
        if (INFO.isActive)
            return;

        G0001.isShowInfo = false;

        this.end("play");
    }

    show(isVisible) {
        this.item.bibleUpCenter.isVisible = this.item.mainFrame.isVisible = isVisible;

        if (isVisible) {
            MENU_BAR.reset("gameMenu");
            SHOW_SCORE.start();

            this.show_updateCaptions();
            this.refresh();
        } else
            SHOW_SCORE.end();
    }

    show_updateCaptions() {
        let CAPTIONS = ["bible_g0001_menu_bookTitle",       "book",
                        "bible_g0001_menu_chapterTitle",    "chapter",
                        "bible_g0001_menu_difficultyTitle", "difficulty"],
            i, l;

        for (i = 0, l = CAPTIONS.length; i < l; i += 2)
            BK_ENGINE.items.getByName(CAPTIONS[i]).caption = G0001.file.lang.getCaption(CAPTIONS[i + 1]);
    }

    refresh() {
        let lvl      = G0001.book.chapters[G0001.chapterI];
        let scoreOld = (lvl == 0) ? 0 : SCORE[DIFFICULTY[lvl - 1].toLowerCase()],
            scoreNew = SCORE[DIFFICULTY[G0001.difficultyI - 1].toLowerCase()],
            score    = "0";

        this.item.book.name.caption    = G0001.book.name;
        this.item.book.prev.isDisabled = G0001.bookI == 0;
        this.item.book.next.isDisabled = G0001.bookI == GW_BIBLE.books.denomIndexes.length - 1;

        this.item.chapter.name.caption    = "" + G0001.chapterI;
        this.item.chapter.prev.isDisabled = G0001.chapterI == 1;
        this.item.chapter.next.isDisabled = G0001.chapterI == G0001.book.chaptersNm - 1;
        this.item.chapter.lvl1.isVisible  = lvl == 1;
        this.item.chapter.lvl2.isVisible  = lvl == 2;
        this.item.chapter.lvl3.isVisible  = lvl == 3;

        this.item.difficulty.name.caption    = G0001.file.lang.getCaption("difficulty" + DIFFICULTY[G0001.difficultyI - 1]);
        this.item.difficulty.prev.isDisabled = G0001.difficultyI == 1;
        this.item.difficulty.next.isDisabled = G0001.difficultyI == 3;

        if (scoreOld == 0)
            score = scoreNew;
        else if (scoreNew > scoreOld)
            score = "+" + (scoreNew - scoreOld);

        SHOW_SCORE.caption = score;
    }
}

const MENU = BK_ENGINE.tasks.add(new Menu(BK_ENGINE.tasks));


SELECTING_BOOK.start2 = function() {
    SELECT_BIBLE_BOOK.start();
}

SELECTING_BOOK.update = function() {
    if (SELECT_BIBLE_BOOK.isActive)
        return;

    let selected = SELECT_BIBLE_BOOK.selected;

    this.end();

    if (selected != "quit") {
        if (selected == "OT")
            selected = "Gn";
        else if (selected == "NT")
            selected = "Mt";

        G0001.bookI = GW_BIBLE.books.denomIndexes.indexOf(GW_BIBLE.books.getByTag(selected).id);
    }

    MENU.resume();
}


SELECTING_CHAPTER.start2 = function() {
    SELECT_BIBLE_CHAPTER.start(G0001.book);
}

SELECTING_CHAPTER.update = function() {
    if (SELECT_BIBLE_CHAPTER.isActive)
        return;

    if (SELECT_BIBLE_CHAPTER.selected > 0)
        G0001.chapterI = SELECT_BIBLE_CHAPTER.selected;

    this.end();
    MENU.resume();
}


export { MENU };
