
import { BK_ENGINE, Task } from "../../../../bk engine/_bk engine.js";
import { pad } from "../../../../bk utils/_bk utils.js";
import { GW_USER } from "../../../../gw user/_gw user.js";
import { CONTROL_BOX } from "../../../control box.js";
import { G0001 } from "../_bible g0001.js";
import { MENU } from "../g0001 menu.js";
import { PLAY_LOGICS } from "./logics/_bible g0001 play logics.js";
import { PLAY_DISPLAY } from "./display/_bible g0001 play display.js";


const CLICKED_LENGTH_MAX = 10;

const QUITTING    = BK_ENGINE.tasks.addNew(),
      ALL_GUESSED = BK_ENGINE.tasks.addNew();


class Play extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.time    = 0;
        this.clicked = [];
    }

    addClicked(name, info = 0) {
        if (this.clicked.length < CLICKED_LENGTH_MAX)
            this.clicked.push({name: name, info: info});
    }

    getClicked() {
        if (this.clicked.length > 0)
            return this.clicked.shift();

        return {name: ""};
    }

    clickedFlush() {
        this.clicked = [];
    }

    start2() {
        this.time = Date.now();

        this.clickedFlush();
        GW_USER.end();           // stop it during gameplay
        PLAY_LOGICS.start();
        PLAY_DISPLAY.start();
    }

    end2() {
        CONTROL_BOX.end();
        PLAY_LOGICS.end();
        PLAY_DISPLAY.end();
        MENU.start();

        if (!GW_USER.isActive)
            GW_USER.start();
    }

    update() {
        if (QUITTING.isActive || ALL_GUESSED.isActive)
            return;

        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "bible_g0001_play_btnLetter":
                this.addClicked("letter", btn.info);
                break;
            case "bible_g0001_play_btnBackSpace":
                this.addClicked("backSpace");
                break;
            case "bible_g0001_play_btnSpacebar":
                this.addClicked("help");
                break;
            case "bible_g0001_play_btnEsc":
                QUITTING.start();
        }
    
        if (PLAY_LOGICS.state == PLAY_LOGICS.STATE.allGuessed)
            ALL_GUESSED.start();
    }

    saveGame() {
        let gameCode    = "a",
            bookI       = pad(G0001.book.id, 2),
            chapterI    = pad(G0001.chapterI, 3),
            difficultyI = G0001.difficultyI,
            time        = pad(Math.min(9999, Math.floor((Date.now() - this.time) / 1000)), 4),     // seconds
            moves       = pad(Math.min(9999, PLAY_LOGICS.letters.moves), 4),
            helpsUsed   = pad(Math.min(99,   PLAY_LOGICS.help.used), 2);
    
    // console.log("gameCode: " + gameCode, "bookI: ", bookI, "chapter: ", chapterI, "time (sec.): ", time, "moves: ", moves, "helpsUsed: ", helpsUsed);
    
        GW_USER.saveGame("bible g0001", gameCode + bookI + chapterI + difficultyI + time + moves + helpsUsed);
    }    
}

const PLAY = BK_ENGINE.tasks.add(new Play(BK_ENGINE.tasks));


QUITTING.start2 = function() {
    CONTROL_BOX.start("", G0001.file.lang.getCaption("Quitting"), ["yes", "no"]);
}

QUITTING.end2 = function() {
    CONTROL_BOX.end();
}

QUITTING.update = function() {
    switch (CONTROL_BOX.selected) {
        case "confirm":
            this.end();
            return PLAY.end();
        case "abandon":
            this.end();
    }
}


ALL_GUESSED.start2 = function() {
    if (G0001.book.setChapterDifficulty(G0001.chapterI, G0001.difficultyI))   // will save only if the difficulty is bigger
        PLAY.saveGame();

    GW_USER.start();
    PLAY_DISPLAY.showMainBtns(false);
}

ALL_GUESSED.update = function() {
    if (BK_ENGINE.clicked.name == "bible_g0001_play_btnFinish")
        return CONTROL_BOX.start(G0001.file.lang.getCaption("AllGuessedTitle"), G0001.file.lang.getCaption("AllGuessedContent"), ["close"]);

    if (CONTROL_BOX.isActive && (CONTROL_BOX.selected == "confirm" || CONTROL_BOX.selected == "abandon")) {
        CONTROL_BOX.end();
        this.end();
        PLAY.end();
    }
}


export { PLAY };
