
import { BK_ENGINE, Coords } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "./_bible g0002 play display.js";
import { PLAY } from "../_bible g0002 play.js";
import { DISPLAY_LETTERS } from "./display letters.js";


const DIGITS_NM       = 11,                             // 0-9 digits and ":"
      COORDS          = {x: 0.5, y: 0.5, h: 0.8},       // of PLAYFIELD.tileSize
      GAP             = 0.25,                           // between two digits
      SHADOW_MULTIPLY = 0.33,                           // let it be a little bit smaller
      ZOOM            = {sec10: 2, sec3: 1.25, sec2: 1.5, sec1: 2, downSpeed: 0.001};


class DisplayTimer {
    constructor() {
        this.canvas  = {digits:       BK_ENGINE.canvases.addNew(),
                        digitsShadow: BK_ENGINE.canvases.addNew()};
        this.coords  = {main:         new Coords(),
                        shadow:       new Coords(),
                        src:          new Coords()};
        this.seconds = 0;
        this.zoom    = 1;
    }

    start() {
    }

    reset() {
    }

    update() {
        this.update_zoom();
        this.refresh();
    }

    update_zoom() {
        let s = this.seconds;

        this.zoom    = Math.max(1, this.zoom - DISPLAY.timer.delta * ZOOM.downSpeed);
        this.seconds = Math.max(0, Math.ceil(LOGICS.getTimerGame() / 1000));

        if (this.seconds <= 10 && s > 10) return this.zoom = ZOOM.sec10;
        if (this.seconds == 3  && s > 3)  return this.zoom = ZOOM.sec3;
        if (this.seconds == 2  && s == 3) return this.zoom = ZOOM.sec2;
        if (this.seconds == 1  && s == 2) return this.zoom = ZOOM.sec1;
    }

    resize() {
        let img    = DISPLAY.image.digits,
            height = Math.round(PLAYFIELD.tileSize * COORDS.h);
        let ratio  = img.width / img.height,
            width  = Math.ceil(height * ratio);

        // coords
        this.coords.main.resize(width, height);

        this.coords.main.left = PLAYFIELD.coords.main.left + Math.round(COORDS.x * PLAYFIELD.coords.main.width);
        this.coords.main.top  = PLAYFIELD.coords.main.top  + Math.round(COORDS.y * PLAYFIELD.tileSize - height / 2);

        this.coords.shadow.left = Math.round(SHADOW.size * PLAYFIELD.tileSize * SHADOW_MULTIPLY);

        this.coords.src.resize(width, height);

        // canvases
        this.canvas.digits.resize(      DIGITS_NM * width, height);
        this.canvas.digitsShadow.resize(DIGITS_NM * width, height);

        this.redraw(img, width, height);
    }

    redraw(img, width, height) {
        let i;

        this.canvas.digits.clear();
        this.canvas.digitsShadow.clear();

        for (i = 0; i < DIGITS_NM; i++)
            BK_ENGINE.canvases.drawImageQuality(img, this.canvas.digits, {left: i * width, top: 0, width: width, height: height}, i);

        this.canvas.digitsShadow.drawImage(this.canvas.digits);
        this.canvas.digitsShadow.makeShadow(SHADOW.transp);
    }

    refresh() {
        let digit1 = Math.floor(this.seconds / 60);
        let digit2 = Math.floor((this.seconds - digit1 * 60) / 10);
        let digit3 = this.seconds - digit1 * 60 - digit2 * 10,
            left   = this.coords.main.left,
            width  = this.coords.main.width;
        let gap    = Math.round(GAP * width);

        // set the left
        if (digit1 > 0)
            left -= Math.round(width * 1.8);
        else
            left = (digit2 > 0) ? left - Math.round(width * 2 + gap * 1.5) : left - Math.round(width * 2.5 + gap * 2);

        // refresh digits
        if (digit1 > 0) {
            this.refresh_digit(digit1, left - gap);
            this.refresh_digit(10, left + Math.round(width * 0.5));
        }

        if (digit2 > 0 || digit1 > 0)
            this.refresh_digit(digit2, left + width + gap);

        this.refresh_digit(digit3, left + (width + gap) * 2);
    }

    refresh_digit(digitI, leftStart) {
        let left       = leftStart,
            top        = this.coords.main.top,
            width      = this.coords.main.width,
            height     = this.coords.main.height,
            shadowLeft = this.coords.shadow.left;
        let zoom       = Math.floor((this.zoom - 1) * width);

        this.coords.src.left = Math.floor(digitI * width);

        if (zoom > 0) {
            left   -= zoom;
            top    -= zoom;
            width  += zoom * 2;
            height += zoom * 2;
        }

        DISPLAY.canvas.pgd.drawImage(this.canvas.digitsShadow, this.coords.src, {left: left + shadowLeft, top: top + shadowLeft, width: width, height: height});

        if (zoom == 0)
            return DISPLAY.canvas.pgd.drawImage(this.canvas.digits, this.coords.src, {left: left, top: top, width: width, height: height});

        BK_ENGINE.canvases.drawImageQuality(DISPLAY.image.digits, DISPLAY.canvas.pgd, {left: left, top: top, width: width, height: height}, digitI);
    }
}

export const DISPLAY_TIMER = new DisplayTimer();
