
import { BK_ENGINE, Task } from "../../bk engine/_bk engine.js"
import { GW_MAIN } from "../_gw main.js";
import { CONTROL_BOX } from "../control box.js"
import { MENU_INFO } from "./_menu info.js";


class SocialMedia extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        this.item        = BK_ENGINE.items.getByName("main_socialMedia");
        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "main_socialMedia_link_discord":
            case "main_socialMedia_link_youtube":
                return window.open("https://" + btn.info, '_blank');
        }

        if (CONTROL_BOX.selected == "abandon")
            this.end();
    }

    show(isVisible) {
        this.item.isVisible = isVisible;

        if (isVisible)
            return CONTROL_BOX.start(GW_MAIN.file.lang.getCaption("socialMediaTitle"), "", ["close"], false);

        CONTROL_BOX.end();
    }
}

export const SOCIAL_MEDIA = BK_ENGINE.tasks.add(new SocialMedia(BK_ENGINE.tasks));
