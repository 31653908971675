
import { BK_ENGINE } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0001 play display.js";


const TIME = {appearing: 500, disappearing: 500};


class Helper {
    constructor(itemRef) {
        this.item      = itemRef;

        this.X         = 0;
        this.Y         = 0;

        this.isCorrect = false;

        this.char      = "";
    }
}


class DisplayHelpers {
    constructor() {
        this.STATE       = {waiting: 0, appearing: 1, guessing: 2, disappearing: 3};
        this.state       = 0;

        this.offsetY     = 0;
        this.gap         = 0;
        this.activeNm    = 0;
        this.time        = 0;

        this.all         = [];

        this.redraw      = null;

        this.isInitiated = false;
    }

    getByI(i) {
        return this.all[i];
    }

    init() {
        let REDRAWS = BK_ENGINE.redraws,
            item    = BK_ENGINE.items.getByName("bible_g0001_play_helper"),
            i;

        // redraws
        this.redraw = {empty:   REDRAWS.getByName("bible_g0001_play_helperEmpty"),
                       correct: REDRAWS.getByName("bible_g0001_play_helperCorrect"),
                       wrong:   REDRAWS.getByName("bible_g0001_play_helperWrong")};

        // helpers
        this.all.push(new Helper(item));

        for (i = 1; i < DISPLAY.LETTERS_MAX; i++) {
            item           = BK_ENGINE.items.clone(item);
            item.zoom      = item.transp = 0;
            item.isVisible = false;

            this.all.push(new Helper(item));
        }

        // offsetY and gap
        switch (BK_ENGINE.viewPort.orientation) {
            case "verNarrow":
                this.offsetY = 0.25;
                this.gap      = 0.14;
                break;
            case "verWide":
                this.offsetY = 0.42;
                this.gap      = 0.10;
                break;
            case "horNarrow":
            case "horWide":
                this.offsetY = 0.6;
                this.gap      = 0.0712;
        }

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.state = this.STATE.waiting;

        this.reset(0);
    }

    reset(_activeNm = 0) {
        this.activeNm = _activeNm;

        let col      = 0,
            row      = 0,
            firstRow = this.activeNm >= DISPLAY.lettersPerRow ? DISPLAY.lettersPerRow : this.activeNm;
        let offsetX  = firstRow % 2 == 0 ? 0.5 - firstRow / 2 * this.gap + this.gap / 2 : 0.5 - firstRow / 2 * this.gap + this.gap / 2,
            i, l, helperLog, helper, item;

        for (i = 0; i < this.activeNm; i++) {
            helperLog = LOGICS.helpers.getByI(i);
            helper    = this.getByI(i);

            item = helper.item;

            helper.X         = item.x = offsetX      + col * this.gap;
            helper.Y         = item.y = this.offsetY + row * this.gap;
            helper.isCorrect = helperLog.isCorrect;
            helper.char      = helperLog.char;

            item.setRedraw(this.redraw.empty);

            item.isVisible = true;
            item.isRedrawn = false;

            if (++col == DISPLAY.lettersPerRow) {
                col = 0;
                row++;
            }
        }

        for (l = this.all.length; i < l; i++)
            this.all[i].item.isVisible = false;
    }

    update() {
        switch (this.state) {
            case this.STATE.waiting:
                this.waiting();
                break;
            case this.STATE.appearing:
                this.appearing();
                break;
            case this.STATE.guessing:
                this.guessing();
                break;
            case this.STATE.disappearing:
                this.disappearing();
                break;
        }
    }

    waiting() {
        let activeNm = LOGICS.helpers.activeNm;

        if (activeNm > 0) {
            this.time  = Date.now();
            this.state = this.STATE.appearing;

            this.reset(activeNm);
            this.refresh();
        }
    }

    appearing() {
        let zoom = Math.min(1, (Date.now() - this.time) / TIME.appearing),
            i;

        for (i = 0; i < this.activeNm; i++)
            this.all[i].item.zoom = this.all[i].item.transp = zoom;

        if (zoom == 1)
            this.state = this.STATE.guessing;
    }

    guessing() {
        let isRefresh = false,
            i, helperLog, helper;

        if (LOGICS.helpers.activeNm == 0) {
            this.time  = Date.now();
            this.state = this.STATE.disappearing;

            return;
        }

        for (i = 0; i < this.activeNm; i++) {
            helperLog = LOGICS.helpers.getByI(i);
            helper    = this.getByI(i);

            if (helperLog.isCorrect != helper.isCorrect ||
                helperLog.char      != helper.char) {

                helper.isCorrect = helperLog.isCorrect;
                helper.char      = helperLog.char;
                isRefresh        = true;
            }
        }

        if (isRefresh)
            this.refresh();
    }

    disappearing() {
        let zoom = Math.max(0, 1 - (Date.now() - this.time) / TIME.disappearing),
            i;

        for (i = 0; i < this.activeNm; i++)
            this.all[i].item.zoom = this.all[i].item.transp = zoom;

        if (zoom == 0)
            this.state = this.STATE.waiting;
    }

    refresh() {
        let i, helper, item, state;

        for (i = 0; i < this.activeNm; i++) {
            helper = this.getByI(i);
            item   = helper.item;

            if (helper.char.length == 0)
                state = "empty";
            else
                state = helper.isCorrect ? "correct" : "wrong";

            item.setRedraw(this.redraw[state]);

            item.isRedrawn = false;
        }
    }
}

export const DISPLAY_HELPERS = new DisplayHelpers();
