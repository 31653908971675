
// *** SIGNING UP WITH METAMASK AND LOGGING IN IS ALMOST THE SAME,
// call SIGN_UP_METAMASK.start(true), if it's not signing up, but logging in

import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_USER } from "../../../gw user/_gw user.js";
import { METAMASK } from "../../../gw user/metamask.js";
import { GW_MAIN, CONTROL_BOX } from "../../_gw main.js";
import { MENU_LOG_IN } from "./_menu log in.js";


const T                            = BK_ENGINE.tasks;
const CHECK_IF_INSTALLED           = T.addNew(),
      ASK_IF_INSTALL               = T.addNew(),
      SIGNING_UP                   = T.addNew(),
      GETTING_NONCE_TO_SIGN        = T.addNew(),
      SIGNING_IN                   = T.addNew(),
      VERIFYING                    = T.addNew(),
      SIGNING_IN_WITH_CUSTOM_TOKEN = T.addNew();


class SignUpMetamask extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.isLoggingIn = false;
    }

    start2(isLoggingIn = false) {
        const caption = isLoggingIn ? "logInMetamask_title" : "signUpMetamask_title";

        this.isLoggingIn = isLoggingIn;

        CONTROL_BOX.start(GW_MAIN.file.lang.getCaption(caption));
        CHECK_IF_INSTALLED.start();
    }

    end2() {
        CONTROL_BOX.end();
        MENU_LOG_IN.end();
    }
}

const SIGN_UP_METAMASK = BK_ENGINE.tasks.add(new SignUpMetamask(BK_ENGINE.tasks));


CHECK_IF_INSTALLED.start2 = function() {
    this.end();

    if (METAMASK.isInstalled()) {
        const caption = SIGN_UP_METAMASK.isLoggingIn ? "logInMetamask_loggingIn" : "signUpMetamask_signingUp";

        CONTROL_BOX.setContent(GW_MAIN.file.lang.getCaption(caption));

        return SIGNING_UP.start();
    }

    ASK_IF_INSTALL.start();
}


ASK_IF_INSTALL.start2 = function() {
    CONTROL_BOX.setBtns("yes", "no");
    CONTROL_BOX.setContent(GW_MAIN.file.lang.getCaption("signUpMetamask_notPresent"));
}

ASK_IF_INSTALL.update = function() {
    switch (CONTROL_BOX.selected) {
        case "confirm":
            METAMASK.startOnboarding();
            location.reload();
            break;
        case "abandon":
            this.end();
            SIGN_UP_METAMASK.end();
    }
}


SIGNING_UP.start2 = function() {
    METAMASK.getAccount();
}

SIGNING_UP.update = function() {
    if (!METAMASK.state.isReady)
        return;

    this.end();

    if (METAMASK.state.isError) {
        if (METAMASK.state.message.code && METAMASK.state.message.code == -32002)
            alert(GW_MAIN.file.lang.getCaption("signUpMetamask_acceptRequest"));

        console.error("Error signingUp: " + METAMASK.state.message.message);

        return SIGN_UP_METAMASK.end();
    }

    console.log("signingUp: ok");
    
    GETTING_NONCE_TO_SIGN.start();
}


GETTING_NONCE_TO_SIGN.start2 = function() {
    METAMASK.getNonceToSign(GW_USER.lang, GW_USER.denom);
}

GETTING_NONCE_TO_SIGN.update = function() {
    if (!METAMASK.state.isReady)
        return;

    this.end();

    if (METAMASK.state.isError) {
        console.error("Error gettingNonceToSign: " + METAMASK.state.message);
            
        return SIGN_UP_METAMASK.end();
    }

    console.log("gettingNonceToSign: ok");

    if (!SIGN_UP_METAMASK.isLoggingIn && METAMASK.state.message == "User already exists") {
        CONTROL_BOX.setContent(GW_MAIN.file.lang.getCaption("signUpMetamask_userExists"));

        return SIGNING_IN.start(false);
    }

    SIGNING_IN.start();
}


SIGNING_IN.start2 = function(isShowNewMessage = true) {
    METAMASK.signIn(METAMASK.account.nonce);

    if (isShowNewMessage)
        CONTROL_BOX.setContent(GW_MAIN.file.lang.getCaption("signUpMetamask_acceptRequest"));
}

SIGNING_IN.update = function() {
    if (!METAMASK.state.isReady)
        return;

    this.end();

    if (METAMASK.state.isError) {
        const caption = SIGN_UP_METAMASK.isLoggingIn ? "logInMetamask_loggingInError" : "signUpMetamask_singingInError";

        GW_MAIN.alertCaption(caption);
        console.error("Error signingIn: ", METAMASK.state.code, METAMASK.state.message);

        return SIGN_UP_METAMASK.end();
    }

    console.log("signingIn: ok");

    VERIFYING.start();
}


VERIFYING.start2 = function() {
    METAMASK.verifySignedMessage();
}

VERIFYING.update = function() {
    if (!METAMASK.state.isReady)
        return;

    this.end();

    if (METAMASK.state.isError) {
        GW_MAIN.alertCaption("signUpMetamask_verifyingError");
        console.error("error verifying: " + METAMASK.state.message);

        return SIGN_UP_METAMASK.end();
    }

    console.log("verifying: ok");

    SIGNING_IN_WITH_CUSTOM_TOKEN.start();
}


SIGNING_IN_WITH_CUSTOM_TOKEN.start2 = function() {
    GW_USER.auth.signInWithCustomToken(METAMASK.account.token);
}

SIGNING_IN_WITH_CUSTOM_TOKEN.update = function() {
    if (!GW_USER.auth.state.isReady)
        return;

    this.end();
    SIGN_UP_METAMASK.end();

    if (GW_USER.auth.state.isError) {
        GW_MAIN.alertCaption("signUpMetamask_customTokenError");
        
        return console.error("error signing in with custom token: " + GW_USER.auth.state.message + GW_USER.auth.state.code);
    }

    console.log("signingInWithCustomToken: ok");
}


export { SIGN_UP_METAMASK };
