
import { BK_ENGINE, Task } from "../../../../bk engine/_bk engine.js";
import { GW_USER } from "../../../../gw user/_gw user.js";
import { BIBLE_BOOKS } from "../../../books.js";
import { G0002 } from "../_bible g0002.js";
import { PATH, FILE_NAME } from "../g0002 server.js";


const DEVELOP = {isShowLoadedQuote: false};


class Quote {
    constructor(year, month, day, difficultyI) {
        this.year          = year;          // full, i.e: 2023
        this.month         = month;         // 0-11
        this.day           = day;           // 1-31
        this.difficultyI   = difficultyI;
        this.ref           = "";            // full reference of book,chapter,verse(s), with no space, i.e.: "Mk3,19"
        this.tag           = "";            // separated tag
        this.chapterVerses = "";            // and the rest
        this.tagNational   = "";            // these 3 are in national languages
        this.bookName      = "";
        this.content       = "";
    }
}


class Quotes extends Task {    
    constructor(PARENT) {
        super(PARENT);

        this.file   = BK_ENGINE.files.addNew();
        this.user   = {lang: "", denom: ""};
        this.all    = [];
        this.quoteI = 0;
    }

    getDateSum(quote) {
        return quote.year * 10000 + quote.month * 100 + quote.day;
    }

    add(data, difficultyI) {        // data: {year, month. day, ref}
        let quoteI      = -1,
            newQuote    = new Quote(data.year, data.month, data.day, difficultyI),
            newQuoteSum = this.getDateSum(data),
            quote, quoteSum;

        while (++quoteI < this.all.length) {
            quote    = this.getByI(quoteI);
            quoteSum = this.getDateSum(quote);

            // if it's the same date, but different difficulty, update only the difficulty
            if (quoteSum == newQuoteSum) {
                if (quote.difficultyI >= difficultyI)
                    return false;

                quote.difficultyI = difficultyI;

                return true;
            } else if (quoteSum > newQuoteSum) {
                this.all.splice(quoteI, 0, newQuote);

                return true;
            }
        }

        this.all.push(newQuote);

        return true;
    }

    getTagAndRest(ref) {
        let l = ref.length,
            i = 1;

        while (i < l && isNaN(parseInt(ref.charAt(i)))) {
            i++;
        }

        if (i < l)
            return {tag: ref.substring(0, i).trim(), rest: ref.substring(i).trim()};

        return {tag: ref, rest: ""};        // just in case
    }

    getNm() {
        return this.all.length;
    }

    getByI(i) {
        return (i < this.all.length) ? this.all[i] : undefined;
    }

    getLast() {
        return (this.all.length > 0) ? this.all[this.all.length - 1] : undefined;
    }

    isLastGuessed() {
        let date  = GW_USER.getDate(),
            quote = this.getLast();

        if (!quote)
            return false;

        return this.getDateSum(date) == this.getDateSum(quote);
    }

    getTodaysWord() {        
        if (this.isLastGuessed())
            return this.getLast();

        return undefined;
    }

    reset() {
        this.all = [];
    }

    start2(isUpdateOnlyLastMonth = false) {     // when called, it will update the data
        this.quoteI = isUpdateOnlyLastMonth ? this.start2_getLastMonthQuoteI() : 0;
    }

    start2_getLastMonthQuoteI() {
        let date   = GW_USER.getDate(),
            quoteI = -1,
            quote;

        do {
            quote = this.getByI(++quoteI);
        } while (quote && (date.year > quote.year || date.month > quote.month));

        return quoteI;
    }

    update() {
        let quote = this.getByI(this.quoteI),
            month, path, fileName;

        if (!quote)
            return this.end();

        month = quote.month + 1;
        month = "" + month;

        if (month.length == 1)
            month = "0" + month;

        path     = PATH.lang + G0002.user.lang + PATH.quotes;
        fileName = FILE_NAME + " " + G0002.user.lang + " " + month + " " + G0002.user.denom;

        if (path != this.file.path || fileName != this.file.fileName) {
            this.file.path     = path;
            this.file.fileName = fileName;

            this.file.load();
        }

        if (!this.file.isLoaded)
            return;

        this.update_one(quote);

        this.quoteI++;
    }

    update_one(quote) {
        let lines     = this.file.data.split(/\r?\n/),
            firstLine = "*" + quote.day,
            i         = -1,
            tagAndRest, book;

        do {
            ++i;
        } while(i < lines.length && lines[i].trim() != firstLine)

        if (i > lines.length - 2)
            return "day's quote not found: " + quote.day + "!";

        quote.ref     = lines[i + 1];
        quote.content = lines[i + 2];

        tagAndRest = this.getTagAndRest(quote.ref);

        quote.tag           = tagAndRest.tag;
        quote.chapterVerses = tagAndRest.rest;

        if (DEVELOP.isShowLoadedQuote)
            console.log("day: " + quote.day + "* tag: " + quote.tag + "* chVs: " + quote.chapterVerses + "***")

        book = BIBLE_BOOKS.getByTag(quote.tag);
    
        if (!book)
            console.log("Wrong ref! " + quote.tag);

        quote.tagNational = book.tagNational;
        quote.bookName    = book.nameSingular;
    }
}

export const QUOTES = BK_ENGINE.tasks.add(new Quotes(BK_ENGINE.tasks));
