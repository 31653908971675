
import { isEmailFormatCorrect } from "../../../bk utils/_bk utils.js";
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_MAIN, CONTROL_BOX, INFO_BOX } from "../../_gw main.js";
import { EMAIL_VER_SENT } from "./_menu log in.js";


const STATE = {main: 10, signingUp: 20, emailVerSent: 30};


class SignUpEmail extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame:    I.getByName("main_userSignUp"),
                     email:        I.getByName("main_userSignUp_email"),
                     passw:        I.getByName("main_userSignUp_passw"),
                     passwConfirm: I.getByName("main_userSignUp_passwConfirm"),
                     isShowPassw:  I.getByName("main_userSignUp_isShowPassw")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = STATE.main;

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (this.state) {
            case STATE.main:
                return this.update_main();
            case STATE.signingUp:
                return this.update_signingUp();
            default:
                this.update_emailVerSent();
        }
    };

    update_main() {
        switch (CONTROL_BOX.selected) {
            case "confirm":
                CONTROL_BOX.selected = "";

                if (!isEmailFormatCorrect(this.item.email.content))
                    return GW_MAIN.alertCaption("errInvalidEmailFormat");
                if (this.item.passw.content != this.item.passwConfirm.content)
                    return GW_MAIN.alertCaption("errPasswordsNotEqual");
                if (this.item.passw.content.length < GW_USER.passwordLengthMin)
                    return GW_MAIN.alertCaption("errPasswordTooShort");

                GW_USER.auth.createUserWithEmailAndPassword(this.item.email.content, this.item.passw.content, GW_USER.lang, GW_USER.denom);
                this.show(false);
                INFO_BOX.start("[*centerV,center*]" + GW_MAIN.file.lang.getCaption("userSignUp_signingUp"), "");

                return this.state = STATE.signingUp;
            case "abandon":
                return this.end();
        }

        this.refresh();
    }

    update_signingUp() {
        if (!GW_USER.auth.state.isReady)
            return;

        INFO_BOX.end();

        if (GW_USER.auth.state.isError) {
            let caption = GW_USER.auth.state.code == "auth/email-already-in-use" ? "errSigningUpEmailAlreadyInUse" : "errSigningUp";

            GW_MAIN.alertCaption(caption);
            this.show(true);

            return this.state = STATE.main;
        }

        this.state = STATE.emailVerSent;

        EMAIL_VER_SENT.start();
    }

    update_emailVerSent() {
        if (EMAIL_VER_SENT.isActive)
            return;

        this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        this.item.email.setVisible(isVisible);
        this.item.passw.setVisible(isVisible);
        this.item.passwConfirm.setVisible(isVisible);

        this.item.passw.content    = "";
        this.item.passw.isPassword = !this.item.isShowPassw.isChecked;

        this.item.passwConfirm.content    = "";
        this.item.passwConfirm.isPassword = !this.item.isShowPassw.isChecked;

        if (isVisible)
            return CONTROL_BOX.start(GW_MAIN.file.lang.getCaption("userSignUp_title"), "", ["confirm", "abandon"]);
        else
            CONTROL_BOX.end();
    }

    refresh() {
        this.item.passw.isPassword = this.item.passwConfirm.isPassword = !this.item.isShowPassw.isChecked;
    }
}

export const SIGN_UP_EMAIL = BK_ENGINE.tasks.add(new SignUpEmail(BK_ENGINE.tasks));
