
import { getByName } from "../bk engine utils.js"
import { BKengineRect } from "../primitives/rect.js"


class Rects {
    constructor() {
        this.all = [];
    }

    add(rect) {
        return this.all[this.all.push(rect) - 1];
    }

    addNew() {
        let args = (typeof arguments[0] == "string") ? arguments : arguments[0],
            rect = new BKengineRect(),
            i, l, prop, value;

        for (i = 0, l = args.length; i < l; i += 2) {
            prop  = args[i];
            value = args[i + 1];

            rect[prop] = value;
        }

        return this.add(rect);
    }

    getByName(value) {
        return getByName(this.all, value);
    }
}


export const RECTS = new Rects();
