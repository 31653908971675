
import { replaceTextFormattingTags } from "../../../bk utils/_bk utils.js";
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { MENU_BAR } from "../../menu bar.js";

const PATH  = {ui:       "gw bible/read/bible intro/ui",
               uiImages: "gw bible/read/bible intro/ui",
               lang:     "gw bible/read/bible intro/lang"},
      FILES = {ui:       {verNarrow: "_ver narrow - 20230501", verWide: "_ver wide - 20230501", horNarrow: "_hor narrow - 20230501", horWide: "_hor wide - 20230501"},
               uiImages: {low: "images low - 20230501", med: "images med - 20230501", high: "images high - 20230501"},
               lang:     {en:   {c: "content c - 20230501", p: "content p - 20230501", ui: "ui - 20230501"},
                          es:   {c: "content c - 20230501", p: "content p - 20230501", ui: "ui - 20230501"},
                          esla: {c: "content c - 20230801", p: "content p - 20230801", ui: "ui - 20230801"},
                          fr:   {c: "content c - 20230501", p: "content p - 20230501", ui: "ui - 20230501"},
                          it:   {c: "content c - 20230501", p: "content p - 20230501", ui: "ui - 20230501"},
                          pl:   {c: "content c - 20230501", p: "content p - 20230501", ui: "ui - 20230501"}}};

const FORMATTING_TAGS = ["n", "0", "i", "1", "b", "2", "c1", "3", "m", "4", "mb", "5"];     // pairs: "tag", text index


class Init extends Task {
    constructor(PARENT) {
        super(PARENT);
        
        this.MAIN        = null;
        this.file        = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                            uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages)};
        this.isInitiated = false;
    }

    start2(MAIN) {
        this.MAIN                   = MAIN;
        this.file.ui.fileName       = FILES.ui[BK_ENGINE.viewPort.orientation];
        this.file.uiImages.fileName = FILES.uiImages[BK_ENGINE.viewPort.imagesQuality]; 
    }

    update() {
        if (!this.file.ui.isLoaded || !this.file.uiImages.isLoaded)
            return;

        // add user interface
        BK_ENGINE.addFromData(this.file.ui.data);
        BK_ENGINE.setFromData(this.file.uiImages.data);

        // items
        this.MAIN.item = {mainFrame: BK_ENGINE.items.getByName("bible_upCenter"),
                          book:      BK_ENGINE.items.getByName("bible_bookBibleIntro")};

        // the book
        this.MAIN.item.book.btnTurnPagePrev = MENU_BAR.getItem("prev");
        this.MAIN.item.book.btnTurnPageNext = MENU_BAR.getItem("next");

        // the rest
        this.isInitiated = true;

        this.end();
        this.MAIN.starting();
    }
}

const INIT = BK_ENGINE.tasks.add(new Init(BK_ENGINE.tasks));


class SetLang extends Task {
    constructor(PARENT) {
        super(PARENT);
        
        this.MAIN = null;

        this.file        = {ui:      BK_ENGINE.files.addNew(),
                            content: BK_ENGINE.files.addNew()};
        this.textContent = new BK_ENGINE.textContent();
    }

    start2(MAIN) {
        this.MAIN                  = MAIN;
        this.file.content.path     = PATH.lang + "/" + MAIN.user.lang;
        this.file.content.fileName = FILES.lang[MAIN.user.lang][MAIN.user.denom];

        this.file.content.load();

        if (MAIN.user.isLangChanged) {
            this.file.ui.path     = PATH.lang + "/" + MAIN.user.lang;
            this.file.ui.fileName = FILES.lang[MAIN.user.lang].ui;

            this.file.ui.load();
        }
    }

    update() {
        if (!this.file.ui.isLoaded || !this.file.content.isLoaded)
            return;

        if (this.MAIN.user.isLangChanged)
            BK_ENGINE.setFromData(this.file.ui.data);

        this.textContent.init(replaceTextFormattingTags(this.file.content.data, FORMATTING_TAGS));

        this.MAIN.item.book.reset();
        this.MAIN.item.book.addTextContent(this.textContent, true);

        this.end();
        this.MAIN.resume();
    }
}

const setLang = BK_ENGINE.tasks.add(new SetLang(BK_ENGINE.tasks));


class BibleIntro extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item = null;
        this.user = {lang: "", denom: "", isLangChanged: false};
    }

    start2() {
        if (INIT.isInitiated)
            return this.starting();

        INIT.start(this);
    }

    starting() {
        let isVerChanged;

        if (this.user.lang != GW_BIBLE.user.lang) {
            this.user.lang          = GW_BIBLE.user.lang;
            this.user.isLangChanged = true;
        } else
            this.user.isLangChanged = false;

        if (this.user.denom != GW_BIBLE.user.denom) {
            this.user.denom = GW_BIBLE.user.denom;
            isVerChanged  = true;
        } else
            isVerChanged = false;

        if (this.user.isLangChanged || isVerChanged)
            return setLang.start(this);

        this.resume();
    }

    resume() {
        this.show(true);
    }

    end2() {
        MENU_BAR.selected = "";

        this.show(false);
        GW_BIBLE.resume();
    }

    update() {
        if (INIT.isActive || setLang.isActive)
            return;

        switch (BK_ENGINE.clicked.name) {
            case "bible_bookBibleIntro_pageL":
            case "bible_bookBibleIntro_coverB":
                this.item.book.turnPagePrev();
                break;
            case "bible_bookBibleIntro_pageR":
            case "bible_bookBibleIntro_coverF":
                this.item.book.turnPageNext();
                break;
            default:
                switch (MENU_BAR.selected) {
                    case "prev":
                        this.item.book.turnPagePrev();
                        break;
                    case "next":
                        this.item.book.turnPageNext();
                        break;
                    case "quit":
                        this.end();
                }
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = this.item.book.isVisible = isVisible;
        BK_ENGINE.pointer.isActive    = !isVisible;

        if (isVisible)
            MENU_BAR.reset("bibleIntro");
    }
}


export const BIBLE_INTRO = BK_ENGINE.tasks.add(new BibleIntro(BK_ENGINE.tasks));
