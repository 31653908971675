
import { VIEW_PORT } from "../_main objects/view port.js";
import { BKengineCanvas } from "../primitives/canvas.js"
import { BKengineItem } from "./_item.js"


class BKenginePicture extends BKengineItem {
    constructor() {
        super();

        this.type        = "picture";
        this.cursor      = "grab";

        this.origWidth   = 0;       // must be set at the beginning, otherwise after zooming in and out the width/height would be a little bit deformed
        this.origHeight  = 0;
        
        this.isClickable = true;

        this.canvas      = new BKengineCanvas();
    }

    setZoom(zoom, left, top) {         // call it if the zoom point is different from it's center, otherwise simply set the value: item.zoom = value
        let lastWidth  = this.width,
            lastHeight = this.height;
        let x          = (left - this.left) / this.width,
            y          = (top  - this.top)  / this.height;

        this.zoom = this.zoomLast = Math.min(Math.max(this.zoomMin, zoom), this.zoomMax);
        
        this.width   = Math.round(this.origWidth  * this.zoom);
        this.height  = Math.round(this.origHeight * this.zoom);
        this.left   += Math.round(x * lastWidth  - x * this.width);
        this.top    += Math.round(y * lastHeight - y * this.height);

        if (this.width != lastWidth || this.height != lastHeight)
            this.isResized = false;
    }

    fitIntoParent() {
        let parentWidth  = this.parent.width  == 0 ? VIEW_PORT.width  : this.parent.width,     // that's because sometime the parent's size isn't updated yet
            parentHeight = this.parent.height == 0 ? VIEW_PORT.height : this.parent.height;
        let ratioPic     = this.origWidth / this.origHeight,
            ratioParent  = parentWidth    / parentHeight;

        if (ratioPic > ratioParent) {
            this.width  = parentWidth;
            this.height = parentWidth / ratioPic;
        } else {
            this.height = parentHeight;
            this.width  = parentWidth / ratioParent;
        }

        this.left = (parentWidth  - this.width)  / 2;
        this.top  = (parentHeight - this.height) / 2;

        this.setZoom(this.height / this.origHeight, this.left + this.width / 2, this.top + this.height / 2);
// console.log(ratioPic, ratioParent, this.parent.width, this.parent.height, this.width, this.height)
    }

    resize() {
        this.canvas.resize(this.width, this.height);

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        this.canvas.clear();

        if (this.redrawAll(this.canvas))
            this.isRedrawn = true;
        else
            this.isRedrawn = false;
    }

    update() {
        let left, top;

        if (this.zoom != this.zoomLast)
            this.setZoom(this.zoom, this.left + this.width / 2, this.top + this.height / 2);

        if (!this.isResized)
            this.resize();

        if (!this.isRedrawn)
            this.redraw();

        // limit left/top
        left = Math.round((this.parent.left + this.parent.width)  / 2);
        top  = Math.round((this.parent.top  + this.parent.height) / 2);

        if (this.left               > left) this.left = left;
        if (this.top                > top)  this.top  = top;
        if (this.left + this.width  < left) this.left = left - this.width;
        if (this.top  + this.height < top)  this.top  = top  - this.height;
    }

    draw(canvas) {
        canvas.drawImage(this.canvas,
                         {left: 0, top: 0, width: this.width, height: this.height},
                         this);
    }
}


export { BKenginePicture };
