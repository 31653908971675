
import { Coords } from "../bk engine utils.js";
import { getByName } from "../bk engine utils.js"
import { BKengineCanvas } from "../primitives/canvas.js"


class Canvases {
    constructor() {
        this.buffer     = new BKengineCanvas();
        this.foreground = new BKengineCanvas();
        this.tmp        = new BKengineCanvas();

        this.all        = [];

        // init
        let body = document.getElementsByTagName("body")[0];

        this.foreground.setStyle("position", "fixed", "zIndex", 0);

        body.appendChild(this.foreground.canvas);
    }

    add(canvas) {
        return this.all[this.all.push(canvas) - 1];
    }

    addNew() {
        let canvas = new BKengineCanvas(),
            args, i, l, prop, value;

        if (arguments.length > 0) {
            args = (typeof arguments[0] == "string") ? arguments : arguments[0];
            
            for (i = 0, l = args.length; i < l; i += 2) {
                prop  = args[i];
                value = args[i + 1];
                
                canvas[prop] = value;
            }
        }

        return this.add(canvas);
    }

    getByName(value) {
        return getByName(this.all, value);
    }

    refresh() {
        this.foreground.clear();
        this.foreground.drawImage(this.buffer);

        this.buffer.clear();
        this.buffer.transp = 1;
    }

    drawImage(image, canvas, oCoordsDst, col = 0, row = 0) {
        if (!image.isLoaded)
            return false;

        let coordsSrc = new Coords(0, 0, image.width, image.height),
            coordsDst = (oCoordsDst == undefined) ? new Coords(0, 0, canvas.width, canvas.height) : new Coords(oCoordsDst.left, oCoordsDst.top, oCoordsDst.width, oCoordsDst.height);

        coordsSrc.left = coordsSrc.width  * col;
        coordsSrc.top  = coordsSrc.height * row;

        canvas.drawImage(image, coordsSrc, coordsDst);

        return true;
    }

    drawImageQuality(image, canvas, oCoordsDst, col = 0, row = 0) {
        if (!image.isLoaded)
            return false;

        let coordsSrc = new Coords(0, 0, image.width, image.height),
            coordsDst = (oCoordsDst == undefined) ? new Coords(0, 0, canvas.width, canvas.height) : new Coords(oCoordsDst.left, oCoordsDst.top, oCoordsDst.width, oCoordsDst.height);

        coordsSrc.left = coordsSrc.width  * col;
        coordsSrc.top  = coordsSrc.height * row;

        if (coordsSrc.width > coordsDst.width && coordsSrc.height > coordsDst.height)   // both sizes must be less than original!
            canvas.drawImageQuality(this.tmp, image, coordsSrc, coordsDst);
        else
            canvas.drawImage(image, coordsSrc, coordsDst);

        return true;
    }

    drawCanvasQuality(canvasSrc, canvasDst, oCoordsSrc, oCoordsDst) {
        let coordsSrc = oCoordsSrc ? oCoordsSrc : new Coords(0, 0, canvasSrc.width, canvasSrc.height),
            coordsDst = oCoordsDst ? oCoordsDst : new Coords(0, 0, canvasDst.width, canvasDst.height);

        if (coordsSrc.width > coordsDst.width && coordsSrc.height > coordsDst.height)   // both sizes must be less than original!
            canvasDst.drawImageQuality(this.tmp, canvasSrc, coordsSrc, coordsDst);
        else
            canvasDst.drawImage(canvasSrc, coordsSrc, coordsDst);

        return true;
    }

    flip(canvas, isFlipH, isFlipV, oCoords) {
        let coords = oCoords ? new Coords(oCoords.left, oCoords.top, oCoords.width, oCoords.height) : new Coords(0, 0, this.tmp.width, this.tmp.height);
        let scaleH = isFlipH ? -1 : 1,
            scaleV = isFlipV ? -1 : 1;
        let left   = isFlipH ? -coords.width  : 0,
            top    = isFlipV ? -coords.height : 0;

        // copy flipped image to temporary canvas
        this.tmp.resize(coords.width, coords.height);
        this.tmp.ctx.save();
        this.tmp.ctx.scale(scaleH, scaleV);
        this.tmp.drawImage(canvas, coords, {left: left, top: top, width: coords.width, height: coords.height});
        this.tmp.ctx.restore();

        // copy it back to the original canvas
        canvas.clearRect(coords);
        canvas.drawImage(this.tmp, {left: 0, top: 0, width: coords.width, height: coords.height}, coords);
    }
}


export const CANVASES = new Canvases();
