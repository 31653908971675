
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { pad } from "../../../bk utils/_bk utils.js";
import { COOKIES } from "../../../gw main/cookies.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { AUDIO } from "./g0002 audio.js";
import { MENU } from "./g0002 menu.js";
import { PLAY } from "./play/_bible g0002 play.js";
import { QUOTES } from "./quotes/_g0002 quotes.js";
import { GW_USER } from "../../../gw user/_gw user.js";


const DEVELOP = {addQuotes: {month: -1, dayStart: 1, dayEnd: 31}};        // month: -1 do nothing, month: 0-11, day: 1-31

const PATH   = {ui:       "gw bible/play/g0002/ui",
                uiImages: "gw bible/play/g0002/ui",
                lang:     "gw bible/play/g0002/lang",
                audio:    "gw bible/play/g0002/audio"},
      FILES  = {ui:       {verNarrow: "_ver narrow - 20230720", verWide: "_ver wide - 20230720", horNarrow: "_hor narrow - 20230720", horWide: "_hor wide - 20230720"},
                uiImages: {low: "images low - 20230501", med: "images med - 20230501", high: "images high - 20230501"},
                lang:     {en: "g0002 en - 20230503", es: "g0002 es - 20230503", esla: "g0002 esla - 20230801", fr: "g0002 fr - 20230503", it: "g0002 it - 20230503", pl: "g0002 pl - 20230503"},
                audio:    {ext: "mp3",
                           all: ["all guessed - 20230501", "sounds - 20230501"]}},
      COOKIE_NAME = "gwBibleGame0002";

const SCORE      = {easy: 5, medium: 10, hard: 20},
      GAME_CODE  = "b",
      DIFFICULTY = {easy:   {bricksNm: 24, ballSpeed: 0.0022, wordsPerAttempt: 5, timeStart: 15, timePerLetter: 3.0, totalHpMax: 150},  // totalHpMax: for all letters to guess it will be calculated the hpPerLetter, so totalHpMax will limit the hpPerLetter
                    medium: {bricksNm: 22, ballSpeed: 0.0030, wordsPerAttempt: 5, timeStart: 12, timePerLetter: 1.6, totalHpMax: 150},
                    hard:   {bricksNm: 20, ballSpeed: 0.0040, wordsPerAttempt: 5, timeStart: 8,  timePerLetter: 1.5, totalHpMax: 150}},
      SET_LANG   = BK_ENGINE.tasks.addNew();


class Init extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file = {ui:       BK_ENGINE.files.addNew("path", PATH.ui),
                     uiImages: BK_ENGINE.files.addNew("path", PATH.uiImages)};
        this.item = {mainFrame: null};

        this.isInitiated = false;
    }

    start2() {
        this.file.ui.fileName       = FILES.ui[BK_ENGINE.viewPort.orientation];
        this.file.uiImages.fileName = FILES.uiImages[BK_ENGINE.viewPort.imagesQuality];
    }

    update() {
        if (!this.file.ui.isLoaded || !this.file.uiImages.isLoaded)
            return;

        BK_ENGINE.addFromData(this.file.ui.data);
        BK_ENGINE.setFromData(this.file.uiImages.data);
        BK_ENGINE.items.orderZ();

        this.item.mainFrame = BK_ENGINE.items.getByName("bible_game");
        this.isInitiated    = true;

        this.end();
        SET_LANG.start();
    }
}

const INIT = BK_ENGINE.tasks.add(new Init(BK_ENGINE.tasks));


class Game0002 extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.file        = {lang: BK_ENGINE.files.addNew()};
        this.user        = {id: "", lang: "", denom: "", isGameLoaded: false};

        this._difficulty = "medium";

        this.isShowInfo            = true;    // all these: only once (updated from cookies)
        this.isShowGuessLastChar   = true;
        this.isShowCopyExplanation = true;
    }

    set difficulty(value) {
        this._difficulty = value;

        this.updateCookie();
    }

    get difficulty() {
        return this._difficulty;
    }

    set difficultyI(value) {
        let diffNames = ["easy", "medium", "hard"];

        this.difficulty = diffNames[value];
    }

    get difficultyI() {
        if (this.difficulty == "easy")
            return 0;
        
        return (this.difficulty == "medium") ? 1 : 2;
    }
 
    readCookie() {
        let cookie = COOKIES.getOne(COOKIE_NAME);

        if (cookie.length < 1) return;
        let difficultyI = parseInt(cookie.charAt(0));
        if (isNaN(difficultyI)) return;
        this.difficultyI = difficultyI;

        if (cookie.length < 2) return;
        AUDIO.isOn = cookie.substring(1, 2) == "1";

        if (cookie.length < 5) return;
        let volume = parseInt(cookie.substring(2, 5));
        if (isNaN(volume)) return;
        AUDIO.volume = Math.max(0, Math.min(1, volume / 100));

        if (cookie.length < 6) return;
        this.isShowInfo = cookie.substring(5, 6) == "1";

        if (cookie.length < 7) return;
        this.isShowGuessLastChar = cookie.substring(6, 7) == "1";

        if (cookie.length < 8) return;
        this.isShowCopyExplanation = cookie.substring(7, 8) == "1";

        // console.log(difficultyI, AUDIO.isOn, AUDIO.volume, this.isShowInfo, this.isShowGuessLastChar);
    }

    updateCookie() {
        let isAudioOn             = AUDIO.isOn ? "1" : "0",
            volume                = pad(Math.floor(AUDIO.volume * 100), 3),
            isShowInfo            = this.isShowInfo ? "1" : "0",
            isShowGuessLastChar   = this.isShowGuessLastChar ? "1" : "0",
            isShowCopyExplanation = this.isShowCopyExplanation ? "1" : "0";

        COOKIES.setOne(COOKIE_NAME, "" + this.difficultyI + isAudioOn + volume + isShowInfo + isShowGuessLastChar + isShowCopyExplanation);
    }

    reset() {       // called from G0002 (user changed or disconnected)
        this.isGameLoaded = false;

        QUOTES.reset();
    }

    start2() {
        BK_ENGINE.pointer.isActive = false;

        GW_BIBLE.showBackground(false);
        AUDIO.start();

        if (!INIT.isInitiated) {
            this.readCookie();

            return INIT.start();
        }

        SET_LANG.start();
    }

    end2() {
        BK_ENGINE.pointer.isActive = true;
        
        // INIT.item.mainFrame.isVisible = false;
        
        AUDIO.end();
        GW_BIBLE.showBackground(true);
        GW_BIBLE.resumeFromGame();
    }

    update() {
        if (INIT.isActive || SET_LANG.isActive || 
            MENU.isActive || PLAY.isActive)
                return;

        this.end();
    }

    loadGame() {
        let i, l, d, date, difficultyI;

        QUOTES.reset();

        for (i = 0, l = GW_USER.progress.length; i < l; i++) {
            d = GW_USER.progress[i].data;

            if (d.charAt(0) == GAME_CODE) {
                date        = d.substring(1, 9);
                difficultyI = parseInt(d.substring(9, 10));

                QUOTES.add({year: parseInt(date.substring(0, 4)), month: parseInt(date.substring(4, 6) - 1), day: parseInt(date.substring(6, 8))}, difficultyI);
            }
        }

        this.user.isGameLoaded = true;

        // the cookies could be disabled, but the user is registred, so turn off the helps
        this.isShowInfo = this.isShowGuessLastChar = this.isShowCopyExplanation = false;

        console.log("Bible game0002: user data loaded");
    }
}


const G0002 = BK_ENGINE.tasks.add(new Game0002(BK_ENGINE.tasks));


SET_LANG.start2 = function() {
    if (G0002.user.id != GW_BIBLE.user.id) {
        G0002.user.id = GW_BIBLE.user.id;
        QUOTES.reset();
    }

    if (G0002.user.denom != GW_BIBLE.user.denom)
        G0002.user.denom = GW_BIBLE.user.denom;

    if (G0002.user.lang != GW_BIBLE.user.lang) {
        G0002.user.lang = GW_BIBLE.user.lang;

        G0002.file.lang.path     = PATH.lang + "/" + G0002.user.lang;
        G0002.file.lang.fileName = FILES.lang[G0002.user.lang];
        
        G0002.file.lang.load();

        return;
    }

    this.end();
}

SET_LANG.end2 = function() {
    MENU.start(0);
}

SET_LANG.update = function() {
    if (!G0002.file.lang.isLoaded)
        return;

    BK_ENGINE.setFromData(G0002.file.lang.data);
    this.end();
}


export { G0002, DEVELOP, PATH, FILES, GAME_CODE, DIFFICULTY, SCORE };
