
import { copyObj, getPropertiesFromSrc, getSrcFromProperties } from "../bk engine utils.js"


export class BKengineImage {
    constructor() {
        this.type       = "image";
        this.name       = "";

        this.path       = "";
        this.fileName   = "";
        this.ext        = "jpg";

        this.img        = new Image;    // DOM image

        this.cols       = 1;
        this.rows       = 1;
    }

    get isLoaded() {
        if (this.path.length == 0 || this.fileName.length == 0)
            return false;

        if (this.img.src.length == 0 || this.img.src != this.src)
            this.img.src = this.src;

        return this.img.complete;
    }

    get width() {
        return this.img.width / this.cols;
    }

    get height() {
        return this.img.height / this.rows;
    }

    set src(value) {
        copyObj(getPropertiesFromSrc(value), this);
    }

    get src() {
        return getSrcFromProperties(this);
    }

    free() {
        this.img.src = null;
    }

    draw(canvas, oCoordsDst, oCol, oRow) {
        if (!this.isLoaded)
            return false;

        let coordsSrc = {left:   0, top: 0,
                         width:  this.img.width  / this.cols,
                         height: this.img.height / this.rows};
        let coordsDst = (oCoordsDst == undefined) ? {left: 0, top: 0, width: canvas.canvas.width, height: canvas.canvas.height} :
                                                    {left: oCoordsDst.left, top: oCoordsDst.top, width: oCoordsDst.width, height: oCoordsDst.height};

        if (oCol != undefined) coordsSrc.left = coordsSrc.width  * oCol;
        if (oRow != undefined) coordsSrc.top  = coordsSrc.height * oRow;

        canvas.drawImage(this, coordsSrc, coordsDst);

        return true;
    }
}
