
import { SHADOW } from "../bk engine constants.js"
import { BKengineCanvas } from "../primitives/canvas.js"
import { BKengineImage } from "../primitives/image.js"
import { BKengineRedraw } from "../primitives/redraw.js"
import { BKengineText } from "../primitives/text.js"
import { BKengineItem } from "./_item.js"


const IMAGE_SRC = "bk engine/img/check and radio box - 20230811.png";


class BKengineRadioBox extends BKengineItem {
    constructor() {
        super();

        this.brightness  = {hover: 1.30, pressed: 0.80};

        this.image       = new BKengineImage();
        this.text        = new BKengineText();
        this.redrawImg   = new BKengineRedraw();
        this.redrawTxt   = new BKengineRedraw();
        this.canvas      = {checked:   {normal:  new BKengineCanvas(),
                                        hover:   new BKengineCanvas(),
                                        pressed: new BKengineCanvas()},
                            unchecked: {normal:  new BKengineCanvas(),
                                        hover:   new BKengineCanvas(),
                                        pressed: new BKengineCanvas()},
                            shadow:    new BKengineCanvas()};

        this.type        = "radioBox";
        this.cursor      = "pointer";
        this.caption     = "";

        this.isChecked   = true;
        this.isClickable = true;
        this.isDisabled  = false;
        this.isShadow    = false;       // better when off

        // init
        this.image.src   = IMAGE_SRC;

        this.init();
    }

    init() {
        // image
        this.image.cols = 4;

        this.redrawImg.type = "image";
        this.redrawImg.wMin = 1;
        this.redrawImg.hMin = 1;
        this.redrawImg.src  = this.image;

        this.redraws.push(this.redrawImg);

        // text
        this.text.font     = "Cambria";
        this.text.align    = "left";
        this.text.baseline = "middle";
        this.text.color    = "#000000";

        this.redrawTxt.type = "text";
        this.redrawTxt.xMin = 1.2;
        this.redrawTxt.y    = 0.5;
        this.redrawTxt.h    = 0.8;
        this.redrawTxt.src  = this.text;

        this.redraws.push(this.redrawTxt);
    }

    resize() {
        this.canvas.checked.normal.resize(this.width, this.height);
        this.canvas.checked.hover.resize(this.width, this.height);
        this.canvas.checked.pressed.resize(this.width, this.height);

        this.canvas.unchecked.normal.resize(this.width, this.height);
        this.canvas.unchecked.hover.resize(this.width, this.height);
        this.canvas.unchecked.pressed.resize(this.width, this.height);

        this.canvas.shadow.resize(this.width, this.height);

        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        let coordsSrc = {left: 0, top: 0, width: 0, height: 0},
            coordsDst = {left: 0, top: 0, width: this.width, height: this.height},
            i, l,
            redraw, image, text;

        this.canvas.checked.normal.clear();
        this.canvas.checked.hover.clear();
        this.canvas.checked.pressed.clear();

        this.canvas.unchecked.normal.clear();
        this.canvas.unchecked.hover.clear();
        this.canvas.unchecked.pressed.clear();

        for (i = 0, l = this.redraws.length; i < l; i++) {
            redraw = this.redraws[i];

            redraw.updateCoords(coordsDst);

            switch (redraw.type) {
                case "image":
                    image = redraw.src;

                    if (!image.isLoaded)
                        return false;

                    coordsSrc.width  = image.width;
                    coordsSrc.height = image.height;
                    coordsSrc.top    = redraw.srcRow * coordsSrc.height;
                    coordsSrc.left   = 2 * coordsSrc.width;     // in the image file

                    this.canvas.checked.normal.drawImage(image, coordsSrc, redraw);

                    coordsSrc.left += coordsSrc.width;

                    this.canvas.unchecked.normal.drawImage(image, coordsSrc, redraw);
                    break;
                case "text":
                    text        = redraw.src;
                    text.height = redraw.height;

                    text.prepare(this.canvas.checked.normal);
                    text.draw(this.canvas.checked.normal, redraw, this.caption);

                    text.prepare(this.canvas.unchecked.normal);
                    text.draw(this.canvas.unchecked.normal, redraw, this.caption);
            }
        }

        this.canvas.checked.hover.drawImage(this.canvas.checked.normal);
        this.canvas.checked.hover.brighten(this.brightness.hover);
        this.canvas.checked.pressed.drawImage(this.canvas.checked.normal);
        this.canvas.checked.pressed.brighten(this.brightness.pressed);

        this.canvas.unchecked.hover.drawImage(this.canvas.unchecked.normal);
        this.canvas.unchecked.hover.brighten(this.brightness.hover);
        this.canvas.unchecked.pressed.drawImage(this.canvas.unchecked.normal);
        this.canvas.unchecked.pressed.brighten(this.brightness.pressed);

        this.canvas.shadow.drawImage(this.canvas.unchecked.normal);
        this.canvas.shadow.makeShadow(SHADOW.transp);

        this.isRedrawn = true;
    }

    draw(canvas) {
        let coordsSrc = {left: 0, top: 0, width: this.width, height: this.height},
            canvasSrc = this.isChecked ? this.canvas.checked : this.canvas.unchecked;

        if (this.isPressed)
            canvas.drawImage(canvasSrc.pressed, coordsSrc, this);
        else if (this.isHover)
            canvas.drawImage(canvasSrc.hover, coordsSrc, this);
        else
            canvas.drawImage(canvasSrc.normal, coordsSrc, this);
    }

    drawShadow(canvas) {
        canvas.drawImage(this.canvas.shadow,
                         {left: 0, top: 0, width: this.width, height: this.height},
                         {left: this.left - this.getShadowLeft(this.left, this.width), top: this.top - this.getShadowTop(this.top, this.height), width: this.width, height: this.height});
    }

    // onClick() {
    //     this.isChecked = !this.isChecked;
    // }
}


export { BKengineRadioBox };
