
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";
import { MENU_BAR } from "./menu bar.js";


const ITEMS_PER_PAGE = {verNarrow: 10, verWide: 14, horNarrow: 16, horWide: 16},
      PIV_Y_START    = 2;


class SelectBibleBook extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item         = null;
        this.user         = {lang: "", denom: ""};        

        this.itemsPerPage = 0;
        this.pageI        = 0;

        this.selected     = "";

        this.isInitiated  = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items,
            bookI = 1,
            book;

        this.itemsPerPage = ITEMS_PER_PAGE[BK_ENGINE.viewPort.orientation];

        // items
        this.item = {mainFrame: ITEMS.getByName("bible_upCenter"),
                     menuBox:   ITEMS.getByName("bible_menuBox"),
                     frame:     ITEMS.getByName("bible_menuSelectBibleBook"),
                     item1:     ITEMS.getByName("bible_menuSelectBibleBook_item1"),
                     item2:     ITEMS.getByName("bible_menuSelectBibleBook_item2"),
                     allBtns:   [],       // buttons with bible books' names
                     btns:      []};      // only currently used

        // buttons
        this.item.allBtns = [{}];           // book 0

        do {
            book = GW_BIBLE.books.getByI(bookI++);

            this.init_addBtn(1, book.tag);
        } while (book.tag != "Rv");

        this.init_addBtn(0, "OT");
        this.init_addBtn(0, "NT");

        ITEMS.remove(this.item.item1);
        ITEMS.remove(this.item.item2);

        this.isInitiated = true;
    }

    init_addBtn(itemI, info) {
        let item = itemI == 0 ? this.item.item1 : this.item.item2;
        let btn  = BK_ENGINE.items.clone(item);

        btn.info = info;

        this.item.allBtns.push(btn);
    }

    start2() {
        !this.isInitiated && this.init();

        this.selected = "";

        if (this.user.lang != GW_BIBLE.user.lang || this.user.denom != GW_BIBLE.user.denom) {
            this.user.lang  = GW_BIBLE.user.lang;
            this.user.denom = GW_BIBLE.user.denom;

            this.reset();
        }

        this.show(true);
        MENU_BAR.reset("selectBibleBook");
    }

    end2(oSelected) {
        this.selected = oSelected || this.selected;

        this.show(false);
    }

    reset() {
        let emptyObj = {isVisible: false, info: "OT"},
            bookI    = 0,
            row      = 0,
            allBtnsL = this.item.allBtns.length,
            btn;

        this.item.btns = [];

        // OLD TESTAMENT
        this.reset_addBtn(this.item.allBtns[allBtnsL - 2], row++);
        this.item.btns.push(emptyObj);

        row++;

        // books of Old Testament
        do {
            btn = this.item.allBtns[GW_BIBLE.books.denomIndexes[bookI]];

            if (btn.info != "Mt") {
                this.reset_addBtn(btn, row);

                if (++row >= this.itemsPerPage)
                    row = 0;
                bookI++;
            }
        } while (btn.info != "Mt");

        // New Testament
        while (row % this.itemsPerPage > 0) {     // skip to next page
            row++;

            this.item.btns.push(emptyObj);
        }

        this.reset_addBtn(this.item.allBtns[allBtnsL - 1], 0);  // NT
        this.item.btns.push(emptyObj);

        row = 2;

        // books of Old Testament
        do {
            btn = this.item.allBtns[GW_BIBLE.books.denomIndexes[bookI++]];

            this.reset_addBtn(btn, row);

            if (++row >= this.itemsPerPage)
                row = 0;
        } while (bookI < GW_BIBLE.books.denomIndexes.length);
    }

    reset_addBtn(btn, row) {
        btn.pivY = PIV_Y_START + row;
    
        switch (btn.info) {
            case "OT":
            case "NT":
                btn.caption = GW_BIBLE.parts.getByTag(btn.info).name;
                break;
            default:
                btn.caption = GW_BIBLE.books.getByTag(btn.info).name;
        }

        this.item.btns.push(btn);
    }

    update() {
        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "bible_menuSelectBibleBook_item1":
            case "bible_menuSelectBibleBook_item2":
                this.end(btn.info);
        }

        switch (MENU_BAR.selected) {
            case "prev":
                return this.turnPage(-1);
            case "next":
                return this.turnPage(1);
            case "quit":
                this.end("quit");
        }
    }

    turnPage(value) {
        let pagesNm = Math.floor(Math.max(0, this.item.btns.length - 1) / this.itemsPerPage);

        this.pageI += value;
        this.pageI = Math.max(0, Math.min(pagesNm, this.pageI));

        this.refresh();
    }

    show(isVisible) {
        let i;

        this.item.mainFrame.isVisible = this.item.menuBox.isVisible = this.item.frame.isVisible = isVisible;

        if (isVisible) {
            for (i = 0; i < this.item.allBtns.length; i++)
                this.item.allBtns[i].isVisible = false;

            this.refresh();
        }
    }

    refresh() {
        let i, l, item;

        for (i = 0, l = this.item.btns.length; i < l; i++) {
            item           = this.item.btns[i];
            item.isVisible = (Math.floor(i / this.itemsPerPage) == this.pageI);
            item.isRedrawn = false;
        }

        i = this.pageI + 1;
        l = Math.floor(Math.max(0, this.item.btns.length - 1) / this.itemsPerPage) + 1;

        MENU_BAR.isPrevEnabled = i > 1;
        MENU_BAR.isNextEnabled = i < l;
        MENU_BAR.setItemCaption("info", "" + i + " / " + l);
    }
}

export const SELECT_BIBLE_BOOK = BK_ENGINE.tasks.add(new SelectBibleBook(BK_ENGINE.tasks));
