
import { BK_ENGINE, Coords } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "./_bible g0002 play display.js";
import { PLAY } from "../_bible g0002 play.js";


const IMAGE_I   = 1,
      COORDS    = {x: {verNarrow: 0.9, verWide: 0.2, horNarrow: 0.2, horWide: 0.2},     // of PLAYFIELD.tileSize
                   w: 0.3},
      GAP       = 0.25,               // between two "attempts"
      OFF_BLACK = 0.5;


class DisplayAttempts {
    constructor() {
        this.canvas     = BK_ENGINE.canvases.addNew();
        this.coords     = {main: new Coords(),
                           src:  new Coords()};
        this.gap        = 0;

        this.attemptsNm = 0;
    }

    start() {
        this.attemptsNm = LOGICS.attemptsNm - 1;
    }

    update() {
        this.refresh();
    }

    resize() {
        let s    = PLAYFIELD.tileSize;
        let size = Math.round(s * COORDS.w),
            x    = COORDS.x[BK_ENGINE.viewPort.orientation];

        // coords
        this.coords.main.resize(size, size);

        this.coords.main.left = PLAYFIELD.coords.main.left + Math.round(s * x);
        this.coords.main.top  = PLAYFIELD.coords.main.top  + Math.round(s / 2 - size / 2);

        this.coords.src.resize(size, size);

        this.gap = Math.round(GAP * size);

        // canvas
        this.canvas.resize(size * 2, size);

        this.redraw(size);
    }

    redraw(size) {
        let img = DISPLAY.image.balls;

        this.canvas.clear();

        // attempt "on"
        BK_ENGINE.canvases.drawImageQuality(img, this.canvas, this.coords.src, IMAGE_I);

        // attempt "off"
        this.canvas.drawImage(this.canvas, this.coords.src, {left: size, top: 0, width: size, height: size});
        this.canvas.makeShadow(OFF_BLACK, {left: size, top: 0, width: size, height: size});
    }

    refresh() {
        let i = 0;

        for (i; i < LOGICS.attemptsNm - 1; i++)
            this.refresh_one(i, true);

        for (; i < this.attemptsNm; i++)
            this.refresh_one(i, false);
    }

    refresh_one(i, isOn) {
        let left = this.coords.main.left + i * (this.coords.main.width + this.gap),
            size = this.coords.main.width;

        if (isOn)
            DISPLAY.canvas.pgd.drawImage(this.canvas, this.coords.src, {left: left, top: this.coords.main.top, width: size, height: size});
        else
            DISPLAY.canvas.pgd.drawImage(this.canvas, {left: size, top: 0, width: size, height: size}, {left: left, top: this.coords.main.top, width: size, height: size});
    }
}

export const DISPLAY_ATTEMPTS = new DisplayAttempts();
