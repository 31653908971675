
import { PLAY_LOGICS as LOGICS } from "./_bible g0001 play logics.js";


class Helper {
    constructor(X) {
        this.X         = X;
        this.Y         = 0;
        this.char      = "";
        this.letterI   = -1;

        this.isCorrect = false;
    }
}


class LogicsHelpers {
    constructor() {
        this.activeNm    = 0;
        this.all         = [];

        this.isInitiated = false;
    }

    getByI(i) {
        return this.all[i];
    }

    getIdFirstEmpty() {
        let i = -1;

        while (this.all[++i].char.length > 0) {};

        return i;
    }

    getFirstEmptyOrNotCorrect() {
        let i = -1;

        while (this.all[++i].isCorrect) {};

        return this.all[i];
    }

    init() {
        let i;

        for (i = 0; i < LOGICS.lettersMax; i++)
            this.all.push(new Helper(i));

        this.isInitiated = true;
    }

    start() {
        let i, l, helper;

        !this.isInitiated && this.init();

        for (i = 0, l = this.all.length; i < l; i++) {
            helper = this.all[i];

            helper.isCorrect = false;
            helper.char      = "";
        }

        this.activeNm = 0;
    }

    reset(oChars = "") {
        let i, l, helper;

        this.activeNm = oChars.length;

        for (i = 0, l = this.activeNm; i < l; i++) {
            helper = this.getByI(i);

            helper.isCorrect = false;
            helper.char      = "";
            helper.letterI   = -1;
        }
    }
}

export const LOGICS_HELPERS = new LogicsHelpers();
