
import { BK_ENGINE } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";


class DisplayProgress {
    constructor() {
        this.wordsToGuessNmMax = 0;
        this.wordsToGuessNm    = 0;

        this.itemProgressBar   = null;

        this.isInitiated       = false;
    }

    init() {
        this.itemProgressBar = {bgd: BK_ENGINE.items.getByName("bible_g0001_play_progressBarBgd"),
                                fgd: BK_ENGINE.items.getByName("bible_g0001_play_progressBarFgd")};

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.wordsToGuessNmMax = LOGICS.wordsToGuessNm;

        this.refresh();
    }

    update() {
        if (LOGICS.wordsToGuessNm != this.wordsToGuessNm) {
            this.wordsToGuessNm = LOGICS.wordsToGuessNm;

            this.refresh();
        }
    }

    show(isVisible) {
        this.itemProgressBar.bgd.isVisible = this.itemProgressBar.fgd.isVisible = isVisible;
    }

    refresh() {
        this.itemProgressBar.fgd.w         = (this.wordsToGuessNmMax - this.wordsToGuessNm) / this.wordsToGuessNmMax;
        this.itemProgressBar.fgd.isResized = false;
    }
}

export const DISPLAY_PROGRESS = new DisplayProgress();
