import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { HELP } from "../help/help.js"
import { GW_BIBLE } from "../gw bible/_gw bible.js";
import { GW_USER } from "../gw user/_gw user.js";
import { GW_MAIN } from "./_gw main.js";
import { MENU_ENTER } from "./menu enter.js";
import { MENU_INFO } from "./menu info/_menu info.js";
import { MENU_LANG } from "./menu lang.js";
import { MENU_NEWS } from "./menu news.js";
import { MENU_USER } from "./menu user/_menu user.js";


const MAIN_TASK = {userDataLoading: null,
                   menuEnter:       null,
                   menuInfo:        null,
                   menuLang:        null,
                   menuNews:        null,
                   menuUser:        null};


class MTuserDataLoading extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        GW_MAIN.item.userDataLoading.isVisible = true;
    }

    update() {
        if (!GW_USER.isDataLoading) {
            GW_MAIN.item.userDataLoading.isVisible = false;

            this.end();
        }
    }
}

MAIN_TASK.userDataLoading = BK_ENGINE.tasks.add(new MTuserDataLoading(BK_ENGINE.tasks));


class MTmenuEnter extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        MENU_ENTER.start();
    }

    update() {
        if (MENU_ENTER.isActive)
            return;

        this.end();

        switch (MENU_ENTER.selected) {
            case "enterGuest":
                return GW_MAIN.end("enterGuest");
            case "enterEmail":
                if (GW_USER.isLoggedIn)
                    return GW_MAIN.end("enterEmail");
                return MAIN_TASK.menuUser.start("signUp_email");
            case "enterWallet":
                if (GW_USER.isLoggedIn)
                    return GW_MAIN.end("enterWallet");
                return MAIN_TASK.menuUser.start("signUp_wallet");
            case "menuInfo":
            case "menuLang":
            case "menuNews":
            case "menuUser":
                MAIN_TASK[MENU_ENTER.selected].start();
        }
    }
}

MAIN_TASK.menuEnter = BK_ENGINE.tasks.add(new MTmenuEnter(BK_ENGINE.tasks));


class MTmenuInfo extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        MENU_INFO.start();
    }

    update() {
        if (MENU_INFO.isActive)
            return;

        this.end();

        switch (MENU_INFO.selected) {
            case "menuEnter":
            case "menuLang":
            case "menuNews":
            case "menuUser":
                MAIN_TASK[MENU_INFO.selected].start();
        }
    }
}

MAIN_TASK.menuInfo = BK_ENGINE.tasks.add(new MTmenuInfo(BK_ENGINE.tasks));


class MTmenuLang extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        MENU_LANG.start();
    }

    update() {
        if (MENU_LANG.isActive)
            return;

        this.end();

        if (GW_MAIN.languages.tags.includes(MENU_LANG.selected)) {
            GW_USER.lang = MENU_LANG.selected;

            return GW_MAIN.update_setLang();
        }

        switch (MENU_LANG.selected) {
            case "menuEnter":
            case "menuInfo":
            case "menuNews":
            case "menuUser":
                MAIN_TASK[MENU_LANG.selected].start();
        }
    }
}

MAIN_TASK.menuLang = BK_ENGINE.tasks.add(new MTmenuLang(BK_ENGINE.tasks));


class MTmenuNews extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        MENU_NEWS.start();
        GW_MAIN.refresh_btnNews();
    }

    update() {
        if (MENU_NEWS.isActive)
            return;

        this.end();
        GW_MAIN.refresh();

        switch (MENU_NEWS.selected) {
            case "menuEnter":
            case "menuInfo":
            case "menuLang":
                return MAIN_TASK[MENU_NEWS.selected].start();
            case "bibleAddedScoringSystem":
                return HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userScoringSystem").trim());
            case "bibleGame2released":
                GW_BIBLE.presetSet("game0002");
                return this.update_enter();
            case "mainAddPersonalData":
                return MAIN_TASK.menuUser.start("menuInfoClose menuSettingsOpen menuLogInClose");
            default:
                if (MENU_NEWS.selected.indexOf("mainAddedNewLang") == 0)
                    MAIN_TASK.menuLang.start();
        }
    }

    update_enter() {
        if (GW_USER.isNewUser)
            return MAIN_TASK.menuEnter.start();
    
        if (GW_USER.isLoggedIn)
            return GW_USER.isWallet ? GW_MAIN.end("enterWallet") : GW_MAIN.end("enterEmail");
    
        GW_MAIN.end("enterGuest");
    }
}

MAIN_TASK.menuNews = BK_ENGINE.tasks.add(new MTmenuNews(BK_ENGINE.tasks));


class MTmenuUser extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2(req = "") {
        MENU_USER.start(req);
    }

    update() {
        if (MENU_USER.isActive)
            return;

        this.end();
        GW_MAIN.refresh();

        switch (MENU_USER.selected) {
            case "menuEnter":
            case "menuLang":
                MAIN_TASK[MENU_USER.selected].start();
        }
    }
}

MAIN_TASK.menuUser = BK_ENGINE.tasks.add(new MTmenuUser(BK_ENGINE.tasks));


export { MAIN_TASK };
