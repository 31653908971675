
import { BK_ENGINE, Task, Coords, BKengineTextContent, BKengineTextDisplay } from "../bk engine/_bk engine.js";
import { isFirstDateEarlier } from "../bk utils/_bk utils.js";
import { GW_USER } from "../gw user/_gw user.js"
import { GW_MAIN } from "./_gw main.js"


const PATH = "gw main/lang/";

const LINE_WIDTH = {ext: 0.01, int: 0.003},
      PADDING    = 0.02,
      COLOR      = {line:    "#dddddd",
                    bgd:     "#000000cc",
                    item:    "#00000088",
                    hover:   "#ffffff44",
                    pressed: "#00000044",
                    read:    "#00000033"},
      BTN_SIZE   = 0.5,
      BTN_H      = {verNarrow: 0.1, verWide: 0.09, horNarrow: 0.08, horWide: 0.07};


class DispItem {
    constructor() {
        this.name        = "";
        this.content     = "";
        this.date        = {year: 0, month: 0, day: 0, hours: 0, minutes: 0};
        this.isRead      = false;
        this.textContent = new BKengineTextContent();
        this.textDisplay = new BKengineTextDisplay();
        this.coords      = new Coords();
    }
}

class DispBtn {
    constructor(name) {
        this.name   = name;
        this.coords = new Coords();
        this.canvas = BK_ENGINE.canvases.addNew();
    }
}


class MenuNews extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE       = {loadingNews: 10, loadingImages: 20, main: 30};
        this.state       = 0;

        this.file        = BK_ENGINE.files.addNew();
        this.image       = null;
        this.canvas      = null;
        this.text        = null;
        this.texts       = [];
        this.item        = null;
        this.coords      = {main:  new Coords(),
                            title: new Coords(),
                            item:  new Coords(),
                            tmp:   new Coords()};
        this.lineWidth   = {ext: 0, int: 0};
        this.padding     = 0;
        this.items       = [];
        this.itemIstart  = 0;
        this.btn         = {up:   new DispBtn("up"),
                            down: new DispBtn("down"),
                            quit: new DispBtn("quit")};
        this.mouse       = {btns:  {mouseDown: -1, hover: 0, pressed: 0, clicked: 0},
                            items: {mouseDown: -1, hover: 0, pressed: 0, clicked: 0}};
        this.selected    = "";

        this.isOnceShown          = false;      // at least
        this.isScrollDownPossible = false;
        this.isInitiated          = false;
    }

    getNewestDate() {
        if (this.items.length == 0)
            return new Date();

        let d = this.items[0].date;
        
        return new Date(d.year, d.month, d.day, d.hours, d.minutes);
    }

    init() {
        let T = BK_ENGINE.texts;

        this.image = {arrows: BK_ENGINE.images.getByName("main_simpleControls")};

        this.canvas = {main: BK_ENGINE.canvases.getByName("main_menuNews_select"),
                       bgd:  BK_ENGINE.canvases.addNew()};

        this.text = {titleBig: T.getByName("main_menuNews_title")};

        this.texts.push(T.getByName("main_menuNews_itemDate"),
                        T.getByName("main_menuNews_itemTitle"),
                        T.getByName("main_menuNews_itemContent"));

        this.item = {main: BK_ENGINE.items.getByName("main_menuNews_select")};

        this.isInitiated = true;
    }

    start2(isShow = true) {
        !this.isInitiated && this.init();

        if (isShow)
            this.isOnceShown = true;

        this.items    = [];
        this.selected = "";
        this.state    = this.STATE.loadingNews;

        this.file.path     = PATH + GW_USER.lang;
        this.file.fileName = GW_MAIN.file.filesListLang.getCaption("news_" + GW_USER.denom).trim();

        this.file.load();
        this.show(isShow);
    }

    end2(selected = "") {
        this.selected = selected;

        this.show(false);
    }

    loadingNews() {
        if (!this.file.isLoaded)
            return;

        this.loadingNews_initItems();

        if (!this.item.main.isVisible)      // this function was called only for loading the news
            return this.end();

        this.state = this.STATE.loadingImages;
    }

    loadingNews_initItems() {
        let lines = this.file.data.split("[*"),
            eol   = "\r\n",
            lineI = 0,
            arrName, arrData, arrTitle, arrContent;

        // look for the first "name"
        while (lineI < lines.length && lines[lineI].indexOf("name") == -1) {
            lineI++;
        }

        // init arrays
        while (lineI < lines.length) {
            arrName    = lines[lineI++].split(eol);
            arrData    = lines[lineI++].split(eol);
            arrTitle   = lines[lineI++].split(eol);
            arrContent = lines[lineI].indexOf("content") == 0 ? lines[lineI++].split(eol) : [];

            this.loadingNews_initItems_one(arrName, arrData, arrTitle, arrContent);
        }
    }

    loadingNews_initItems_one(arrName, arrDate, arrTitle, arrContent) {
        if (arrName.length < 2 || arrDate.length < 2 || arrTitle < 2) {
            console.warn("Data lines too short!");

            return;
        }

        let item    = new DispItem(),
            date    = arrDate[1],
            content = "",
            i, s, title;

        item.name       = arrName[1];
        item.date.year  = parseInt(date.substring(0, 4));
        item.date.month = Math.max(0, parseInt(date.substring(4, 6) - 1));
        item.date.day   = parseInt(date.substring(6, 8));

        if (!isNaN(i = parseInt(date.substring(8, 10))))
            item.date.hours = i;
        if (!isNaN(i = parseInt(date.substring(10, 12))))
            item.date.minutes = i;

        item.title = arrTitle[1];
        
        title = GW_USER.getStringFromDate(item.date.day, item.date.month + 1, item.date.year) + "\n[*1*]" + item.title;

        if (arrContent.length > 0) {
            for (i = 1; i < arrContent.length; i++)
                content += arrContent[i] + "\n";

            title += "\n[*2*]";
        }

        item.textContent.texts = this.texts;

        item.textContent.init(title.trim() + content.trim());

        item.isRead = isFirstDateEarlier(item.date, GW_USER.lastSession.date);

        this.items.push(item);
    }

    loadingImages() {
        if (!this.image.arrows.isLoaded || this.item.main.width == 0 || this.item.main.height == 0)
            return;

        this.resize();

        this.state = this.STATE.main;
    }

    update() {
        switch (this.state) {
            case this.STATE.loadingNews:
                return this.loadingNews();
            case this.STATE.loadingImages:
                return this.loadingImages();
            case this.STATE.main:
                this.main();
        }
    }

    main() {
        let btn = BK_ENGINE.clicked;

        if (this.item.main.width != this.canvas.main.width || this.item.main.height != this.canvas.main.height)
            this.resize();

        switch (btn.name) {
            case "main_menuNews_menu":
                return this.end();
            case "main_menuNews_select":
                break;
            default:
                if (btn.name != "")
                    this.end(GW_MAIN.checkMenus());
        }

        this.refresh();

        // check buttons clicked
        this.main_checkMouse_btns();
 
        switch (this.mouse.btns.clicked) {
            case "up":
                return this.itemIstart = Math.max(0, this.itemIstart - 1);
            case "down":
                if (this.isScrollDownPossible)
                    this.itemIstart++;
                return;
            case "quit":
                return this.end("quit");
            default:
                if (BK_ENGINE.input.mouseWheel < 0)
                    return this.itemIstart = Math.max(0, this.itemIstart - 1);
                if (BK_ENGINE.input.mouseWheel > 0 && this.isScrollDownPossible)
                    return this.itemIstart++;
        }

        // check items clicked
        this.mouse.items.hover = this.mouse.items.pressed = -1;

        if (this.mouse.btns.hover == -1) {
            this.main_checkMouse_items();

            if (this.mouse.items.clicked)
                return this.end(this.mouse.items.clicked.name);
        }
    }

    main_checkMouse_btns() {
        let i = 0,
            btn;

        this.mouse.btns.hover   = this.mouse.btns.pressed = -1;
        this.mouse.btns.clicked = "";

        for (const prop in this.btn) {
            btn = this.btn[prop];

            if (BK_ENGINE.input.isMouseInsideRect({left: this.item.main.left + btn.coords.left, top: this.item.main.top + btn.coords.top, width: btn.coords.width, height: btn.coords.height})) {
                this.mouse.btns.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.btns.mouseDown == -1)
                        this.mouse.btns.mouseDown = i;

                    this.mouse.btns.pressed = i;
                } else {
                    if (this.mouse.btns.mouseDown == i)
                        this.mouse.btns.clicked   = btn.name;

                    this.mouse.btns.mouseDown = -1;
                }

                return;
            }

            i++;
        }
    }

    main_checkMouse_items() {
        let i, item;

        this.mouse.items.clicked = null;
        this.coords.item.left    = this.item.main.left;
        this.coords.item.top     = this.coords.title.height;

        for (i = this.itemIstart; i < this.items.length; i++) {
            item = this.items[i];

            this.coords.item.height = item.coords.height;

            if (BK_ENGINE.input.isMouseInsideRect(this.coords.item)) {
                this.mouse.items.hover = i;

                if (BK_ENGINE.input.isMouseDown) {
                    if (this.mouse.items.mouseDown == -1)
                        this.mouse.items.mouseDown = i;

                    this.mouse.items.pressed = i;
                } else {
                    if (this.mouse.items.mouseDown == i)
                        this.mouse.items.clicked   = item;

                    this.mouse.items.mouseDown = -1;
                }

                return;
            }

            this.coords.item.top += item.coords.height;
        }
    }

    resize() {
        let width  = this.item.main.width,
            height = this.item.main.height,
            btnH   = BTN_H[BK_ENGINE.viewPort.orientation],
            min;

        min = this.coords.main.min = width < height ? width : height;

        this.lineWidth.ext = Math.max(1, Math.round(min * LINE_WIDTH.ext));
        this.lineWidth.int = Math.max(1, Math.round(min * LINE_WIDTH.int));
        this.padding       = Math.max(1, Math.round(min * PADDING));

        this.coords.main.resize(width, height);
        this.coords.title.resize(width, Math.round(min * btnH));

        this.coords.item.width = width;

        this.canvas.main.resize(width, height);
        this.canvas.bgd.resize(width, height);

        this.resize_items();
        this.resize_btns();
        this.redraw();
    }

    resize_items() {
        let i, item;

        for (i = 0; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.reset();
            item.textDisplay.resize(this.coords.main.width, this.coords.main.height);
        }
    }

    resize_btns() {
        let width  = Math.round(this.coords.main.width / 3),
            height = this.coords.title.height,
            top    = this.coords.main.height - this.coords.title.height;

        // btn up
        this.btn.up.canvas.resize(width, height);
        this.btn.up.coords.resize(width, height);
        this.btn.up.coords.top  = top;

        // btn down
        this.btn.down.canvas.resize(width, height);
        this.btn.down.coords.resize(width, height);
        this.btn.down.coords.left = width;
        this.btn.down.coords.top  = top;

        // btn quit
        this.btn.quit.coords.left = this.coords.main.width - width;

        width = this.coords.main.width - this.btn.quit.coords.left;

        this.btn.quit.canvas.resize(width, height);
        this.btn.quit.coords.resize(width, height);
        this.btn.quit.coords.top = top;
    }

    redraw() {
        this.canvas.bgd.drawRect(this.coords.main,  COLOR.bgd, true);

        this.text.titleBig.calcHeight(this.coords.title.height);
        this.text.titleBig.prepare(this.canvas.bgd);
        this.text.titleBig.draw(this.canvas.bgd, {left: Math.round(this.coords.title.width / 2), top: this.coords.title.top + Math.round(this.coords.title.height / 2)}, GW_MAIN.file.lang.getCaption("newsTitleBig"));

        this.redraw_items();
        this.redraw_btns();
    }

    redraw_items() {
        let i, item;

        for (i = 0; i < this.items.length; i++) {
            item = this.items[i];

            item.textDisplay.addTextContent(item.textContent);
            item.coords.resize(this.item.main.width, item.textDisplay.effectiveHeight + this.padding * 2);
        }
    }

    redraw_btns() {
        let i = 0,
            btn, left, top, size;

        for (const prop in this.btn) {
            btn = this.btn[prop];

            size = Math.round(BTN_SIZE * btn.coords.height);
            left = Math.round((btn.coords.width - size) / 2);
            top  = Math.round((btn.coords.height - size) / 2);

            btn.canvas.clear();
            BK_ENGINE.canvases.drawImageQuality(this.image.arrows, btn.canvas, {left: left, top: top, width: size, height: size}, i++);
        }
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;
    }

    refresh() {
        this.canvas.main.clear();
        this.canvas.main.drawImage(this.canvas.bgd);
        this.refresh_items();
        this.refresh_btns();
        this.canvas.main.drawRect(this.coords.main, COLOR.line, false, this.lineWidth.ext);

        this.item.main.isRedrawn = false;
    }

    refresh_items() {
        let i, l, item, isHover, isPressed;

        this.isScrollDownPossible = false;
        this.coords.tmp.left      = 0;
        this.coords.tmp.top       = this.coords.title.height;
        this.coords.tmp.width     = this.coords.title.width;

        for (i = this.itemIstart, l = this.items.length; i < l; i++) {
            item    = this.items[i];
            isHover = isPressed = false;

            if (i == this.mouse.items.pressed)
                isPressed = true;
            else if (!BK_ENGINE.isTouchScreen && i == this.mouse.items.hover)
                isHover = true;

            this.coords.tmp.height = item.coords.height;

            this.canvas.main.drawRect(this.coords.tmp, COLOR.item, true);
            item.textDisplay.draw(this.canvas.main, {left: this.coords.tmp.left + this.padding, top: this.coords.tmp.top + this.padding, width: item.coords.width - this.padding * 4, height: item.coords.height - this.padding * 2});
            this.refresh_rect(this.coords.tmp, isHover, isPressed);

            if (item.isRead)
                this.refresh_rect(this.coords.tmp, false, false, true);

            this.canvas.main.drawRect(this.coords.tmp, COLOR.line, false, this.lineWidth.int);

            this.coords.tmp.top += item.coords.height;

            if (this.coords.tmp.top >= this.btn.up.coords.top)
                return this.isScrollDownPossible = true;
        }
    }

    refresh_btns() {
        let i = 0,
            btn, isHover, isPressed;

        for (const prop in this.btn) {
            btn     = this.btn[prop];
            isHover = isPressed = false;

            if (i == this.mouse.btns.pressed)
                isPressed = true;
            else if (!BK_ENGINE.isTouchScreen && i == this.mouse.btns.hover)
                isHover = true;

            this.canvas.main.drawImage(btn.canvas, {left: 0, top: 0, width: btn.coords.width, height: btn.coords.height}, btn.coords);
            this.refresh_rect(btn.coords, isHover, isPressed);
            this.canvas.main.drawRect(btn.coords, COLOR.line, false, this.lineWidth.int);

            i++;
        }
    }

    refresh_rect(coords, isHover, isPressed, isRead) {
        if (isHover)   return this.canvas.main.drawRect(coords, COLOR.hover, true);
        if (isPressed) return this.canvas.main.drawRect(coords, COLOR.pressed, true);
        if (isRead)    return this.canvas.main.drawRect(coords, COLOR.read, true);
    }
}

export const MENU_NEWS = BK_ENGINE.tasks.add(new MenuNews(BK_ENGINE.tasks));
