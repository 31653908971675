
import { BKengineText } from "../primitives/text.js"
import { BKengineItem } from "./_item.js"


class BKengineTextInput extends BKengineItem {
    constructor() {
        super();

        this.type     = "textInput";
        this.caption  = "";

        this.text     = new BKengineText();

        this.divInput = document.createElement("div");
        this.input    = document.createElement("input");

        this.init();
    }

    init() {
        this.divInput.display       = "fixed";
        this.divInput.style.cssText = "position: absolute; left: 100px; width: 200px; z-index: 100;";

        document.body.appendChild(this.divInput);

        this.input.type  = "text";
        this.input.style = "display: block;";

        this.divInput.appendChild(this.input);

        this.text.font     = "Cambria";
        this.text.align    = "center";
        this.text.baseline = "middle";
        this.text.color    = "#000000";
    }

    setVisible(value) {         // must call from outside
        this.divInput.style.display = value ? "block" : "none";
    }

    // get isVisible() {
    //     return this.divInput.style.display == "block";
    // }

    set isPassword(value) {
        this.input.type = value ? "password" : "text";
    }

    get isPassword() {
        return this.input.type == "password";
    }

    set content(value) {
        this.input.value = value;
    }

    get content() {
        return this.input.value;
    }

    set isDisabled(value) {
        this.input.disabled = value;
    }

    get isDisabled() {
        return this.input.disabled;
    }

    set color(value) {
        // this.text.color = set(textInput.captionI, "fillStyle", value);
    }

    get color() {
    }

    set maxLength(value) {
        this.input.maxLength = value;
    }

    get maxLength() {
        return this.input.maxLength;
    }
    // set isVisible(value) {
    //     // bk_engine.item.set(textInput.itemI, "isHidden", value);
    //     // textInput.divInput.style.display = value ? "none" : "block";
    // }

    // get isVisible() {        
    // }

    // case "value":
    //     textInput.input.value = value;
    //     break;

    resize() {
        this.text.height = this.height;

        this.divInput.style.height    = this.height + "px";
        this.input.style.width        = this.width  + "px";
        this.input.style["font-size"] = this.height + "px";

        this.isResized = true;
        this.isRedrawn = false;
    }

    update() {
        this.updateParentMin();

        if (this.isUpdateSize)
            this.updateSize();

        if (!this.isResized)
            this.resize();

        if (!this.isRedrawn)
            this.redraw();

        if (this.isUpdatePos)
            this.updatePos();

        this.divInput.style.left = this.left + "px";
        this.divInput.style.top  = this.top  + "px";
    }

    draw(canvas) {
        let coords = {left:   this.left + Math.round(this.width / 2),
                      top:    Math.round(this.top - this.height),
                      width:  this.width,
                      height: this.height};

        this.text.prepare(canvas);
        this.text.draw(canvas, coords, this.caption);
    }
}


export { BKengineTextInput };
