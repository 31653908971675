
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"


class InfoBox extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_infoBox"),
                     content:   I.getByName("main_infoBox_content"),
                     btn:       {gotIt: I.getByName("main_infoBox_btnGotIt"),
                                 close: I.getByName("main_infoBox_btnClose")}};

        this.isInitiated = true;
    }

    updateContent(content) {
        if (this.item)
            this.item.content.content = content;
    }

    start2(content = "", btnName = "") {     // btn: "gotIt" or "close"
        !this.isInitiated && this.init();

        this.updateContent(content);
        this.show(true, btnName);
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (BK_ENGINE.clicked.name) {
            case "main_infoBox_btnGotIt":
            case "main_infoBox_btnClose":
                this.end();
        }
    }

    show(isVisible, btnName) {
        if (!this.item)         // sometimes it could be not initiated
            return;

        this.item.mainFrame.isVisible = isVisible;

        if (isVisible) {
            this.item.btn.gotIt.isVisible = btnName == "gotIt";
            this.item.btn.close.isVisible = btnName == "close";
        }
    }
}

export const INFO_BOX = BK_ENGINE.tasks.add(new InfoBox(BK_ENGINE.tasks));
