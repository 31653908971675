
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";


const PRESET = {mainRead:        ["quit", "switchReadPlay", "prev", "next"],
                mainPlay:        ["quit", "switchReadPlay", "prev", "next"],
                bibleIntro:      ["quit", "prev", "next"],
                holyBible:       ["quit", "prev", "next", "selectBibleBook", "selectChapter"],
                selectBibleBook: ["quit", "prev", "next", "info"],
                selectChapter:   ["quit", "prev", "next", "info"],
                gameMenu:        ["quit", "play"]};


class MenuBar extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
        this.selected    = "";
    }

    set isPrevEnabled(value) {
        this.item.prev.isDisabled = !value;
    }
    
    set isNextEnabled(value) {
        this.item.next.isDisabled = !value;
    }

    setItemCaption(name, caption) {
        this.item[name].caption   = caption;
        this.item[name].isRedrawn = false;
    }

    getItem(name) {
        return this.item[name];
    }

    reset(presetName, isPrevDisabled = false, isNextDisabled = false) {
        let names = PRESET[presetName],
            i, l;

        // set all buttons invisible
        for (const prop in this.item) {
            if (prop != "mainFrame") {
                this.item[prop].isVisible = false;
                this.item[prop].isRedrawn = false;
            }
        }

        // set some visible
        for (i = 0, l = names.length; i < l; i++)
            this.item[names[i]].isVisible = true;

        // left, right visible, disabled?
        this.item.prev.isDisabled = isPrevDisabled;
        this.item.next.isDisabled = isNextDisabled;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.item = {mainFrame:       ITEMS.getByName("bible_menuBar"),
                     play:            ITEMS.getByName("bible_menuBar_play"),
                     read:            ITEMS.getByName("bible_menuBar_read"),
                     prev:            ITEMS.getByName("bible_menuBar_prev"),
                     next:            ITEMS.getByName("bible_menuBar_next"),
                     selectBibleBook: ITEMS.getByName("bible_menuBar_selectBibleBook"),
                     selectChapter:   ITEMS.getByName("bible_menuBar_selectChapter"),
                     switchReadPlay:  ITEMS.getByName("bible_menuBar_switchReadPlay"),
                     quit:            ITEMS.getByName("bible_menuBar_quit"),
                     info:            ITEMS.getByName("bible_menuBar_info")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.selected = "";

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        this.selected = "";

        switch (BK_ENGINE.clicked.name) {
            case "bible_menuBar_read":
                this.selected = "read";
                break;
            case "bible_menuBar_play":
                this.selected = "play";
                break;
            case "bible_menuBar_prev":
                this.selected = "prev";
                break;
            case "bible_menuBar_next":
                this.selected = "next";
                break;
            case "bible_menuBar_selectBibleBook":
                this.selected = "selectBibleBook";
                break;
            case "bible_menuBar_selectChapter":
                this.selected = "selectChapter";
                break;
            case "bible_menuBar_switchReadPlay":
                this.selected = "switchReadPlay";
                break;
            case "bible_menuBar_quit":
                this.selected = "quit";
                break;
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const MENU_BAR = BK_ENGINE.tasks.add(new MenuBar(BK_ENGINE.tasks));
