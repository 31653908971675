
import { CANVASES } from "../groups/canvases.js";


const DEVELOP = {imagesQuality: ""};    // if empty, do nothing, otherwise: low/med/high

const IMAGES_QUALITY    = {low: 450, med: 750, high: 1200},         // value is the min of width/height (low: mobile phone, med: tablet - laptop, high: 2k, max: 4k - TO DO)
      ORIENTATION_RATIO = {verWideStart: 0.7, horWideStart: 1.3};   // width


class ViewPort {
    constructor() {
        this.width         = 0;
        this.height        = 0;
        this.centerX       = 0;
        this.centerY       = 0;
        this.min           = 0;
        this.ratio         = 0;      // width / height
        this.orientation   = "";     // wille be set to: verNarrow/verWide/horNarrow/horWide
        this.imagesQuality = "";     // low, med, high

        this.isHor         = true;   // will be set only at the very beginning
        this.isResized     = true;

        // init
        this.resize();

        window.addEventListener("resize", e => {
            this.resize();
        });

        console.log("View port, orientation: \"" + this.orientation + "\", imagesQuality: \"" + this.imagesQuality + "\"");
    }
 
    resize() {
        this.width       = window.innerWidth  - 1;    // -1! otherwise, there could appear scroll bars in some browsers :/
        this.height      = window.innerHeight - 1;
        this.centerX     = Math.round(this.width  / 2);
        this.centerY     = Math.round(this.height / 2);
        this.min         = this.width < this.height ? this.width : this.height;
        this.ratio       = this.width / this.height;
        this.isHor       = this.ratio > 1;
        this.orientation = this.resize_getOrientation();

        this.imagesQuality = this.min < IMAGES_QUALITY.low ? "low" : this.min < IMAGES_QUALITY.med ? "med" : "high";

        DEVELOP.imagesQuality.length > 0 && (this.imagesQuality = DEVELOP.imagesQuality);

        this.isResized = true;
    }

    resize_getOrientation() {
        if (this.ratio < ORIENTATION_RATIO.verWideStart) return "verNarrow";
        if (this.ratio < 1)                              return "verWide";
        if (this.ratio < ORIENTATION_RATIO.horWideStart) return "horNarrow";

        return "horWide";
    }

    update() {
        if (!this.isResized)
            return false;

        CANVASES.buffer.resize(this.width, this.height);
        CANVASES.foreground.resize(this.width, this.height);
        
        this.isResized = false;

        return true;
    }
}

export const VIEW_PORT = new ViewPort();
