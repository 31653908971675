
import { BKengineItem } from "./_item.js"


class BKengineLabel extends BKengineItem {
    constructor() {
        super();

        this.type    = "label";

        this.text    = null;    // must be set from outside to a BKengineText
        this.caption = "";
    }

    resize() {
        this.text.height = this.updateHeight();

        this.isResized   = true;
        this.isRedrawn   = true;
    }

    redraw() {
    }

    draw(canvas) {
        // if (this.name == "edit0")
            // console.log(this.name)

        this.text.prepare(canvas);
        this.text.draw(canvas, this, this.caption);
    }
}


export { BKengineLabel };
