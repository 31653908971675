
import { copyObj, getPropertiesFromSrc, getSrcFromProperties } from "../bk engine utils.js"


export class BKengineFile {
    constructor() {
        this.type      = "file";
        this.name      = "";        // name of this object (can be different from fileName)

        this.path      = "";
        this.fileName  = "";
        this.ext       = "txt";
        this.extAdd    = "";        // for example "?time"

        this.isLoading = false;
        this.isReady   = false;

        this.data      = "";        // after loaded here's the content
    }

    set src(value) {
        copyObj(getPropertiesFromSrc(value), this);
    }

    get src() {
        let result = getSrcFromProperties(this);

        this.extAdd.length > 0 && (result += "?" + this.extAdd);

        return result;
    }

    get isLoaded() {
        if (this.isReady)   return true;
        if (this.isLoading) return false;

        return this.load();
    }

    reset() {
        this.isLoading = this.isReady = false;
    }

    free() {
    }

    load() {
        this.isLoading = true;
        this.isReady   = false;

        fetch(this.src).then(response => response.text()).then((data) => {
            this.data      = data;
            this.isLoading = false;
            this.isReady   = true;
        })

        return this.isReady;
    }

    getCaption(name) {
        let caption = "",
            start, end;

        start = this.data.indexOf("[*" + name);

        if (start > -1) {
            start = this.data.indexOf("\n", start + 2) + 1;
            end   = this.data.indexOf("[*", start + 2);

            if (end < start)
                end = this.data.length;

            caption = this.data.substring(start, end);

            while (caption.length > 0 &&                             // cut off eols
                (caption.charCodeAt(caption.length - 1) == 10 ||
                 caption.charCodeAt(caption.length - 1) == 13)) {

                caption = caption.substring(0, caption.length - 1);
            }
        } else
            console.log("caption: " + name + " not found!");

        return caption;
    }
}
