
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { GW_MAIN } from "../../_gw main.js"


const DEVELOP = {daysLimitNm: -1};      // -1: no limits

const STATE         = {main: 10, scrolling: 20},
      TIME_LINE     = {x: 0.01, lineW: 0.0025, width: {min: 1, max: 100}},
      COORDS        = {timeLine: {x: 0.01, lineW: 0.0025},
                       grace:    {y: 0.95, h: 2.2},
                       faith:    {lineW: 0.004, hMax: 0.85}},
      GRID_LINES_NM = 7,
      MONTH_GAP_MIN = 10,
      SCROLL        = {w: 0.95, time: 500};


class aDay {
    constructor(dayUTC, faithTotal) {
        this.dayUTC     = dayUTC;
        this.faithTotal = faithTotal;
        this.graces     = [0, 0];           // for game "a" and "b"
    }
}

class aMonth {
    constructor(dayI, caption, width) {
        this.dayI    = dayI;
        this.caption = caption;
        this.width   = width;
    }
}


class FaithGraph extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = 0;

        this.canvas      = null;
        this.color       = null;
        this.text        = null;
        this.item        = null;

        this.timeLine    = {coords: new Coords(), leftMin: 0, leftMax: 0, lineWidth: 0, scroll: {start: 0, end: 0}};

        this.gridStep    = 0;
        this.timer       = 0;

        this.days        = [];
        this.faith       = [];
        this.months      = [];

        this.isInitiated = false;
    }

    init() {
        let T = BK_ENGINE.texts,
            I = BK_ENGINE.items;

        this.canvas = BK_ENGINE.canvases.getByName("main_userFaithGraph");

        this.color = BK_ENGINE.rects.getByName("main_userFaithGraph_bgd").colorFill;

        this.text = {faith:  T.getByName("main_userFaithGraph_faith"),
                     graceA: T.getByName("main_userFaithGraph_graceA"),
                     graceB: T.getByName("main_userFaithGraph_graceB"),
                     date:   T.getByName("main_userFaithGraph_date"),
                     grid:   T.getByName("main_userFaithGraph_grid")};

        this.item = {mainFrame: I.getByName("main_userFaithGraph"),
                     name:      I.getByName("main_userFaithGraph_name"),
                     date:      I.getByName("main_userFaithGraph_date"),
                     btnPrev:   I.getByName("main_userFaithGraph_btnPrev"),
                     btnNext:   I.getByName("main_userFaithGraph_btnNext")};

        this.isInitiated = true;
    }

    initDays() {
        let daysNm = GW_USER.days.length,
            i, dayI, userDay, day, dayUTC, faithTotal;

        if (DEVELOP.daysLimitNm > -1 && DEVELOP.daysLimitNm < daysNm)
            daysNm = DEVELOP.daysLimitNm;

        this.days = [];        
        userDay   = GW_USER.days[0];
        day       = this.initDays_one(userDay.dayUTC, userDay.grace, userDay.faithTotal, userDay);
        dayUTC    = day.dayUTC;

        for (dayI = 1; dayI < daysNm; dayI++) {
            userDay = GW_USER.days[dayI];

            for (i = 0; ++dayUTC < userDay.dayUTC; i++)     // add "empty" days
                this.initDays_one(dayUTC, 0, --faithTotal);

            day        = this.initDays_one(userDay.dayUTC, userDay.grace, userDay.faithTotal, userDay);
            faithTotal = userDay.faithTotal;
        }
    }

    initDays_one(dayUTC, graceTotal, faithTotal, userDay) {
        let day = new aDay(dayUTC, faithTotal),
            i, point;

        if (graceTotal > 0) {
            for (i = 0; i < userDay.points.length; i++) {
                point = userDay.points[i];

                switch (point.gameCode) {
                    case "a":
                        day.graces[0] += point.grace;
                        break;
                    case "b":
                        day.graces[1] += point.grace;
                }
            }
        }

        this.days.push(day);

        return day;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state                 = STATE.main;
        BK_ENGINE.pointer.isActive = false;

        this.show(true);
        this.initDays();
    }

    end2() {
        this.show(false);

        BK_ENGINE.pointer.isActive = true;
    }

    update() {
        if (this.state == STATE.main)
            this.main();
        else
            this.scrolling();

        this.refresh();
    }

    main() {
        if (this.canvas.width != this.item.mainFrame.width || this.canvas.height != this.item.mainFrame.height)
            this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "main_userFaithGraph_btnZoomIn":
                return this.main_zoom(1);
            case "main_userFaithGraph_btnZoomOut":
                return this.main_zoom(-1);
            case "main_userFaithGraph_btnPrev":
                return this.startScrolling(-1);
            case "main_userFaithGraph_btnNext":
                return this.startScrolling(1);
            case "main_userFaithGraph_btnQuit":
                return this.end();
            default:
                this.main_zoom(Math.sign(BK_ENGINE.input.mouseWheel));
        }
    }

    main_zoom(value) {
        let center = this.canvas.width / 2,
            left   = this.timeLine.coords.left,
            width  = this.timeLine.coords.width;

        this.timeLine.coords.width = Math.max(TIME_LINE.width.min, Math.min(TIME_LINE.width.max, this.timeLine.coords.width + value));

        if (width != this.timeLine.coords.width) {
            this.resize_timeLine();

            this.timeLine.coords.left = Math.max(this.timeLine.leftMin, Math.min(this.timeLine.leftMax, Math.round(center - (center - left) / (width / this.timeLine.coords.width))));
        }
    }

    startScrolling(dir) {
        let width = Math.round(this.canvas.width * SCROLL.w);

        this.timeLine.scroll.start = this.timeLine.coords.left;
        this.timeLine.scroll.end   = (dir == 1) ? Math.max(this.timeLine.leftMin, this.timeLine.coords.left - width) : Math.min(this.timeLine.leftMax, this.timeLine.coords.left + width);

        this.state = STATE.scrolling;
        this.timer = new Date();
    }

    scrolling() {
        let now     = new Date();
        let percent = Math.min(1, (now - this.timer) / SCROLL.time);

        this.timeLine.coords.left = this.timeLine.scroll.start + Math.round((this.timeLine.scroll.end - this.timeLine.scroll.start) * percent);

        if (percent == 1)
            this.state = STATE.main;
    }

    resize() {
        this.canvas.resize(this.item.mainFrame.width, this.item.mainFrame.height);

        this.resize_timeLine();
        this.resize_months();
        this.resize_grid();
        this.resize_faith();
    }

    resize_timeLine() {
        this.timeLine.coords.left = Math.max(1, Math.round(this.canvas.width * COORDS.timeLine.x));

        if (this.timeLine.coords.width == 0)    // first time
            this.timeLine.coords.width = Math.max(TIME_LINE.width.min, Math.min(TIME_LINE.width.max, (Math.floor((this.canvas.width - this.timeLine.coords.left) / this.days.length))));

        this.timeLine.coords.top       = this.item.date.top;
        this.timeLine.coords.lineWidth = Math.max(1, Math.round(TIME_LINE.lineW * BK_ENGINE.viewPort.min));

        this.timeLine.leftMax = this.timeLine.coords.left;
        this.timeLine.leftMin = Math.min(this.timeLine.leftMax, this.canvas.width - this.days.length * this.timeLine.coords.width - this.timeLine.coords.width);
    }

    resize_months() {
        let dayOfMonth;

        this.months = [];

        if (this.days.length == 0)
            return;

        this.text.date.calcHeight(this.canvas.height - this.timeLine.coords.top);

        this.resize_months_addOne(0);

        this.days.map(function(day, dayI) {
            dayOfMonth = GW_USER.getDateFromDayUTC(day.dayUTC).getUTCDate();

            if (dayOfMonth == 16)
                this.resize_months_addOne(dayI);
        }, this);

        if (this.months.length > 1)
            this.months.shift();
    }

    resize_months_addOne(dayI) {
        let caption = GW_USER.getStringFromDayUTC(this.days[dayI].dayUTC, 1);
        let width   = Math.ceil(this.text.date.getWidth(this.canvas, caption));

        this.months.push(new aMonth(dayI, caption, width));
    }

    resize_grid() {
        this.gridStep = this.resize_grid_round(Math.max(1, Math.floor(GW_USER.faith.max / GRID_LINES_NM)));
    }

    resize_grid_round() {
        let data     = [2, 5, 10, 15, 20, 25, 30, 50, 100, 150, 200, 250, 300, 400, 500],
            lastGood = 1,
            index    = 0,
            i, faith, step;

        do {
            faith = step = data[index];

            for (i = 1; i < GRID_LINES_NM; i++)
                faith += step;
            
            if (faith <= GW_USER.faith.max) {
                lastGood = step;
                index++;
            } else
                index = data.length;
        } while (index < data.length);

        return lastGood;
    }

    resize_faith() {
        let i, day1, day2, day3, sign1, sign2;

        this.faith = [];

        for (i = 0; i < this.days.length; i++)
            this.faith.push(i);

        i = 1;

        // remove when the line is straight (faith's grow doesn't change)
        while (i < this.faith.length - 1) {
            day1 = this.days[this.faith[i - 1]];
            day2 = this.days[this.faith[i]];
            day3 = this.days[this.faith[i + 1]];

            sign1 = Math.sign(day2.faithTotal - day1.faithTotal);
            sign2 = Math.sign(day3.faithTotal - day2.faithTotal);

            if (sign1 == sign2)
                this.faith.splice(i, 1);
            else
                i++;
        }

        if (this.faith.length == 1)
            return this.faith.push(0);

        this.faith.shift();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible)
            this.item.name.caption = GW_USER.nameOrAddress;
    }

    refresh() {
        this.canvas.drawRect(this.item.mainFrame, this.color, true);

        this.refresh_grid();
        this.refresh_grace();
        this.refresh_faith();
        this.refresh_timeLine();
        this.refresh_months();

        this.item.btnPrev.isVisible   = this.timeLine.leftMin != this.timeLine.leftMax;
        this.item.btnNext.isVisible   = this.timeLine.leftMin != this.timeLine.leftMax;
        this.item.mainFrame.isRedrawn = false;
    }

    refresh_grid() {
        let heightMax = Math.round(COORDS.faith.hMax * this.canvas.height),
            faith     = this.gridStep,
            left      = Math.round(this.canvas.width / 2);
        let topMin    = this.timeLine.coords.top - heightMax,
            top       = this.timeLine.coords.top - Math.round(faith / GW_USER.faith.max * heightMax);

        this.text.grid.calcHeight(BK_ENGINE.viewPort.min);
        this.text.grid.prepare(this.canvas);

        do {
            this.canvas.drawLine({left: 0, top: top, width: this.canvas.width, height: 0}, "strokeStyle", this.text.grid.color, "lineWidth", 1);
            this.text.grid.draw(this.canvas, {left: left, top: top}, "" + faith);
            
            faith += this.gridStep;
            top    = this.timeLine.coords.top - Math.round(faith / GW_USER.faith.max * heightMax);
        } while (top > topMin);
    }

    refresh_grace() {
        let dayI       = -1,
            left       = this.timeLine.coords.left,
            width      = this.timeLine.coords.width,
            heightMax  = Math.round(this.item.date.height * COORDS.grace.h);
        let offsetLeft = width > 5 ? 1 : 0,
            realWidth  = width > 5 ? width - 2 : width - 1,
            day, height, top;

        if (width < 4)
            realWidth = width;

        while (++dayI < this.days.length && left < this.canvas.width) {
            day = this.days[dayI];

            top    = this.timeLine.coords.top;
            height = day.graces[0] / 100 * heightMax;

            if (height > 0) {
                this.canvas.drawRect({left: left + offsetLeft, top: top, width: realWidth, height: -height}, this.text.graceA.color, true);

                top -= height;
            }

            height = day.graces[1] / 100 * heightMax;

            if (height > 0)
                this.canvas.drawRect({left: left + offsetLeft, top: top, width: realWidth, height: -height}, this.text.graceB.color, true);

            left += width;
        }
    }

    refresh_faith() {
        let left      = this.timeLine.coords.left,
            top       = this.timeLine.coords.top,
            width     = this.timeLine.coords.width,
            heightMax = Math.round(COORDS.faith.hMax * this.canvas.height),
            lineWidth = Math.max(2, Math.round(COORDS.faith.lineW * BK_ENGINE.viewPort.min)),
            faithI    = -1,
            dayI, day, left2, top2;

        while (++faithI < this.faith.length && left < this.canvas.width) {
            dayI = this.faith[faithI];
            day  = this.days[dayI];
            
            left2 = this.timeLine.coords.left + (dayI + 1) * width - 1;
            top2  = this.timeLine.coords.top - Math.round(day.faithTotal / GW_USER.faith.max * heightMax);

            this.canvas.drawLine({left: left, top: top, width: left2 - left, height: top2 - top}, "strokeStyle", this.text.faith.color, "lineWidth", lineWidth);

            left = left2;
            top  = top2;
        }
    }

    refresh_timeLine() {
        let linePreset = {day:   {height: 2, lineWidth: 2},
                          day10: {height: 4, lineWidth: 3},
                          month: {height: 6, lineWidth: 6}},
            coords     = this.timeLine.coords,
            left       = this.timeLine.coords.left,
            dayI       = -1,
            data, dayOfMonth;

        // time horizontal line
        this.canvas.drawLine({left: coords.left, top: coords.top, width: this.days.length * coords.width, height: 0}, "strokeStyle", this.text.date.color, "lineWidth", this.timeLine.lineWidth);

        // days', days10' and months' vertical lines
        while (++dayI < this.days.length && left < this.canvas.width) {
            dayOfMonth = GW_USER.getDateFromDayUTC(this.days[dayI].dayUTC).getUTCDate();

            if (dayOfMonth == 1)
                data = linePreset.month;
            else
                data = ((dayOfMonth - 1) % 10 == 0) ? linePreset.day10 : linePreset.day;

            this.canvas.drawLine({left: left, top: coords.top - data.height, width: 0, height: data.height * 2}, "strokeStyle", this.text.date.color, "lineWidth", data.lineWidth);

            left += this.timeLine.coords.width;
        }
    }

    refresh_months() {
        let top    = this.canvas.height - (Math.round(this.canvas.height - this.timeLine.coords.top) / 2),
            monthI = -1,
            end    = -1000,
            month, left;

        this.text.date.calcHeight(this.canvas.height - this.timeLine.coords.top);
        this.text.date.prepare(this.canvas);

        if (this.months.length == 1) {
            month = this.months[0];

            return this.text.date.draw(this.canvas, {left: this.timeLine.coords.left + Math.round(month.width / 2), top: top}, month.caption);
        }

        while (++monthI < this.months.length) {
            month = this.months[monthI];
            left  = this.timeLine.coords.left + month.dayI * this.timeLine.coords.width;

            if (left - month.width / 2 - end > MONTH_GAP_MIN) {
                this.text.date.draw(this.canvas, {left: left, top: top}, month.caption);
                
                end = left + month.width / 2;
            }
        }
    }
}

export const FAITH_GRAPH = BK_ENGINE.tasks.add(new FaithGraph(BK_ENGINE.tasks));
