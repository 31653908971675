
import { Coords } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0002 play display.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { DISPLAY_QUOTE as QUOTE } from "./display quote.js";
import { DISPLAY_LETTERS as LETTERS } from "./display letters.js";
import { DISPLAY_PLAYFIELD_REMOVABLE_BRICKS as REMOVABLE_BRICKS } from "./playfield/playfield removable bricks.js";


const MARK_LINE = {y:      0.45, w: 0.5, lineW: 0.1,
                   color:  "0000aa",
                   transp: {offset: 0.4, multiply: 0.15, freq: 0.004}};


class Letter {
    constructor(char) {
        this.char = char;
        this.col  = 0;
        this.row  = 0;
    }
}


class DisplayGuessingLastChar {
    constructor() {
        this.coords        = {main:        new Coords(),
                              quoteLetter: new Coords(),
                              markLine:    {left: 0, top: 0, width: 0, height: 0, lineWidth: 0}};
 
        this.cols          = 0;
        this.rows          = 0;
        this.letters       = [];

        this.isSelected    = false;
        this.isCorrect     = false;
        this.selectedI     = 0;
        this.timeMax       = 0;
        this.progress      = 0;
        this.transpBgd     = 0;
        this.transp2       = 0;

        this.time          = 0;

        this.isInitiated   = false;
    }

    getClicked(left, top) {
        let col     = Math.floor((left - this.coords.main.left) / PLAYFIELD.tileSize),
            row     = Math.floor((top  - this.coords.main.top)  / PLAYFIELD.tileSize);
        let letterI = col + row * this.cols;

        if (col >= 0 && col < this.cols && row >= 0 && row < this.rows && letterI < this.letters.length)
            return this.letters[letterI].char;

        return "";
    }

    getLetterI(char) {
        let letterI = 0;

        while (this.letters[letterI].char != char)
            letterI++;

        return letterI;
    }

    getBrickCoords(letterI) {
        let letter = this.letters[letterI],
            s      = PLAYFIELD.tileSize;

        return new Coords(this.coords.main.left + letter.col * s,
                          this.coords.main.top  + letter.row * s,
                          s,
                          s);
    }

    getLetterCoords(letterI, progress = 0) {     // between playfield and letterQuote
        let coordsSrc = this.getBrickCoords(letterI),
            s         = PLAYFIELD.tileSize;
        let coordsDst = new Coords(this.coords.quoteLetter.left, this.coords.quoteLetter.top, s, s);

        coordsSrc.left += Math.round((s - LETTERS.charsWidth[letterI]) / 2);
        coordsDst.left -= Math.round(LETTERS.charsWidth[letterI] / 2);
        coordsDst.top  -= Math.round(QUOTE.wordHeight / 2);

        coordsDst.left = Math.floor(coordsSrc.left + (coordsDst.left - coordsSrc.left) * progress);
        coordsDst.top  = Math.floor(coordsSrc.top  + (coordsDst.top  - coordsSrc.top)  * progress);

        return coordsDst;
    }

    start() {
        let i;

        this.letters = [];

        for (i = 0; i < LOGICS.alphabet.length; i++)
            this.letters.push(new Letter(LOGICS.alphabet.charAt(i)));
    }

    reset() {
        this.isSelected = false;
        this.progress   = 0;

        this.resize();
    }

    startCorrect(data) {
        this.isSelected         = this.isCorrect = true;
        this.selectedI          = this.getLetterI(data.char);
        this.timeMax            = data.time;
        this.coords.quoteLetter = QUOTE.getLastLetterCoords();
        this.transpBgd          = Math.random() * Math.PI * 2;
    }

    startWrong(data) {
        this.isSelected = true;
        this.isCorrect  = false;
        this.selectedI  = this.getLetterI(data.char);
    }

    update() {
        let progress;

        if (this.isSelected) {
            progress = Math.min(1, Math.max(0, 1 - LOGICS.getTimerWaiting() / this.timeMax));

            if (progress > this.progress)
                this.progress = progress;
        }

        this.refreshBricks();
        this.refreshLetters();

        if (this.progress < 0.9)
            this.refreshMarkLastWord();
    }

    resize() {
        let s   = PLAYFIELD.tileSize,
            col = 0,
            row = 0,
            letterI, lettersNm, letter;

        this.cols = this.rows = 0;

        for (letterI = 0, lettersNm = LOGICS.alphabet.length; letterI < lettersNm; letterI++) {
            letter     = this.letters[letterI];
            letter.col = col;
            letter.row = row;

            col++;

            if ((col + 1) * s >= DISPLAY.coords.width) {
                col = 0;
                row++;
            }

            if (col > this.cols)
                this.cols = col;
        }

        this.cols++;
        this.rows = (col == 0) ? row : row + 1;

        this.coords.main.resize(this.cols * s, this.rows * s);

        this.coords.main.left = Math.floor((DISPLAY.coords.width - this.cols * s) / 2);
        this.coords.main.top  = QUOTE.coords.height + Math.floor(((DISPLAY.coords.height - QUOTE.coords.height) - this.rows * s) / 2);

        // mark line
        this.coords.markLine.width     = Math.max(1, Math.round(QUOTE.wordHeight * MARK_LINE.w));
        this.coords.markLine.lineWidth = Math.max(1, Math.round(QUOTE.wordHeight * MARK_LINE.lineW));
    }

    refreshBricks() {
        let letterI, lettersNm, colSrc, transp;

        for (letterI = 0, lettersNm = this.letters.length; letterI < lettersNm; letterI++) {
            colSrc = REMOVABLE_BRICKS.hpMax - 1;
            transp = 1;

            if (this.isSelected && this.selectedI == letterI) {
                if (this.isCorrect)
                    transp = Math.max(0, 1 - this.progress);
                else
                    colSrc = REMOVABLE_BRICKS.hpMax;
            }

            REMOVABLE_BRICKS.refreshBrick_one(DISPLAY.canvas.pgd, this.getBrickCoords(letterI), DISPLAY.coords, colSrc, transp);
        }
    }

    refreshLetters() {
        let letterI, lettersNm;

        for (letterI = 0, lettersNm = this.letters.length; letterI < lettersNm; letterI++) {
            if (this.isSelected && this.isCorrect && this.selectedI == letterI) {
                LETTERS.refresh_movingLetter(letterI, this.getLetterCoords(letterI, this.progress), this.progress, this.progress);
            } else
                LETTERS.refresh_stillLetter(letterI, this.getLetterCoords(letterI));
        }
    }

    refreshMarkLastWord() {
        let coords = QUOTE.getLastLetterCoords(),
            transp = Math.max(0, Math.min(1, Math.sin(this.time * MARK_LINE.transp.freq) * MARK_LINE.transp.multiply + MARK_LINE.transp.offset));

        this.coords.markLine.left  = coords.left - coords.width;
        this.coords.markLine.top   = coords.top + Math.round(QUOTE.wordHeight * MARK_LINE.y);
        this.time                 += DISPLAY.timer.delta;

        DISPLAY.canvas.pgd.saveTransp();
        DISPLAY.canvas.pgd.setTransp(transp);
        DISPLAY.canvas.pgd.drawLine(this.coords.markLine, "strokeStyle", MARK_LINE.color, "lineWidth", this.coords.markLine.lineWidth, "lineCap", "round");
        DISPLAY.canvas.pgd.restoreTransp();
    }
}


export const DISPLAY_GUESSING_LAST_CHAR = new DisplayGuessingLastChar();
    