
import { isEmailFormatCorrect } from "../../../bk utils/_bk utils.js";
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { GW_MAIN, CONTROL_BOX } from "../../_gw main.js";


const STATE = {main: 10, loggingIn: 20};


class LogInEmail extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame:   I.getByName("main_userLogIn"),
                     email:       I.getByName("main_userLogIn_email"),
                     passw:       I.getByName("main_userLogIn_passw"),
                     isShowPassw: I.getByName("main_userLogIn_isShowPassw")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = STATE.main;

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        if (this.state == STATE.main)
            return this.update_main();
            
        this.update_loggingIn();
    }

    update_main() {
        switch (CONTROL_BOX.selected) {
            case "confirm":
                if (isEmailFormatCorrect(this.item.email.content)) {
                    this.state = STATE.loggingIn;

                    GW_USER.auth.signInWithEmailAndPassword(this.item.email.content, this.item.passw.content);
                } else
                    GW_MAIN.alertCaption("errInvalidEmailFormat");

                return CONTROL_BOX.selected = "";
            case "abandon":
                this.end();
        }

        this.refresh();
    }

    update_loggingIn() {
        if (!GW_USER.auth.state.isReady)
            return;

        if (GW_USER.auth.state.isError) {
            let caption = GW_USER.auth.state.code == "auth/too-many-requests" ? "errLoggingInTooManyRequests" : "errLoggingIn";
            
            GW_MAIN.alertCaption(caption);

            return this.state = STATE.main;
        }

        this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        this.item.email.setVisible(isVisible);
        this.item.passw.setVisible(isVisible);

        this.item.passw.content    = "";
        this.item.passw.isPassword = !this.item.isShowPassw.isChecked;

        if (isVisible)
            CONTROL_BOX.start(GW_MAIN.file.lang.getCaption("logInTitle"), "", ["confirm", "abandon"]);
        else
            CONTROL_BOX.end();
    }

    refresh() {
        this.item.passw.isPassword = !this.item.isShowPassw.isChecked;
    }
}

export const LOG_IN_EMAIL = BK_ENGINE.tasks.add(new LogInEmail(BK_ENGINE.tasks));
