
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { GW_MAIN } from "../../_gw main.js"
import { COOKIES } from "../../cookies.js"
import { HELP } from "../../../help/help.js"
import { MENU_USER } from "../_menu user.js";
import { SELECT_DENOM } from "./select denom.js";
import { SELECT_DATE_FORMAT } from "./select date format.js";
import { SET_DATA } from "./set data.js";
import { SET_AUTHORIZATIONS } from "./set authorizations.js";


class MenuSettings {
    constructor() {
        this.isInitiated = false;
    }

    isBusy() {
        return (SELECT_DENOM.isActive || SELECT_DATE_FORMAT.isActive || SET_DATA.isActive || SET_AUTHORIZATIONS.isActive)
    }

    init() {
        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        GW_USER.isNewUser && !MENU_USER.isOnceShown && SELECT_DENOM.start();
    }

    end() {}

    update() {
        if (this.isBusy())
            return;

        switch (BK_ENGINE.clicked.name) {
            case "main_userSettings_btnCookies":
                if (COOKIES.isActive)
                    return COOKIES.hide();
                return COOKIES.start();
            case "main_userSettings_btnDenom":
                return SELECT_DENOM.start();
            case "main_userSettings_btnDateFormat":
                return SELECT_DATE_FORMAT.start();
            case "main_userSettings_btnData":
                return SET_DATA.start();
            case "main_userSettings_btnAuthorizations":
                return SET_AUTHORIZATIONS.start();
            case "main_userSettings_btnInfo":
                HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userSettings").trim());
        }
    }
}

export const MENU_SETTINGS = new MenuSettings();
