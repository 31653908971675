
import { BK_ENGINE, Task, Coords } from "../../../../../bk engine/_bk engine.js";
import { G0002 } from "../../_bible g0002.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { DISPLAY_ATTEMPTS as ATTEMPTS } from "./display attempts.js";
import { DISPLAY_CLICK_ANYWHERE as CLICK_ANYWHERE } from "./display click anywhere.js";
import { DISPLAY_GUESSING_LAST_CHAR as GUESSING_LAST_CHAR } from "./display guessing last char.js";
import { DISPLAY_HIGHLIGHT_WORD as HIGHLIGHT_WORD } from "./display highlight word.js";
import { DISPLAY_LETTERS as LETTERS } from "./display letters.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { DISPLAY_QUOTE as QUOTE } from "./display quote.js";
import { DISPLAY_TIMER as TIMER } from "./display timer.js";
import { LOGICS_EVENTS } from "../logics/logics events.js";

const BGD_GRADIENT = [0, "#222222", 1, "#888888"],
      SHADOW       = {size:   0.2,                  // of a playfield tileSize
                      transp: 0.25},
      TIME_FADE_IN = 1000;


class PlayDisplay extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE        = {loading: 10, starting: 20, playing: 30, guessingLastChar: 40, highlightWord: 50};
        this.state        = 0;

        this.image        = null;
        this.canvas       = null;
        this.item         = null;

        this.coords       = new Coords();
        this.isInitiated  = false;

        this.timer        = {dateLast: 0, delta: 0, fadeIn: 0};

        this.timer.update = function() {
            let dateNow = Date.now();
    
            this.delta     = dateNow - this.dateLast;
            this.fadeIn   += this.delta;
            this.dateLast  = dateNow;
        }
    }

    // *** CALL FROM OUTSIDE ***

    // first: start()

    isReady() {
        return this.isInitiated && this.state > this.STATE.loading;
    }

    getPlayfieldColFromLeft(left) {
        return PLAYFIELD.getColFromLeft(left);
    }

    getPlayfieldRowFromTop(top) {
        return PLAYFIELD.getRowFromTop(top);
    }

    getGuessingLastCharClicked(left, top) {
        return GUESSING_LAST_CHAR.getClicked(left, top);
    }

    areLettersFinished() {
        return LETTERS.state == LETTERS.STATE.waiting;
    }

    // *** INTERNAL FUNCTION ***

    init() {
        let CANVASES = BK_ENGINE.canvases,
            IMAGES   = BK_ENGINE.images,
            ITEMS    = BK_ENGINE.items;

        this.canvas = {bgd:     CANVASES.addNew(),
                       shadows: CANVASES.addNew(),
                       pgd:     CANVASES.getByName("bible_g0002_pgd")};

        this.image = {balls:        IMAGES.getByName("bible_g0002_play_balls"),
                      bricks:       IMAGES.getByName("bible_g0002_play_bricks"),
                      digits:       IMAGES.getByName("bible_g0002_play_digits"),
                      quoteBgd:     IMAGES.getByName("bible_g0002_menu_quoteBgd"),
                      letterBgd:    IMAGES.getByName("bible_g0002_play_letterBgd"),
                      playfield:    IMAGES.getByName("bible_g0002_play_playfield")};

        this.item = {mainFrame:   ITEMS.getByName("bible_g0002_play"),
                     pgd:         ITEMS.getByName("bible_g0002_play_pgd"),
                     pleaseWait:  ITEMS.getByName("bible_g0002_play_pleaseWait"),
                     btnQuit:     ITEMS.getByName("bible_g0002_play_btnQuit")};

        BK_ENGINE.items.orderZ();
        this.timer.update();

        this.state       = this.STATE.loading;
        this.isInitiated = true;
    }

    start2() {
        this.state = this.STATE.starting;

        !this.isInitiated && this.init();

        this.show(true);
    }

    loading() {
        this.item.pleaseWait.caption = "";

        for (const prop in this.image) {
            if (!this.image[prop].isLoaded)
                return;

            this.item.pleaseWait.caption += "*";
        }

        this.item.pleaseWait.isVisible = false;
        this.state                     = this.STATE.starting;
    }

    starting() {
        if (this.item.pgd.width == 0 || this.item.pgd.height == 0)
            return;

        if (!LOGICS.isGameStarted())
            return;

        PLAYFIELD.start();
        ATTEMPTS.start();
        LETTERS.start();
        QUOTE.start();
        TIMER.start();
        GUESSING_LAST_CHAR.start();
        HIGHLIGHT_WORD.start();
        CLICK_ANYWHERE.start();

        this.state = this.STATE.playing;
    }

    end2() {
        this.show(false);
        // this.canvas.bgd.resize(1, 1);
        // this.canvas.pgd.resize(1, 1);
    }

    update() {
        this.timer.update();

        switch (this.state) {
            case this.STATE.loading:
                this.loading();
                break;
            case this.STATE.starting:
                this.starting();
                break;
            case this.STATE.playing:
                this.playing();
                break;
            case this.STATE.guessingLastChar:
                this.guessingLastChar();
                break;
            case this.STATE.highlightWord:
                this.highlightWord();
        }
    }

    playing() {
        let logicsEvent = LOGICS_EVENTS.getNext("display"),
            transp      = this.timer.fadeIn / TIME_FADE_IN;

        if (this.item.pgd.width != this.canvas.pgd.width || this.item.pgd.height != this.canvas.pgd.height)
            this.resize();

        if (logicsEvent.name == "guessingNewWord")
            this.startGuessingNewWord();

        this.canvas.shadows.clear();

        PLAYFIELD.update();

        this.canvas.pgd.drawImage(this.canvas.bgd);
        this.canvas.pgd.drawImage(this.canvas.shadows);
        this.canvas.pgd.drawImage(QUOTE.canvas.pgd, {left: 0, top: 0, width: QUOTE.coords.width, height: QUOTE.coords.height}, QUOTE.coords);

        if (transp < 1) {
            this.canvas.pgd.saveTransp();
            this.canvas.pgd.setTransp(transp);
        }

        this.canvas.pgd.drawImage(PLAYFIELD.canvas.pgd, {left: 0, top: 0, width: PLAYFIELD.coords.main.width, height: PLAYFIELD.coords.main.height}, PLAYFIELD.coords.main);

        if (transp < 1)
            this.canvas.pgd.restoreTransp();
            
        ATTEMPTS.update();
        TIMER.update();
        LETTERS.update();

        if (!LOGICS.isPlayingStarted)
            CLICK_ANYWHERE.update();

        this.item.pgd.isRedrawn = false;

        switch (logicsEvent.name) {
            case "guessingLastChar":
                this.state = this.STATE.guessingLastChar;

                QUOTE.reset();
                GUESSING_LAST_CHAR.reset();
                break;
            case "highlightingWord":
                this.state = this.STATE.highlightWord;

                QUOTE.reset(true);
                HIGHLIGHT_WORD.reset(logicsEvent.data);
        }
    }

    guessingLastChar() {
        let logicsEvent = LOGICS_EVENTS.getNext("display");

        if (this.item.pgd.width != this.canvas.pgd.width || this.item.pgd.height != this.canvas.pgd.height)
            this.resize();

        this.canvas.shadows.clear();

        this.canvas.pgd.drawImage(this.canvas.bgd);
        this.canvas.pgd.drawImage(this.canvas.shadows);
        this.canvas.pgd.drawImage(QUOTE.canvas.pgd, {left: 0, top: 0, width: QUOTE.coords.width, height: QUOTE.coords.height}, QUOTE.coords);

        GUESSING_LAST_CHAR.update();
        LETTERS.update();

        this.item.pgd.isRedrawn = false;

        switch (logicsEvent.name) {
            case "lastCharCorrect":
                return GUESSING_LAST_CHAR.startCorrect(logicsEvent.data);
            case "lastCharWrong":
                return GUESSING_LAST_CHAR.startWrong(logicsEvent.data);
            case "highlightingWord":
                this.state = this.STATE.highlightWord;

                QUOTE.reset(true);
                return HIGHLIGHT_WORD.reset(logicsEvent.data);
            case "guessingNewWord":
                this.startGuessingNewWord();
        }
    }

    highlightWord() {
        let logicsEvent = LOGICS_EVENTS.getNext("display");

        if (this.item.pgd.width != this.canvas.pgd.width || this.item.pgd.height != this.canvas.pgd.height)
            this.resize();

        this.canvas.shadows.clear();

        if (LOGICS.isGuessingLastChar)
            PLAYFIELD.update();

        this.canvas.pgd.drawImage(this.canvas.bgd);
        this.canvas.pgd.drawImage(this.canvas.shadows);
        this.canvas.pgd.drawImage(QUOTE.canvas.pgd, {left: 0, top: 0, width: QUOTE.coords.width, height: QUOTE.coords.height}, QUOTE.coords);

        if (LOGICS.isGuessingLastChar)
            this.canvas.pgd.drawImage(PLAYFIELD.canvas.pgd, {left: 0, top: 0, width: PLAYFIELD.coords.main.width, height: PLAYFIELD.coords.main.height}, PLAYFIELD.coords.main);

        HIGHLIGHT_WORD.update();

        this.item.pgd.isRedrawn = false;

        switch (logicsEvent.name) {
            case "guessingNewWord":
                this.state = this.STATE.playing;
                this.startGuessingNewWord();
        }
    }

    startGuessingNewWord() {
        this.state        = this.STATE.playing;
        this.timer.fadeIn = 0;

        PLAYFIELD.reset();
        QUOTE.reset();
        LETTERS.reset();
    }

    resize() {
        let width  = this.item.pgd.width,
            height = this.item.pgd.height;

        // coords
        this.coords.resize(width, height);

        // canvases
        this.canvas.bgd.resize(width, height);
        this.canvas.shadows.resize(width, height);
        this.canvas.pgd.resize(width, height);

        // all the rest
        PLAYFIELD.resize();            // must be here: the first
        ATTEMPTS.resize();
        QUOTE.resize();
        GUESSING_LAST_CHAR.resize();
        LETTERS.resize();
        TIMER.resize();
        HIGHLIGHT_WORD.resize();
        CLICK_ANYWHERE.resize();

        // // btn "Quit" and "Settings"
        // if ((this.coords.width - PLAYFIELD.coords.width) / 2 < PLAYFIELD.tileSize) {
        //     this.item.btnQuit.x = (PLAYFIELD.coords.main.left + PLAYFIELD.tileSize * (PLAYFIELD.size - 0.5)) / this.coords.width;
        //     this.item.btnQuit.y = (PLAYFIELD.coords.main.top  + PLAYFIELD.tileSize * 0.5) / this.coords.height;
        // } else {
        //     this.item.btnQuit.x = PLAYFIELD.tileSize / this.coords.width / 2;
        //     this.item.btnQuit.y = (this.coords.height - PLAYFIELD.tileSize / 2) / this.coords.height;
        // }

        this.redraw();
    }

    redraw() {
        this.canvas.bgd.createLinearGradient(this.coords, BGD_GRADIENT);
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

const PLAY_DISPLAY = BK_ENGINE.tasks.add(new PlayDisplay(BK_ENGINE.tasks));

export { PLAY_DISPLAY, SHADOW };
