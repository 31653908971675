
class Task {
    constructor(PARENT) {
        this.PARENT   = PARENT;

        this.isActive = false;
    }

    start() {
        this.isActive = this.PARENT.isRefreshActive = true;

        this.start2(...arguments);
    }
    
    end() {
        this.isActive               = false;        
        this.PARENT.isRefreshActive = true;
        
        this.end2(...arguments);
    }
    
    start2() {}     // any of these: replace with own function
    end2()   {}
    update() {}
}


class Tasks {
    constructor() {
        this.all    = [];
        this.active = [];

        this.isRefreshActive = true;
    }

    add(task) {
        return this.all[this.all.push(task) - 1];
    }

    addNew() {
        return this.all[this.all.push(new Task(this)) - 1];
    }
//     getByName(name) {
//         let i = -1,
//             l = this.all.length;

// //         while( ++i < l) {console.log(this.all[i].constructor.name)};
// // i = -1;
//         while (++i < l && this.all[i].constructor.name != name) {}

//         if (i < l)
//             return this.all[i];
//         else
//             console.error("Task not found: \"" + name +"\"!");
//     }

    refreshActive() {
        let i, l;

        this.active = [];

        for (i = 0, l = this.all.length; i < l; i++) {
            if (this.all[i].isActive)
                this.active.push(this.all[i]);
        }

        this.isRefreshActive = false;
    }

    update() {
        let i;

        if (this.isRefreshActive)
            this.refreshActive();

        for (i = 0; i < this.active.length; i++)
            this.active[i].update();
    }
}


const TASKS = new Tasks();

export { TASKS, Task };
