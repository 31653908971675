
import { getIdByPropExcept } from "../../../../../bk utils/_bk utils.js";
import { PLAY_LOGICS as LOGICS } from "./_bible g0001 play logics.js";


const SPEED = 0.004;


class Letter {
    constructor(X) {
        this.X         = X;     // original position
        this.Y         = 1;

        this.srcX      = 0;     // source position (the last one)
        this.srcY      = 0;
        this.dstX      = 0;     // destination
        this.dstY      = 0;

        this.progress  = 0;     // 0 - 1

        this.char      = "";

        this.isGuessed = false;
    }
}


class LogicsLetters {
    constructor() {
        this.all         = [];

        this.activeNm    = 0;
        this.moves       = 0;

        this.areMoving   = false;
        this.isInitiated = false;
    }

    getByI(i) {
        return this.all[i];
    }

    getIdByChar(char) {
        let i = -1,
            l = this.activeNm,
            letter;

        while (++i < l) {
            letter = this.all[i];

            if (!letter.isGuessed && letter.char == char)
                return i;
        }
    }

    init() {
        let i;

        for (i = 0; i < LOGICS.lettersMax; i++)
            this.all.push(new Letter(i));

        this.isInitiated = true;
    }

    start() {
        let i, l, letter;

        !this.isInitiated && this.init();

        for (i = 0, l = this.all.length; i < l; i++) {
            letter = this.all[i];

            letter.srcX      = letter.srcY = letter.dstX = letter.dstY = letter.progress = 0;
            letter.isGuessed = false;
            letter.char      = "";
        }

        this.activeNm = this.moves = 0;
    }

    reset(oChars = "") {
        let i, letter;

        this.activeNm = oChars.length;

        for (i = 0; i < this.activeNm; i++) {
            letter = this.getByI(i);

            letter.char      = oChars.charAt(i);
            letter.isGuessed = false;
            letter.srcX      = letter.dstX = letter.X;
            letter.srcY      = letter.dstY = letter.Y;
            letter.progress  = 1;
        }

        if (this.activeNm > 0)
            this.reset_guessed();
    }

    reset_guessed() {
        let chars  = LOGICS.word.shortGuessed,
            except = [],
            i, l,
            char, letter, helper;

        for (i = 0, l = chars.length; i < l; i++) {
            char = chars.charAt(i);

            if (char != " ") {
                helper = LOGICS.helpers.getByI(i);

                helper.letterI = getIdByPropExcept(this.all, "char", char, except);

                except.push(helper.letterI);

                letter = this.getByI(helper.letterI);
                
                letter.isGuessed = helper.isCorrect = true;
                letter.char      = helper.char      = char;
                letter.srcX      = letter.dstX      = helper.X;
                letter.srcX      = letter.dstY      = helper.Y;
            }
        }
    }

    update() {
        let i, letter;

        this.areMoving = false;

        for (i = 0; i < this.activeNm; i++) {
            letter = this.all[i];

            if (letter.progress < 1) {
                letter.progress += LOGICS.timer.delta * SPEED;

                if (letter.progress >= 1) {
                    letter.progress = 1;
                    letter.srcX     = letter.dstX;
                    letter.srcY     = letter.dstY;
                } else
                    this.areMoving = true;
            }
        }
    }

    moveTo(letterI, x, y) {
        let letter = this.getByI(letterI),
            helper;

        // free the helper, if the letter is starting (if it's already moving, the helper is free already)
        if (letter.srcY == 0 && letter.dstY == 0) {
            helper         = LOGICS.helpers.getByI(letter.srcX);
            helper.letterI = -1;
            helper.char    = "";
        }

        // set the new destination
        letter.dstX     = x;
        letter.dstY     = y;
        letter.progress = 0;

        // if the new destination is a helper, update it
        if (letter.dstY == 0) {
            helper         = LOGICS.helpers.getByI(letter.dstX);
            helper.letterI = letterI;
            helper.char    = letter.char;
        }

        this.moves++;
    }

    backSpace() {
        let i       = LOGICS.word.shortSorted.length,
            isFound = false,
            helper, letter;

        while (--i > -1 && !isFound) {
            helper = LOGICS.helpers.getByI(i);

            if (helper.char.length > 0 && !helper.isCorrect)
                isFound = true;
        }

        if (isFound) {
            letter = this.getByI(helper.letterI);

            this.moveTo(helper.letterI, letter.X, letter.Y);

            return true;
        }

        return false;
    }
}

export const LOGICS_LETTERS = new LogicsLetters();
