
import { BK_ENGINE, Task, Coords } from "../../../../bk engine/_bk engine.js";
import { GW_USER } from "../../../../gw user/_gw user.js";
import { SHOW_SCORE } from "../../../show score.js";
import { AUDIO } from "../g0002 audio.js";
import { QUOTES } from "./_g0002 quotes.js";
import { SHOW_QUOTE } from "./show quote.js";


const LEFT_OFFSET       = {ref: 0.15, content: 0.05},
      COLOR             = {bgdGradient: [0, "#000000", 0.5, "#550000", 1, "#000000"],
                           difficulty:  ["#00cc00", "#ffa500", "#cc0000"],
                           stripes:     ["#6D0E10", "#6D0E10", "#6D0E10",        // one
                                         "#961316", "#961316", "#961316"]},      // two
      FADE_OUT          = {w: 0.15, gradient: [0, "#6D0E1000", 1, "#6D0E10ff"]},
      SCROLL_SPEED      = 0.005,
      MOUSE_WHEEL_DELTA = 3;


class DispQuote {
    constructor(quote, colorI) {
        this.quote  = quote;
        this.colorI = colorI;
    }
}


class SelectQuote extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE       = {loading: 10, main: 20};
        this.state       = 0;

        this.canvas      = null;
        this.text        = null;
        this.item        = null;
        this.coords      = {date:    new Coords(),
                            ref:     new Coords(),
                            content: new Coords(),
                            fadeOut: new Coords()};

        this.all         = [];

        this.timer       = 0;
        this.quoteI      = {now: 0, dst: 0, speed: 0};

        this.isFirstTime = true;
        this.isInitiated = false;
    }

    init() {
        let TEXTS = BK_ENGINE.texts,
            ITEMS = BK_ENGINE.items;

        this.canvas = {main:      BK_ENGINE.canvases.getByName("bible_g0002_selectQuote_pgd"),
                       bgd:       BK_ENGINE.canvases.addNew(),
                       fadeOut:   BK_ENGINE.canvases.addNew()};
        this.text   = {date:      TEXTS.getByName("bible_g0002_selectQuote_date"),
                       ref:       TEXTS.getByName("bible_g0002_selectQuote_ref"),
                       content:   TEXTS.getByName("bible_g0002_selectQuote_content")},
        this.item   = {bibleGame: ITEMS.getByName("bible_game"),
                       main:      ITEMS.getByName("bible_g0002_selectQuote"),
                       date:      ITEMS.getByName("bible_g0002_selectQuote_date"),
                       ref:       ITEMS.getByName("bible_g0002_selectQuote_ref"),
                       diff:      ITEMS.getByName("bible_g0002_selectQuote_diff"),
                       content:   ITEMS.getByName("bible_g0002_selectQuote_content"),
                       btnUp:     ITEMS.getByName("bible_g0002_selectQuote_btnUp"),
                       btnUp2:    ITEMS.getByName("bible_g0002_selectQuote_btnUp2"),
                       btnDown:   ITEMS.getByName("bible_g0002_selectQuote_btnDown"),
                       btnDown2:  ITEMS.getByName("bible_g0002_selectQuote_btnDown2")};

        this.refresh_btns(false);

        this.isInitiated = true;
    }

    loading() {
        this.show(true);

        if (QUOTES.isActive || this.item.main.width == 0 || this.item.main.height == 0)
            return;

        this.resize();
        this.reset();

        if (this.isFirstTime) {
            this.isFirstTime = false;

            this.quoteI.dst   = 1000000;      // show the last part of the table
            this.quoteI.speed = SCROLL_SPEED * 10000000000;
        }

        this.state = this.STATE.main;
    }

    reset() {
        let quoteLast = QUOTES.getLast(),
            colorI    = 0,
            i, l, quote;

        this.all = [];

        for (i = 0, l = QUOTES.getNm(); i < l; i++) {
            quote = QUOTES.getByI(i);

            if (quoteLast.year != quote.year || quoteLast.month != quote.month) {
                quoteLast = quote;
                colorI++;
            }

            this.all.push(new DispQuote(quote, colorI % 2 * 3));
        }
    }

    start2() {
        !this.isInitiated && this.init();
        
        this.state = this.STATE.loading;
        this.timer = Date.now();

        QUOTES.start();     // will update the quotes according to selected lang and denom
        SHOW_SCORE.end();
    }

    end2() {
        this.show(false);
        this.canvas.main.resize(1, 1);
        this.canvas.bgd.resize(1, 1);

        SHOW_SCORE.start();
    }

    update() {
        switch (this.state) {
            case this.STATE.loading:
                return this.loading();
            case this.STATE.main:
                this.main();
        }
    }

    main() {
        let now       = Date.now();
        let timeDelta = (now - this.timer) * this.quoteI.speed,
            linesPerPage, max;
        
        this.timer = now;
        
        if (this.item.main.width != this.canvas.main.width || this.item.main.height != this.canvas.main.height)
            this.resize();

        linesPerPage = Math.max(2, Math.floor(this.item.main.height / this.item.date.height) - 2);

        if (this.quoteI.now != this.quoteI.dst)
            this.quoteI.now = this.quoteI.now < this.quoteI.dst ? Math.min(this.quoteI.now + timeDelta, this.quoteI.dst) : (Math.max(this.quoteI.now - timeDelta, this.quoteI.dst));

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_selectQuote_btnUp":
                this.quoteI.dst--;
                this.quoteI.speed = SCROLL_SPEED;
                AUDIO.play("menuClick");
                break;
            case "bible_g0002_selectQuote_btnUp2":
                this.quoteI.dst   -= linesPerPage;
                this.quoteI.speed  = SCROLL_SPEED * linesPerPage;
                AUDIO.play("menuClick");
                break;
            case "bible_g0002_selectQuote_btnDown":
                this.quoteI.dst++;
                this.quoteI.speed = SCROLL_SPEED;
                AUDIO.play("menuClick");
                break;
            case "bible_g0002_selectQuote_btnDown2":
                this.quoteI.dst   += linesPerPage;
                this.quoteI.speed  = SCROLL_SPEED * linesPerPage;
                AUDIO.play("menuClick");
                break;
            case "bible_g0002_selectQuote_btnQuit":
                AUDIO.play("menuClick");
                this.end();
                break;
            case "bible_g0002_selectQuote":
                return this.main_itemSelected();
            default:
                if (BK_ENGINE.input.mouseWheel != 0) {
                    this.quoteI.dst   = BK_ENGINE.input.mouseWheel > 0 ? this.quoteI.dst + MOUSE_WHEEL_DELTA : this.quoteI.dst - MOUSE_WHEEL_DELTA;
                    this.quoteI.speed = SCROLL_SPEED * MOUSE_WHEEL_DELTA;
                }
        }

        // limit the scroll
        max             = Math.max(0, this.all.length - linesPerPage);
        this.quoteI.dst = Math.min(Math.max(0, this.quoteI.dst), max);

        // console.log(linesPerPage, max, this.quoteI.dst)

        this.refresh();
    }

    main_itemSelected() {
        let i = Math.floor(BK_ENGINE.input.top / this.item.date.height) + Math.floor(this.quoteI.now);

        if (i < this.all.length) {
            AUDIO.play("menuClick");
            SHOW_QUOTE.start(i, 50);
            this.end();
        }
    }

    resize() {
        let h     = this.item.date.height,
            width = Math.round((this.item.main.width - this.item.content.left) * FADE_OUT.w);

        this.coords.date.left    = Math.round(this.item.date.width / 2);
        this.coords.ref.left     = this.item.ref.left + Math.round(h * LEFT_OFFSET.ref);
        this.coords.content.left = this.item.content.left + Math.round(h * LEFT_OFFSET.content);
            
        this.coords.fadeOut.resize(width, this.item.content.height);
        this.coords.fadeOut.left = this.item.main.width - width;

        this.canvas.main.resize(this.item.main.width, this.item.main.height);
        this.canvas.bgd.resize(this.item.main.width, this.item.main.height);
        this.canvas.fadeOut.resize(this.coords.fadeOut.width, this.coords.fadeOut.height);
        this.canvas.fadeOut.createLinearGradient({left: 0, top: 0, width: this.coords.fadeOut.width, height: 0}, FADE_OUT.gradient);

        this.canvas.bgd.createLinearGradient(this.item.main, COLOR.bgdGradient);

        this.redraw();
        this.refresh();
    }

    redraw() {
        let height = this.item.date.height;

        this.text.date.calcHeight(height);
        this.text.ref.calcHeight(height);
        this.text.content.calcHeight(height);
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;
    }

    refresh() {
        let height     = this.item.date.height;
        let heightHalf = Math.round(height / 2),
            top        = Math.floor(1 - (this.quoteI.now - Math.floor(this.quoteI.now)) * height),
            i, l, dispQuote;

        this.canvas.main.drawImage(this.canvas.bgd);
// console.log(this.quoteI.now)
        for (i = Math.floor(this.quoteI.now), l = this.all.length; i < l; i++) {
            dispQuote = this.all[i];

            // date
            this.canvas.main.drawRect({left: this.item.date.left, top: top, width: this.item.date.width, height: height}, COLOR.stripes[dispQuote.colorI], true, 1);
            this.text.date.prepare(this.canvas.main);
            this.text.date.draw(this.canvas.main, {left: this.coords.date.left, top: top + heightHalf}, this.refresh_getDate(dispQuote));

            // referance
            this.canvas.main.drawRect({left: this.item.ref.left, top: top, width: this.item.ref.width, height: height}, COLOR.stripes[dispQuote.colorI + 1], true, 1);
            this.text.ref.prepare(this.canvas.main);
            this.text.ref.draw(this.canvas.main, {left: this.coords.ref.left, top: top + heightHalf}, dispQuote.quote.tagNational + " " + dispQuote.quote.chapterVerses);

            // difficulty
            this.canvas.main.drawRect({left: this.item.diff.left, top: top, width: this.item.diff.width, height: height}, COLOR.difficulty[dispQuote.quote.difficultyI], true, 1);

            // content
            this.canvas.main.drawRect({left: this.item.content.left, top: top, width: this.item.content.width, height: height}, COLOR.stripes[dispQuote.colorI + 2], true, 1);
            this.text.content.prepare(this.canvas.main);
            this.text.content.draw(this.canvas.main, {left: this.coords.content.left, top: top + heightHalf}, dispQuote.quote.content);
            this.canvas.main.drawImage(this.canvas.fadeOut, {left: 0, top: 0, width: this.canvas.fadeOut.width, height: this.canvas.fadeOut.height}, {left: this.coords.fadeOut.left, top: top, width: this.canvas.fadeOut.width, height: this.canvas.fadeOut.height});

            // highlight
            if (BK_ENGINE.input.top > top && BK_ENGINE.input.top < top + height)
                this.canvas.main.drawRect({left: this.item.date.left, top: top, width: this.canvas.main.width, height: height}, "#ffffff55", true, 1, 5);

            top += height;
        }

        this.refresh_btns(this.all.length * height > this.canvas.main.height);

        this.item.main.isRedrawn = false;
    }

    refresh_getDate(dispQuote) {
        let q = dispQuote.quote;

        return GW_USER.getStringFromDate(q.day, q.month + 1, q.year);
    }

    refresh_btns(isVisible) {
        this.item.btnUp.isVisible = this.item.btnDown.isVisible = this.item.btnUp2.isVisible = this.item.btnDown2.isVisible = isVisible;
    }

}

export const SELECT_QUOTE = BK_ENGINE.tasks.add(new SelectQuote(BK_ENGINE.tasks));
