
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_USER } from "../gw user/_gw user.js"
import { GW_MAIN } from "./_gw main.js"
import { HELP } from "../help/help.js"


class MenuEnter extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = true;
        this.isInitiated = false;
        this.selected    = "";
    }

    isUserLoggedIn() {      // will be called frequently, if by chance the user is got logged in when showing this menu 
        if (GW_USER.isLoggedIn) {
            this.selected = GW_USER.isWallet ? "enterWallet" : "enterEmail";
    
            return true;
        }
    
        return false;
    }
    
    init() {
        this.item = {mainFrame: BK_ENGINE.items.getByName("main_menuEnter"),
                     btnMenu:   BK_ENGINE.items.getByName("main_menuEnter_menu")};

        this.isInitiated = true;
    }

    start2() {
        this.selected = "";

        if (this.isUserLoggedIn())
            return this.end();

        !this.isInitiated && this.init();

        this.show(true);
    }

    end2(selected) {
        this.selected = selected || this.selected;

        if (this.isInitiated)
            this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked,
            s;

        if (this.isUserLoggedIn())
            return this.end();

        switch (btn.name) {
            case "main_menuEnter_guest":
                return this.end("enterGuest");
            case "main_menuEnter_email":
                return this.end("enterEmail");
            case "main_menuEnter_wallet":
                return this.end("enterWallet");
            case "main_menuEnter_help":
                return HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userSignUp").trim());
            case "main_menuEnter_back":
                return this.end("back");
            default:
                if ((s = GW_MAIN.checkMenus()) != "")
                    return this.end(s);

                if (btn.name != "")
                    this.end();
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
        this.item.btnMenu.isVisible   = !isVisible;
    }
}

export const MENU_ENTER = BK_ENGINE.tasks.add(new MenuEnter(BK_ENGINE.tasks));
