
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { GW_USER } from "../../../gw user/_gw user.js"
import { GW_MAIN, CONTROL_BOX, INFO_BOX } from "../../_gw main.js"
import { HELP } from "../../../help/help.js"
import { SIGN_UP_EMAIL } from "./sign up email.js"
import { SIGN_UP_METAMASK } from "./sign up metamask.js"
import { LOG_IN_EMAIL } from "./log in email.js"


const STATE        = {main: 10, signUp: 20, logIn: 30},
      TIME_REFRESH = 500;


class EmailVerSent extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        GW_USER.auth.sendEmailVerification();
        INFO_BOX.start(GW_MAIN.file.lang.getCaption("userSignUp_emailVerSent"), "gotIt");
    }

    update() {
        if (!INFO_BOX.isActive)
            this.end();
    }
}
    
const EMAIL_VER_SENT = BK_ENGINE.tasks.add(new EmailVerSent(BK_ENGINE.tasks));


class ResendEmailVer extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.isEmailVerSent = false;
    }
    
    start2() {
        this.isEmailVerSent = false;

        CONTROL_BOX.start("", GW_MAIN.file.lang.getCaption("resendEmailVerContent"), ["confirm", "abandon"]);
    }

    end2() {
        CONTROL_BOX.end();
    }

    update() {
        if (this.isEmailVerSent) {
            if (!EMAIL_VER_SENT.isActive)
                this.end();
            
            return;
        }

        switch (CONTROL_BOX.selected) {
            case "confirm":
                this.isEmailVerSent = true;
                return EMAIL_VER_SENT.start();
            case "abandon":
                this.end();
        }
    }
}

const RESEND_EMAIL_VER = BK_ENGINE.tasks.add(new ResendEmailVer(BK_ENGINE.tasks));


class LogOut extends Task {
    constructor(PARENT) {
        super(PARENT);
    }

    start2() {
        CONTROL_BOX.start("", GW_MAIN.file.lang.getCaption("userLogOut_content"), ["yes", "no"]);
    }

    update() {
        switch (CONTROL_BOX.selected) {
            case "confirm":
                GW_USER.logOut();
            case "abandon":
                CONTROL_BOX.end();
                this.end();
        }
    }
}

const LOG_OUT = BK_ENGINE.tasks.add(new LogOut(BK_ENGINE.tasks));


class MenuLogIn {
    constructor() {
        this.state       = STATE.main;
        this.item        = null;
        this.date        = new Date();

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {btn: {signUp:         I.getByName("main_menuLogIn_signUp"),
                           signUpEmail:    I.getByName("main_menuLogIn_signUp_email"),
                           signUpMetaMask: I.getByName("main_menuLogIn_signUp_metamask"),
                           logIn:          I.getByName("main_menuLogIn_logIn"),
                           logInEmail:     I.getByName("main_menuLogIn_logIn_email"),
                           logInMetaMask:  I.getByName("main_menuLogIn_logIn_metamask"),
                           resendEmailVer: I.getByName("main_menuLogIn_resendEmailVer"),
                           logOut:         I.getByName("main_menuLogIn_logOut"),
                           back:           I.getByName("main_menuLogIn_back")}};

        this.isInitiated = true;
    }

    start(req = "") {
        !this.isInitiated && this.init();

        switch (req) {
            case "signUp_email":
                SIGN_UP_EMAIL.start();
                break;
            case "signUp_wallet":
                SIGN_UP_METAMASK.start();            
        }

        this.refresh();
    }

    end() {}

    update() {
        if (SIGN_UP_EMAIL.isActive || SIGN_UP_METAMASK.isActive || RESEND_EMAIL_VER.isActive || LOG_IN_EMAIL.isActive || LOG_OUT.isActive)
            return;

        if (BK_ENGINE.clicked.name == "main_menuLogIn_btnInfo")
            HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userSignUp").trim());

        // refresh?
        let date = new Date();

        if (date - this.date >= TIME_REFRESH) {
            this.date = date;
            
            this.refresh();
        }

        // update
        switch (this.state) {
            case STATE.main:
                return this.update_main();
            case STATE.signUp:
                return this.update_signUp();
            case STATE.logIn:
                this.update_logIn();
        }
    }

    update_main() {
        let btn = this.item.btn;

        switch (BK_ENGINE.clicked.id) {
            case btn.signUp.id:
                this.state = STATE.signUp;
                return this.refresh();
            case btn.logIn.id:
                this.state = STATE.logIn;
                return this.refresh();
            case btn.resendEmailVer.id:
                return RESEND_EMAIL_VER.start();
            case btn.logOut.id:
                LOG_OUT.start();
        }
    }

    update_signUp() {
        let btn = this.item.btn;

        switch (BK_ENGINE.clicked.id) {
            case btn.signUpEmail.id:
                this.state = STATE.main;
                SIGN_UP_EMAIL.start();
                break;
            case btn.signUpMetaMask.id:
                SIGN_UP_METAMASK.start();
            case btn.back.id:
                this.state = STATE.main;
        }

        if (this.state != STATE.signUp)
            this.refresh();
    }

    update_logIn() {
        let btn = this.item.btn;

        switch (BK_ENGINE.clicked.id) {
            case btn.logInEmail.id:
                this.state = STATE.main;
                LOG_IN_EMAIL.start();
                break;
            case btn.logInMetaMask.id:
                SIGN_UP_METAMASK.start(true);
            case btn.back.id:
                this.state = STATE.main;
        }

        if (this.state != STATE.logIn)
            this.refresh();
    }

    refresh() {
        let btn  = this.item.btn;

        for (const prop in btn)
            btn[prop].isVisible = false;

        switch (this.state) {
            case STATE.main:
                return this.refresh_btns("signUp", !GW_USER.isLoggedIn, "logIn", !GW_USER.isLoggedIn, "resendEmailVer", GW_USER.isLoggedIn && !GW_USER.isVerified, "logOut", GW_USER.isLoggedIn);
            case STATE.signUp:
                return this.refresh_btns("signUpEmail", true, "signUpMetaMask", true, "back", true);
            case STATE.logIn:
                this.refresh_btns("logInEmail", true, "logInMetaMask", true, "back", true);
        }
    }

    refresh_btns() {
        let i;

        for (i = 0; i < arguments.length; i += 2)
            this.item.btn[arguments[i]].isVisible = arguments[i + 1];
    }
}

const MENU_LOG_IN = new MenuLogIn();


export { MENU_LOG_IN, EMAIL_VER_SENT };
