
import { PLAY_LOGICS as LOGICS } from "./_bible g0002 play logics.js";
import { LOGICS_BRICKS as BRICKS } from "./logics bricks.js";
import { TRIGGERS, BALL_HITS_BRICK } from "./logics triggers.js";


const TIME_LIMIT = 25;     // in case of a lag (with this algorithm the ball could go beyond the playfield)


class LogicsBall {
    constructor() {
        this.x     = 0;
        this.y     = 0;
        this.r     = 0.2;      // of a tile

        this.speed = 0;

        this.dx    = 0;
        this.dy    = 0;

        this.col   = 0;
        this.row   = 0;
    }

    isTileEmpty(col, row) {     // call refreshColRow first!
        let dist;

        if (col == this.col && row == this.row)     // inside!
            return false;

        dist = Math.sqrt(Math.pow((this.x - col - 0.5), 2) + Math.pow((this.y - row - 0.5), 2));

        if (dist > 1.2)
            return true;

        if (col == this.col                    && row == Math.floor(this.y + this.r))
            return false;
        if (col == this.col                    && row == Math.floor(this.y - this.r))
            return false;
        if (col == Math.floor(this.x + this.r) && row == this.row)
            return false;
        if (col == Math.floor(this.x - this.r) && row == this.row)
            return false;

        
        return true;
    }

    refreshColRow() {
        this.col = Math.floor(this.x);
        this.row = Math.floor(this.y);
    }

    start(speed) {
        this.speed = speed;
    }

    reset() {
        let emptyBricks = BRICKS.getEmptyBricks();
        let rnd         = emptyBricks[Math.floor(Math.random() * emptyBricks.length)];

        // convert col, row into x, y
        this.x  = rnd.col + 0.5;
        this.y  = rnd.row + 0.5;
        this.dx = (Math.random() > 0.5) ? this.speed : -this.speed;
        this.dy = (Math.random() > 0.5) ? this.speed : -this.speed;

        this.refreshColRow();
    }

    update() {
        let timeDelta = Math.min(TIME_LIMIT, LOGICS.timer.delta);

        this.x += this.dx * timeDelta;
        this.y += this.dy * timeDelta;

        this.checkCollisions();
    }

    checkCollisions() {
        let isBounced = false,
            col, row, bounce, touchPoint, dist;

        this.refreshColRow();

        if (this.dx < 0) {              // left
            touchPoint = this.x - this.r;
            col        = Math.floor(touchPoint);

            if (!BRICKS.getBrick(col, this.row).isEmpty) {
                bounce    = this.col - touchPoint;
                this.x   += bounce * 2;
                this.dx   = -this.dx;
                this.col  = Math.floor(this.x);
                isBounced = true;

                TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: this.row});
            }
        } else {                        // right
            touchPoint = this.x + this.r;
            col        = Math.floor(touchPoint);

            if (!BRICKS.getBrick(col, this.row).isEmpty) {
                bounce    = touchPoint - col;
                this.x   -= bounce * 2;
                this.dx   = -this.dx;
                this.col  = Math.floor(this.x);
                isBounced = true;

                TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: this.row});
            }
        }

        if (this.dy < 0) {              // top
            touchPoint = this.y - this.r;
            row        = Math.floor(touchPoint);

            if (!BRICKS.getBrick(this.col, row).isEmpty) {
                bounce    = this.row - touchPoint;

                this.y   += bounce * 2;
                this.dy   = -this.dy;
                isBounced = true;

                TRIGGERS.add(BALL_HITS_BRICK, {col: this.col, row: row});
            }
        } else {                        // bottom
            touchPoint = this.y + this.r;
            row        = Math.floor(touchPoint);
            
            if (!BRICKS.getBrick(this.col, row).isEmpty) {
                bounce    = touchPoint - row;
                this.y   -= bounce * 2;
                this.dy   = -this.dy;
                isBounced = true;

                TRIGGERS.add(BALL_HITS_BRICK, {col: this.col, row: row});
            }
        }

        if (isBounced)
            return;

        // check corners
        if (this.dx > 0) {
            if (this.dy > 0) {          // right-bottom
                col = this.col + 1;
                row = this.row + 1;

                if (!BRICKS.getBrick(col, row).isEmpty) {
                    dist = this.r - this.getDistance(this.x, this.y, col, row);

                    if (dist > 0) {
                        if (this.x + this.r - col > this.y + this.r - row) {
                            this.y  -= dist;
                            this.dy  = -this.dy;
                        } else {
                            this.x  -= dist;
                            this.dx  = -this.dx;
                        }

                        TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: row});
                    }
                }
            } else {                    // right-top
                col = this.col + 1;
                row = this.row - 1;

                if (!BRICKS.getBrick(col, row).isEmpty) {
                    dist = this.r - this.getDistance(this.x, this.y, col, this.row);

                    if (dist > 0) {
                        if (this.x + this.r - col > this.row - (this.y - this.r)) {
                            this.y  += dist;
                            this.dy  = -this.dy;
                        } else {
                            this.x  -= dist;
                            this.dx  = -this.dx;
                        }

                        TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: row});
                    }
                }
            }
        } else {
            if (this.dy > 0) {          // left-bottom
                col = this.col - 1;
                row = this.row + 1;

                if (!BRICKS.getBrick(col, row).isEmpty) {
                    dist = this.r - this.getDistance(this.x, this.y, this.col, row);

                    if (dist > 0) {
                        if (this.col - (this.x - this.r) > this.y + this.r - row) {
                            this.y  -= dist;
                            this.dy  = -this.dy;
                        } else {
                            this.x  -= dist;
                            this.dx  = -this.dx;
                        }

                        TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: row});
                    }
                }
            } else {                    // left-top
                col = this.col - 1;
                row = this.row - 1;

                if (!BRICKS.getBrick(col, row).isEmpty) {
                    dist = this.r - this.getDistance(this.x, this.y, this.col, this.row);

                    if (dist > 0) {
                        if (this.col - (this.x - this.r) > this.row - (this.y - this.r)) {
                            this.y  += dist;
                            this.dy  = -this.dy;
                        } else {
                            this.x  -= dist;
                            this.dx  = -this.dx;
                        }

                        TRIGGERS.add(BALL_HITS_BRICK, {col: col, row: row});
                    }
                }
            }
        }
    }

    getDistance(x1, y1, x2, y2) {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }
}


export const LOGICS_BALL = new LogicsBall();
