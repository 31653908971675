
import { getByName } from "../bk engine utils.js"
import { BKengineImage } from "../primitives/image.js"


class Images {
    constructor() {
        this.all = [];
    }

    add(image) {
        return this.all[this.all.push(image) - 1];
    }

    addNew() {
        let args  = (typeof arguments[0] == "string") ? arguments : arguments[0],
            image = new BKengineImage(),
            i, l, prop, value;

        for (i = 0, l = args.length; i < l; i += 2) {
            prop  = args[i];
            value = args[i + 1];

            image[prop] = value;
        }

        return this.add(image);
    }

    getByName(name) {
        return getByName(this.all, name);
    }
}


export const IMAGES = new Images();
