
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_BIBLE } from "./_gw bible.js";


const TIME_MOVE = 500;


class PlayMove extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.time = 0;
    }

    start2(dst, isOpen = false) {
        this.time                    = (dst == GW_BIBLE.playSelected.src) ? Date.now() - TIME_MOVE : Date.now();
        GW_BIBLE.playSelected.dst    = dst;
        GW_BIBLE.playSelected.isOpen = isOpen;
    }

    update(oProgress, isOpen = true) {
        let progress = oProgress || Math.min((Date.now() - this.time) / TIME_MOVE, 1),
            src      = -0.5 - GW_BIBLE.playSelected.src,
            dst      = -0.5 - GW_BIBLE.playSelected.dst;

        GW_BIBLE.item.desktopPlayBtn.pivX = src + (dst - src) * progress;

        if (progress == 1) {                        // animation ended
            GW_BIBLE.playSelected.src = GW_BIBLE.playSelected.dst;
            
            this.end();
            GW_BIBLE.resetMenuBar();
            GW_BIBLE.updateCookie();

            if (isOpen && GW_BIBLE.playSelected.isOpen)
                GW_BIBLE.startGame();
        }
    }
}

export const PLAY_MOVE = BK_ENGINE.tasks.add(new PlayMove(BK_ENGINE.tasks));
