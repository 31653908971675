
import { BK_ENGINE } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";


const BOTTOM_Y    = 0.94,
      SCROLL_TIME = 750;       // 1000


class Line {
    constructor(chars, align) {
        this.chars = chars;
        this.align = align;
    }
}


class DisplayLines {
    constructor() {
        this.coordsInit  = {x: 0, w: 0};
        this.coords      = {left: 0, width: 0, bottom: 0};
        this.scroll      = {value: 0, add: 0, dateStart: 0};

        this.wordI       = 0;
        this.lineI       = 0;

        this.curr        = "";

        this.all         = [];

        this.canvas      = null;
        this.text        = null;
        this.sprite      = null;

        this.isIntiated = false;
    }

    addNewLine(chars = "", align = "left") {
        this.all.push(new Line(chars, align));

        this.scroll.add++;
    }

    getLineWidth(chars) {
        return this.text.getWidth(this.canvas, chars);
    }

    init() {
        this.canvas = BK_ENGINE.canvases.getByName("bible_g0001_play_lines");
        this.text   = BK_ENGINE.texts.getByName("bible_g0001_play_lines");
        this.sprite = BK_ENGINE.items.getByName("bible_g0001_play_lines");

        switch (BK_ENGINE.viewPort.orientation) {
            case "verNarrow":
                this.coordsInit.x = 0.017;
                this.coordsInit.w = 0.966;
                break;
            case "verWide":
                this.coordsInit.x = 0.03;
                this.coordsInit.w = 0.94;
                break;
            case "horNarrow":
            case "horWide":
                this.coordsInit.x = 0.05;
                this.coordsInit.w = 0.9;
        }

        this.isIntiated = true;
    }

    start(bookName, chapterName) {
        !this.isIntiated && this.init();

        this.wordI = this.lineI = this.scroll.value = 0;
        this.curr  = "";
        this.all   = [];

        this.update_canvas();
        this.start_bookName(bookName);
        this.addNewLine();
        this.addNewLine(chapterName, "center");
        this.addNewLine();
        this.addNewLine();
        this.addNewLine();

        this.scroll.add = 1;
    }

    start_bookName(bookName) {      // add line(s) with book's name, if the name is too long divide it in more lines
        let words = bookName.split(" "),
            line  = "",
            word;

        while (words.length > 0) {
            word = words.shift();

            if (this.getLineWidth(line + word) < this.coords.width)
                line += word + " ";
            else {
                this.addNewLine(line.trim(), "center");

                line = word;
            }
        }

        this.addNewLine(line.trim(), "center");
    }

    update() {
        let isRefresh = this.update_canvas();

        if (this.update_scroll())
            isRefresh = true;

        if (this.update_newWord() || this.update_currWord())
            isRefresh = true;

        if (isRefresh)
            this.refresh();
    }

    update_canvas() {
        if (this.canvas.width != this.sprite.width || this.canvas.height != this.sprite.height) {
            this.canvas.resize(this.sprite.width, this.sprite.height);
            this.text.calcHeight(this.canvas.height);

            this.coords.left   = Math.round(this.coordsInit.x * this.canvas.width);
            this.coords.width  = Math.round(this.coordsInit.w * this.canvas.width);
            this.coords.bottom = Math.round(BOTTOM_Y          * this.canvas.height);

            return true;
        }

        return false;
    }

    update_scroll() {
        if (this.scroll.value > 0) {
            this.scroll.value = 1 - (Date.now() - this.scroll.dateStart) / SCROLL_TIME;

            if (this.scroll.value <= 0)
                this.scroll.value = 0;

            return true;
        }
        
        if (this.scroll.add > 0) {
            this.scroll.add--;
            this.scroll.value = 1;
            this.scroll.dateStart = Date.now();

            return true;
        }

        return false;
    }

    update_newWord() {
        let isNewLine = false,
            line, wordLog;

        if (LOGICS.words.all.length <= this.wordI)
            return false;

        this.curr = "";

        line    = this.all[this.all.length - 1];

        wordLog = LOGICS.words.all[this.wordI++];

        // new word in a new line?
        if (wordLog.lineI != this.lineI) {
            this.lineI = wordLog.lineI;
            isNewLine  = true;
        }

        if (!isNewLine && (this.getLineWidth(line.chars + wordLog.chars) > this.coords.width))
            isNewLine = true;

        if (isNewLine)
            this.addNewLine();

        this.all[this.all.length - 1].chars += wordLog.chars;

        if (this.all[this.all.length - 1].chars.trim().length == 0)
            this.all[this.all.length - 1].chars = "";         // no spaces at the beginning of a line!

        return true;
    }

    update_currWord() {
        let wordLog = LOGICS.word;

        if (wordLog.curr != this.curr) {
            this.curr = wordLog.curr;

            if ((wordLog.lineI != this.lineI) ||
                (this.getLineWidth(this.all[this.all.length - 1].chars + wordLog.wholeSorted) > this.coords.width)) {
                    this.lineI = wordLog.lineI;

                    this.addNewLine();
            }

            return true;
        }

        return false;
    }

    refresh() {
        let coords  = {left: 0, top: 0},
            lineI   = -1,
            linesNm = this.all.length,
            offsetTop, line, chars;

        this.canvas.clear();

        // redraw, starting from the bottom line
        offsetTop = Math.round(this.text.height * this.scroll.value + this.text.height * this.scroll.add);

        while (++lineI < linesNm) {
            line  = this.all[linesNm - lineI - 1];
            chars = line.chars;

            if (lineI == 0)
                chars += this.curr;

            coords.left = line.align == "left" ? this.coords.left : this.coords.left + Math.round(this.coords.width / 2);
            coords.top  = offsetTop + this.coords.bottom - lineI * this.text.height;

            this.text.align = line.align;

            this.text.prepare(this.canvas);
            this.text.draw(this.canvas, coords, chars);
        }

        // remove first line if outside the canvas
        if (top < 0)
            this.all.shift();

        this.sprite.isRedrawn = false;
    }
}

export const DISPLAY_LINES = new DisplayLines();
