
import { BK_ENGINE, Coords, RGBAtoString, stringToRGBA } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0002 play display.js";
import { DISPLAY_QUOTE as QUOTE } from "./display quote.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";


const HIGHLIGHT = {size:      0.1,             // of PLAYFIELD.tileSize
                   colorFrom: "#000000ff",      // will be updated from this.text
                   colorTo:   "#00000000"};


class DisplayHiglightWord {
    constructor() {
        this.canvas      = BK_ENGINE.canvases.addNew();
        this.coords      = {canvas: new Coords(),
                            quote:  new Coords()};
        this.text        = null;
        this.timeStart   = 0;

        this.isInitiated = false;
    }

    init() {
        this.text           = BK_ENGINE.texts.getByName("bible_g0002_play_letter");
        HIGHLIGHT.colorFrom = this.text.color;

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();
    }

    reset(timeStart) {
        this.timeStart = timeStart;

        this.redraw();
    }

    update() {
        let time   = LOGICS.getTimerWaiting() / this.timeStart * 0.5;
        let transp = Math.max(0, Math.min(1, Math.pow(time, 2) + time * 2));    // parabola equation

        DISPLAY.canvas.pgd.saveTransp();
        DISPLAY.canvas.pgd.setTransp(transp);
        DISPLAY.canvas.pgd.drawImage(this.canvas, this.coords.canvas, this.coords.quote);
        DISPLAY.canvas.pgd.restoreTransp();
    }

    resize() {
        this.redraw();
    }

    redraw() {
        let s          = PLAYFIELD.tileSize;
        let sHalf      = Math.floor(s / 2),
            sHighlight = Math.max(2, Math.round(s * HIGHLIGHT.size)),
            word       = QUOTE.getLastWord(),
            i, letter;

        if (!word || word.letters.length == 0)
            return;

        // calc coords
        this.coords.quote.width = 0;

        for (i = 0; i < word.letters.length; i++)
            this.coords.quote.width += word.letters[i].coords.width;

        this.coords.quote.left    = word.letters[0].coords.left - sHighlight;
        this.coords.quote.top     = word.letters[0].coords.top  - sHighlight - sHalf;
        this.coords.quote.width  += sHighlight * 2;
        this.coords.quote.height  = s + sHighlight * 2;

        this.coords.canvas.resize(this.coords.quote.width, this.coords.quote.height);

        this.canvas.resize(this.coords.quote.width, this.coords.quote.height);

        this.text.calcHeight(PLAYFIELD.tileSize);
        this.text.prepare(this.canvas);

        for (i = 0; i < word.letters.length; i++) {
            letter = word.letters[i];

            this.text.draw(this.canvas, {left: letter.coords.left - this.coords.quote.left, top: sHalf + sHighlight}, letter.char);
        }

        this.redraw_halo(sHighlight);
     }

     redraw_halo(size) {
        let colorFrom = stringToRGBA(HIGHLIGHT.colorFrom),
            colorTo   = stringToRGBA(HIGHLIGHT.colorTo),
            rgba      = stringToRGBA(),
            i, l;

        this.canvas.antiAlias(HIGHLIGHT.colorFrom);

        for (i = 0, l = size - 1; i <= l; i++) {
            rgba.r = Math.floor((colorFrom.r * (1 - i / l) + colorTo.r * i / l));
            rgba.g = Math.floor((colorFrom.g * (1 - i / l) + colorTo.g * i / l));
            rgba.b = Math.floor((colorFrom.b * (1 - i / l) + colorTo.b * i / l));
            rgba.a = Math.floor((colorFrom.a * (1 - i / l) + colorTo.a * i / l));

            this.canvas.outline(RGBAtoString(rgba));
        }
    }
}

export const DISPLAY_HIGHLIGHT_WORD = new DisplayHiglightWord();
