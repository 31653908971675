
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"
import { HELP } from "../help/help.js"
import { CONTROL_BOX } from "./_gw main.js";


class UserTAC extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_userTAC"),
                     cb:        I.getByName("main_userTAC_cb"),
                     title:     I.getByName("main_userTAC_title")};

        this.isInitiated = true;
    }

    start2(title) {
        !this.isInitiated && this.init();

        this.item.title.content = title;
        this.show(true);
    }

    end2(selected = "") {
        this.selected = selected;

        this.show(false);
    }

    update() {
        this.refresh();

        switch (BK_ENGINE.clicked.name) {
            case "main_userTAC_accept":
                return this.item.cb.isChecked = !this.item.cb.isChecked;
            case "main_userTAC_link":
                HELP.openWindow(BK_ENGINE.clicked.info);
        }

        switch (CONTROL_BOX.selected) {
            case "confirm":
            case "abandon":
                this.end(CONTROL_BOX.selected);
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible) {
            CONTROL_BOX.start("", "", ["abandonL", "confirm"]);

            return this.item.cb.isChecked = false;            
        }

        CONTROL_BOX.disableBtn("confirm", false);
        CONTROL_BOX.end();
    }

    refresh() {
        CONTROL_BOX.disableBtn("confirm", !this.item.cb.isChecked);
    }
}


export const USER_TAC = BK_ENGINE.tasks.add(new UserTAC(BK_ENGINE.tasks));
