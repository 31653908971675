
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../_gw bible.js";
import { HOLY_BIBLE } from "./_holy bible.js";


const TIME_UPDATE = 500;        // how often to update the progress bar (ms)


class LoadingBooks extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.itemProgressBar = null;
        this.bookI           = 0;
        this.isInitiated     = false;
    }

    start2() {
        if (!this.isInitiated) {
            this.itemProgressBar = BK_ENGINE.items.getByName("bible_bookHolyBible_progressBar");
            this.isInitiated     = true;
        }

        // this.booksIndexes = GW_BIBLE.books.denomIndexes;
        this.bookI = 0;

        this.show(true);
        HOLY_BIBLE.item.book.reset();
    }

    update() {
        this.addTextContent();

        this.itemProgressBar.progress = this.bookI / GW_BIBLE.books.denomIndexes.length;

        if (this.bookI == GW_BIBLE.books.denomIndexes.length) {
            GW_BIBLE.books.isAnyChanged = false;

            this.show(false);
            this.end();
        }
    }

    addTextContent() {
        let dateStart = Date.now(),
            textContentGuessed, part, book, isContinue;

        // Old Testament
        do {
            book = GW_BIBLE.books.getByI(GW_BIBLE.books.denomIndexes[this.bookI]);

            if (book.part == "OT") {
                if ((textContentGuessed = book.getTextContentGuessed()) != null) {
                    if (this.bookI == 0) {                      // Title "Old Testament"
                        part        = GW_BIBLE.parts.OT;
                        part.pageNm = HOLY_BIBLE.item.book.addEmptyPage();

                        HOLY_BIBLE.item.book.addTextContent(part.textContent);
                    }

                    book.pageNm = HOLY_BIBLE.item.book.addEmptyPage();
                    
                    HOLY_BIBLE.item.book.addTextContent(book.textContentName);
                    HOLY_BIBLE.item.book.addEmptyPage();
                    HOLY_BIBLE.item.book.addTextContent(textContentGuessed, true);

                    this.bookI++;

                    isContinue = true;

                    if ((Date.now() - dateStart) > TIME_UPDATE)
                        return;
                } else
                    isContinue = false;
            } else
                isContinue = false;
        } while (isContinue);

        // New Testament
        do {
            book = GW_BIBLE.books.getByI(GW_BIBLE.books.denomIndexes[this.bookI]);

            if ((textContentGuessed = book.getTextContentGuessed()) != null) {
                if (book.tag == "Mt") {                         // Title "New Testament"
                    part        = GW_BIBLE.parts.NT;
                    part.pageNm = HOLY_BIBLE.item.book.addEmptyPage();

                    HOLY_BIBLE.item.book.addTextContent(part.textContent);
                }

                book.pageNm = HOLY_BIBLE.item.book.addEmptyPage();

                HOLY_BIBLE.item.book.addTextContent(book.textContentName);
                HOLY_BIBLE.item.book.addEmptyPage();
                HOLY_BIBLE.item.book.addTextContent(textContentGuessed, true);

                isContinue = (++this.bookI == GW_BIBLE.books.denomIndexes.length) ? false : true;

                if ((Date.now() - dateStart) > TIME_UPDATE)
                    return;
            } else
                isContinue = false;
        } while (isContinue);
    }

    show(isVisible) {
        this.itemProgressBar.progress       = 0;
        HOLY_BIBLE.item.mainFrame.isVisible = this.itemProgressBar.isVisible = isVisible;
    }
}

export const LOADING_BOOKS = BK_ENGINE.tasks.add(new LoadingBooks(BK_ENGINE.tasks));
