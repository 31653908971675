
import { PLAY_LOGICS as LOGICS } from "./_bible g0002 play logics.js";


const SPEED = 0.00075;


class LogicsLetters {
    constructor() {
        this.STATE       = {waiting: 10, guessing: 20};
        this.state       = 0;

        this.all         = [];
        this.activeNm    = 0;

        this.isInitiated = false;
    }

    getByI(i) {
        return this.all[i];
    }

    start() {
        this.state = this.STATE.waiting;
    }

    reset() {
        let word = LOGICS.currWord,
            i, letter;

        this.all = [];

        for (i = 0; i < word.letters.length; i++)
            word.letters[i].progress = 1;

        if (LOGICS.isGuessingLastChar) {
            this.activeNm   = 1;
            letter          = word.letters[word.partFirst.length + word.charsToGuess.length - 1];
            letter.progress = 0;

            this.all.push(letter);
        } else {
            this.activeNm = word.charsToGuess.length - 1;

            for (i = 0; i < this.activeNm; i++) {
                letter          = word.letters[word.partFirst.length + i];
                letter.progress = 0;

                this.all.push(letter);
            }
        }

        this.state = this.STATE.guessing;
    }

    update() {
        switch (this.state) {
            // case this.STATE.waiting:     // nothing happens
            case this.STATE.guessing:
                this.guessing();
        }
    }

    guessing() {
        let arrivedNm = 0,
            i, letter;

        for (i = 0; i < this.activeNm; i++) {
            letter = this.all[i];

            if (letter.brick.hp == 0) {
                if (letter.progress < 1)
                    letter.progress = Math.min(1, letter.progress + LOGICS.timer.delta * SPEED);
                else
                    arrivedNm++;
            }
            //  else
            //     letter.progress = 0;
            // console.log(letter.progress)
        }

        if (arrivedNm == this.activeNm)
            this.state = this.STATE.waiting;
    }
}

export const LOGICS_LETTERS = new LogicsLetters();
