
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { msToDHMS } from "../../../bk utils/_bk utils.js"
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_DB } from "../../../gw db/_gw db.js";
import { GW_MAIN } from "../../_gw main.js";
import { USER_TAC } from "../../user tac.js";
import { CONTROL_BOX, INFO_BOX } from "../../_gw main.js";
import { MENU_SELECT } from "./menu select.js";


const DEVELOP = {isTest: false};

const STATE = {notLoggedIn: 10, loadingData: 20, main: 30, selectingCountry: 40, selectingRelig: 50, selectingReligDenom: 60, limitsInfo: 70, confirming: 80};


class SetData extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = null;
        this.item        = null;
        this.user        = {name: "", contact: "", country: "", relig: "", religDenom: ""};
        this.data        = {country: [], relig: [], religDenom: []};
        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame:     I.getByName("main_userSetData"),
                     name:          I.getByName("main_userSetData_name"),
                     nameWait:      I.getByName("main_userSetData_nameWait"),
                     contact:       I.getByName("main_userSetData_contact"),
                     contactWait:   I.getByName("main_userSetData_contactWait"),
                     countryLbl:    I.getByName("main_userSetData_countryLbl"),
                     countryBtn:    I.getByName("main_userSetData_countryBtn"),
                     religLbl:      I.getByName("main_userSetData_religLbl"),
                     religBtn:      I.getByName("main_userSetData_religBtn"),
                     religDenomLbl: I.getByName("main_userSetData_religDenomLbl"),
                     religDenomBtn: I.getByName("main_userSetData_religDenomBtn")};

        this.isInitiated = true;
    }

    initData() {
        this.user.name       = GW_USER.name;
        this.user.contact    = GW_USER.contact;
        this.user.country    = GW_USER.country;
        this.user.relig      = GW_USER.relig;
        this.user.religDenom = GW_USER.religDenom;

        this.item.countryLbl.caption    = GW_DB.user.country;
        this.item.religLbl.caption      = GW_DB.user.relig;
        this.item.religDenomLbl.caption = GW_DB.user.religDenom;

        // religion
        this.data.relig = [];

        for (const prop in GW_DB.relig)
            this.data.relig.push(prop, GW_DB.relig[prop]);

        // christian denomination
        this.data.religDenom = [];

        for (const prop in GW_DB.religDenom)
            this.data.religDenom.push(prop, GW_DB.religDenom[prop]);

        // countries
        this.data.country = [];

        for (const prop in GW_DB.country)
            this.data.country.push(prop, GW_DB.country[prop].name);
    }

    start2() {
        !this.isInitiated && this.init();

        if (GW_USER.isLoggedIn)
            return this.state = STATE.loadingData;

        this.state = STATE.notLoggedIn;

        INFO_BOX.start(GW_MAIN.file.lang.getCaption("userSetData_notLoggedIn"), "gotIt");
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (this.state) {
            case STATE.notLoggedIn:
                return this.update_notLoggedIn();
            case STATE.loadingData:
                return this.update_loadingData();
            case STATE.selectingCountry:
                return this.update_selectingCountry();
            case STATE.selectingRelig:
                return this.update_selectingRelig();
            case STATE.selectingReligDenom:
                return this.update_selectingReligDenom();
            case STATE.limitsInfo:
                return this.update_limitsInfo();
            case STATE.confirming:
                return this.update_confirming();
            default:
                this.update_main();
        }
    }

    resume() {
        this.state = STATE.main;
    
        this.show(true);
    }

    update_notLoggedIn() {
        if (!INFO_BOX.isActive)
            this.end();
    }

    update_loadingData() {
        if (GW_DB.isReady()) {
            this.initData();
            this.resume();
        }
    }

    update_main() {
        switch (BK_ENGINE.clicked.name) {
            case "main_userSetData_countryBtn":
                this.state = STATE.selectingCountry;
                this.show(false);
                return MENU_SELECT.start(this.item.countryLbl.caption, this.data.country, this.user.country);
            case "main_userSetData_religBtn":
                this.state = STATE.selectingRelig;
                this.show(false);
                return MENU_SELECT.start(this.item.religLbl.caption, this.data.relig, this.user.relig);
            case "main_userSetData_religDenomBtn":
                this.state = STATE.selectingReligDenom;
                this.show(false);
                return MENU_SELECT.start(this.item.religDenomLbl.caption, this.data.religDenom, this.user.religDenom);
        }

        switch (CONTROL_BOX.selected) {
            case "confirm":
                this.user.name    = this.item.name.content;
                this.user.contact = this.item.contact.content;

                for (const prop in this.user) {
                    if (this.user[prop] != GW_USER[prop]) {
                        this.show(false);
                        CONTROL_BOX.end();

                        // name or contact changed?
                        if (this.user.name != GW_USER.name || this.user.contact != GW_USER.contact) {
                            this.state = STATE.limitsInfo;

                            return CONTROL_BOX.start("", GW_MAIN.file.lang.getCaption("userSetData_limits"), ["abandonL", "confirm"]);
                        }

                        return this.update_confirmingStart();
                    }
                }
            case "abandon":
                return this.end();
        }

        this.update_main_time();
    }

    update_main_time() {
        let nameTime    = GW_USER.getNameUpdateTime(),
            contactTime = GW_USER.getContactUpdateTime(),
            days        = GW_MAIN.file.lang.getCaption("userSetData_days"),
            d;

        // name
        if (nameTime > 0) {
            d = msToDHMS(nameTime * 1000);

            this.item.nameWait.caption = d.days > 0 ? GW_DB.user.name + " (" + days + ": " + d.days + ")" :
                                                      GW_DB.user.name + " (" + d.hours + ":" + d.mins + ":" + d.secs + ")";
        }

        this.item.name.isDisabled = this.item.nameWait.isVisible = nameTime > 0;
        this.item.name.caption    = nameTime > 0 ? "" : GW_DB.user.name;

        // contact
        if (contactTime > 0) {
            d = msToDHMS(contactTime * 1000);

            this.item.contactWait.caption = d.days > 0 ? GW_DB.user.contact + " (" + days + ": " + d.days + ")" :
                                                         GW_DB.user.contact + " (" + d.hours + ":" + d.mins + ":" + d.secs + ")";
        }

        this.item.contact.isDisabled = this.item.contactWait.isVisible = contactTime > 0;
        this.item.contact.caption    = contactTime > 0 ? "" : GW_DB.user.contact;
    }

    update_selectingCountry() {
        if (MENU_SELECT.isActive)
            return;

        if (MENU_SELECT.selected != "quit")
            this.user.country = MENU_SELECT.selected;

        this.resume();
    }

    update_selectingRelig() {
        if (MENU_SELECT.isActive)
            return;

        if (MENU_SELECT.selected != "quit")
            this.user.relig = MENU_SELECT.selected;

        this.resume();
    }

    update_selectingReligDenom() {
        if (MENU_SELECT.isActive)
            return;

        if (MENU_SELECT.selected != "quit")
            this.user.religDenom = MENU_SELECT.selected;

        this.resume();
    }

    update_limitsInfo() {
        switch (CONTROL_BOX.selected) {
            case "confirm":
                return this.update_confirmingStart();
            case "abandon":
                this.resume();
        }
    }

    update_confirmingStart() {
        this.state = STATE.confirming;

        USER_TAC.start(GW_MAIN.file.lang.getCaption("userSetData_confirmTAC"));
    }

    update_confirming() {
        if (USER_TAC.isActive)
            return;

        if (USER_TAC.selected == "confirm") {
            for (const prop in this.user) {
                if (this.user[prop] != GW_USER[prop])
                    GW_USER[prop] = this.user[prop];
            }

            this.end();
        } else
            this.resume();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        this.item.name.setVisible(isVisible);
        this.item.contact.setVisible(isVisible);

        if (isVisible) {
            CONTROL_BOX.start("", "", ["abandonL", "confirm"]);

            this.item.religDenomBtn.isDisabled = this.user.relig != "c";

            return this.refresh();
        }

        this.user.name    = this.item.name.content;
        this.user.contact = this.item.contact.content;

        CONTROL_BOX.end();
    }

    refresh() {
        let select     = GW_MAIN.file.lang.getCaption("userSetData_select"),
            country    = GW_DB.country[this.user.country],
            relig      = GW_DB.relig[this.user.relig],
            religDenom = GW_DB.religDenom[this.user.religDenom];

        this.item.name.content    = this.user.name;
        this.item.contact.content = this.user.contact;

        this.item.countryBtn.caption   = country ? country.name : select;
        this.item.countryBtn.isRedrawn = false;

        this.item.religBtn.caption   = relig ? relig : select;
        this.item.religBtn.isRedrawn = false;

        this.item.religDenomBtn.caption   = (religDenom && this.user.relig == "c") ? religDenom : select;
        this.item.religDenomBtn.isRedrawn = false;
    }
}

export const SET_DATA = BK_ENGINE.tasks.add(new SetData(BK_ENGINE.tasks));
