
import { BK_ENGINE, Task } from "../../../../../bk engine/_bk engine.js";
import { LOGICS_BALL as BALL, LOGICS_BALL } from "./logics ball.js";
import { LOGICS_BRICKS as BRICKS } from "./logics bricks.js";
import { LOGICS_LETTERS as LETTERS } from "./logics letters.js";
import { TRIGGERS, PLAYFIELD_CLICKED } from "./logics triggers.js";
import { LOGICS_EVENTS as EVENTS } from "./logics events.js";
import { PLAY_DISPLAY as DISPLAY } from "../display/_bible g0002 play display.js";


class Letter {
    constructor(char, progress) {
        this.char     = char;
        this.progress = progress;
        this.brick    = BRICKS.grid[0][0];
    }
}

class Word {
    constructor(partFirst, charsToGuess, partLast) {
        this.partFirst    = partFirst;
        this.charsToGuess = charsToGuess;
        this.partLast     = partLast;

        this.letters      = [];
    }
}


class PlayLogics extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE              = {waiting: 10, playing: 20, guessingLastChar: 30, highlightingWord: 40};
        this.state              = 0;

        this.alphabet           = "";
        this.allCharsUsed       = "";
        this.playfieldSize      = 0;
        this.attemptsNm         = 0;
        this.hp                 = 0;

        this.words              = [];
        this.wordI              = 0;
        this.currWord           = null;
        this.charsToGuessNm     = 0;
        this.playerMoves        = 0;        // for some stats to be saved into the database

        this.isPaused           = false;
        this.isPlayingStarted   = false;
        this.isGuessingLastChar = false;
        this.isWordGuessed      = false;
        this.isTimeOut          = false;

        this.timer              = {dateLast: 0, delta: 0, waiting: 0, game: 0, gameLast: 0, seconds: 0};

        this.timer.reset = function(oGameTime) {
            this.game     = oGameTime || this.gameLast;
            this.gameLast = this.game;
            this.dateLast = Date.now();
            this.delta    = 0;
        }

        this.timer.update = function(isUpdateGame = true) {
            let dateNow = Date.now();
    
            this.delta     = dateNow - this.dateLast;
            this.dateLast  = dateNow;
            this.waiting  -= this.delta;

            if (isUpdateGame)
                this.game -= this.delta;
        }

        this.timer.unpause = function() {
            this.dateLast = Date.now();
            this.delta    = 0;
        }
    }

    // *** CALL FROM OUTSIDE ***

    // first to call: start();

    isReady() {
        return true;
    }

    setProp() {
        let i, prop, value;

        for (i = 0; i < arguments.length; i += 2) {
            prop  = arguments[i];
            value = arguments[i + 1];

            switch (prop) {
                // case "hp":
                // case "isWordReset":
                // case "playfieldSize":
                    // this[prop] = value;
                    // break;
                default:
                    console.log("LOGICS.setProp: " + prop + " not servered!");
            }
        }
    }

    getLastWord() {
        return this.words[this.words.length - 1];
    }

    getTimerGame() {
        return this.timer.game;
    }

    startGame(playfieldSize, bricksNm, ballSpeed, attemptsNm, hp, alphabet, allCharsUsed) {
        this.playfieldSize  = playfieldSize;
        this.attemptsNm     = attemptsNm;
        this.hp             = hp;
        this.alphabet       = alphabet;
        this.allCharsUsed   = allCharsUsed;
// console.log("attemptsNm: " + this.attemptsNm + " hp per letter: " + this.hp)
        this.state          = this.STATE.playing;
        this.words          = [];
        this.wordI          = this.charsToGuessNm = this.playerMoves = 0;
        this.isWordGuessed  = this.isPaused = this.isGuessingLastChar = false;

        BALL.start(ballSpeed);
        BRICKS.start(bricksNm);
        LETTERS.start();
        TRIGGERS.start();
    }

    addNewWord(partFirst, charsToGuess, partLast) {
        let word = new Word(partFirst, charsToGuess, partLast),
            letterI;

        // letters from the first part (guessed)
        for (letterI = 0; letterI < partFirst.length; letterI++)
            word.letters.push(new Letter(partFirst.charAt(letterI), 1));

        // letters to guess
        for (letterI = 0; letterI < charsToGuess.length; letterI++)
            word.letters.push(new Letter(charsToGuess.charAt(letterI), 0));

        // letters from the last part (guessed)
        for (letterI = 0; letterI < partLast.length; letterI++)
            word.letters.push(new Letter(partLast.charAt(letterI), 1));

        this.words.push(word);

        this.wordI    = this.words.length - 1;
        this.currWord = this.words[this.wordI];
    }

    startGuessingNewWord(oGameTime, oIsGuessingLastChar) {
        if (oIsGuessingLastChar != undefined)
            this.isGuessingLastChar = oIsGuessingLastChar;

        this.state            = this.STATE.playing;
        this.wordI            = this.words.length - 1;
        this.currWord         = this.words[this.wordI];
        this.charsToGuessNm   = this.isGuessingLastChar ? 1 : this.currWord.charsToGuess.length - 1;
        this.isPlayingStarted = this.isWordGuessed = this.isTimeOut = false;

        this.timer.reset(oGameTime);
        BRICKS.clear();        
        BALL.reset();
        LETTERS.reset();
        BRICKS.reset();
        TRIGGERS.reset();
        
        EVENTS.add("display", "guessingNewWord");
    }

    startPlaying() {
        this.isPlayingStarted = true;

        this.timer.reset();
    }

    startGuessingLastChar() {
        this.state = this.STATE.guessingLastChar;

        EVENTS.add("display", "guessingLastChar");
    }

    startHighlightingWord(time) {
        this.state         = this.STATE.highlightingWord;
        this.timer.waiting = time;

        EVENTS.add("display", "highlightingWord", time);
    }

    setLastCharGuessed(char, time) {
        this.updateLastCharGuessed(char);

        this.timer.waiting = time;

        EVENTS.add("display", "lastCharCorrect", {char: char, time: time});
        EVENTS.add("audio",   "lastCharCorrect");
    }

    setLastCharToGuess(charWrong, charCorrect, time) {
        this.updateLastCharGuessed(charCorrect);

        this.timer.waiting = time;

        EVENTS.add("display", "lastCharWrong", {char: charWrong, time: time});
        EVENTS.add("audio",   "lastCharWrong");
    }

    updateLastCharGuessed(char) {
        let word = this.getLastWord();
        let l    = word.charsToGuess.length;

        word.charsToGuess = word.charsToGuess.substring(0, l - 1) + char;
        word.letters[word.partFirst.length + l - 1].char = char;
    }

    startWaiting(time = this.timer.waiting) {
        // let time = oTime || this.timer.waiting;

        this.state         = this.STATE.waiting;
        this.timer.waiting = time;
    }

    isGameStarted() {
        return this.state != this.STATE.waiting;
    }

    isGuessingLastChar() {
        return this.state == this.STATE.guessingLastChar;
    }

    isPlaying() {
        return this.state == this.STATE.playing;
    }

    getBrickFromLeftTop(left, top) {
        let col = DISPLAY.getPlayfieldColFromLeft(left),
            row = DISPLAY.getPlayfieldRowFromTop(top);

        return (col > -1 && col < this.playfieldSize && row > -1 && row < this.playfieldSize) ? BRICKS.getBrick(col, row) : {col: -1, letter: null};
    }

    getTimerWaiting() {
        return this.timer.waiting;
    }

    playfieldClicked(data) {
        TRIGGERS.add(PLAYFIELD_CLICKED, data);
    }

    pause() {
        this.isPaused = true;
    }

    unpause() {
        this.isPaused = false;

        this.timer.unpause();
    }

    // *** INTERNAL FUNCTIONS ***

    start2() {
        this.state = this.STATE.waiting;
    }

    end2() {}

    update() {
        if (this.isPaused)
            return;

        switch (this.state) {
            case this.STATE.playing:
                this.playing();
                break;
            default:
                this.timer.update();
        }
    }

    playing() {
        if (!this.isPlayingStarted || this.isWordGuessed || this.isTimeOut)
            return;

        if (TRIGGERS.letterBricksNm == 0) {
            this.timer.update(false);

            if (LETTERS.state == LETTERS.STATE.waiting) {
                this.isWordGuessed = true;
                LETTERS.activeNm   = 0;
            }
        } else {
            this.timer.update();
            BALL.update();
            this.playing_checkTimer();
        }

        BRICKS.update();
        LETTERS.update();
        TRIGGERS.update();
    }

    playing_checkTimer() {
        let s = this.timer.seconds;

        this.timer.seconds = Math.max(0, Math.ceil(this.timer.game / 1000));

        if (this.timer.seconds == 10 && s > 10) return EVENTS.add("audio", "timer10");
        if (this.timer.seconds == 3  && s > 3)  return EVENTS.add("audio", "timer3");
        if (this.timer.seconds == 2  && s == 3) return EVENTS.add("audio", "timer2");
        if (this.timer.seconds == 1  && s == 2) return EVENTS.add("audio", "timer1");

        if (this.timer.game <= 0)
            this.isTimeOut = true;
    }
}

export const PLAY_LOGICS = BK_ENGINE.tasks.add(new PlayLogics(BK_ENGINE.tasks));
