
const DEVELOP = {isShowAllWordsToGuess: false,
                 isInifinityHelp:       false,
                 finishAfterWordsNm:    0,          // to check out the "all guessed" (0: do nothing)
                 wordsToGuessNmMax:     0},         // max words to guess number (0: do nothing)
      WORD    = {lengthMin:             4,
                 lengthMax:             14,         // must be divisible by 2
                 repeatMax:             1,          // how many times a word can be repeated
                 toGuessFreq:           16},        // every toGuessFreq word will be to guess (16)
      HELP    = {nm:                    10};        // every how many words to guess add 1 help

const CHARS_TO_EXCLUDE = {first: " ‘\"(“¡ʼ",        // these chars will be removed from the word before deciding if it can be guessed
                          last: ".,;:”\"!?)"};


class Word {
    constructor(lineI, whole, short) {
        this.isToGuess = false;
        this.isGuessed = true;
    
        this.lineI     = lineI;
    
        this.whole     = whole;         // whole world (with '.; for example)
        this.short     = short;         // short (no other signs), ready to guess
    }
}


class CurrWord {
    constructor() {
        this.difficultyI   = 0;
        this.inWholeShortI = 0;

        this.whole         = "";
        this.wholeSorted   = "";
        this.wholeGuessed  = "";
        
        this.short         = "";
        this.shortSorted   = "";
        this.shortGuessed  = "";
        
        this.guessedStates = [];
        
        this.lineI         = 0;

        this.isInitiated   = false;

        // init
        for (let i = 0; i < WORD.lengthMax; i++)
            this.guessedStates.push(false);
    }

    start(difficultyI) {
        let i, l;

        this.difficultyI   = difficultyI;
        this.inWholeShortI = 0;

        this.whole         = "";
        this.wholeSorted   = "";
        this.wholeGuessed  = "";
        
        this.short         = "";
        this.shortSorted   = "";
        this.shortGuessed  = "";

        this.lineI         = 0;

        for (i = 0, l = this.guessedStates.length; i < l; i++)
            this.guessedStates[i] = false;
    }

    reset(word) {
        let i, j, k, l, char1, char2;

        this.inWholeShortI  = word.whole.indexOf(word.short);

        this.whole       = this.wholeGuessed = word.whole;
        this.wholeSorted = this.whole.substring(0, this.inWholeShortI) + word.short.sort() + this.whole.substring(this.inWholeShortI + word.short.length);

        this.short       = this.shortGuessed = word.short.toLowerCase();
        this.shortSorted = this.short;

        for (i = 0, l = this.shortSorted.length; i < l; i++) {
            j = Math.floor(Math.random() * l);
            k = Math.floor(Math.random() * l);

            char1 = this.shortSorted.charAt(j);
            char2 = this.shortSorted.charAt(k);

            this.shortSorted = this.shortSorted.substring(0, j) + char2 + this.shortSorted.substring(j + 1);
            this.shortSorted = this.shortSorted.substring(0, k) + char1 + this.shortSorted.substring(k + 1);
        }

        for (i = 0, l = word.short.length; i < l; i++)
            this.guessedStates[i] = false;

        this.lineI = word.lineI;

        if (!word.isGuessed) {
            if (this.difficultyI < 3)
                this.init_revealLetter();
            
            this.updateGuessed();
        }
    }

    init_revealLetter() {
        let chars   = "",
            charsNm = [],
            i, l, char, charI;

        // count the chars
        for (i = 0, l = this.short.length; i < l; i++) {
            char  = this.short.charAt(i);
            charI = chars.indexOf(char);

            if (charI == -1) {
                chars += char;
                charsNm.push(1);
            } else
                charsNm[charI]++;
        }

        // pick up one char to reveal
        if (chars.length > 2) {                 // there must be at least 3 different chars
            charI = this.init_revealLetter_one(chars);

            if (chars.length > 3 && this.difficultyI == 1) {    // if difficulty == "easy", reveal one more
                chars = chars.substring(0, charI) + chars.substring(charI + 1);
                this.init_revealLetter_one(chars);
            }
        }
    }

    init_revealLetter_one(chars) {
        let charI = Math.floor(Math.random() * chars.length),
            i, l, char;

        char = chars.charAt(charI);

        for (i = 0, l = this.short.length; i < l; i++) {
            if (this.short.charAt(i) == char)
                this.guessedStates[i] = true;
        }

        return charI;
    }

    checkGuessed(word, charsGuessed) {
        let isGuessed = false,
            i         = -1,
            l         = this.short.length,
            isContinue = true;

        while (++i < l && isContinue) {
            if (charsGuessed.charAt(i) === this.short.charAt(i))
                this.guessedStates[i] = true;
            else
                isContinue = false;
        }

        if (isContinue && i == l) {
            isGuessed = true;

            this.wholeGuessed = word.whole;
            this.shortGuessed = word.short;
        } else
            this.updateGuessed();

        return isGuessed;
    }

    updateGuessed() {
        let i, l,
            charShort, charWhole;

        for (i = 0, l = this.short.length; i < l; i++) {
            charShort = this.guessedStates[i] ? this.short.charAt(i) : " ";
            charWhole = this.guessedStates[i] ? this.whole.charAt(this.inWholeShortI + i) : " ";

            this.shortGuessed = this.shortGuessed.replaceCharAt(i, charShort);
            this.wholeGuessed = this.wholeGuessed.replaceCharAt(this.inWholeShortI + i, charWhole);
        }
    }
}


class Server {
    constructor() {
        this.difficultyI = 1;                            // passed to the start function: 1: easy, 2: normal, 3: hard
        this.words       = {i:         0,
                            all:       [],
                            curr:      new CurrWord(),
                            toGuessNm: 0};               // all the words of this text (GW_main_game_server_word)
        this.help        = {isPossible: false,
                            nm:         0,
                            char:       ""};             // for difficulty "easy" it will be: charsGuessedNm + 3, for other difficulties it will be always 3
        this.isActive    = false;                        // useless?

        // init
        if (!String.prototype.hasOwnProperty("sort")) {
            String.prototype.sort = function() {
                return this.split('').sort().join('');
            }
        }

        if (!String.prototype.hasOwnProperty("replaceCharAt")) {
            String.prototype.replaceCharAt = function(index, char) {
                return this.substring(0, index) + char + this.substring(index + 1);
            }
        }
    }

    start(content, difficultyI) {
        this.difficultyI = difficultyI;
        this.words.i     = this.words.lineI = 0;
        this.words.all   = [];

        this.start_initAllWords(content);
        this.start_initWordsToGuess();

        this.words.curr.start(difficultyI);
        this.words.curr.reset(this.words.all[0]);

        this.help.nm = DEVELOP.isInifinityHelp ? 1000 : Math.floor(this.words.toGuessNm / HELP.nm) + 1;

        return {wordsToGuessNm: this.words.toGuessNm, helpNm: this.help.nm};
    }

    start_initAllWords(content) {
        let arr = content.split(/\r?\n/),
            lineI, linesL, line,
            charI, charsL,
            word, whole, short;

        for (lineI = 0, linesL = arr.length; lineI < linesL; lineI++) {
            line = arr[lineI].split(" ");

            for (charI = 0, charsL = line.length; charI < charsL; charI++) {
                whole = line[charI];
                short = this.start_initAllWords_clearWord(whole);

                word = new Word(lineI, whole, short);

                this.words.all.push(word);

                // add an empty char after a word
                if (charI < charsL - 1) {
                    word = new Word(lineI, " ", " ");
                    this.words.all.push(word);
                }
            }
        }
    }

    start_initAllWords_clearWord(word) {     // get rid of some characters: ".,;" and similar
        let charI, wordL;

        // get rid of "'" chars
        charI = word.indexOf("’");

        if (charI > -1)
            word = word.substring(charI + 1);

        charI = word.indexOf("'");

        if (charI > -1)
            word = word.substring(charI + 1);

        // get rid of other chars
        do {
            wordL = word.length;

            if (CHARS_TO_EXCLUDE.first.includes(word.charAt(0)))
                word = word.substring(1);

            if (CHARS_TO_EXCLUDE.last.includes(word.charAt(word.length - 1)))
                word = word.substring(0, word.length - 1);
        } while (wordL != word.length);

        return word;
    }

    start_initWordsToGuess() {
        let i, rnd, arr,
            wordI, word, wordsL;

        // select words to guess by length
        for (wordI = 0, wordsL = this.words.all.length; wordI < wordsL; wordI++) {
            word           = this.words.all[wordI];
            word.isToGuess = false;

            if (word.short.length >= WORD.lengthMin && word.short.length <= WORD.lengthMax)
                word.isToGuess = true;
        }

        if (DEVELOP.isShowAllWordsToGuess) {
            console.clear();

            for (wordI = 0, wordsL = this.words.all.length; wordI < wordsL; wordI++) {
                word = this.words.all[wordI];
                
                if (word.isToGuess)
                    console.log(word.short);
            }
        }

        // every WORD.toGuessFreq choose only 1
        wordI = 0;

        while (wordI < this.words.all.length - WORD.toGuessFreq + 1) {
            arr = [];

            for (i = 0; i < WORD.toGuessFreq; i++) {
                if (this.words.all[wordI + i].isToGuess)
                    arr.push(i);
            }
            if (arr.length > 1) {
                rnd = Math.floor(Math.random() * arr.length);

                for (i = 0; i < arr.length; i++)
                    this.words.all[wordI + arr[i]].isToGuess = (i == rnd) ? true : false;
            }
            wordI += WORD.toGuessFreq;
        }

        // set "isGuessed"
        this.words.toGuessNm = 0;

        for (wordI = 0, wordsL = this.words.all.length; wordI < wordsL; wordI++) {
            word = this.words.all[wordI];

            if (word.isToGuess && (DEVELOP.wordsToGuessNmMax == 0 || DEVELOP.wordsToGuessNmMax > this.words.toGuessNm)) {
                word.isGuessed = false;

                this.words.toGuessNm++;
            }
        }
    }

    end() {
        this.words.curr.guessedStates = [];
        this.words.all                = [];
    }

    getPacket(clientPacket) {
        let word = this.words.all[this.words.i],
            isLast;

        if (this.help.nm > 0)
            this.help.isPossible = true;

        switch (clientPacket.state) {
            case "newWord":
                if (clientPacket.wordI > this.words.i && word.isGuessed) {
                    word = this.words.all[++this.words.i];

                    this.words.curr.reset(word);
                }
                break;
            case "guessing":
                if (!word.isGuessed) {
                    if (word.isGuessed = this.words.curr.checkGuessed(word, clientPacket.charsGuessed))
                        this.words.toGuessNm--;
                    else if (this.checkIfHelpPossible() && clientPacket.isAskHelp)
                        this.revealNextChar();
                }
        }

        if (DEVELOP.finishAfterWordsNm > 0) {
            if (this.words.i >= DEVELOP.finishAfterWordsNm)
                isLast = true;
        } else
            isLast = this.words.i == this.words.all.length - 1;

        return {words: {i:              this.words.i,
                        isLast:         isLast,
                        toGuessNm:      this.words.toGuessNm,
                        word:           {isGuessed:     word.isGuessed,
                                         wholeSorted:   this.words.curr.wholeSorted,
                                         wholeGuessed:  this.words.curr.wholeGuessed,
                                         shortSorted:   this.words.curr.shortSorted,
                                         shortGuessed:  this.words.curr.shortGuessed,
                                         guessedStates: this.words.curr.guessedStates,
                                         lineI:         this.words.curr.lineI}},
                help:  {isPossible:     this.help.isPossible,
                        nm:             this.help.nm,
                        char:           this.help.char}};
    }

    checkIfHelpPossible() {
        let i            = 0,
            l            = this.words.curr.short.length,
            notGuessedNm = 0;

        this.help.isPossible = false;

        if (this.help.nm > 0) {
            while (i < l) {
                if (!this.words.curr.guessedStates[i]) {
                    if (++notGuessedNm == 2)
                        i = l;
                }
                i++;
            }
        }

        if (notGuessedNm > 1)
            this.help.isPossible = true;

        return this.help.isPossible;
    }

    revealNextChar() {
        let i = 0,
            l = this.words.curr.short.length;
            
        this.help.char = "";

        while (i < l) {
            if (!this.words.curr.guessedStates[i]) {
                this.help.char = this.words.curr.short.charAt(i);
                this.help.nm--;

                i = l;
            }
            i++;
        }
    }
}


const SERVER = new Server();

export { SERVER, WORD as SERVER_WORD };
