
import { SHADOW } from "../bk engine constants.js"
import { getIdByProp } from "../bk engine utils.js"
import { INPUT } from "../_main objects/input.js"


export class BKengineItem {
    constructor() {
        this.id             = -1;
        this.name           = "";
        this.info           = "";       // some useful info
        this.type           = "";

        this.isActive       = true;
        this.isBinded       = false;
        this.isClickable    = false;
        this.isDraggable    = false;
        // this.isDisabled     = false;    // for example: buttons
        this.isHover        = false;
        this.isKeepPressed  = false;
        this.isMouseDown    = false;
        this.isPressed      = false;
        this.isRedrawn      = false;    // set to false to forse the redraw
        this.isResized      = false;    // set to false to resize
        this.isShadow       = false;
        this.isUpdatePos    = true;     // if false, must update position from outside
        this.isUpdateSize   = true;     // if false, must update size from outside
        this.isVisible      = true;
        this.isZoomable     = false;

        this.parent         = {};

        this.children       = [];

        this.x              = 0;        // of parent's width
        this.y              = 0;
        this.z              = 0;    
        this.w              = 0;
        this.h              = 0;

        this.xMin           = 0;        // of parent's minimum: width or height
        this.yMin           = 0;
        this.hMin           = 0;
        this.wMin           = 0;
        this.parentMin      = 0;        // will be updated

        this.pivX           = 0;        // of item's width (left, top will be translated)
        this.pivY           = 0;

        this.left           = 0;        // will be calculateed from parent's coords
        this.top            = 0;
        this.width          = 0;
        this.height         = 0;

        this.zoom           = 1;
        this.zoomLast       = 1;
        this.zoomMin        = 0.5;
        this.zoomMax        = 2;
        this.zoomCoords     = {left: 0, top: 0, width: 0, height: 0};   // will be updated and used if zoom != 1

        this.transp         = 1;

        this.cursor         = "default";    // document.cursor.style, will be set when hover

        this.redraws        = [];
    }

    getShadowLeft(itemLeft, itemWidth) {
        return Math.min(SHADOW.offsetMax, (INPUT.left - itemLeft - itemWidth / 2) * SHADOW.multiply);
    }

    getShadowTop(itemTop, itemHeight) {
        return Math.min(SHADOW.offsetMax, (INPUT.top - itemTop - itemHeight / 2) * SHADOW.multiply);
    }

    addChild(child) {
        this.children.push(child);

        this.isRedrawn = false;
    }

    removeChild(child) {
        let i = getIdByProp(this.children, "id", child.id);

        if (i > -1)
            this.children.splice(i, 1);
        else
            console.error("No such child to be removed from parent: " + child);
    }

    resetRedraws() {
        this.redraws = [];
    }

    addRedraws() {
        let i, l;

        for (i = 0, l = arguments.length; i < l; i++)
            this.redraws.push(arguments[i]);

        this.isRedrawn = false;
    }

    setRedraw(redraw) {
        this.resetRedraws();
        this.addRedraws(redraw);
    }

    isMouseOverItem() {
        return INPUT.isMouseInsideRect(this);
    }

    updateParentMin() {
        this.parentMin = this.parent.width < this.parent.height ? this.parent.width : this.parent.height;
    }

    updateHeight() {                // useful for texts (no width needed)
        return this.height = Math.round(this.parent.height * this.h + this.parentMin * this.hMin);
    }

    updateSize() {
        let width  = Math.round(this.parent.width  * this.w + this.parentMin * this.wMin),
            height = Math.round(this.parent.height * this.h + this.parentMin * this.hMin);

        if (width != this.width || height != this.height) {
            this.width  = width;
            this.height = height;

            this.isResized = false;
        } else
            this.isResized = true;

        if (this.zoom != 1) {
            this.zoomCoords.width  = Math.round(this.zoom * width);
            this.zoomCoords.height = Math.round(this.zoom * height);
        }
    }

    updatePos() {
        let offsetLeft = this.parent.width  * this.x + this.parentMin * this.xMin,
            offsetTop  = this.parent.height * this.y + this.parentMin * this.yMin;

        this.left = this.parent.left + Math.round(offsetLeft + this.width  * this.pivX);
        this.top  = this.parent.top  + Math.round(offsetTop  + this.height * this.pivY);

        // if (this.name.includes("helper"))
        //     console.log(this.left, this.top, this.isVisible)
        if (this.zoom != 1) {
            this.zoomCoords.left = this.parent.left + Math.round(offsetLeft + this.zoomCoords.width  * this.pivX);
            this.zoomCoords.top  = this.parent.top  + Math.round(offsetTop  + this.zoomCoords.height * this.pivY);
        }
    }

    resize() {
        this.isResized = true;
        this.isRedrawn = false;
    }

    redraw() {
        this.isRedrawn = true;
    }

    redrawAll(canvas, caption = "") {
        let redraw;

        // if (this.name.includes("helper")) {

        //     console.log(this.redraws.length)
        // }
        for (let i = 0, l = this.redraws.length; i < l; i++) {
            redraw = this.redraws[i];

            redraw.updateCoords({left: 0, top: 0, width: this.width, height: this.height});

            // if (this.name.includes("helper")) {
            //     console.log(this.width, this.height, this.info)
            // }
    
            if (!redraw.draw(canvas, caption))
                return false;
        }

        return true;    
    }

    update() {
        this.updateParentMin();

        if (this.isUpdateSize)
            this.updateSize();

        if (!this.isResized)
            this.resize();

        if (!this.isRedrawn)
            this.redraw();

        if (this.isUpdatePos)
            this.updatePos();
    }

    draw() {}
    drawShadow() {}
    onClick() {}
}
