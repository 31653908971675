
import { G0001, ADDING_CHAR_TIME } from "../../_bible g0001.js";
import { PLAY_LOGICS as LOGICS } from "./_bible g0001 play logics.js";


class Word {
    constructor(chars, lineI) {
        this.chars = chars;
        this.lineI = lineI;
    }
}


class LogicsWords {
    constructor() {
        this.charI    = 0;
        this.dateLast = 0;
        this.all      = [];
    }

    start() {
        this.charI = 0;
        this.all   = [];
    }

    add(chars, lineI) {
        this.all.push(new Word(chars, lineI));
    }

    startAdding() {
        this.charI       = 0;
        this.dateLast    = Date.now();
        LOGICS.word.curr = "";
    }

    update() {
        let dateNow;

        dateNow = Date.now();

        if ((dateNow - this.dateLast) >= ADDING_CHAR_TIME.min + ADDING_CHAR_TIME.max - G0001.addingCharTime) {
            this.dateLast     = dateNow;
            LOGICS.word.curr += LOGICS.word.wholeGuessed.charAt(this.charI++);
        }

        return this.charI >= LOGICS.word.wholeGuessed.length;
    }
}

export const LOGICS_WORDS = new LogicsWords();
