
import { getByName } from "../bk engine utils.js"
import { BKengineTextDisplay } from "../complex/text display.js"


class TextDisplays {
    constructor() {
        this.all = [];
    }

    add(textDisplay) {
        return this.all[this.all.push(textDisplay) - 1];
    }

    addNew() {
        let args        = (typeof arguments[0] == "string") ? arguments : arguments[0],
            textDisplay = new BKengineTextDisplay(),
            i, l, prop, value;

        if (args != undefined) {
            for (i = 0, l = args.length; i < l; i += 2) {
                prop  = args[i];
                value = args[i + 1];
                
                textDisplay[prop] = value;
            }
        }

        return this.add(textDisplay);
    }

    addFromNames = function(obj, names) {
        let arr = names.split(" "),
            i, l, name, textDisplay;

        for (i = 0, l = arr.length; i < l; i++) {
            name = arr[i];

            if (name.length > 0) {
                textDisplay = this.getByName(name);

                if (textDisplay != undefined)
                    obj.addTextDisplay(textDisplay);
                else
                    console.log("textDisplay: *" + name + "* not found!");
            }
        }
    }

    getByName(value) {
        return getByName(this.all, value);
    }
}


export const TEXT_DISPLAYS = new TextDisplays();
