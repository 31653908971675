
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"


class HintBox extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.btnName     = "";
        this.isInitiated = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.item = {mainFrame: ITEMS.getByName("main_hintBox"),
                     content:   ITEMS.getByName("main_hintBox_content")};

        this.isInitiated = true;
    }

    start2() {      // "x=value" "y=value" "pivX=value" "pivY=value" "btnName=value" (can be: any)
        let i, j, l, arg, name, value;

        !this.isInitiated && this.init();

        this.btnName = "any";

        for (i = 0, l = arguments.length; i < l; i++) {
            arg   = arguments[i];
            j     = arg.indexOf("=");
            name  = arg.substring(0, j);
            value = arg.substring(j + 1);

            switch (name) {
                case "x":
                case "y":
                case "pivX":
                case "pivY":
                    this.item.mainFrame[name] = parseFloat(value);
                    break;
                case "content":
                    this.item.content.content = "[*centerV,center*]" + value;
                    break;
                case "btnName":
                    this.btnName = value;
                    break;
                default:
                    console.log("HINT_BOX, not such name and value: " + name, value);
            }
        }

        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        let clickedName = BK_ENGINE.clicked.name;

        if ((this.btnName == "any" && clickedName != "") ||
            clickedName == this.btnName)
                this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const HINT_BOX = BK_ENGINE.tasks.add(new HintBox(BK_ENGINE.tasks));
