
import { PLAY_LOGICS as LOGICS } from "./_bible g0002 play logics.js";
import { LOGICS_BALL as BALL } from "./logics ball.js";
import { LOGICS_LETTERS as LETTERS } from "./logics letters.js";


const REMOVABLES_NM       = 3,
      NOT_REMOVABLE_VALUE = 999999999;      // useful for finding the path


class Brick {
    constructor(col, row) {
        this.col              = col;
        this.row              = row;
        this.hp               = -1;

        this.isEmpty          = true;
        this.isBooked         = false;
        this.isRemovable      = true;
        this.isRemovableFirst = false;

        this.value            = 0;      // used for finding the path
        this.letter           = null;
    }
}


class LogicsBricks {
    constructor() {
        this.notRemovablesNm = 0;

        this.grid            = [];
        this.removables      = [];

        this.isInitiated     = false;
    }

    setBrick(col, row) {
        let brick = this.grid[col][row],
            i, l;

        for (i = 2, l = arguments.length; i < l; i += 2)
            brick[arguments[i]] = arguments[i + 1];
    }

    getBrick(col, row) {
        if (col < 0 || row < 0 || col >= LOGICS.playfieldSize || row >= LOGICS.playfieldSize)
            return {isEmpty: true};

        return this.grid[col][row];
    }

    getEmptyBricks(isRemoveBall = false) {
        let result = [],
            col, row, brick;

        for (col = 1; col < LOGICS.playfieldSize - 1; col++) {
            for (row = 1; row < LOGICS.playfieldSize - 1; row++) {
                brick = this.getBrick(col, row);

                if (brick.isEmpty && (!isRemoveBall || (BALL.col != col && BALL.row != row)))
                    result.push(brick);
            }
        }

        return result;
    }

    getBricksWithLetters() {
        let result = [],
            col, row, brick;

        for (col = 1; col < LOGICS.playfieldSize - 1; col++) {
            for (row = 1; row < LOGICS.playfieldSize - 1; row++) {
                brick = this.getBrick(col, row);

                if (brick.letter != null)
                    result.push(brick);
            }
        }

        return result;
    }

    getRemovableI(col, row) {
        let i, l, brick;

        for (i = 0, l = this.removables.length; i < l; i++) {
            brick = this.removables[i];

            if (brick.col == col && brick.row == row)
                return i;
        }
    }

    isBallReachable(brick) {
        let bricks = [brick],
            i      = 0,
            col, row, brick1, brick2;

        // prepare the values
        for (col = 1; col < LOGICS.playfieldSize - 1; col++) {
            for (row = 1; row < LOGICS.playfieldSize - 1; row++) {
                let brick = this.getBrick(col, row);

                if (brick.value < NOT_REMOVABLE_VALUE)
                    brick.value = 0;
            }
        }

        do {
            brick1 = bricks[i++];

            if (brick1.col == BALL.col && brick1.row == BALL.row)
                return true;

            brick1.value = 1;

            brick2 = this.getBrick(brick1.col, brick1.row - 1);     // up

            if (brick2.value == 0) {
                brick2.value = 1;
                bricks.push(brick2);
            }

            brick2 = this.getBrick(brick1.col + 1, brick1.row);     // right

            if (brick2.value == 0) {
                brick2.value = 1;
                bricks.push(brick2);
            }

            brick2 = this.getBrick(brick1.col, brick1.row + 1);     // down

            if (brick2.value == 0) {
                brick2.value = 1;
                bricks.push(brick2);
            }

            brick2 = this.getBrick(brick1.col - 1, brick1.row);     // left

            if (brick2.value == 0) {
                brick2.value = 1;
                bricks.push(brick2);
            }
        } while (i < bricks.length);

        return false;
    }

    replaceRemovable(brick) {
        let i, l, removable;

        if (!brick.isEmpty) {
            brick.isEmpty = true;
            i             = this.getRemovableI(brick.col, brick.row);

            this.removables.splice(i, 1);
            this.refreshRemovableFirst();

            return;
        }

        // if the removable already exists, remove it
        for (i = 0, l = this.removables.length; i < l; i++) {
            removable = this.removables[i];

            if (brick.col == removable.col && brick.row == removable.row) {
                removable.isBooked = false;

                this.removables.splice(i, 1);
                this.refreshRemovableFirst();

                return;
            }
        }

        // remove the first?
        if (this.removables.length == REMOVABLES_NM) {
            removable = this.removables[0];

            removable.isEmpty  = true;
            removable.isBooked = false;

            this.removables.shift();
            this.refreshRemovableFirst();
        }

        brick.isBooked = true;

        this.removables.push(brick);

        this.refreshRemovableFirst();
    }

    refreshRemovableFirst() {
        let i = 0,
            l = this.removables.length;

        if (l == 0)
            return;

        if (l == REMOVABLES_NM)
            this.removables[i++].isRemovableFirst = true;

        for (; i < l; i++)
            this.removables[i].isRemovableFirst = false;
    }

    clear() {
        let col, row, brick;

        for (col = 1; col < LOGICS.playfieldSize - 1; col++) {
            for (row = 1; row < LOGICS.playfieldSize - 1; row++) {

                brick = this.grid[col][row];

                brick.isEmpty  = brick.isRemovable = true;
                brick.isBooked = false;
                brick.hp       = -1;
                brick.value    = 0;
                brick.letter   = null;        
            }
        }

        this.removables = [];
    }

    init() {
        let line, col, row, brick, i;

        // the grid
        for (col = 0; col < LOGICS.playfieldSize; col++) {
            line = [];

            for (row = 0; row < LOGICS.playfieldSize; row++) {
                brick = new Brick(col, row);

                if (col == 0 || row == 0 || col == (LOGICS.playfieldSize - 1) || row == (LOGICS.playfieldSize - 1)) {
                    brick.isEmpty = brick.isRemovable = false;
                    brick.value   = NOT_REMOVABLE_VALUE;
                }

                line.push(brick);
            }

            this.grid.push(line);
        }

        this.isInitiated = true;
    }

    start(notRemovablesNm) {
        this.notRemovablesNm = notRemovablesNm;

        !this.isInitiated && this.init();
    }

    reset() {
        let letterBricks = this.reset_letters();

        this.reset_notRemovables(letterBricks);
    }

    reset_letters() {
        let emptyBricks  = this.getEmptyBricks(true),
            letterBricks = [],
            i, letter, rnd;

        // reset letters to be guessed
        for (i = 0; i < LETTERS.activeNm; i++) {
            letter       = LETTERS.getByI(i);
            rnd          = Math.floor(Math.random() * emptyBricks.length);
            letter.brick = emptyBricks[rnd];

            this.setBrick(letter.brick.col, letter.brick.row, "isEmpty", false, "isRemovable", false, "hp", LOGICS.hp, "letter", letter);

            letterBricks.push(letter.brick);
            emptyBricks.splice(rnd, 1);
        }

        return letterBricks;
    }

    reset_notRemovables(letterBricks) {
        let nm          = this.notRemovablesNm,
            emptyBricks = this.getEmptyBricks(true),
            rnd, brick;

        // set some not removables
        while (nm > 0 && emptyBricks.length > 1) {
            rnd   = Math.floor(Math.random() * emptyBricks.length);
            brick = emptyBricks[rnd];

            this.setBrick(brick.col, brick.row, "isEmpty", false, "isRemovable", false, "value", NOT_REMOVABLE_VALUE);

            if (!this.reset_areLettersReachable(letterBricks))
                this.setBrick(brick.col, brick.row, "isEmpty", true, "isRemovable", true, "value", 0);
            else
                nm--;

            emptyBricks.splice(rnd, 1);
        }
    }

    reset_areLettersReachable(letters) {
        let i, l;

        for (i = 0, l = letters.length; i < l; i++) {
            if (!this.isBallReachable(letters[i]))
                return false;
        }

        return true;
    }

    resetInOrder() {
        let i   = -1,
            col = 1,
            row = 1,
            letter;

        // set the letters
        while (++i < LETTERS.activeNm) {
            letter       = LETTERS.getByI(i);
            letter.brick = this.grid[col][row];

            this.setBrick(letter.brick.col, letter.brick.row, "letter", letter);

            if (++col == LOGICS.playfieldSize - 1) {
                col = 1;
                row++;
            }
        }
    }

    update() {
        let i = 0,
            l = this.removables.length,
            removable;

        // check if there's a need to place a removable brick
        BALL.refreshColRow();   // mandatory when calling BALL.isTileEmpty()

        while (i < l) {
            removable = this.removables[i++];

            if (removable.isEmpty && BALL.isTileEmpty(removable.col, removable.row))
                removable.isEmpty = removable.isBooked = false;
        }
    }
}

export const LOGICS_BRICKS = new LogicsBricks();
