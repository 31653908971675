
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js";
import { GW_BIBLE } from "../../_gw bible.js";


const PATH  = {ui:       "gw bible/read/bible atlas/ui",
               mapsData: "gw bible/read/bible atlas"},
      FILES = {ui:       {verNarrow: "ver narrow - 20230501", verWide: "ver wide - 20230501", horNarrow: "hor narrow - 20230501", horWide: "hor wide - 20230501"},
               mapsData: "maps data - 20230501"};

const TAGS      = ["t","r","m","ri","c"],
      COLORS    = {mountain: "#222200", city: "#000000"},
      TEXT_I    = {title: 0, region: 1, mountain: 2, river: 3, cityL: 4, cityC: 5, cityR: 6},
      MAPS_DATA = ["abraham",      "bible_bibleAtlas_map_abraham",     1760, 906, "bible_bibleAtlas_mapShapes_abraham",      "bible_bibleAtlas_mapTD_abraham",
                   "israelTribes", "bible_bibleAtlas_map_israelTribes", 880, 906, "bible_bibleAtlas_mapShapes_israelTribes", "bible_bibleAtlas_mapTD_israelTribes",
                   "israelJesus",  "bible_bibleAtlas_map_israelJesus",  880, 906, "bible_bibleAtlas_mapShapes_israelJesus",  "bible_bibleAtlas_mapTD_israelJesus",
                   "paul1",        "bible_bibleAtlas_map_paul1",       1133, 906, "bible_bibleAtlas_mapShapes_paul1",        "bible_bibleAtlas_mapTD_paul1", 
                   "paul2",        "bible_bibleAtlas_map_paul2",       1133, 906, "bible_bibleAtlas_mapShapes_paul2",        "bible_bibleAtlas_mapTD_paul2",
                   "paul3",        "bible_bibleAtlas_map_paul3",       1133, 906, "bible_bibleAtlas_mapShapes_paul3",        "bible_bibleAtlas_mapTD_paul3",
                   "paulRome",     "bible_bibleAtlas_map_paulRome",    1280, 906, "bible_bibleAtlas_mapShapes_paulRome",     "bible_bibleAtlas_mapTD_paulRome"],
      MOVE_SIZE = 0.1;          // 0-1: moving the map with the arrows


class Map {
    constructor(name, item, width, height, texts) {
        this.shapes      = new BK_ENGINE.shapes();
        this.textDisplay = BK_ENGINE.textDisplays.addNew();

        this.name        = name;
        this.item        = item;

        // init
        this.textDisplay.resize(width, height);
        this.textDisplay.setTexts(texts);
    }

    redraw(data) {
        let lines = data.split("\n"),
            i, l;

        this.textDisplay.reset();
        this.textDisplay.initToUseOnlyWords();

        for (i = 0, l = lines.length; i < l; i++)
            this.redraw_line(lines[i]);
    }

    redraw_line(line) {
        let props = line.split(","),
            name, left, top, size, offL, offT, align, coords, textI, vertices;

        if (props.length == 0 || TAGS.indexOf(props[0]) == -1)
            return;

        left   = parseInt(props[1]);
        top    = parseInt(props[2]);
        name   = props[3];
        coords = this.getXY(left, top);

        name = GW_BIBLE.file.lang.getCaption(name);

        switch (props[0]) {
            case "t":                   // title
                return this.textDisplay.addWord(0, 0, 0, TEXT_I.title, name, coords.x, coords.y);
            case "m":                   // mountain
                size  = props[4] / 500;
                align = props[5];
                offL  = parseInt(props[6]);
                offT  = parseInt(props[7]);

                // mountain triangle
                if (size > 0) {
                    vertices = [coords.x, coords.y, coords.x + size / 2, coords.y + size, coords.x - size / 2, coords.y + size];

                    this.shapes.addTriangle(vertices, COLORS.mountain, true);
                }

                coords = this.getXY(left + offL, top + offT);

                return this.textDisplay.addWord(0, 0, 0, TEXT_I.mountain, name, coords.x, coords.y);
            case "r":                   // region
                return this.textDisplay.addWord(0, 0, 0, TEXT_I.region, name, coords.x, coords.y);
            case "ri":                  // river
                return this.textDisplay.addWord(0, 0, 0, TEXT_I.river, name, coords.x, coords.y);
            case "c":                   // city
                size  = props[4] / 1000;
                align = props[5];
                offL  = parseInt(props[6]);
                offT  = parseInt(props[7]);

                if (size > 0)           // city circle
                    this.shapes.addCircle(coords.x, coords.y, size, COLORS.city, true);

                                        // city name
                coords = this.getXY(left + offL, top + offT);
                textI  = (align == "l") ? TEXT_I.cityL : (align == "c") ? TEXT_I.cityC : TEXT_I.cityR;

                this.textDisplay.addWord(0, 0, 0, textI, name, coords.x, coords.y);
        }
    }

    getXY(left, top) {
        return {x: left / this.textDisplay.width, y: top / this.textDisplay.height};
    }
}
    
class Init extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.MAIN        = null;
        this.fileUi      = BK_ENGINE.files.addNew("path", PATH.ui);
        this.isInitiated = false;
    }

    start2(MAIN) {
        this.MAIN = MAIN;

        this.fileUi.fileName = FILES.ui[BK_ENGINE.viewPort.orientation];
        this.fileUi.load();
        MAIN.fileMapsData.load();
    }

    update() {
        let ITEMS = BK_ENGINE.items,
            TEXTS = BK_ENGINE.texts,
            texts = [],
            i, l, map, name, item, width, height, redrawShapes, redrawTextD;

        if (!this.fileUi.isLoaded || !this.MAIN.fileMapsData.isLoaded)
            return;

        // user interface
        BK_ENGINE.addFromData(this.fileUi.data);

        this.MAIN.item = {mainFrame: ITEMS.getByName("bible_bibleAtlas"),
                          info:      ITEMS.getByName("bible_bibleAtlas_info")};

        // texts
        texts.push(TEXTS.getByName("bible_bibleAtlas_title"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_region"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_mountain"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_river"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_cityL"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_cityC"));
        texts.push(TEXTS.getByName("bible_bibleAtlas_cityR"));

        // add maps
        for (i = 0, l = MAPS_DATA.length; i < l; i += 6) {
            name         = MAPS_DATA[i];
            item         = ITEMS.getByName(MAPS_DATA[i + 1]);
            width        = MAPS_DATA[i + 2];
            height       = MAPS_DATA[i + 3];
            redrawShapes = BK_ENGINE.redraws.getByName(MAPS_DATA[i + 4]);
            redrawTextD  = BK_ENGINE.redraws.getByName(MAPS_DATA[i + 5]);

            map = new Map(name, item, width, height, texts);

            map.item.fitIntoParent();
            this.MAIN.maps.push(map);

            redrawShapes.src = map.shapes;
            redrawTextD.src  = map.textDisplay;
        }

        this.isInitiated = true;

        this.end();
        this.MAIN.starting();
    }
}

const INIT = BK_ENGINE.tasks.add(new Init(BK_ENGINE.tasks));


class BibleAtlas extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.fileMapsData = BK_ENGINE.files.addNew("path", PATH.mapsData, "fileName", FILES.mapsData);
        this.item         = null;
        this.dragging     = {is:    false,
                             start: {left: 0, top: 0}};
        this.user         = {lang: "", denom: ""};
        this.maps         = [];
        this.mapI         = 0;
        this.map          = null;
    }

    start2() {
        if (INIT.isInitiated)
            return this.starting();

        INIT.start(this);
    }

    starting() {
        if (this.user.lang != GW_BIBLE.user.lang || this.user.denom != GW_BIBLE.user.denom) {
            this.user.lang  = GW_BIBLE.user.lang;
            this.user.denom = GW_BIBLE.user.denom;

            this.redraw();
        }

        this.show(true);
    }

    end2() {
        this.show(false);
        GW_BIBLE.resume();
    }

    update() {
        if (INIT.isActive)
            return;

        switch (BK_ENGINE.clicked.name) {
            case "bible_bibleAtlas_left":
                return this.map.item.left += Math.round(this.map.item.width * MOVE_SIZE);
            case "bible_bibleAtlas_right":
                return this.map.item.left -= Math.round(this.map.item.width * MOVE_SIZE);
            case "bible_bibleAtlas_up":
                return this.map.item.top += Math.round(this.map.item.height * MOVE_SIZE);
            case "bible_bibleAtlas_down":
                return this.map.item.top -= Math.round(this.map.item.height * MOVE_SIZE);
            case "bible_bibleAtlas_prev":
                return this.changeMap(-1);
            case "bible_bibleAtlas_next":
                return this.changeMap(1);
            case "bible_bibleAtlas_zoomIn":
                return this.zoom(1);
            case "bible_bibleAtlas_zoomOut":
                return this.zoom(-1);
            case "bible_bibleAtlas_quit":
                this.end();
        }
    }

    changeMap(value) {
        let mapsNm = this.maps.length;

        this.mapI += value;

        if (this.mapI >= mapsNm)
            this.mapI = 0;
        else if (this.mapI < 0)
            this.mapI = mapsNm - 1;

        this.refresh();
    }

    zoom(value) {
        this.map.item.zoom = this.map.item.zoom + value / 10;
    }

    redraw() {
        let data  = this.fileMapsData.data,
            start = 0,
            i, l, s, end;

        for (i = 0, l = this.maps.length; i < l; i++) {
            end = (i < l - 1) ? data.indexOf("*" + this.maps[i + 1].name) : data.length;
            s   = data.substring(start, end);

            this.maps[i].redraw(s);

            start = end;
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
        BK_ENGINE.pointer.isActive    = !isVisible;

        if (isVisible)
            this.refresh();
    }

    refresh() {
        let i, l;

        // set visibilty of the maps
        for (i = 0, l = this.maps.length; i < l; i++) {
            if (this.mapI == i) {
                this.map                = this.maps[i];
                this.map.item.isVisible = true;
            } else
                this.maps[i].item.isVisible = false;
        }

        // refresh info
        i = this.mapI + 1;

        this.item.info.caption = "" + i; 
    }
}

export const BIBLE_ATLAS = BK_ENGINE.tasks.add(new BibleAtlas(BK_ENGINE.tasks));
