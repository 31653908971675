
import { BK_ENGINE, Coords, Task } from "../../../bk engine/_bk engine.js";
import { GW_USER } from "../../../gw user/_gw user.js";
import { SHOW_SCORE } from "../../show score.js";
import { G0002, DEVELOP, SCORE } from "./_bible g0002.js";
import { AUDIO } from "./g0002 audio.js";
import { INFO } from "./g0002 info.js";
import { PLAY } from "./play/_bible g0002 play.js";
import { QUOTES } from "./quotes/_g0002 quotes.js";
import { SHOW_QUOTE } from "./quotes/show quote.js";
import { SELECT_QUOTE } from "./quotes/select quote.js";
import { SERVER } from "./g0002 server.js";
import { SETTINGS } from "./g0002 settings.js";


const DIFFICULTY           = ["easy", "medium", "hard"],
      BGD_GRADIENT         = [0, "#550000", 0.5, "#000000", 1, "#550000"],
      QUOTE_FADE_OUT_WIDTH = 0.2;


class Menu extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE       = {updatingQuotes: 10, main: 20, initServer: 30},
        this.state       = 0;

        this.canvas      = null;
        this.text        = null;
        this.item        = null;

        this.quote       = {isGuessed: false, content: ""};

        this.isResized   = false;
        this.isInitiated = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.canvas = {main:             BK_ENGINE.canvases.getByName("bible_g0002_menu_bgd"),
                       quote:            BK_ENGINE.canvases.getByName("bible_g0002_menu_quoteContent"),
                       tmp:              BK_ENGINE.canvases.addNew()};
        this.text   = {quote:            {guessed:    BK_ENGINE.texts.getByName("bible_g0002_menu_quoteGuessed"),
                                          notGuessed: BK_ENGINE.texts.getByName("bible_g0002_menu_quoteNotGuessed")}};
        this.item   = {mainFrame:        ITEMS.getByName("bible_g0002_menu"),
                       menuFrame:        ITEMS.getByName("bible_g0002_menu_frame"),
                       selectQuote:      ITEMS.getByName("bible_g0002_menu_selectQuote"),
                       quote:            ITEMS.getByName("bible_g0002_menu_quoteContent"),
                       difficultyEmpty1: ITEMS.getByName("bible_g0002_menu_difficultyEmpty1"),
                       difficultyEmpty2: ITEMS.getByName("bible_g0002_menu_difficultyEmpty2"),
                       difficultyFull1:  ITEMS.getByName("bible_g0002_menu_difficultyFull1"),
                       difficultyFull2:  ITEMS.getByName("bible_g0002_menu_difficultyFull2")};

        if (DEVELOP.addQuotes.month > -1) {
            let day;

            for (day = DEVELOP.addQuotes.dayStart; day <= DEVELOP.addQuotes.dayEnd; day++)
                QUOTES.add({year: 2023, month: DEVELOP.addQuotes.month, day: day}, Math.floor(Math.random() * 3));

            QUOTES.add({year: 2023, month: 4, day: 1}, 1);
            QUOTES.add({year: 2023, month: 4, day: 1}, 2);
            QUOTES.add({year: 2023, month: 4, day: 1}, 0);
                // QUOTES.add({year: 2023, month: DEVELOP.addQuotes.month, day: day}, Math.floor(Math.random() * 3));
        }

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state = this.STATE.updatingQuotes;

        this.show(true);
        QUOTES.start(true);
    }

    end2(selected) {
        this.show(false);

        if (selected == "play")
            PLAY.start();
        // else if (selected == "quote")
        //     SHOW_QUOTE.start(SERVER.getPacket({type: "quote"}));
    }

    resume() {
        this.show(true);
    }

    update() {
        if (INFO.isActive || SETTINGS.isActive || SHOW_QUOTE.isActive || SELECT_QUOTE.isActive || PLAY.isActive)
            return;

        switch (this.state) {
            case this.STATE.updatingQuotes:
                return this.update_updatingQuotes();
            case this.STATE.main:
                return this.update_main();
            case this.STATE.initServer:
                this.update_initServer();
        }
    }

    update_updatingQuotes() {
        if (QUOTES.isActive)
            return;

        this.state     = this.STATE.main;
        this.isResized = false;
    }

    update_main() {
        if (!this.isResized ||
            this.item.mainFrame.width  != this.canvas.main.width ||
            this.item.mainFrame.height != this.canvas.main.height)
               this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_menu_quit":
                AUDIO.play("menuClick");
                return this.end("quit");
            case "bible_g0002_menu_settings":
                AUDIO.play("menuClick");
                return SETTINGS.start();
            case "bible_g0002_menu_info":
                AUDIO.play("menuClick");
                return INFO.start();
            case "bible_g0002_menu_selectQuote":
                AUDIO.play("menuClick");
                return SELECT_QUOTE.start();
            case "bible_g0002_menu_quoteContent":
                if (QUOTES.isLastGuessed()) {
                    AUDIO.play("menuClick");
                    SHOW_QUOTE.start(QUOTES.getNm() - 1, 30);
                }
                break;
            case "bible_g0002_menu_play":
                if (G0002.isShowInfo)
                    INFO.start();
                AUDIO.play("menuClick");
                SERVER.start(G0002.user.lang, G0002.user.denom);
                return this.state = this.STATE.initServer;
            default:
                this.update_checkDifficulty();

                if (!G0002.user.isGameLoaded && GW_USER.isLoggedIn && GW_USER.isVerified && GW_USER.isLoaded) {
                    G0002.loadGame();
                    QUOTES.start(true);
                    this.isResized = false;
                }
        }

        this.refresh();
    }

    update_initServer() {
        if (SERVER.isActive || INFO.isActive)
            return;

        this.end("play");
    }

    update_checkDifficulty() {
        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_menu_difficultyFull0":
                return this.update_checkDifficulty_set("easy");
            case "bible_g0002_menu_difficultyEmpty1":
            case "bible_g0002_menu_difficultyFull1":
                return this.update_checkDifficulty_set("medium");
            case "bible_g0002_menu_difficultyEmpty2":
            case "bible_g0002_menu_difficultyFull2":        // this one only to make the "click" sound :)
                this.update_checkDifficulty_set("hard");
        }
    }

    update_checkDifficulty_set(difficulty) {
        G0002.difficulty = difficulty;

        AUDIO.play("menuClick");
    }

    resize() {
        this.canvas.main.resize(this.item.mainFrame.width, this.item.mainFrame.height);
        this.canvas.quote.resize(this.item.quote.width, this.item.quote.height);

        this.redraw();

        this.isResized = true;
    }

    redraw() {
        this.canvas.main.createLinearGradient(this.item.mainFrame, BGD_GRADIENT);
        this.redraw_quote();

        this.item.mainFrame.isRedrawn = false;
    }

    redraw_quote() {
        let item           = this.item.quote,
            canvasQuote    = this.canvas.quote,
            canvasGradient = this.canvas.tmp,
            canvasTmp      = BK_ENGINE.canvases.tmp,
            text           = this.quote.isGuessed ? this.text.quote.guessed : this.text.quote.notGuessed,
            left, width, align, coordsTmp, coordsQuote;

        text.calcHeight(canvasQuote.height);

        width = text.getWidth(canvasQuote, this.quote.content);

        if (width < item.width) {
            left  = Math.round(item.width / 2);
            align = "center";
        } else {
            left  = Math.round(item.width * 0.01);
            align = "left";
        }

        // draw the quote
        text.align = align;

        canvasQuote.clear();
        text.prepare(canvasQuote);
        text.draw(canvasQuote, {left: left, top: 0}, this.quote.content);

        item.isRedrawn = false;

        if (align == "center")
            return;

        // add fade out at the end of the quote
        width       = Math.round(canvasQuote.width * QUOTE_FADE_OUT_WIDTH);
        coordsTmp   = new Coords(                        0, 0, width, canvasQuote.height);
        coordsQuote = new Coords(canvasQuote.width - width, 0, width, canvasQuote.height);

        canvasGradient.resize(width,1)
        canvasTmp.resize(coordsTmp.width, coordsTmp.height);

        canvasGradient.createLinearGradient({left: 0, top: 0, width: width, height: 0}, [0, "#ffffffff", 1, "#ffffff00"]);

        canvasTmp.drawImage(canvasQuote, coordsQuote, coordsTmp);
        canvasTmp.globalCompositeOperationSet("destination-in");
        canvasTmp.drawImage(canvasGradient, {left: 0, top: 0, width: width, height: 1}, coordsTmp);
        canvasTmp.globalCompositeOperationReset();

        canvasQuote.clearRect(coordsQuote);
        canvasQuote.drawImage(canvasTmp, coordsTmp, coordsQuote);
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        this.showMenuFrame(isVisible);
        this.refresh();

        if (!isVisible)
            SHOW_SCORE.end();
    }

    showMenuFrame(isVisible) {
        this.item.menuFrame.isVisible = isVisible;

        SHOW_SCORE.start();
    }

    refresh() {
        this.refresh_quote();
        this.refresh_difficulty();
        this.refresh_score();

        if (BK_ENGINE.clicked.name == "")
            return;
    }

    refresh_quote() {
        let newQuoteContent;

        this.quote.isGuessed = QUOTES.isLastGuessed();
        newQuoteContent      = this.quote.isGuessed ? QUOTES.getLast().content : G0002.file.lang.getCaption("menuPlayToDiscover");

        if (newQuoteContent != this.quote.content) {
            this.quote.content = newQuoteContent;

            this.redraw_quote();
        }
    }

    refresh_difficulty() {
        let diff = G0002.difficultyI;

        this.item.selectQuote.isVisible = QUOTES.getNm() > 0;

        this.item.difficultyEmpty1.isVisible = diff < 1;
        this.item.difficultyEmpty2.isVisible = diff < 2;

        this.item.difficultyFull1.isVisible = diff > 0;
        this.item.difficultyFull2.isVisible = diff > 1;
    }

    refresh_score() {
        let scoreOld = 0,
            scoreNew = SCORE[G0002.difficulty],
            score    = "",
            quote;

        if (QUOTES.isLastGuessed()) {
            quote    = QUOTES.getLast();
            scoreOld = SCORE[DIFFICULTY[quote.difficultyI]];
            
            score = (scoreOld < scoreNew) ? "+" + (scoreNew - scoreOld) : 0;
        } else
            score = scoreNew;

        SHOW_SCORE.caption = score;
    }
}

export const MENU = BK_ENGINE.tasks.add(new Menu(BK_ENGINE.tasks));
