
const FPS_MIN     = {isGlass: 30, isShadows: 20, isPointer: 10},    // minimum necessary to turn them on
      FONT        = {name: "Cambria", size: 16, align: "left", baseline: "top", color: "#ff0000", boldItalic: ""},
      COORDS      = {marginL: 200, top: 200, gap: 20},
      RESET_EVERY = 3000;


class Performance {
    constructor() {
        this.time        = 0;
        this.counter     = 120;     // only the first time

        this.testing     = {i:   0,
                            all: ["glass", "shadows", "pointer"]};

        this.fps         = {glass: 0, shadows: 0, pointer: 0};

        this.isShow      = false;
        this.isTesting   = false;
        this.isShadows   = false;
        this.isPointer   = false;
        this.isGlass     = false;
    }

    update() {
        let now       = Date.now();
        let timeDelta = now - this.time,
            fps, name;

        if (timeDelta >= RESET_EVERY) {
            fps          = Math.round(this.counter / RESET_EVERY * 1000);
            this.time    = now;
            this.counter = 1;

            if (this.isTesting) {
                name           = this.testing.all[this.testing.i];
                this.fps[name] = fps;

                if (++this.testing.i >= this.testing.all.length)
                    this.testing.i = 0;

                this.isGlass   = name == "pointer";
                this.isShadows = name == "glass";
                this.isPointer = name == "shadows";
            } else {
                this.isGlass   = fps >= FPS_MIN.isGlass;
                this.isShadows = fps >= FPS_MIN.isShadows;
                this.isPointer = fps >= FPS_MIN.isPointer;
            }
        } else
            this.counter++;
    }

    draw(canvas) {
        let font = FONT.boldItalic + " " + FONT.size + "px " + FONT.name;

        canvas.textSet("font", font, "fillStyle", FONT.color, "textAlign", FONT.align, "textBaseline", FONT.baseline);

        this.draw_one(canvas,   0, false,          "TESTING")
        this.draw_one(canvas, 1.5, this.isGlass,   "onlyGlass: "   + this.fps.glass);
        this.draw_one(canvas, 2.5, this.isShadows, "onlyShadows: " + this.fps.shadows);
        this.draw_one(canvas, 3.5, this.isPointer, "onlyPointer: " + this.fps.pointer);
    }

    draw_one(canvas, row, isAddSign, caption) {
        if (isAddSign)
            canvas.textFill("* " + caption, {left: viewPort.width - COORDS.marginL, top: COORDS.top + row * COORDS.gap});
        else
            canvas.textFill(caption, {left: viewPort.width - COORDS.marginL, top: COORDS.top + row * COORDS.gap});
    }
}

export const PERFORMANCE = new Performance();
