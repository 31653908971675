
import { BK_ENGINE, Coords, Point, getThunder } from "../../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../../logics/_bible g0002 play logics.js";
import { LOGICS_BRICKS } from "../../logics/logics bricks.js";
import { DISPLAY_PLAYFIELD as PLAYFIELD } from "./_display playfield.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "../_bible g0002 play display.js";


const DEVELOP = {letterImageI: -1};   // -1: default

const LETTER          = {image:         {presets: [[2,3,4,5,6,7,9,10],[2,3,4,5,6,7,8,9,10],[2,3,4,5,6,7,9,10],[2,3,4,5,6,7,10],[2,3,4,8,9,10]],     // for every imageI there are letter bricks' images to be randomly selected (because not all letter brick colors match to the playfield)
                                         wrongI:  1},
                         thunderShape:  {lineElms: 7, lineWidthStart: 0.2, coneWidth: 0.7},
                         transp:        {start: 0.9, speed: 0.002}},
      PLAYER          = {imageI:        0,
                         firstBrighten: 1.5,
                         bookedTransp:  0.7},
      BRICK           = {letter:        0,
                         player:        1,
                         playerFirst:   2,
                         playerBooked:  3},
      SHADOW_MULTIPLY = 0.75;


class LetterBrick {
    constructor(col, row) {
        this.col    = col;
        this.row    = row;
        this.transp = 1;
    }
}


class DisplayPlayfieldRemovableBricks {
    constructor() {
        let CANVASES = BK_ENGINE.canvases;

        this.canvas       = {letter: CANVASES.addNew(),       // more letter bricks, less hp: more broken
                             player: CANVASES.addNew(),       // 3 types: normal, 1st laid (brighter) and "booked"
                             shadow: CANVASES.addNew()};      // of 1 brick (the same for letter and player brick)
        this.coords       = {letter: new Coords(),
                             player: new Coords(),
                             shadow: new Coords()};

        this.hpMax        = 0;
        this.letterBricks = [];
        this.letterImageI = 0;

        this.isResized    = false;
        this.isInitiated  = false;
    }

    start() {
        let preset = LETTER.image.presets[PLAYFIELD.imageI];

        this.hpMax        = LOGICS.hp;
        this.letterImageI = (DEVELOP.letterImageI > -1) ? DEVELOP.letterImageI : preset[Math.floor(Math.random() * preset.length)];
        // console.log("playfieldImageI, letterImageI: " + PLAYFIELD.imageI, this.letterImageI);
        this.isResized    = false;
    }

    reset() {
        let s = PLAYFIELD.size - 1,
            row, col, brick;

        this.letterBricks = [];

        for (row = 1; row < s; row++) {
            for (col = 1; col < s; col++) {
                brick = LOGICS_BRICKS.getBrick(col, row);

                if (brick.letter)
                    this.letterBricks.push(new LetterBrick(col, row, brick.letter));
            }
        }
    }

    update() {
        let size = PLAYFIELD.size - 1,
            col, row, logicsBrick, letterBrick, hp, i, l;

        if (!this.isResized && PLAYFIELD.tileSize > 0)
            this.resize();

        // player bricks
        for (row = 1; row < size; row++) {
            for (col = 1; col < size; col++) {
                logicsBrick = LOGICS_BRICKS.getBrick(col, row);

                if (logicsBrick.isEmpty) {
                    if (logicsBrick.isBooked) {
                        // PLAYFIELD.canvas.pgd.saveTransp();
                        // PLAYFIELD.canvas.pgd.setTransp(PLAYER.bookedTransp);
                        this.refreshBrick(BRICK.playerBooked, col, row, 0, PLAYER.bookedTransp);
                        // PLAYFIELD.canvas.pgd.restoreTransp();
                    }
                } else if (logicsBrick.isRemovable) {
                    if (logicsBrick.isRemovableFirst)
                        this.refreshBrick(BRICK.playerFirst, col, row);
                    else
                        this.refreshBrick(BRICK.player, col, row);
                }
            }
        }

        // letter bricks
        for (i = 0, l = this.letterBricks.length; i < l; i++) {
            letterBrick = this.letterBricks[i];

            if (letterBrick.transp == 1) {
                logicsBrick = LOGICS_BRICKS.getBrick(letterBrick.col, letterBrick.row);
                hp          = logicsBrick.hp;

                if (logicsBrick.isEmpty) {
                    letterBrick.transp = LETTER.transp.start;
                    hp                 = 1;
                }

                this.refreshBrick(BRICK.letter, letterBrick.col, letterBrick.row, hp, letterBrick.transp);
            } else {
                letterBrick.transp -= DISPLAY.timer.delta * LETTER.transp.speed;

                if (letterBrick.transp > 0)
                    this.refreshBrick(BRICK.letter, letterBrick.col, letterBrick.row, 1, letterBrick.transp);
            }

        }
    }

    resize() {
        let s            = PLAYFIELD.tileSize;
        let shadowOffset = Math.round(s * SHADOW.size * SHADOW_MULTIPLY);

        // letter
        this.coords.letter.resize(s, s);
        this.canvas.letter.resize(s * (this.hpMax + 1), s);

        // player
        this.coords.player.resize(s, s);
        this.canvas.player.resize(s * 3, s);    // first, normal, and "booked" brick

        // shadow
        this.coords.shadow.resize(s + shadowOffset, s + shadowOffset);
        this.canvas.shadow.resize(s + shadowOffset, s + shadowOffset);

        this.redraw(s, shadowOffset);

        this.isResized = true;
    }

    redraw() {
        let s            = PLAYFIELD.tileSize;
        let shadowOffset = Math.round(s * SHADOW.size * SHADOW_MULTIPLY),
            i;

        // player
        BK_ENGINE.canvases.drawImageQuality(DISPLAY.image.bricks, this.canvas.player, this.coords.player, PLAYER.imageI);   // normal
        this.canvas.player.drawImage(this.canvas.player, this.coords.player, {left:     s, top: 0, width: s, height: s});   // the 1st laid, brighter
        this.canvas.player.drawImage(this.canvas.player, this.coords.player, {left: s * 2, top: 0, width: s, height: s});   // the "booked" one

        this.canvas.player.brighten(PLAYER.firstBrighten,       {left:     s, top: 0, width: s, height: s});
        this.canvas.player.makeTransparent(PLAYER.bookedTransp, {left: s * 2, top: 0, width: s, height: s});

        // shadow
        for (i = 1; i < shadowOffset; i++)
            this.canvas.shadow.drawImage(this.canvas.player, this.coords.player, {left: i, top: i, width: s, height: s});
    
        this.canvas.shadow.makeShadow(SHADOW.transp);

        this.redraw_letterBricks(s);
    }

    redraw_letterBricks(s) {            // letter bricks (less hp: more "broken")
        let canvasTmp  = BK_ENGINE.canvases.tmp,
            sHalf      = s / 2;
        let dist       = Math.sqrt(Math.pow(sHalf, 2) * 2);
        let offsetX    = -dist + sHalf,
            pointStart = new Point(0, 0),
            pointEnd   = new Point(dist, 0),
            i, j, points, rotation, point0, point1, point2, point3;

        canvasTmp.resize(s, s);
        this.canvas.letter.clear();

        BK_ENGINE.canvases.drawImageQuality(DISPLAY.image.bricks, this.canvas.letter, {left: (this.hpMax - 1) * s, top: 0, width: s, height: s}, this.letterImageI);     // normal
        BK_ENGINE.canvases.drawImageQuality(DISPLAY.image.bricks, this.canvas.letter, {left: this.hpMax * s, top: 0, width: s, height: s}, LETTER.image.wrongI);        // wrong

        for (i = this.hpMax - 2; i >= 0; i--) {
            canvasTmp.clear();
            canvasTmp.drawImage(this.canvas.letter, {left: (i + 1) * s, top: 0, width: s, height: s}, {left: 0, top: 0, width: s, height: s});

            points   = getThunder(pointStart, pointEnd, LETTER.thunderShape.lineElms, s * LETTER.thunderShape.lineWidthStart, s * LETTER.thunderShape.coneWidth);
            rotation = Math.random() * Math.PI * 2;

            canvasTmp.globalCompositeOperationSet("destination-out");

            for (j = 0; j < points.length; j += 2) {
                point2 = points[j];
                point3 = points[j + 1];

                point2.rotate(rotation, dist, 0);
                point3.rotate(rotation, dist, 0);

                point2.translate(offsetX, sHalf);
                point3.translate(offsetX, sHalf);

                point2.round();
                point3.round();

                if (j > 0)
                    canvasTmp.drawPolygon([point0, point1, point2, point3], true, "#ff0000");

                point0 = point3;
                point1 = point2;
            }

            canvasTmp.globalCompositeOperationReset();
            this.canvas.letter.drawImage(canvasTmp, {left: 0, top: 0, width: s, height: s}, {left: i * s, top: 0, width: s, height: s});
        }
    }

    refreshBrick(type, col, row, hp, transp = 1) {
        let s      = PLAYFIELD.tileSize,
            colSrc = 0;     // BRICK.player

        switch (type) {
            case BRICK.letter:
                colSrc = hp - 1;
                break;
            case BRICK.playerFirst:
                colSrc = 1;
                break;
            case BRICK.playerBooked:
                colSrc = 2;
        }

        this.refreshBrick_one(PLAYFIELD.canvas.pgd, {left: col * s, top: row * s, width: s, height: s}, PLAYFIELD.coords.main, colSrc, transp, type == BRICK.letter, type != BRICK.playerBooked);
    }

    refreshBrick_one(canvasDst, coordsDst, coordsShadow, colSrc, transp, isLetter = true, isShadow = true) {     // can be called from outside
        let canvasSrc = isLetter ? this.canvas.letter : this.canvas.player,
            s         = PLAYFIELD.tileSize;

        canvasDst.saveTransp();
        canvasDst.setTransp(transp);
        canvasDst.drawImage(canvasSrc, {left: colSrc * s, top: 0, width: s, height: s}, coordsDst);
        canvasDst.restoreTransp();

        if (isShadow) {
            DISPLAY.canvas.shadows.saveTransp();
            DISPLAY.canvas.shadows.setTransp(transp);
            DISPLAY.canvas.shadows.drawImage(this.canvas.shadow, this.coords.shadow, {left: coordsShadow.left + coordsDst.left, top: coordsShadow.top + coordsDst.top, width: this.coords.shadow.width, height: this.coords.shadow.height});
            DISPLAY.canvas.shadows.restoreTransp();
        }
    }
}

export const DISPLAY_PLAYFIELD_REMOVABLE_BRICKS = new DisplayPlayfieldRemovableBricks();
