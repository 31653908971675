
import { BK_ENGINE, Task, Coords } from "../../../../bk engine/_bk engine.js";
import { G0002 } from "../_bible g0002.js";
import { AUDIO } from "../g0002 audio.js";
import { CONTROL_BOX } from "../g0002 control box.js";
import { QUOTES } from "./_g0002 quotes.js";

const MARGIN   = {top: 0.05, side: 0.05},
      GAP_LINE = 0.05,
      TIME     = {fadeIn: 10000, copy: 3000},
      COPY     = {round: 0.1, lineW: 0.03, colorBgd: "#ffffffee", colorFrame: "#00000066"};


class Line {
    constructor(text, chars, left, top, width, height) {
        this.text   = text;
        this.chars  = chars;
        this.coords = new Coords(left, top, width, height);
    }
}


class ShowQuote extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.STATE       = {loading: 10, copyExplanation: 20, main: 30};
        this.state       = 0;

        this.canvas      = null;
        this.item        = null;
        this.text        = null;

        this.quote       = null;

        this.lines       = [];
        this.line        = {i: 0, max: 0};

        this.marginTop   = 0;
        this.timer       = {copy: 0, fadeIn: 0};
        this.fadeInSpeed = 0;

        this.isInitiated = false;
    }

    init() {
        let ITEMS = BK_ENGINE.items;

        this.canvas = {quote:     BK_ENGINE.canvases.getByName("bible_g0002_showQuote_quote"),
                       copy:      BK_ENGINE.canvases.getByName("bible_g0002_showQuote_copy")},
        this.item   = {bibleGame: ITEMS.getByName("bible_game"),
                       main:      ITEMS.getByName("bible_g0002_showQuote"),
                       copyBgd:   ITEMS.getByName("bible_g0002_showQuote_copyBgd"),
                       copyTa:    ITEMS.getByName("bible_g0002_showQuote_copyTa"),
                       btnUp:     ITEMS.getByName("bible_g0002_showQuote_btnUp"),
                       btnDown:   ITEMS.getByName("bible_g0002_showQuote_btnDown")};
        this.text   = {content:   BK_ENGINE.texts.getByName("bible_g0002_showQuote_content"),
                       ref:       BK_ENGINE.texts.getByName("bible_g0002_showQuote_ref")};

        this.isInitiated = true;
    }

    copyQuoteToClipboard() {
        navigator.clipboard.writeText(this.quote.content + "\n" + this.quote.bookName + " " + this.quote.chapterVerses + "\n(" + this.quote.tagNational + " " + this.quote.chapterVerses + ")").then(() => {
        }).catch(() => {
            alert("Can't copy to the clipboard!");
        });

        this.timer.copy = Date.now();
    }

    start2(quoteI, fadeInSpeed = 1) {
        !this.isInitiated && this.init();

        this.item.main.transp = this.item.copyBgd.transp = this.item.copyTa.transp = 0;
        this.timer.fadeIn     = Date.now();
        this.fadeInSpeed      = fadeInSpeed;
        this.quote            = (quoteI == undefined) ? QUOTES.getLast() : QUOTES.getByI(quoteI);

        this.state = this.STATE.loading;
    }

    loading() {
        this.show(true);

        if (this.item.main.width == 0 || this.item.main.height == 0)
            return;

        this.resize();

        if (!G0002.isShowCopyExplanation)
            return this.state = this.STATE.main;
        
        this.state = this.STATE.copyExplanation;

        CONTROL_BOX.start("", G0002.file.lang.getCaption("btnCopyExplanation"), ["copy"]);
    }

    end2() {
        this.show(false);
        this.canvas.quote.resize(1, 1);
        this.canvas.copy.resize(1, 1);
    }

    update() {
        switch (this.state) {
            case this.STATE.loading:
                return this.loading();
            case this.STATE.copyExplanation:
                return this.update_copyExplanation();
            case this.STATE.main:
                this.update_main();
        }
    }

    update_copyExplanation() {
        if (CONTROL_BOX.isActive)
            return;

        this.state                  = this.STATE.main;
        G0002.isShowCopyExplanation = false;

        this.copyQuoteToClipboard();
        G0002.updateCookie();
    }

    update_main() {
        let progress, transp;

        if (this.item.main.width != this.canvas.quote.width || this.item.main.height != this.canvas.quote.height)
            this.resize();

        switch (BK_ENGINE.clicked.name) {
            case "bible_g0002_showQuote_btnUp":
                this.line.i = Math.max(0, this.line.i - 1);

                AUDIO.play("menuClick");
                this.refresh();
                break;
            case "bible_g0002_showQuote_btnDown":
                this.line.i = Math.min(this.line.max, this.line.i + 1);

                AUDIO.play("menuClick");
                this.refresh();
                break;
            case "bible_g0002_showQuote_btnCopy":
                AUDIO.play("menuClick");
                this.copyQuoteToClipboard();
                break;
            case "bible_g0002_showQuote_btnQuit":
                AUDIO.play("menuClick");
                this.end();
                break;
            default:
                this.line.i = Math.max(0, Math.min(this.line.max, this.line.i + Math.sign(BK_ENGINE.input.mouseWheel)));
                this.refresh();
        }

        // update fade in transparency
        this.item.main.transp = Math.min(1, (Date.now() - this.timer.fadeIn) / TIME.fadeIn * this.fadeInSpeed);

        // update copy to the clipboard transparency
        progress = 1 - (Date.now() - this.timer.copy) / TIME.copy;
        transp   = progress > 0.2 ? 1 : Math.max(0, progress * 5);

        this.item.copyBgd.transp = transp;
        this.item.copyTa.transp  = transp;
    }

    resize() {
        this.canvas.quote.resize(this.item.main.width, this.item.main.height);
        this.canvas.copy.resize(this.item.copyBgd.width, this.item.copyBgd.height);

        this.redraw();
        this.refresh();
    }

    redraw() {
        if (this.quote.content == "")
            return;

        this.redraw_quote();
        this.redraw_copy();
    }

    redraw_quote() {
        let min = this.canvas.quote.width < this.canvas.quote.height ? this.canvas.quote.width : this.canvas.quote.height,
            top = 0,
            i, j, line, height, heightMax;

        this.marginTop = Math.round(this.canvas.quote.height * MARGIN.top),
        this.lines     = [];
        this.line.i    = this.line.max = 0;

        // content
        top = this.redraw_quote_addLine(this.text.content, min, top, this.quote.content);

            // center horizontally
        for (i = 0; i < this.lines.length; i++) {
            line = this.lines[i];

            line.coords.left = Math.round((this.canvas.quote.width - line.coords.width) / 2);
        }

        // one empty line
        top = this.redraw_quote_addLine(this.text.content, min, top, " ");
        
        // book name
        i = this.lines.length;

        this.redraw_quote_addLine(this.text.ref, min, top, this.quote.bookName + " " + this.quote.chapterVerses);

        j = this.canvas.quote.width - Math.floor(MARGIN.side * min);

            // align right
        for (; i < this.lines.length; i++)
            this.lines[i].coords.left = j;

        // center all vertically
        height    = 0;
        heightMax = this.canvas.quote.height - this.marginTop * 2;

        for (i = 0; i < this.lines.length; i++)
            height += this.lines[i].coords.height;

        if (height < heightMax) {
            top = Math.floor((this.canvas.quote.height - height) / 2) - this.marginTop;

            for (i = 0; i < this.lines.length; i++)
                this.lines[i].coords.top += top;

            return;
        }

        // find line max
        height = 0;

        this.line.max = this.lines.length;

        while (--this.line.max >= 0) {
            height += this.lines[this.line.max].coords.height;

            if (heightMax - height <= 0) {
                this.line.max = Math.min(this.line.max + 2, this.lines.length - 1);

                return;
            }
        }
    }

    redraw_quote_addLine(text, min, topStart, line) {
        let top         = topStart,
            words       = line.split(" "),
            sideMargins = Math.floor(MARGIN.side * min) * 2,
            chars       = "",
            lineWidth   = 0,
            height      = text.calcHeight(min),
            i, word, wordWidth, spaceWidth;
            
        height += Math.max(1, Math.round(height * GAP_LINE));

        text.prepare(this.canvas.quote);
    
        spaceWidth = text.getWidth(this.canvas.quote, " ");

        for (i = 0; i < words.length; i++) {
            word      = words[i];
            wordWidth = text.getWidth(this.canvas.quote, word);

            if (lineWidth + wordWidth + sideMargins >= this.canvas.quote.width) {
                this.lines.push(new Line(text, chars, 0, top, lineWidth, height));

                top       += height;
                chars      = word + " ";
                lineWidth  = wordWidth;
            } else {
                chars     += word;
                chars     += " ";
                lineWidth += wordWidth + spaceWidth;
            }
        }

        if (chars != "") {
            this.lines.push(new Line(text, chars, 0, top, lineWidth, height));

            top += height;
        }

        return top;
    }

    redraw_copy() {
        let round     = Math.round(this.canvas.copy.height * COPY.round),
            lineWidth = Math.round(this.canvas.copy.height * COPY.lineW);

        this.canvas.copy.drawRect(new Coords(0, 0, this.canvas.copy.width, this.canvas.copy.height), COPY.colorBgd,   true,  lineWidth, round);
        this.canvas.copy.drawRect(new Coords(0, 0, this.canvas.copy.width, this.canvas.copy.height), COPY.colorFrame, false, lineWidth, round);

        this.item.copyTa.content    = "[*centerV,center*]" + G0002.file.lang.getCaption("quoteCopiedToClipboard");
        this.item.copyBgd.isRedrawn = false;
    }

    refresh() {
        if (this.lines.length == 0)
            return;

        let top = this.line.max == 0 ? -this.marginTop : this.lines[this.line.i].coords.top - this.marginTop,
            i, line;

        this.canvas.quote.clear();

        for (i = this.line.i; i < this.lines.length; i++) {
            line = this.lines[i];

            line.text.prepare(this.canvas.quote);
            line.text.draw(this.canvas.quote, new Coords(line.coords.left, line.coords.top - top), line.chars);
        }

        this.item.main.isRedrawn = false;

        this.showBtns(this.line.max > 0);
    }

    show(isVisible) {
        this.item.main.isVisible = isVisible;

        this.showBtns(false);
    }

    showBtns(isVisible) {
        this.item.btnUp.isVisible = this.item.btnDown.isVisible = isVisible;
    }
}

export const SHOW_QUOTE = BK_ENGINE.tasks.add(new ShowQuote(BK_ENGINE.tasks));
