
import { Coords, fitPictureIntoFrame, getArrayFromString, getByName, readPropFromText, RGBAtoString, stringToRGBA } from "./bk engine utils.js";

// MAIN OBJECTS
import { INPUT } from "./_main objects/input.js";
import { PERFORMANCE } from "./_main objects/performance.js";
import { POINTER } from "./_main objects/pointer.js";
import { VIEW_PORT } from "./_main objects/view port.js";
import { WORLD3D } from "./_main objects/world3d.js";
import { Point, getThunder } from "./_main objects/world2d.js";

// GROUPS
import { CANVASES } from "./groups/canvases.js";
import { FILES } from "./groups/files.js";
import { IMAGES } from "./groups/images.js";
import { ITEMS } from "./groups/items.js";
import { RECTS } from "./groups/rects.js";
import { REDRAWS } from "./groups/redraws.js";
import { TASKS, Task } from "./groups/tasks.js";
import { TEXT_DISPLAYS } from "./groups/text displays.js";
import { TEXTS } from "./groups/texts.js";

// COMPLEX
import { BKengineTextContent } from "./complex/text content.js";
import { BKengineTextDisplay } from "./complex/text display.js";

// PRIMITIVES: TO DO, if necessary
import { BKengineShapes } from "./primitives/shapes.js";


const DEVELOP = {
    isShowStats:      false,
    isShowTasksNm:    true,
    isShowTasksNames: true
}


class BKengine {
    constructor() {
        // MAIN OBJECTS
        this.input         = INPUT;
        this.performance   = PERFORMANCE;
        this.pointer       = POINTER;
        this.viewPort      = VIEW_PORT;
        this.world3d       = WORLD3D;

        // GROUPS
        this.canvases      = CANVASES;
        this.files         = FILES;     // can be called from outside
        this.images        = IMAGES;
        this.items         = ITEMS;
        this.rects         = RECTS;
        this.redraws       = REDRAWS;
        this.tasks         = TASKS;
        this.textDisplays  = TEXT_DISPLAYS;
        this.texts         = TEXTS;
        
        // COMPLEX (functions)
        this.textContent   = BKengineTextContent;
        this.textDisplay   = BKengineTextDisplay;

        // PRIMITIVES
        this.shapes        = BKengineShapes;

        // other
        this.firstItem     = ITEMS.addNew("item", "name", "000", "isClickable", true);  // the very first item: the screen
        this.timer         = {now: 0, delta: 0};
        this.clicked       = {name: ""};        // will be updated with object clicked
        this.isTouchScreen = false;
    }

    addFromData(data) {
        let lines    = getArrayFromString(data);
        let patterns = [],
            lineI    = 0,
            linesNm  = lines.length,
            i, j, m, s, line, start, props, prop, pattern, arr;

        // read the lines
        while (lineI < linesNm) {
            line  = lines[lineI++];
            props = [];
            start = 0;

            // update lines' patterns
            while ((prop = readPropFromText(line, start)).isFound) {
                switch (prop.name) {
                    case "pattern":             // add a new pattern
                        patterns.push({name: prop.value, value: line.substring(prop.end).trim()});

                        start = line.length;
                        break;
                    case "patterns":            // use saved pattern(s)
                        arr = prop.value.split(" ");
                        s   = "";

                        for (j = 0, m = arr.length; j < m; j++) {
                            pattern = getByName(patterns, arr[j]);

                            if (pattern != undefined)
                                s += " " + pattern.value;
                            else {
                                start = prop.end;
                                console.log("pattern: " + arr[j] + " not found!");
                            }
                        }
                        line = line.substring(0, start) + s + line.substring(prop.end);
                        break;
                    default:                    // other properties and values
                        props.push(prop.name);
                        props.push(prop.value);

                        start = prop.end;
                }
            }

            // add objects
            if (props.length > 0) {
                switch (props[0]) {
                    case "canvas":
                        props[0] = "name";
                        this.canvases.addNew(props);
                        break;
                    case "image":
                        props[0] = "name";
                        this.images.addNew(props);
                        break;
                    case "rect":
                        props[0] = "name";
                        this.rects.addNew(props);
                        break;
                    case "redraw":
                        props[0] = "name";
                        this.redraws.addNew(props);
                        break;
                    case "text":
                        props[0] = "name";
                        this.texts.addNew(props);
                        break;
                    case "textDisplay":
                        props[0] = "name";
                        this.textDisplays.addNew(props);
                        break;
                    case "item":
                        props[0] = "name";

                        props.unshift("item");

                        i = props.indexOf("type");

                        if (i > -1) {
                            props[0] = props[i + 1];

                            props.splice(i, 2);
                        }
        
                        this.items.addNew(props);
                }
            }
        }
    }

    setFromData(data) {
        let lineI    = 0,
            patterns = [],
            i, j, m, s, obj, arr, props, prop, start,
            line, lines, linesNm, pattern;

        // init the lines
        i = data.indexOf("*** TRANSLATIONS ***");

        if (i == -1)
            i = data.length;

        lines   = getArrayFromString(data.substring(0, i));
        linesNm = lines.length;

        // read the lines
        while (lineI < linesNm) {
            line  = lines[lineI++];
            props = [];
            start = 0;

            // update lines' patterns
            while ((prop = readPropFromText(line, start)).isFound) {
                switch (prop.name) {
                    case "pattern":             // add a new pattern
                        patterns.push({name: prop.value, value: line.substring(prop.end).trim()});

                        start = line.length;

                        break;
                    case "patterns":            // use saved pattern(s)
                        arr = prop.value.split(" ");
                        s   = "";

                        for (j = 0, m = arr.length; j < m; j++) {
                            pattern = getByName(patterns, arr[j]);

                            if (pattern != undefined)
                                s += " " + pattern.value;
                            else {
                                start = prop.end;
                                console.log("pattern: " + arr[j] + " not found!");
                            }
                        }

                        line = line.substring(0, start) + s + line.substring(prop.end);

                        break;
                    default:                    // other properties and values
                        props.push(prop.name);
                        props.push(prop.value);

                        start = prop.end;
                }
            }

            // set objects' properties
            if (props.length > 0) {
                switch (props[0]) {
                    case "canvas":
                        obj = this.canvases.getByName(props[1]);
                        break;
                    case "image":
                        obj = this.images.getByName(props[1]);
                        break;
                    case "item":
                        obj = this.items.getByName(props[1]);
                        break;
                    case "text":
                        obj = this.texts.getByName(props[1]);
                        break;
                    case "rect":
                        obj = this.rects.getByName(props[1]);
                        break;
                    case "redraw":
                        obj = this.redraws.getByName(props[1]);
                        break;
                    case "textDisplay":
                        obj = this.textDisplays.getByName(props[1]);
                        break;
                    default:
                        console.log("setFromData: to do: " + props[0] + "!");
                }

                if (obj == undefined)
                    console.error("obj undefined! name: " + props[1]);
                else for (i = 2; i < props.length; i += 2) {
                        // if (props[i] == "src")
                        //     console.log(props[i + 1]);
                        
                        obj[props[i]] = props[i + 1];
                }
            }
        }

        this.items.redrawAll();
    }

    flushInput() {
        this.items.clickable.flush();
        this.update_clicked();
    }

    update() {
        this.update_timer();

        this.canvases.refresh();
        this.items.render();

        if (this.viewPort.update()) {
            this.firstItem.width  = this.viewPort.width;
            this.firstItem.height = this.viewPort.height;
        }

        this.input.update();
        this.tasks.update();
        this.world3d.update();
        this.items.update();
        this.performance.update();

        this.update_clicked();
        this.update_develop();
    }

    update_timer() {
        let now = Date.now();

        this.timer.delta = now - this.timer.now;
        this.timer.now   = now;
    }

    update_clicked() {      // can be called from outside
        this.clicked = this.items.clickable.getLast();
    }

    update_develop() {
        let s = "";

        if (!DEVELOP.isShowStats)
            return;

        if (DEVELOP.isShowTasksNm)
            s += "Active tasks: " + this.tasks.active.length + " ";

        if (DEVELOP.isShowTasksNames) {
            for (let i = 0, l = this.tasks.active.length; i < l; i++)
                s += this.tasks.active[i].constructor.name + ",";
        }

        console.log(s);
    }
}


const BK_ENGINE = new BKengine();


window.addEventListener('touchstart', function() {
    BK_ENGINE.isTouchScreen = true;
});


BK_ENGINE.loop = function() {
    BK_ENGINE.update();

    requestAnimationFrame(BK_ENGINE.loop);
}

BK_ENGINE.start = function() {
    this.loop();
}


export { BK_ENGINE, Coords, BKengineTextContent, BKengineTextDisplay, fitPictureIntoFrame, getThunder, Point, RGBAtoString, stringToRGBA, Task };
