
import { BK_ENGINE } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0001 play logics.js";
import { PLAY_DISPLAY as DISPLAY } from "./_bible g0001 play display.js";


const APPEARING_ZOOM_STEP = 0.02,
      TIME                = {appearing: 300, disappearing: 500};


class Letter {
    constructor(itemRef) {
        this.item      = itemRef;

        this.X         = 0;
        this.Y         = 0;

        this.isGuessed = false;
    }
}


class DisplayLetters {
    constructor() {
        this.STATE       = {waiting: 10, appearing: 20, guessing: 30, disappearing: 40};
        this.state       = 0;

        this.offsetY     = 0;
        this.gap         = 0;
        this.activeNm    = 0;
        this.time        = 0;

        this.all         = [];

        this.isInitiated = false;
    }

    getByI(i) {
        return this.all[i];
    }

    init() {
        let item = BK_ENGINE.items.getByName("bible_g0001_play_btnLetter"),
            i    = 0;

        // letters
        item.info      = i++;
        item.isVisible = false;

        this.all.push(new Letter(item));

        for (; i < DISPLAY.LETTERS_MAX; i++) {
            item = BK_ENGINE.items.clone(item);

            item.info      = i;
            item.zoom      = item.transp = 0;
            item.isVisible = false;

            this.all.push(new Letter(item));
        }

        // offsetY and gap
        switch (BK_ENGINE.viewPort.orientation) {
            case "verNarrow":
                this.offsetY = 0.57;
                this.gap      = 0.14;
                break;
            case "verWide":
                this.offsetY = 0.68;
                this.gap      = 0.10;
                break;
            case "horNarrow":
            case "horWide":
                this.offsetY = 0.75;
                this.gap      = 0.0712;
        }

        this.isInitiated = true;
    }

    start() {
        !this.isInitiated && this.init();

        this.state = this.STATE.waiting;

        // random buttons color every session
        let btnImage  = BK_ENGINE.images.getByName("bible_g0001_play_lettersBtns"),
            btnRedraw = BK_ENGINE.redraws.getByName("bible_g0001_play_letterImg");

        btnRedraw.srcCol = Math.floor((Math.random() * btnImage.cols));

        this.reset();
    }

    reset(_activeNm = 0) {
        let guessedNm = 0,
            i, l, letterLog, letter, item, helper;

        this.activeNm = _activeNm;

        // init some values
        for (i = 0; i < this.activeNm; i++) {
            letterLog = LOGICS.letters.getByI(i);
            letter    = this.getByI(i);
            item      = letter.item;

            item.zoom      = item.transp = 0;
            item.isVisible = true;
            item.isRedrawn = false;
            item.caption   = letterLog.char.toUpperCase();
            item.keyCode   = BK_ENGINE.input.getKeyCodeFromName(item.caption);

            if (letterLog.isGuessed)
                guessedNm++;
        }

        // turn the rest off
        for (l = this.all.length; i < l; i++)
            this.all[i].item.isVisible = false;
    
        // init coords
        let activeNm = this.activeNm - guessedNm,
            col      = 0,
            row      = 0;
        let firstRow = activeNm >= DISPLAY.lettersPerRow ? DISPLAY.lettersPerRow : activeNm;
        let offsetX  = firstRow % 2 == 0 ? 0.5 - firstRow / 2 * this.gap + this.gap / 2 : 0.5 - firstRow / 2 * this.gap + this.gap / 2;

        for (i = 0; i < this.activeNm; i++) {
            letterLog = LOGICS.letters.getByI(i);
            letter    = this.getByI(i);

            letter.isGuessed = letterLog.isGuessed;

            if (letter.isGuessed) {
                helper = DISPLAY.helpers.getByI(i);

                letter.X = helper.X;
                letter.Y = helper.Y;
            } else {
                letter.X = offsetX       + col * this.gap;
                letter.Y = this.offsetY + row * this.gap;

                if (++col == DISPLAY.lettersPerRow) {
                    col = 3 - Math.floor((activeNm - DISPLAY.lettersPerRow) / 2);
                    row++;
                }
            }
        }

        this.refresh();
    }

    update() {
        switch (this.state) {
            case this.STATE.waiting:
                this.waiting();
                break;
            case this.STATE.appearing:
                this.appearing();
                break;
            case this.STATE.guessing:
                this.guessing();
                break;
            case this.STATE.disappearing:
                this.disappearing();
        }
    }

    waiting() {
        let activeNm = LOGICS.letters.activeNm;

        if (activeNm > 0) {
            this.reset(activeNm);

            this.time  = Date.now();
            this.state = this.STATE.appearing;
        }
    }

    appearing() {
        let zoomStart = (Date.now() - this.time) / TIME.appearing;
        let zoom      = zoomStart,
            isFinish  = true,
            i, letter, value;

        for (i = 0; i < this.activeNm; i++) {
            letter = this.getByI(i);

            if (letter.isGuessed)
                value = Math.min(1, zoomStart);
            else {
                zoom  -= APPEARING_ZOOM_STEP;
                value  = Math.min(1, Math.max(0, zoom));

                if (value < 1)          // finish only if the last zoom == 1
                    isFinish = false;
            }

            letter.item.zoom = letter.item.transp = value;
        }

        if (isFinish)
            this.state = this.STATE.guessing;
    }

    guessing() {
        if (LOGICS.letters.activeNm == 0) {
            this.time  = Date.now();
            this.state = this.STATE.disappearing;
        } else
            this.refresh();
    }

    disappearing() {
        let zoom = Math.max(0, 1 - (Date.now() - this.time) / TIME.disappearing),
            i;

        for (i = 0; i < this.activeNm; i++)
            this.all[i].item.zoom = this.all[i].item.transp = zoom;

        if (zoom == 0)
            this.state = this.STATE.waiting;
    }

    refresh() {
        let charsBinded = [],
            i, srcX, srcY, dstX, dstY,
            letterLog, letter, char;

        for (i = 0; i < this.activeNm; i++) {
            letterLog = LOGICS.letters.getByI(i);
            letter    = this.getByI(i);

            // position
            if (letterLog.srcY == 0) {
                srcX = DISPLAY.helpers.getByI(letterLog.srcX).X;
                srcY = DISPLAY.helpers.getByI(letterLog.srcX).Y;
            } else {
                srcX = this.getByI(letterLog.srcX).X;
                srcY = this.getByI(letterLog.srcX).Y;
            }

            if (letterLog.dstY == 0) {
                dstX = DISPLAY.helpers.getByI(letterLog.dstX).X;
                dstY = DISPLAY.helpers.getByI(letterLog.dstX).Y;
            } else {
                dstX = this.getByI(letterLog.dstX).X;
                dstY = this.getByI(letterLog.dstX).Y;
            }

            letter.item.x = srcX + (dstX - srcX) * letterLog.progress;
            letter.item.y = srcY + (dstY - srcY) * letterLog.progress;

            // is active?
            letter.item.isActive = !letterLog.isGuessed;

            // is binded?
            letter.item.isBinded = false;
            char                 = letter.item.caption;

            if (letterLog.dstY == 1 && (charsBinded.indexOf(char) == -1)) {
                charsBinded.push(char);

                letter.item.isBinded = true;
            }
        }
    }
}

export const DISPLAY_LETTERS = new DisplayLetters();
