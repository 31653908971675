import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"
import { HELP } from "../help/help.js"
import { GW_MAIN } from "./_gw main.js"

// as soon as program start, call: COOKIES.preload() - will be read if cookies are enabled
// some functions can be called from outside, even if this task isn't active (setOne(), getOne());

const SPEED                 = 0.004,    // cookie appear/disappear
      STEP_FIRST_TIME       = 0.25,     // multiply the speed when the cookies banner appear for the first time
      COOKIES_TO_BE_SET_MAX = 100,
      STATE                 = {moving: 10, main: 20};


class Cookies extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.counter     = {value: 0, step: 0};
        this.toBeSet     = [];        // if not enabled, here will be gathered cookies to set, if by change they will be set to enabled in the future

        this._isEnabled  = false;
        this.isNewUser   = true;
        this.isInitiated = false;
    }

    set isEnabled(value) {
        let val = value ? "true" : "false",
            i;

        this._isEnabled = value;

        this.setNecessary("isCookieEnabled", val);

        if (value) {
            for (i = 0; i < this.toBeSet.length; i += 3)
                this.setNecessary(this.toBeSet[i], this.toBeSet[i + 1], this.toBeSet[i + 2]);

            this.toBeSet = [];
        }
    }

    get isEnabled() {
        return this._isEnabled;
    }

    preload() {
        let cookie = this.getNecessary("isCookieEnabled");

        this.isNewUser  = cookie == "";
        this._isEnabled = cookie == "true";
    }

    setNecessary(name, value = "true", expDays = 365) {
        let date = new Date(),
            expires;
    
        date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    
        expires = "expires=" + date.toUTCString();
    
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    getNecessary(name) {
        let name2 = name + "=",
            arr   = document.cookie.split(';'),
            i, l, cookie;

        for (i = 0, l = arr.length; i < l; i++) {
            cookie = arr[i];

            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }

            if (cookie.indexOf(name2) == 0)
                return cookie.substring(name2.length, cookie.length);
        }

        return "";
    }

    addToBeSet(name, value, expDays) {
        let i;

        for (i = 0; i < this.toBeSet.length; i += 3) {
            if (this.toBeSet[i] == name) {      // if the cookie already exists, just update it
                this.toBeSet[i + 1] = value;
                this.toBeSet[i + 2] = expDays;

                return;
            }
        }

        if (this.toBeSet.length < COOKIES_TO_BE_SET_MAX)
            this.toBeSet.push(name, value, expDays);
    }

    extractNumber(cookie, from, to) {
        let result = {isValid: false, value: 0};

        if (cookie.length >= to) {
            result.value = parseInt(cookie.substring(from, to));

            if (!isNaN(result.value))
                result.isValid = true;
        }

        return result;
    }

    setOne(name, value = "true", expDays = 365) {
        if (this.isEnabled)
            return this.setNecessary(name, value, expDays);

        return this.addToBeSet(name, value, expDays);
    }

    getOne(name) {
        if (this.isEnabled)
            return this.getNecessary(name);

        return "";
    }

    init() {
        this.item        = {mainFrame: BK_ENGINE.items.getByName("cookieBanner")};
        this.isInitiated = true;
    }

    start2() {
        let step = 1;

        if (!this.isInitiated) {
            this.init();

            if (!this.isNewUser)
                return this.end();

            step = STEP_FIRST_TIME;
        }

        this.show(true);
        this.start_moving(step);
    }

    start_moving(step) {
        if (step == 1)
            this.counter.value = 0;

        this.counter.step = step;
        this.state        = STATE.moving;
    }

    update() {
        if (this.state == STATE.moving)
            return this.update_moving();

        this.update_main();
    }

    update_moving() {
        this.counter.value += BK_ENGINE.timer.delta * this.counter.step * SPEED;
        this.counter.value = Math.min(Math.max(0, this.counter.value), 1);

        this.refresh();

        if (this.counter.value == 1)
            return this.state = STATE.main;
        else if (this.counter.value == 0) {
            this.show(false);
            this.end();
        }
    }

    update_main() {
        switch (BK_ENGINE.clicked.name) {
            case "cookieBanner_btnYes":
                this.isEnabled = true;
                return this.start_moving(-1);
            case "cookieBanner_btnNo":
                this.isEnabled = false;
                return this.start_moving(-1);
            case "cookieBanner_btnMoreInfo":
                HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_cookies").trim());
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }

    hide() {        // can be called from outside
        this.start_moving(-1);
    }

    refresh() {
        this.item.mainFrame.pivY = -this.counter.value;
    }
}


export const COOKIES = BK_ENGINE.tasks.add(new Cookies(BK_ENGINE.tasks));
