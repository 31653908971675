
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { GW_USER } from "../gw user/_gw user.js"
import { GW_MAIN, INFO_BOX } from "./_gw main.js"


const STATE = {main: 10, otherLang: 20};


class MenuLang extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state       = STATE.main;

        this.item        = true;
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_menuLang"),
                     btnLang:   {en:   I.getByName("main_menuLang_en"),
                                 es:   I.getByName("main_menuLang_es"),
                                 esla: I.getByName("main_menuLang_esla"),
                                 fr:   I.getByName("main_menuLang_fr"),
                                 it:   I.getByName("main_menuLang_it"),
                                 pl:   I.getByName("main_menuLang_pl")},
                     info:      I.getByName("main_menuLang_otherInfo")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.selected = "";

        this.show(true);
    }

    end2(selected = "") {
        GW_USER.lang != selected && (this.selected = selected);

        this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked;

        if (this.state == STATE.main)
            return this.update_main(btn);

        this.update_otherLang(btn);
    }

    update_main(btn) {
        switch (btn.name) {
            case "main_menuLang_menu":
                BK_ENGINE.flushInput();     // the same button!
                return this.end();
            case "main_menuLang_other":
                return this.update_otherLang();
            default:
                if (btn.name.indexOf("main_menuLang_") == 0)
                    return this.end(btn.info);             // a language is selected

                if (btn.name != "")
                    this.end(GW_MAIN.checkMenus());
        }
    }

    update_otherLang(btn) {
        if (this.state != STATE.otherLang) {
            this.state               = STATE.otherLang;
            this.item.info.isVisible = true;

            return INFO_BOX.start("", "close");
        }

        if (btn.name == "main_menuLang_otherInfo_link")
            return window.open("https://" + btn.info, '_blank');

        if (INFO_BOX.isActive)
            return;

        this.item.info.isVisible = false;
        this.state               = STATE.main;
    }

    show(isVisible) {
        let btn;

        this.item.mainFrame.isVisible = isVisible;

        if (!isVisible)
            return;

        // set the selected lang button to be pressed
        for (const prop in this.item.btnLang) {
            btn = this.item.btnLang[prop];

            btn.isKeepPressed = GW_USER.lang == btn.name.substring(btn.name.length - 2).toLowerCase();
        }
    }

    refresh() {    // call from outside, after the lang is set
        BK_ENGINE.redraws.getByName("main_menuLang_menu").srcRow = GW_MAIN.languages.tags.indexOf(GW_USER.lang);
    }
}

export const MENU_LANG = BK_ENGINE.tasks.add(new MenuLang(BK_ENGINE.tasks));
