
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"


class ControlBox extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.selected    = "";
        this.isInitiated = false;
    }

    setBtns() {
        let i, l, prop, btnName;

        // all off
        for (prop in this.item.btn)
            this.item.btn[prop].isVisible = false;

        // set only the selected ones
        for (i = 0, l = arguments.length; i < l; i++) {
            btnName = arguments[i];

            if (this.item.btn.hasOwnProperty(btnName))
                this.item.btn[btnName].isVisible = true;
            else
                console.error("bible.tasks, not such button: " + btnName + "!");
        }
    }

    setContent(sContent, isCenterV = true) {
        this.item.content.content = isCenterV ? "[*centerV,center*]" + sContent : "[*center*]" + sContent;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("bible_controlBox"),
                     title:     I.getByName("bible_controlBox_title"),
                     content:   I.getByName("bible_controlBox_content"),
                     btn:       {abandonL: I.getByName("bible_controlBox_abandonL"),
                                 abandon:  I.getByName("bible_controlBox_abandon"),
                                 close:    I.getByName("bible_controlBox_close"),
                                 confirm:  I.getByName("bible_controlBox_confirm"),
                                 gotIt:    I.getByName("bible_controlBox_gotIt"),
                                 no:       I.getByName("bible_controlBox_no"),
                                 ok:       I.getByName("bible_controlBox_ok"),
                                 prev:     I.getByName("bible_controlBox_prev"),
                                 proceed:  I.getByName("bible_controlBox_proceed"),
                                 yes:      I.getByName("bible_controlBox_yes")}};

        this.isInitiated = true;
    }

    start2(sTitle, sContent = "", arrBtns = [], isCenterV = true) {         // title, content, buttons names
        !this.isInitiated && this.init();

        this.selected           = "";
        this.item.title.caption = sTitle;

        this.setContent(sContent, isCenterV);
        this.setBtns(...arrBtns)
        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (BK_ENGINE.clicked.name) {
            case "bible_controlBox_confirm":
            case "bible_controlBox_gotIt":
            case "bible_controlBox_ok":
            case "bible_controlBox_proceed":
            case "bible_controlBox_yes":
                return this.selected = "confirm";
            case "bible_controlBox_abandonL":
            case "bible_controlBox_abandon":
            case "bible_controlBox_close":
            case "bible_controlBox_no":
                return this.selected = "abandon";
            case "bible_controlBox_prev":
                this.selected = "prev";
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const CONTROL_BOX = BK_ENGINE.tasks.add(new ControlBox(BK_ENGINE.tasks));
