
import { CANVASES } from "../groups/canvases.js";


class Word {
    constructor(textI, chars, oX, oY) {
        this.textI = textI;
        this.chars = chars;

        this.x     = oX || 0;
        this.y     = oY || 0;

        this.left  = 0;
        this.top   = 0;
        this.width = 0;
    }
}

class Expression {
    constructor() {
        this.chars = "";
        this.left  = 0;
        this.top   = 0;
        this.width = 0;                 // sum of words' widths

        this.words = [];
    }
}

class Line {
    constructor() {
        this.align       = "left";      // left, center, right, justify

        this.left        = 0;
        this.top         = 0;
        this.width       = 0;
        this.height      = 0;

        this.expressions = [];
    }
}

class Page {
    constructor() {
        this.top   = 0;

        this.lines = [];
    }
}


class AddTextContent {
    constructor(PARENT) {
        this.PARENT         = PARENT;

        this.isCenterH      = false;
        this.isCenterV      = false;

        this.page           = null;
        this.line           = null;
        this.expression     = null;

        this.tcLine         = {expressions: [],
                               isFirst:     true,
                               isLast:      false,
                               isChapter:   false,
                               isCenterV:   false,
                               align:       "left",     // = isCenterH
                               indent:      0};
        this.lastLineHeight = 0;           // if there's an empty line it will have this height

        this.init();
    }

    init() {
        this.tcLine.update = function(parentWidth, newLine) {
            let i, j, f;
    
            this.expressions = newLine.expressions;
            this.isFirst     = true;
            this.isLast      = false;
            this.isChapter   = false;
            
            // formatting tags?
            if ((f = newLine.formatting).length > 0) {
                if (f.includes("center "))      this.align     = "center";
                else if (f.includes("center*")) this.align     = "center";
                else if (f.includes("left"))    this.align     = "left";
                else if (f.includes("right"))   this.align     = "right";
                else if (f.includes("justify")) this.align     = "justify";
                if (f.includes("chapter"))      this.isChapter = true;
                if (f.includes("centerV"))      this.isCenterV = true;
    
                if ((i = f.indexOf("auto-indent")) > -1) {
                    i = j = i + "auto-indent".length;
                    
                    while (j < f.length && (!isNaN(f.charAt(j)) || f.charAt(j) == "."))
                        j++;
    
                    i = parseFloat(f.substring(i, j));
    
                    if (i)
                        this.indent = i * parentWidth;
                    else
                        console.error("text content, tag \"indent\" not recognized!")
                }
            }
        }        
    }

    start(textContent) {
        let i, j, k, l, m, n,
            tcExpression, tcWord,
            indent, word, text;

        this.page       = new Page();
        this.line       = new Line();
        this.expression = new Expression();

        this.tcLine.align     = this.isCenterH ? "center" : "left";
        this.tcLine.isCenterV = this.isCenterV;
        this.tcLine.indent    = 0;
        this.lastLineHeight   = this.PARENT.texts[0].height;
        // this.lastLineHeight   = 0;

        CANVASES.tmp.resize(this.PARENT.width, this.PARENT.height);

        // lines loop
        for (i = 0, l = textContent.lines.length; i < l; i++) {
            this.tcLine.update(this.PARENT.width, textContent.lines[i]);

            // expressions loop
            for (j = 0, m = this.tcLine.expressions.length; j < m; j++) {
                tcExpression = this.tcLine.expressions[j];

                // words loop
                for (k = 0, n = tcExpression.words.length; k < n; k++) {
                    tcWord                 = tcExpression.words[k];
                    word                   = new Word(tcWord.textI, tcWord.chars);
                    text                   = this.PARENT.texts[word.textI];
                    word.width             = text.getWidth(CANVASES.tmp, word.chars);
                    this.expression.chars += word.chars;
                    this.expression.width += word.width;

                    this.expression.words.push(word);
                }

                // expression wider than line?
                indent = this.tcLine.isFirst ? this.tcLine.indent : 0;

                if (this.line.width + this.expression.width + indent >= this.PARENT.width) {
                    this.addLine();

                    this.tcLine.isFirst = false;
                }

                this.addExpression();
            }

            this.tcLine.isLast = true;
            
            this.addLine();
        }

        if (this.page.lines.length > 0)
            this.addPage();

        CANVASES.tmp.resize(0, 0);
    }

    addExpression() {
        this.line.width += this.expression.width;

        this.line.expressions.push(this.expression);

        this.expression = new Expression();
    }

    addLine() {
        this.line.align = this.tcLine.align;

        // remove the space at the beginning of the line, if it's not the first line
        if (!this.tcLine.isFirst) {
            if (this.line.expressions[0].chars.trim().length == 0)
                this.line.expressions.shift();
        }

        // align
        switch (this.tcLine.align) {
            case "center":
                this.addLine_center();
                break;
            case "justify":
                if (!this.tcLine.isLast) {
                    this.addLine_justify();
                    break;
                }
            default:
                this.addLine_left();
                break;
        }

        // is the line a "chapter" type?
        if (this.tcLine.isChapter && this.tcLine.isFirst)
            this.addLine_adjustChapterTop();
    
        this.addLine_updateHeight();

        if (this.page.top + this.line.height >= this.PARENT.height)     // add a new page?
            this.addPage();

        this.line.top  = this.page.top;
        this.page.top += this.line.height;

        this.page.lines.push(this.line);

        this.line = new Line();
    }

    addLine_updateHeight() {
        let textI = -1,
            i, j, l, m,
            expression, word;

        for (i = 0, l = this.line.expressions.length; i < l; i++) {
            expression = this.line.expressions[i];

            for (j = 0, m = expression.words.length; j < m; j++) {
                word = expression.words[j];

                if (word.textI != textI) {
                    textI = word.textI;

                    if (this.PARENT.texts[textI].height > this.line.height)
                        this.line.height = this.PARENT.texts[textI].height;
                }
            }
        }

        if (this.line.height == 0)
            this.line.height = this.lastLineHeight;
        else
            this.lastLineHeight = this.line.height;
    }

    addLine_left() {
        let left = this.tcLine.isFirst ? this.tcLine.indent : 0,
            i, l, expression;

        for (i = 0, l = this.line.expressions.length; i < l; i++) {
            expression       = this.line.expressions[i];
            expression.left  = left;
            left            += expression.width;
        }
    }

    addLine_updateWidth() {
        let i, l;

        this.line.width = 0;

        for (i = 0, l = this.line.expressions.length; i < l; i++)
            this.line.width += this.line.expressions[i].width;
    }

    addLine_updateExpressionsLeft() {
        let left = 0,
            i, l, expression;

        for (i = 0, l = this.line.expressions.length; i < l; i++) {
            expression       = this.line.expressions[i];
            expression.left  = left;
            left            += expression.width;
        }
    }

    addLine_center() {
        // remove empty expressions at the beginning and end of line
        while (this.line.expressions.length > 0 && this.line.expressions[0].chars.trim().length == 0)
            this.line.expressions.shift();

        while (this.line.expressions.length > 0 && this.line.expressions[this.line.expressions.length - 1].chars.trim().length == 0)
            this.line.expressions.pop();

        this.addLine_updateWidth();
        this.addLine_updateExpressionsLeft();

        this.line.left = (this.PARENT.width - this.line.width) / 2;
    }

    addLine_justify() {
        let left = this.tcLine.isFirst ? this.tcLine.indent : 0,
            i    = 0,
            l, spaceW;

        this.line.width = 0;

        // remove spaces
        while (i < this.line.expressions.length) {
            if (this.line.expressions[i].chars.trim().length == 0)
                this.line.expressions.splice(i, 1);
            else
                this.line.width += this.line.expressions[i++].width;
        }

        // set expressions' left
        if (this.line.expressions.length > 1) {
            spaceW = (this.PARENT.width - this.line.width - left) / (this.line.expressions.length - 1);

            for (i = 0, l = this.line.expressions.length; i < l; i++) {
                this.line.expressions[i].left  = left;
                left                          += this.line.expressions[i].width + spaceW;
            }
        }
    }

    addLine_adjustChapterTop() {
        let top = this.PARENT.texts[0].height - this.PARENT.texts[2].height,      // chapter height - verse height
            l   = this.line.expressions.length,
            i;

        // chapter number
        if (l > 0)
            this.line.expressions[0].top = Math.round(this.PARENT.ADJUST_CHAPTER_TOP * this.PARENT.texts[0].height);

        // expressions
        for (i = 1, l = this.line.expressions.length; i < l; i++)
            this.line.expressions[i].top = top;
    }

    addPage() {
        this.page.top = this.tcLine.isCenterV ? this.addPage_centerV() : 0;

        this.PARENT.pages.push(this.page);
        
        this.page = new Page();
    }

    addPage_centerV() {
        let pageHeight = 0,
            i, l;

        for (i = 0, l = this.page.lines.length; i < l; i++)
            pageHeight += this.page.lines[i].height;

        return (this.PARENT.height - pageHeight) / 2;
    }
}


export class BKengineTextDisplay {
    constructor() {
        this.ADJUST_CHAPTER_TOP = 0.15;

        this.width              = 0;
        this.height             = 0;
        
        this.texts              = [];
        this.pages              = [];

        this._addTextContent    = new AddTextContent(this);
    }

    set isCenterH(value) {
        this._addTextContent.isCenterH = value;
    }

    set isCenterV(value) {
        this._addTextContent.isCenterV = value;
    }

    get effectiveHeight() {
        let height = 0,
            pageI, pagesNm, page, lineI, linesNm;

        for (pageI = 0, pagesNm = this.pages.length; pageI < pagesNm; pageI++) {
            page = this.pages[pageI];

            for (lineI = 0, linesNm = page.lines.length; lineI < linesNm; lineI++)
                height += page.lines[lineI].height;
        }

        return height;
    }

    initToUseOnlyWords() {
        this.pages.push(new Page());
        this.pages[0].lines.push(new Line());
        this.pages[0].lines[0].expressions.push(new Expression());
    }

    // call these 2 functions before calling any other
    resize(width, height) {
        this.width  = width;
        this.height = height;
    }

    setTexts(arrTexts) {
        this.texts = [...arrTexts];
    }

    setWordProp(pageI, lineI, exprI, wordI, prop, value) {
        let word = this.pages[pageI].lines[lineI].expressions[exprI].words[wordI];
        
        word[prop] = value;
    }

    getPageNmByChars(pageStart, chars) {
        let pageI   = pageStart,
            pagesNm = this.pages.length,
            page, lineI, linesNm, line, exprI, exprsNm;

        while (pageI < pagesNm) {
            page    = this.pages[pageI++];
            lineI   = 0;
            linesNm = page.lines.length;

            while (lineI < linesNm) {
                line    = page.lines[lineI++];
                exprI   = 0;
                exprsNm = line.expressions.length;

                while (exprI < exprsNm) {
                    if (line.expressions[exprI++].chars == chars)
                        return pageI;
                }
            }
        }

        return -1;
    }

    addWord(pageI, lineI, exprI, textI, chars, x, y) {
        let obj = this.pages[pageI].lines[lineI].expressions[exprI].words;
        let i = obj.push(new Word(textI, chars, x, y));

        return obj[i - 1];
    }

    addEmptyPage() {
        return this.pages.push(new Page());
    }

    addTextContent(textContent, isPagesNmEven = false) {
        this.setTexts(textContent.texts);
        this.updateTextsHeight(this.height);

        this._addTextContent.start(textContent, isPagesNmEven);

        if (isPagesNmEven && this.pages.length % 2 == 1)
            this.addEmptyPage();

        this.updateWordsCoords();
    }

    isPageEmpty(pageI) {
        return this.pages[pageI].lines.length == 0;
    }

    reset() {
        this.pages = [];
    }

    updateTextsHeight(objHeight) {
        for (let i = 0, l = this.texts.length; i < l; i++)
            this.texts[i].calcHeight(objHeight);
    }

    updateWordsCoords() {
        let i, j, k, l, m, n, t, u,
            page, line, expression, word,
            lineTop, exprTop, exprLeft, wordLeft;

        for (i = 0, l = this.pages.length; i < l; i++) {
            page = this.pages[i];

            for (j = 0, m = page.lines.length; j < m; j++) {
                line    = page.lines[j];
                lineTop = page.top + line.top;

                for (k = 0, n = line.expressions.length; k < n; k++) {
                    expression = line.expressions[k];
                    exprLeft   = line.left + expression.left;
                    exprTop    = lineTop + expression.top;
                    wordLeft   = 0;

                    for (t = 0, u = expression.words.length; t < u; t++) {
                        word       = expression.words[t];
                        word.x     = (exprLeft + wordLeft) / this.width;
                        word.y     = exprTop               / this.height;
                        word.left  = Math.round(word.x * this.width);
                        word.top   = Math.round(word.y * this.height);
                        wordLeft  += word.width;
                    }
                }
            }
        }
    }

    updateWordsCoordsFromXY(coords, oPageI) {
        let page = oPageI == undefined ? this.pages[0] : this.pages[oPageI],
            i, j, k, l, m, n,
            line, expression, word;

        if (this.pages.length == 0)
            return;

        for (i = 0, l = page.lines.length; i < l; i++) {
            line = page.lines[i];

            for (j = 0, m = line.expressions.length; j < m; j++) {
                expression = line.expressions[j];

                for (k = 0, n = expression.words.length; k < n; k++) {
                    word = expression.words[k];

                    word.left = Math.round(word.x * coords.width);
                    word.top  = Math.round(word.y * coords.height);
                }
            }
        }
    }

    draw(canvas, coords, oPageI) {
        let page  = oPageI == undefined ? this.pages[0] : this.pages[oPageI],
            textI = -1,
            i, j, k, l, m, n,
            line, expression, word, text;

        if (this.pages.length == 0)
            return;

        for (i = 0, l = page.lines.length; i < l; i++) {
            line = page.lines[i];

            for (j = 0, m = line.expressions.length; j < m; j++) {
                expression = line.expressions[j];

                for (k = 0, n = expression.words.length; k < n; k++) {
                    word = expression.words[k];

                    if (word.textI != textI) {
                        textI = word.textI;
                        text  = this.texts[textI];

                        text.prepare(canvas);
                    }

                    text.draw(canvas, {left: coords.left + word.left, top: coords.top + word.top}, word.chars);
                }
            }
        }
    }
}
