
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { HELP } from "../../../help/help.js"
import { GW_USER } from "../../../gw user/_gw user.js";
import { GW_MAIN, CONTROL_BOX, INFO_BOX } from "../../_gw main.js";


class SelectDenom extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.selected    = "";

        this.isInitiated = false;
    }

    init() {
        let I = BK_ENGINE.items;

        this.item = {mainFrame: I.getByName("main_userSelectDenom"),
                     content:   I.getByName("main_userSelectDenom_content"),
                     rbCath:    I.getByName("main_userSelectDenom_rbCath"),
                     rbProt:    I.getByName("main_userSelectDenom_rbProt")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        GW_USER.isVersionSet = true;
        this.selected        = GW_USER.denom;

        this.show(true);        
    }

    end2() {
        if (this.selected != GW_USER.denom)
            GW_USER.denom = this.selected;

        this.show(false);
    }

    update() {
        if (INFO_BOX.isActive)
            return;

        let btn = BK_ENGINE.clicked;

        switch (btn.name) {
            case "main_userSelectDenom_rbCath":
                return this.refresh("c");
            case "main_userSelectDenom_rbProt":
                return this.refresh("p");
            case "main_userSelectDenom_btnInfo":
                HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_userSettings").trim());
        }

        if (CONTROL_BOX.selected == "abandon")
            this.end();
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible) {
            CONTROL_BOX.start("", "", ["close"]);
            this.refresh();
        } else
            CONTROL_BOX.end();
    }

    refresh(selected) {
        if (selected != undefined)
            this.selected = selected;

        this.item.rbCath.isChecked = this.selected == "c";
        this.item.rbProt.isChecked = this.selected == "p";
    }
}

export const SELECT_DENOM = BK_ENGINE.tasks.add(new SelectDenom(BK_ENGINE.tasks));
