
import { BK_ENGINE, Task, Coords } from "../../../bk engine/_bk engine.js";
import { GW_USER } from "../../../gw user/_gw user.js";
import { G0002 } from "./_bible g0002.js";
import { AUDIO } from "./g0002 audio.js";
import { MENU } from "./g0002 menu.js";


class Info extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.item        = null;
        this.timeCaption = "";

        this.isInitiated = false;
    }

    init() {
        this.item = {mainFrame: BK_ENGINE.items.getByName("bible_g0002_info"),
                     textArea:  BK_ENGINE.items.getByName("bible_g0002_info_ta_time")};

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.timeCaption = G0002.file.lang.getCaption("timeInfo");

        MENU.showMenuFrame(false);
        this.show(true);

        if (G0002.isShowInfo) {
            G0002.isShowInfo = false;

            G0002.updateCookie();
        }
    }

    end2() {
        this.show(false);
        MENU.showMenuFrame(true);
    }

    update() {
        let date        = GW_USER.getDate();
        let minutes     = 24 * 60 - (date.hours * 60 + date.minutes);
        let hoursLeft   = Math.floor(minutes / 60),
            minutesLeft = minutes % 60;

        if (minutesLeft == 0)
            minutesLeft = "00";
        else if (minutesLeft < 10)
            minutesLeft = "0" + minutesLeft;

        this.item.textArea.content = this.timeCaption + " " + hoursLeft + ":" + minutesLeft;

        if (BK_ENGINE.clicked.name == "bible_g0002_info_btnQuit") {
            AUDIO.play("menuClick");
            this.end();
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;
    }
}

export const INFO = BK_ENGINE.tasks.add(new Info(BK_ENGINE.tasks));
