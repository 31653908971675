
import { getByName } from "../bk engine utils.js"
import { BKengineRedraw } from "../primitives/redraw.js"

import { CANVASES } from "./canvases.js"
import { IMAGES } from "./images.js"
import { RECTS } from "./rects.js"
import { TEXTS } from "./texts.js"
import { TEXT_DISPLAYS } from "./text displays.js"


class Redraws {
    constructor() {
        this.all = [];
    }

    add(redraw) {
        return this.all[this.all.push(redraw) - 1];
    }

    addNew() {
        let args   = (typeof arguments[0] == "string") ? arguments : arguments[0],
            redraw = new BKengineRedraw(),
            i, l, prop, value, obj;

        for (i = 0, l = args.length; i < l; i += 2) {
            prop  = args[i];
            value = args[i + 1];

            switch (prop) {
                case "src":
                    switch (redraw.type) {
                        case "image":
                            obj = IMAGES.getByName(value);

                            if (obj != undefined)
                                redraw.src = obj;
                            else
                                console.error("addRedraw, image: " + value + " not found!");
                            break;
                        case "canvas":
                            obj = CANVASES.getByName(value);

                            if (obj != undefined)
                                redraw.src = obj;
                            else
                                console.error("addRedraw, canvas: " + value + " not found!");
                            break;
                        case "rect":
                            obj = RECTS.getByName(value);

                            if (obj != undefined)
                                redraw.src = obj;
                            else
                                console.error("addRedraw, rect: " + value + " not found!");
                            break;
                        case "text":
                            obj = TEXTS.getByName(value);

                            if (obj != undefined)
                                redraw.src = obj;
                            else
                                console.error("addRedraw, text: " + value + " not found!");
                            break;
                        case "textDisplay":
                            obj = TEXT_DISPLAYS.getByName(value);

                            if (obj != undefined)
                                redraw.src = obj;
                            else
                                console.error("addRedraw, textDisplay: " + value + " not found!");
                    }
                    break;
                default:
                    // console.log(prop, value)
                    redraw[prop] = value;
            }
        }

        return this.add(redraw);
    }

    addFromNames = function(obj, names) {
        let arr = names.split(" "),
            i, l, name, redraw;

        for (i = 0, l = arr.length; i < l; i++) {
            name = arr[i];

            if (name.length > 0) {
                redraw = this.getByName(name);

                if (redraw != undefined)
                    obj.addRedraws(redraw);
                else
                    console.error("redraw: *" + name + "* not found!");
            }
        }
    }

    getByName(value) {
        return getByName(this.all, value);
    }
}


export const REDRAWS = new Redraws();
