
import { BK_ENGINE, Task } from "../../../bk engine/_bk engine.js"
import { CRYPTO, PAYPAL } from "../../../const.js"
import { HELP } from "../../../help/help.js"
import { GW_MAIN } from "../../_gw main.js";
import { CONTROL_BOX } from "../../control box.js"
import { INFO_BOX } from "../../info box.js"
import { MENU_INFO } from "../_menu info.js";
import { DONATORS_LIST } from "./donators list.js";


const STATE = {menu: 10, loadingTxns: 20, donatorsList: 30, selectMethod: 40, crypto: 50};


class Donations extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.state            = 0;
        this.form             = null;
        this.item             = null;
        this.isInitiated      = false;
        this.crypto           = {isUpdating: false, isUpdated: false, data: null};
        this.isBalanceUpdated = false;
        this.timer            = 0;
    }

    fetchCrypto(action) {
        let url;

        this.crypto.isUpdating = true;
        this.crypto.isUpdated  = false;
        this.timer             = BK_ENGINE.timer.now;

        switch (action) {
            case "balance":
                url = CRYPTO.api + "?module=account&action=balance&address=" + CRYPTO.address.main + "&apikey=" + CRYPTO.apiKey;
                break;
            case "tokenbalance":
                url = CRYPTO.api + "?module=account&action=tokenbalance&contractaddress=" + CRYPTO.address.tokenContract + "&address=" + CRYPTO.address.main + "&tag=latest&apikey=" + CRYPTO.apiKey;
                break;
            case "tokentx":
                url = CRYPTO.api + "?module=account&action=tokentx&contractaddress=" + CRYPTO.address.tokenContract + "&address=" + CRYPTO.address.main + "&page=1&offset=5&startblock=" + CRYPTO.block.start + "&endblock=" + CRYPTO.block.end + "&sort=asc&apikey=" + CRYPTO.apiKey;
                // url = CRYPTO.api + "?module=account&action=tokentx&contractaddress=0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51&address=0x7bb89460599dbf32ee3aa50798bbceae2a5f7f6a&page=1&offset=5&startblock=0&endblock=999999999&sort=asc&apikey=YourApiKeyToken
        }

        fetch(url)
            .then((res) => res.text())
            .then((res) => {
                let obj = JSON.parse(res);

                if (obj.status == "1") {
                    this.crypto.isUpdating = false;
                    this.crypto.isUpdated  = true;
                    this.crypto.data       = obj.result;
                }});
    }

    init() {
        let I     = BK_ENGINE.items,
            div   = document.createElement("div"),
            input = document.createElement("input");

        this.form = document.createElement("form");

        div.style.display = "none";

        this.form.setAttribute("method", "post");
        this.form.setAttribute("target", "_blank");
        this.form.setAttribute("action", PAYPAL.address);

        input.setAttribute("name", "hosted_button_id");
        input.setAttribute("value", PAYPAL.id);

        document.body.appendChild(div);
        div.appendChild(this.form);
        this.form.appendChild(input);

        this.item = {mainFrame:           I.getByName("main_donations"),
                     menuFrame:           I.getByName("main_donations_menu"),
                     menuBalance:         I.getByName("main_donations_menu_balance"),
                     selectMethodFrame:   I.getByName("main_donations_selectMethod"),
                     selectMethodContent: I.getByName("main_donations_selectMethod_content"),
                     cryptoFrame:         I.getByName("main_donations_crypto")};

        I.getByName("main_donations_crypto_btnAddress").caption = CRYPTO.address.main;

        this.isInitiated = true;
    }

    start2() {
        !this.isInitiated && this.init();

        this.state            = STATE.menu;
        this.isBalanceUpdated = false;

        this.fetchCrypto("tokenbalance");
        this.show(true);
    }

    end2() {
        this.show(false);
    }

    update() {
        switch (this.state) {
            case STATE.menu:
                return this.update_menu();
            case STATE.loadingTxns:
                return this.update_loadingTxns();
            case STATE.donatorsList:
                return this.update_donatorsList();
            case STATE.selectMethod:
                return this.update_selectMethod();
            case STATE.crypto:
                this.update_crypto();
        }
    }

    update_menu() {
        if (this.state != STATE.menu) {
            this.state = STATE.menu;

            this.refresh();
        }

        this.update_menu_balance();

        switch (BK_ENGINE.clicked.name) {
            case "main_donations_menu_btnVerify":
                return window.open(CRYPTO.webScan + "address/" + CRYPTO.address.main + "#tokentxns", '_blank');
            case "main_donations_menu_btnInfo":
                return HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_donationsInfo").trim());
            case "main_donations_menu_btnDonate":
                return this.update_selectMethod();
            case "main_donations_menu_btnDonators":
                return this.update_loadingTxns();
        }

        if (CONTROL_BOX.selected == "abandon")
            this.end();
    }

    update_menu_balance() {
        let i, l;

        if (this.isBalanceUpdated)
            return;

        if (this.crypto.isUpdated) {
            this.item.menuBalance.caption = CRYPTO.valueToDecBUSD(this.crypto.data) + " BUSD";

            return this.isBalanceUpdated = true;
        }

        // waiting for crypto data to be read
        this.item.menuBalance.caption = "";

        for (i = -1, l = Math.floor((BK_ENGINE.timer.now - this.timer) / 500) % 10; i < l; i++)
            this.item.menuBalance.caption += ".";
    }

    update_loadingTxns() {
        let content = GW_MAIN.file.lang.getCaption("donations_loadingTxns") + "<br><br>",
            i, l;

        if (this.state != STATE.loadingTxns) {
            this.state = STATE.loadingTxns;

            this.show(false);
            this.fetchCrypto("tokentx");

            return INFO_BOX.start(content, "close");
        }

        if (this.crypto.isUpdated) {
            if (typeof this.crypto.data == "string")    // something gone wrong!
                this.fetchCrypto("tokentx");
            else {
                this.state = STATE.donatorsList;
                
                INFO_BOX.end();
                this.show(false);

                return DONATORS_LIST.start(this.crypto.data);
            }
        }

        if (!INFO_BOX.isActive)     // user clicked on "close"?
            return this.state = STATE.menu;

        // waiting for crypto data to be read
        for (i = -1, l = Math.floor((BK_ENGINE.timer.now - this.timer) / 500) % 10; i < l; i++)
            content += "*";

        INFO_BOX.updateContent(content);
    }

    update_donatorsList() {
        if (!DONATORS_LIST.isActive) {
            this.state = STATE.menu;

            this.show(true);
        }
    }

    update_selectMethod() {
        if (this.state != STATE.selectMethod) {
            this.state = STATE.selectMethod;
            
            CONTROL_BOX.start("", "", ["prev", "close"]);

            return this.refresh();
        }

        switch (BK_ENGINE.clicked.name) {
            case "main_donations_selectMethod_btnCrypto":
                return this.update_crypto();
            case "main_donations_selectMethod_btnPayPal":
                this.form.submit();
                return this.end();
            case "main_donations_selectMethod_btnInfo":
                return HELP.openWindow(GW_MAIN.file.filesListLang.getCaption("help_donations").trim());
        }

        if (CONTROL_BOX.selected == "prev")
            return this.update_menu();

        if (CONTROL_BOX.selected == "abandon")
            this.end();
    }

    update_crypto() {
        if (this.state != STATE.crypto) {
            this.state = STATE.crypto;

            this.fetchCrypto("tokenbalance");
            this.refresh();
        }

        if (CONTROL_BOX.selected == "prev")
            return this.update_selectMethod();

        if (CONTROL_BOX.selected == "abandon")
            return this.end();

        if (BK_ENGINE.clicked.name == "main_donations_crypto_btnAddress") {
            navigator.clipboard.writeText(CRYPTO.address.main).then(() => {
                alert(GW_MAIN.file.lang.getCaption("donations_crypto_addressCopied"));
            }).catch(() => {
                alert("Can't copy to the clipboard!");
            });
        }
    }

    show(isVisible) {
        this.item.mainFrame.isVisible = isVisible;

        if (isVisible)
            return this.refresh();

        CONTROL_BOX.end();
    }

    refresh() {
        this.item.menuFrame.isVisible = this.item.selectMethodFrame.isVisible = this.item.cryptoFrame.isVisible = false;

        switch (this.state) {
            case STATE.menu:
                this.item.menuFrame.isVisible = true;
                return CONTROL_BOX.start(GW_MAIN.file.lang.getCaption("donations_title"), "", ["close"]);
            case STATE.selectMethod:
                return this.item.selectMethodFrame.isVisible = true;
            case STATE.crypto:
                this.item.cryptoFrame.isVisible = true;
                CONTROL_BOX.start("", "", ["prev", "close"]);
        }
    }
}

const DONATIONS = BK_ENGINE.tasks.add(new Donations(BK_ENGINE.tasks));

export { DONATIONS };
