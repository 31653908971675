
import { getIdByProp } from "../bk utils/_bk utils.js"
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js"
import { GW_USER } from "./_gw user.js"


const END_POINT  = {updateData: "https://us-central1-god-s-world-d402d.cloudfunctions.net/updateData"},
      TO_ADD_MAX = 30;      // max elements in queue to be saved (when for example not signed up, yet, or offline)


class Saving extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.toUpdate     = [];     // for example: "lang", "denom"
        this.toUpdateFunc = [];     // to update through a function on the server (props: name, contact)
        this.toAdd        = [];     // for example: game progress

        this.isUpdating   = false;
    }

    get isToBeUpdated() {
        return (this.toUpdate.length > 0 || this.toUpdateFunc.length > 0 || this.toAdd.length > 0);
    }

    add(name, value) {      // call it to save information into dataBase
        switch (name) {
            case "lang":
            case "country":
            case "denom":
            case "relig":
            case "religDenom":
            case "dateFormat":
            case "auths":
                return this.add_toUpdate(name, value);
            case "name":
            case "contact":
                return this.add_toUpdateFunc(name, value);
            default:
                if (this.toAdd.length < TO_ADD_MAX)
                    this.toAdd.push({name: name, value: value});
        }
    }

    add_toUpdate(name, value) {
        let i = getIdByProp(this.toUpdate, "name", name);

        if (i == -1)
            return this.toUpdate.push({name: name, value: value});

        this.toUpdate[i].value = value;
    }

    add_toUpdateFunc(name, value) {
        let i = getIdByProp(this.toUpdateFunc, "name", name);

        if (i == -1)
            return this.toUpdateFunc.push({name: name, value: value});

        this.toUpdateFunc[i].value = value;
    }

    flushToUpdate() {       // call after user data loaded
        this.toUpdate = [];
    }

    reset() {               // call it when the user is logged out
        this.toAdd = [];

        if (this.isActive)
            this.end();
    }

    start2() {
        this.isUpdating = false;
    }

    update() {
        if (this.isUpdating)
            return;

        if (this.toUpdate.length > 0)
            return this.save_toUpdate();

        if (this.toUpdateFunc.length > 0)
            return this.save_toUpdateFunc();

        if (this.toAdd.length > 0)
            return this.save_toAdd();

        this.end();
        GW_USER.afterSaved();
    }

    save_toUpdate() {
        let obj     = this.toUpdate.shift(),
            userDoc = GW_USER.firestore.doc(GW_USER.firestore.firestore, "users", GW_USER.auth.auth.currentUser.uid, "userInfo", "info");

        switch (obj.name) {
            case "lang":
            case "country":
            case "denom":
            case "relig":
            case "religDenom":
            case "dateFormat":
            case "auths":
                this.isUpdating = true;
                GW_USER.firestore.updateDoc(userDoc, {[obj.name]: obj.value}).then(() => {
                    this.isUpdating = false;});
                break;
            // case "denom":     // put it in variable: "data", in this moment there is only 1 data
            //     GW_USER.firestore.updateDoc(userDoc, {"denom": obj.value}).then(() => {
            //         this.isUpdating = false;});
            //     break;
            // case "country":
            //     GW_USER.firestore.updateDoc(userDoc, {"country": obj.value}).then(() => {
            //         this.isUpdating = false;});
            //     break;
            default:
                console.error("save_toUpdate: property not served: " + obj.name);
        }
    }

    save_toUpdateFunc() {
        let obj = this.toUpdateFunc.shift();

        switch (obj.name) {
            case "name":
            case "contact":
                this.isUpdating = true;
                return this.save_toUpdateFunc_one(obj.name, obj.value);
            default:
                console.error("SAVING.save_toUpdateFunc: property not served: " + obj.name);
        }
    }

    async save_toUpdateFunc_one(name, value) {
        const options = {method: 'POST',
                         body:   JSON.stringify({userAddress: GW_USER.address, name: name, value: value})};

        try {
            await fetch(END_POINT.updateData, options).
                // then(() => { this.isUpdating = false; });
                then((resp) => resp.json()).
                    then((data) => {
                        this.isUpdating = false;
                        console.log(data);
                    }).catch((err) => {
                        this.isUpdating = false;
                        console.error("Error, SAVING.save_toUpdateFunc: " + err);
                    });
        } catch(error) {
            console.error("SAVING.save_toUpdateFunc_one: fetch error" + error);
        }    
    }

    save_toAdd() {
        let obj = this.toAdd.shift(),
            collection;

        switch (obj.name) {
            case "bible g0001":
            case "bible g0002":
                this.isUpdating = true;
    
                collection = GW_USER.firestore.collection(GW_USER.firestore.firestore, "users", GW_USER.auth.auth.currentUser.uid, "progress");
    
                GW_USER.firestore.addDoc(collection, {
                        time: GW_USER.firestore.serverTimestamp(),
                        data: obj.value})
                    .then(() => {
                        this.isUpdating = false;});
        }
    }
}


export const SAVING = BK_ENGINE.tasks.add(new Saving(BK_ENGINE.tasks));
