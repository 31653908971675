
import { BK_ENGINE, Task } from "../bk engine/_bk engine.js";
import { MENU_BAR } from "./menu bar.js";


const CHAPTERS_MAX = 150,           // Psalms
      PADDING      = {verNarrow: 1.31, verWide: 1.25, horNarrow: 1.2, horWide: 1.2};


class SelectBibleChapter extends Task {
    constructor(PARENT) {
        super(PARENT);

        this.redrawText   = null;
        this.item         = null;
        this.redrawsLevel = [];
        this.colsMax      = 5;
        this.rowsMax      = 0;
        this.itemsPerPage = 0;
        this.pageI        = 0;
        this.book         = null;
        this.isInitiated  = false;
        this.selected     = 0;
    }

    init() {
        let ITEMS    = BK_ENGINE.items,
            REDRAWS  = BK_ENGINE.redraws,
            chapterI = 1,
            col      = 0,
            row      = 0;

        this.rowsMax      = BK_ENGINE.viewPort.orientation == "verNarrow" ? 8 : 10;
        this.itemsPerPage = this.colsMax * this.rowsMax;

        // redraws
        this.redrawsLevel.push(REDRAWS.getByName("bible_menuSelectChapter_btnBgd0"));
        this.redrawsLevel.push(REDRAWS.getByName("bible_menuSelectChapter_btnBgd1"));
        this.redrawsLevel.push(REDRAWS.getByName("bible_menuSelectChapter_btnBgd2"));
        this.redrawsLevel.push(REDRAWS.getByName("bible_menuSelectChapter_btnBgd3"));
        
        // items
        this.redrawText = BK_ENGINE.redraws.getByName("bible_menuSelectChapter_btnTxt");

        this.item = {mainFrame: ITEMS.getByName("bible_upCenter"),
                     menuBox:   ITEMS.getByName("bible_menuBox"),
                     frame:     ITEMS.getByName("bible_menuSelectChapter"),
                     btn1:      ITEMS.getByName("bible_menuSelectChapter_item"),
                     btns:      []};      // buttons with chapter numbers

            // buttons
        this.item.btns = [];

        do {
            this.init_addBtn(col, row, chapterI++);
            
            if (++col == this.colsMax) {
                col = 0;

                if (++row == this.rowsMax)
                    row = 0;
            }
        } while (chapterI <= CHAPTERS_MAX);

        ITEMS.remove(this.item.btn1);

        this.isInitiated = true;
    }

    init_addBtn(col, row, chapterI) {
        let btn     = BK_ENGINE.items.clone(this.item.btn1),
            padding = PADDING[BK_ENGINE.viewPort.orientation];

        btn.pivX    = col * padding;
        btn.pivY    = row * padding;
        btn.info    = chapterI;
        btn.caption = "" + chapterI;

        this.item.btns.push(btn);
    }

    start2(book) {
        !this.isInitiated && this.init();

        this.book  = book;
        this.pageI = 0;

        MENU_BAR.reset("selectChapter");
        this.show(true);
    }

    end2(selected) {
        this.selected = selected;

        this.show(false);
    }

    update() {
        let btn = BK_ENGINE.clicked;

        if (btn.name == "bible_menuSelectChapter_item")
            return this.end(btn.info);

        switch (MENU_BAR.selected) {
            case "prev":
                return this.turnPage(-1);
            case "next":
                return this.turnPage(1);
            case "quit":
                this.end(0);
        }
    }

    turnPage(value) {
        let pagesNm = Math.floor(Math.max(0, this.book.chaptersNm - 1) / this.itemsPerPage);

        this.pageI += value;
        this.pageI = Math.max(0, Math.min(pagesNm, this.pageI));

        this.refresh();
    }

    show(isVisible) {
        this.item.mainFrame = this.item.menuBox.isVisible = this.item.frame.isVisible = isVisible;

        if (isVisible) {
            this.show_updateBtns();
            this.refresh();
        }
    }

    show_updateBtns() {
        let i, l, btn, level;

        for (i = 1, l = this.book.chaptersNm; i < l; i++) {
            btn   = this.item.btns[i - 1];
            level = this.book.chapters[i];

            btn.resetRedraws();
            btn.addRedraws(this.redrawsLevel[level], this.redrawText);

            btn.isRedrawn = false;
        }
    }

    refresh() {
        let chaptersNm = this.book.chaptersNm - 1,
            i, l, item;

        // show chapter buttons
        for (i = 0; i < chaptersNm; i++) {
            item           = this.item.btns[i];
            item.isVisible = (Math.floor(i / this.itemsPerPage) == this.pageI);
        }

        // hide the rest
        for (l = this.item.btns.length; i < l; i++) {
            item           = this.item.btns[i];
            item.isVisible = false;
        }

        // update the arrows and info
        i = this.pageI + 1;
        l = Math.floor(Math.max(0, chaptersNm - 1) / this.itemsPerPage) + 1;

        MENU_BAR.isPrevEnabled = i > 1;
        MENU_BAR.isNextEnabled = i < l;
        MENU_BAR.setItemCaption("info", "" + i + " / " + l);
    }
}

export const SELECT_BIBLE_CHAPTER = BK_ENGINE.tasks.add(new SelectBibleChapter(BK_ENGINE.tasks));
