
import { BK_ENGINE, Coords } from "../../../../../bk engine/_bk engine.js";
import { PLAY_LOGICS as LOGICS } from "../logics/_bible g0002 play logics.js";
import { DISPLAY_PLAYFIELD, DISPLAY_PLAYFIELD as PLAYFIELD } from "./playfield/_display playfield.js";
import { DISPLAY_QUOTE as QUOTE } from "./display quote.js";
import { PLAY_DISPLAY as DISPLAY, SHADOW } from "./_bible g0002 play display.js";
import { PLAY } from "../_bible g0002 play.js";


let GAP_CHAR      = 0.05,   // gap between 2 chars
    SHADOW_OFFSET = 1,
    BGD_TRANSP    = {multiply: 0.3, speed: 0.005, fadeOutStart: 0.7};


class Letter {
    constructor() {
        this.char   = "";
        this.charI  = 0;
        this.brick  = null;
        this.transp = 0;        // for 3 bgd sprites
    }
}


class DisplayLetters {
    constructor() {
        this.text         = null;
        this.canvas       = null;
        this.coords       = {tmp:          new Coords(),
                             letterBgdSrc: new Coords(),
                             letterBgdDst: new Coords()};
        this.shadowOffset = 0;
        this.txtHeight    = 0;

        this.allCharsUsed = "";
        this.quoteWord    = null;
        this.logicsWord   = null;
        this.activeNm     = 0;
        this.all          = [];
        this.charsWidth   = [];

        this.isInitiated  = false;
    }

    getCharI(char) {
        let i = this.allCharsUsed.indexOf(char);

        return (i == -1) ? 0 : i;
    }

    getLetterCoords(letterI, progress) {     // between playfield and letterQuote
        let letter      = this.all[letterI],
            letterQuote = this.quoteWord.letters[letterI],
            s           = PLAYFIELD.tileSize;

        let leftSrc = PLAYFIELD.coords.main.left + letter.brick.col * s + Math.round((s - this.charsWidth[letter.charI]) / 2),
            topSrc  = PLAYFIELD.coords.main.top  + letter.brick.row * s,
            leftDst = letterQuote.coords.left,
            topDst  = letterQuote.coords.top - Math.floor(s / 2);

        return new Coords(Math.floor(leftSrc + (leftDst - leftSrc) * progress),
                          Math.floor(topSrc  + (topDst  - topSrc)  * progress),
                          s,
                          s);
    }

    init() {
        let CANVASES = BK_ENGINE.canvases,
            i;

        this.canvas = {allChars:        CANVASES.addNew(),
                       letterBgd:       CANVASES.addNew(),
                       letterBgdShadow: CANVASES.addNew()};
        this.text   = BK_ENGINE.texts.getByName("bible_g0002_play_letter");
        this.nmMax  = Math.pow(PLAYFIELD.size - 2, 2) - 1;

        for (i = 0; i < this.nmMax; i++)
            this.all.push(new Letter());

        this.isResized   = false;
        this.isInitiated = true;
    }

    start() {
        let i, char;

        !this.isInitiated && this.init();

        this.isResized    = false;
        this.allCharsUsed = LOGICS.alphabet + LOGICS.alphabet.toUpperCase();

        for (i = 0; i < LOGICS.allCharsUsed.length; i++) {      // add some "not a letter" characters
            char = LOGICS.allCharsUsed.charAt(i);

            if (!this.allCharsUsed.includes(char))
                this.allCharsUsed += char;
        }
    }

    reset() {
        let letterI, logicLetter, letter;

        this.logicsWord = LOGICS.words[LOGICS.wordI];
        this.quoteWord  = QUOTE.words[LOGICS.wordI];

        this.activeNm = this.logicsWord.letters.length;

        for (letterI = 0; letterI < this.activeNm; letterI++) {
            logicLetter = this.logicsWord.letters[letterI];
            letter      = this.all[letterI];

            letter.char   = logicLetter.char;
            letter.charI  = this.getCharI(letter.char);
            letter.brick  = logicLetter.brick;
            letter.transp = Math.random() * Math.PI * 2;
        }
    }

    update() {
        if (!this.isResized && PLAYFIELD.tileSize > 0)
            this.resize();

        this.refresh();
    }

    resize() {
        let s = PLAYFIELD.tileSize;

        this.coords.letterBgdSrc.resize(s, s);
        this.coords.letterBgdDst.resize(s, s);
        this.coords.tmp.resize(s, s);

        this.canvas.allChars.resize(this.allCharsUsed.length * s, s);
        this.canvas.letterBgd.resize(s * DISPLAY.image.letterBgd.cols, s);      // letter background's sprites
        this.canvas.letterBgdShadow.resize(s, s);

        this.shadowOffset = Math.round(s * SHADOW.size * SHADOW_OFFSET);

        this.redraw();

        this.isResized = true;
    }

    redraw() {
        this.redraw_letters();
        this.redraw_lettersBgd();
    }

    redraw_letters() {
        let s     = PLAYFIELD.tileSize;
        let sHalf = Math.round(s / 2),
            i, l, char;

        this.canvas.allChars.clear();

        this.charsWidth = [];
        this.txtHeight  = this.text.calcHeight(s);

        this.text.prepare(this.canvas.allChars);

        for (i = 0, l = this.allCharsUsed.length; i < l; i++) {
            char = this.allCharsUsed.charAt(i);

            this.charsWidth.push(Math.ceil(this.text.getWidth(this.canvas.allChars, char)));
            this.text.draw(this.canvas.allChars, {left: i * s, top: sHalf}, char);

            if (i > 0)
                this.canvas.allChars.clearRect({left: i * s - 3, top: 0, width: 3, height: s});   // clear the last part because some fonts draws the chars in "box" before (i.e.: font Arial, char "i")
        }
    }

    redraw_lettersBgd() {
        let img = DISPLAY.image.letterBgd,
            s   = this.coords.letterBgdSrc.width,
            i;

        for (i = 0; i < img.cols; i++)
            BK_ENGINE.canvases.drawImageQuality(img, this.canvas.letterBgd, {left: i * s, top: 0, width: s, height: s}, i);

        this.canvas.letterBgdShadow.drawImage(this.canvas.letterBgd, {left:     0, top: 0, width: s, height: s}, {left: 0, top: 0, width: s, height: s});
        this.canvas.letterBgdShadow.drawImage(this.canvas.letterBgd, {left:     s, top: 0, width: s, height: s}, {left: 0, top: 0, width: s, height: s});
        this.canvas.letterBgdShadow.drawImage(this.canvas.letterBgd, {left: s * 2, top: 0, width: s, height: s}, {left: 0, top: 0, width: s, height: s});
        this.canvas.letterBgdShadow.makeShadow(SHADOW.transp);
    }

    refresh() {
        let timeDelta = BGD_TRANSP.speed * DISPLAY.timer.delta,
            letterI, letter, progress;
    
        for (letterI = 0; letterI < this.activeNm; letterI++) {
            letter   = this.all[letterI];
            progress = this.logicsWord.letters[letterI].progress;
    
            if (letter.char == " ")     // don't show the rest
                return;
    
            if (progress == 0 || progress == 1)
                this.refresh_stillLetter(letter.charI, this.getLetterCoords(letterI, progress));
            else {
                this.refresh_movingLetter(letter.charI, this.getLetterCoords(letterI, progress), progress, letter.transp);

                letter.transp += timeDelta;
            }
        }
    }

    refresh_stillLetter(charI, coords) {    // can be called from outside
        DISPLAY.canvas.pgd.drawImage(this.canvas.allChars,
                                     {left: charI * PLAYFIELD.tileSize, top: 0, width: PLAYFIELD.tileSize, height: PLAYFIELD.tileSize},
                                     coords);
    }

    refresh_movingLetter(charI, coords, progress, transp) {       // can be called from outside
        let s             = PLAYFIELD.tileSize,
            bgdCenter     = Math.floor(this.coords.letterBgdSrc.width / 2),
            transpStep    = Math.PI * 2 / DISPLAY.image.letterBgd.cols,
            transpFadeOut = progress > BGD_TRANSP.fadeOutStart ? (1 - progress) / (1 - BGD_TRANSP.fadeOutStart) : 1;

        // shadow
        DISPLAY.canvas.pgd.saveTransp();
        DISPLAY.canvas.pgd.setTransp(transpFadeOut);
        DISPLAY.canvas.pgd.drawImage(this.canvas.letterBgdShadow, {left: 0, top: 0, width: this.coords.letterBgdSrc.width, height: this.coords.letterBgdSrc.height}, {left: coords.left - bgdCenter + Math.floor(this.charsWidth[charI] / 2) + this.shadowOffset, top: coords.top + this.shadowOffset, width: this.coords.letterBgdSrc.width, height: this.coords.letterBgdSrc.height});
        DISPLAY.canvas.pgd.restoreTransp();

        // bgd sprites
        this.coords.letterBgdDst.left = coords.left - bgdCenter + Math.floor(this.charsWidth[charI] / 2);
        this.coords.letterBgdDst.top  = coords.top;

        DISPLAY.canvas.pgd.saveTransp();
        this.refresh_bgd(0, (Math.sin(transp)              + 1) * BGD_TRANSP.multiply * transpFadeOut, this.coords.letterBgdDst);
        this.refresh_bgd(1, (Math.sin(transp + transpStep) + 1) * BGD_TRANSP.multiply * transpFadeOut, this.coords.letterBgdDst);
        this.refresh_bgd(2, (Math.sin(transp - transpStep) + 1) * BGD_TRANSP.multiply * transpFadeOut, this.coords.letterBgdDst);
        DISPLAY.canvas.pgd.restoreTransp();

        // letter
        DISPLAY.canvas.pgd.drawImage(this.canvas.allChars, {left: charI * s, top: 0, width: s, height: s}, coords);
    }

    refresh_bgd(spriteI, transp, coords) {
        DISPLAY.canvas.pgd.setTransp(transp);
        DISPLAY.canvas.pgd.drawImage(this.canvas.letterBgd, {left: spriteI * this.coords.letterBgdSrc.width, top: 0, width: this.coords.letterBgdSrc.width, height: this.coords.letterBgdSrc.height}, coords);
    }
}

export const DISPLAY_LETTERS = new DisplayLetters();
